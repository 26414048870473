<script lang="ts" setup>
import { onClickOutside } from '@vueuse/core';
import {
  disableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks,
} from 'body-scroll-lock';
import { onMounted, onUnmounted, ref, watch } from 'vue';

import HeaderLogo from '@/components/Header/HeaderLogo.vue';

type Props = {
  isActive: boolean;
  isDark?: boolean;
  position?: 'left' | 'right';
  showLogo?: boolean;
  isDesktop?: boolean;
  fixedWidth?: boolean;
};

type Emits = {
  'on-close': [];
};

const props = withDefaults(defineProps<Props>(), {
  isDark: false,
  position: 'right',
  showLogo: false,
  isDesktop: false,
});
const emit = defineEmits<Emits>();

const drawerRef = ref<HTMLElement | null>(null);
const headerLogo = ref<Element | null>(null);

const toggleBodyScrollLock = (value: boolean) => {
  if (value) {
    disableBodyScroll(drawerRef.value);
  } else {
    enableBodyScroll(drawerRef.value);
  }
};

const logoHeight = ref<`${number}px`>('0px');

const calculateLogoHeight = () => {
  logoHeight.value = `${headerLogo.value?.clientHeight ?? 0}px`;
};

watch(
  () => props.isActive,
  (isActive) => {
    toggleBodyScrollLock(isActive);
  },
);

onMounted(() => {
  toggleBodyScrollLock(props.isActive);
  calculateLogoHeight();
});

onUnmounted(() => {
  clearAllBodyScrollLocks();
});

onClickOutside(drawerRef, () => {
  if (!props.isActive) return;

  emit('on-close');
});
</script>

<template>
  <div
    class="h-drawer-overlay"
    :class="{
      'h-drawer-overlay--active': isActive,
    }"
  />

  <div
    id="h-drawer"
    ref="drawerRef"
    class="h-drawer"
    :class="{
      'h-drawer--dark': isDark,
      'h-drawer--active': isActive,
      'h-drawer--left': position === 'left',
      'h-drawer--right': position === 'right',
      'h-drawer--desktop': isDesktop,
      'h-drawer--fixed-width': fixedWidth,
    }"
  >
    <button
      v-if="isActive"
      data-qa="hdrawer-close-button"
      class="h-drawer__close"
      :class="{
        'h-drawer__close--left': position === 'right',
        'h-drawer__close--right': position === 'left',
      }"
      @click="emit('on-close')"
    >
      <HpIcon :color="isDark ? 'light' : 'primary'" icon="icon-cross" />
    </button>

    <div
      class="h-drawer__content"
      :class="{
        'h-drawer__content--with-logo': showLogo,
      }"
    >
      <div v-if="showLogo" ref="headerLogo">
        <HeaderLogo
          class="h-drawer__logo"
          force-desktop-logo
          is-logo-link-disabled
        />
      </div>
      <slot />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.h-drawer {
  --drawer-close-button-offset: 56px;

  flex-direction: column;
  height: 100%;
  transition: transform 0.5s cubic-bezier(0.32, 0.72, 0, 1);
  position: fixed;
  z-index: var(--z-index-10);
  background-color: var(--white);
  top: 0;
  width: calc(100% - var(--drawer-close-button-offset));
  pointer-events: none;

  &--fixed-width {
    width: 296px;
  }

  &--desktop {
    @media (min-width: 570px) {
      width: 503px;
    }
  }

  &--active {
    pointer-events: auto;
    opacity: 1;
    transform: translateX(0) !important;
  }

  &--left {
    transform: translateX(-100%);
  }

  &--right {
    right: 0;
    transform: translateX(100%);
  }

  &--dark {
    background-color: var(--dark);
  }

  &__close {
    position: absolute;
    top: 12px;
    border: none;
    background: none;
    background: white;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;

    &--left {
      left: -44px;
    }

    &--right {
      right: -44px;
    }
  }

  &__content {
    height: 100%;

    &--with-logo {
      height: calc(100% - v-bind(logoHeight));
    }
  }

  &__logo {
    padding: 20px 32px;
    border-bottom: 1px solid;
    border-color: var(--general-border-color);
  }
}

.h-drawer-overlay {
  transition: opacity 0.1s ease-in-out;
  position: fixed;
  display: block;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  background: rgba(0, 0, 0, 0.6);
  opacity: 0;
  pointer-events: none;
  z-index: calc(var(--z-index-10) - 1);

  &--active {
    opacity: 1;
    pointer-events: auto;
  }
}
</style>
