import { createApp } from 'vue';

import '@hostinger/hpanel-components/dist/style.css';
import '@hostinger/hpanel-components/dist/scss/main.scss';
import './sass/typography-text.scss';
import '@hostinger/hcomponents/style.css';
import App from './App.vue';
import { setGlobalComponents } from './components';
import { setDirectives } from './directives';
import { setGlobals } from './globals';
import { setMixins } from './mixins';
import { loadPlugins } from './plugins';
import { initializeErrorLogger } from './utils/services/errorLogging';

import { initAllPlugins } from '@/plugins/index';
import { setRouter } from '@/router';
import { routerWithRoutes } from '@/router/routerWithRoutes';
import store from '@/store';
import { initCurrenciesConvertRates } from '@/utils/helpers/currencyHelpers';
import {
  errorBoundaryHandler,
  ERROR_HANDLER_VARIANT,
} from '@/utils/helpers/errorBoundaryHelpers';
import './sass/components/_tooltip.scss';
import './sass/components/_code.scss';
import './sass/main.scss';
import { $t } from '@/utils/services/i18nService';

window.$t = $t;

(window as any).updatePusherToken = (token: string) => {
  const pusher = store.state.services.pusher;
  if (pusher) {
    pusher.port.postMessage({
      event: 'token-change',
      clientToken: token,
    });
  }
};

/**
 * Setting the router to the global context from main as to prevent circular dependency injection
 */
setRouter(routerWithRoutes);
(window as any).appStarting = true;
const app = createApp(App);

initializeErrorLogger(app, routerWithRoutes);
const pluginContext = { app, router: routerWithRoutes, store };
initAllPlugins(pluginContext);
initCurrenciesConvertRates();

loadPlugins(app);
setGlobals(app);
setGlobalComponents(app);
setMixins(app);
setDirectives(app);

app.config.errorHandler = (error, instance, info) => {
  errorBoundaryHandler({
    error: error as { message: string },
    instance,
    info,
    variant: ERROR_HANDLER_VARIANT.MAIN,
  });
};

app.mount('#app');
