import type { HColor, HIconUnion } from '@hostinger/hcomponents';

import type { MigrationStatus } from '@/types';

export interface IMigration {
  id: string;
  panel: string;
  adminLink: string | null;
  backupLink: string | null;
  panelLink: string | null;
  domain: string;
  info: string | null;
  status: MigrationStatus;
  reason: string | null;
  createdAt: string;
  updatedAt: string;
  finishedAt: string | null;
}

export interface IMigrationDomainVHost {
  cdnBypassCache: string | null;
  cdnEnabled: '0' | '1';
  createdAt: string;
  domainOwner: '0' | '1';
  enabled: '0' | '1';
  hasDomainAccess: '0' | '1';
  orderId: string;
  rootdir: string;
  username: string;
  vhost: string;
  vhostType: string;
}

export interface IDisplayedMigration extends IMigration {
  isLoading?: boolean;
}

export interface IVerifyWpMigrationCredentials {
  adminUrl: string;
  wpUsername: string;
  wpPassword: string;
}

export interface IVerifyCpanelMigrationCredentials {
  panelUrl: string;
  panelUsername: string;
  panelPassword: string;
}

export interface IGenericMigrationCredentials {
  username: string;
  password: string;
  loginUrl: string;
}

export interface IRequestMigration {
  id?: string;
  domain: string;
  onboardingId?: number;
  provider?: string;
  overwrite?: number;
  panel?: string;
  panelLink?: string;
  adminLink?: string;
  info?: string;
  config: {
    username?: string;
    password?: string;
    wpUsername?: string;
    wpPassword?: string;
  };
  status?: string;
}

export interface IMigrationCapsule {
  props: {
    text: string;
    fullText: string;
    iconColor: HColor;
    icon: HIconUnion;
    isCapsuleCloseVisible: boolean;
    actionText?: string;
  };
  action: () => void;
  closeAction: () => void;
}

export const MIGRATION_CONNECTION_STATUS = {
  WORDPRESS_CREDENTIALS_ERROR: 'wp_credentials_error',
  LOGIN_SUCCESS: 'login_success',
  CANNOT_SUBMIT_DETAILS: 'cannot_submit_details',
  INCORRECT_LOGIN_DETAILS: 'incorrect_login_details',
  ADMIN_PANEL_UNREACHABLE: 'admin_panel_unreachable',
  CANNOT_MIGRATE_WORDPRESSCOM: 'cannot_migrate_wordpresscom',
  DOMAIN_IS_POINTING: 'domain_is_pointing',
  WEBSITE_BUILDER: 'website_builder',
  PLUGIN_PREPARATION_FAILURE: 'plugin_preparation_failure',
  ADMIN_REQUIRED: 'admin_required',
  DETECTED_2FA: '2fa_detected',
  TIMEOUT: 'timeout',
} as const;

export type MigrationConnectionStatus =
  (typeof MIGRATION_CONNECTION_STATUS)[keyof typeof MIGRATION_CONNECTION_STATUS];
