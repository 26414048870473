import { computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';

import { useGlobals } from '@/composables';
import { MONARX_SUPPORTED_OS } from '@/data/hVps';
import {
  useVpsOnboardingStore,
  useVpsResourceStore,
  useVpsServerStore,
  useVpsTemplateStore,
} from '@/stores';
import type { ServerOS } from '@/types';
import { AmplitudeEvent, HVps, Route } from '@/types';
import { mapKeyValue } from '@/utils/helpers';

export const useVpsOnboarding = () => {
  const router = useRouter();
  const route = useRoute();

  const onboardingStore = useVpsOnboardingStore();
  const resourceStore = useVpsResourceStore();
  const templateStore = useVpsTemplateStore();
  const serverStore = useVpsServerStore();

  const { amplitudeV2 } = useGlobals();

  const orderId = computed(() => route.params.order_id as string);

  const vpsOrderType = computed(() => resourceStore.getVpsType(orderId.value));

  const templateTypes = computed((): ServerOS[] =>
    templateStore.getTemplatesByType(onboardingStore.currentOnboarding.osType),
  );

  const isMinecraftOnboarding = computed(
    () => vpsOrderType.value === HVps.OrderType.MINECRAFT,
  );

  const minecraftTemplateTypes = computed(() => {
    const ALLOWED_MINECRAFT_TEMPLATE_IDS = [251, 1120];

    return templateTypes.value.filter(({ id }) =>
      ALLOWED_MINECRAFT_TEMPLATE_IDS.includes(id),
    );
  });

  const cyberpanelTemplateTypes = computed(() =>
    templateTypes.value.filter(({ id }) => id === HVps.OsTemplates.LITESPEED),
  );

  const directadminTemplateTypes = computed(() =>
    templateTypes.value.filter(({ id }) => id === HVps.OsTemplates.DIRECTADMIN),
  );

  const recommendedTemplates = computed((): ServerOS[] => {
    const recommendedTemplateMap = {
      [HVps.OrderType.MINECRAFT]: minecraftTemplateTypes.value,
      [HVps.OrderType.CYBERPANEL]: cyberpanelTemplateTypes.value,
      [HVps.OrderType.DIRECTADMIN]: directadminTemplateTypes.value,
      default: templateTypes.value.filter(({ isRecommended }) => isRecommended),
    };

    return mapKeyValue(recommendedTemplateMap, vpsOrderType.value);
  });

  const otherTemplates = computed(() => {
    if (HVps.OrderTypes.includes(vpsOrderType.value)) {
      return [];
    }

    return templateTypes.value.filter(({ isRecommended }) => !isRecommended);
  });

  const shouldSkipOSSelectionStep = computed(
    () =>
      recommendedTemplates.value.length === 1 && !otherTemplates.value.length,
  );

  const hasOtherTemplates = computed(() => otherTemplates.value.length > 0);

  const getVmId = (orderId: string) => {
    const vm = serverStore.servers.find(
      (vm) =>
        [HVps.ServerState.INITIAL, HVps.ServerState.CREATING].includes(
          vm.state,
        ) && vm.metadata?.orderId === orderId,
    );

    if (!vm) return;

    return vm.id;
  };

  const isMonarxSupported = (selectedOS: ServerOS | null | undefined) =>
    MONARX_SUPPORTED_OS.some((os) => selectedOS?.label?.includes(os));

  const goToAdditionalFeatures = (selectedOS: ServerOS | null) => {
    if (!selectedOS) return;

    onboardingStore.setState('template', selectedOS);
    onboardingStore.setState('osPassword', '');

    amplitudeV2(AmplitudeEvent.Vps.ONBOARDING_OS_CHOSEN, {
      osChosen: selectedOS.label,
    });

    if (!isMonarxSupported(selectedOS)) {
      goToCredentialsStep();

      return;
    }

    router.push({
      name: Route.VpsOnboarding.SERVER_ONBOARDING_ADDITIONAL_FEATURES,
      query: route.query,
    });
  };
  const goToCredentialsStep = () => {
    const selectedOS = onboardingStore.currentOnboarding.template;

    if (selectedOS?.isPanelPassResetSupported) {
      router.push({
        name: Route.VpsOnboarding.SERVER_ONBOARDING_CREATE_OS_PASSWORD,
        query: route.query,
      });

      return;
    }

    router.push({ name: Route.VpsOnboarding.SERVER_ONBOARDING_CREDENTIALS });
  };

  const selectFirstOSAndGoToCredentialsStep = () => {
    const firstRecommendedTemplate = recommendedTemplates.value[0];

    onboardingStore.setState('template', firstRecommendedTemplate);

    const hostname =
      HVps.ServerHost.PREFIX +
      onboardingStore.currentOnboarding.vmId +
      HVps.ServerHost.SUFFIX;
    onboardingStore.setState('hostname', hostname);

    amplitudeV2(AmplitudeEvent.Vps.ONBOARDING_OS_CHOSEN, {
      osChosen: firstRecommendedTemplate.label,
    });

    goToCredentialsStep();
  };

  return {
    vpsOrderType,
    templateTypes,
    recommendedTemplates,
    minecraftTemplateTypes,
    cyberpanelTemplateTypes,
    directadminTemplateTypes,
    otherTemplates,
    shouldSkipOSSelectionStep,
    hasOtherTemplates,
    isMinecraftOnboarding,
    goToAdditionalFeatures,
    goToCredentialsStep,
    selectFirstOSAndGoToCredentialsStep,
    isMonarxSupported,
    getVmId,
  };
};
