import type { IHDomainResource } from '@hostinger/hdomains-status';
import { useRouter } from 'vue-router';

import { useManagedDomains } from '@/composables/hostingerPro/useManagedDomains';
import { useAccessList } from '@/composables/useAccessList';
import { useImpersonation } from '@/composables/useImpersonation';
import { useHDomainResourceStore } from '@/stores/domain/hDomainResourceStore';
import { Route } from '@/types';
import { handleDomainResourceSetup } from '@/utils/services/domains/hDomains';

export const useDomainManagementRedirect = () => {
  const router = useRouter();
  const { enterImpersonationMode } = useImpersonation();
  const { getAccessIdByClientId } = useAccessList();
  const { getIsManagedDomain } = useManagedDomains();
  const { getHDomainResourceByDomain, fetchHDomainsResources } =
    useHDomainResourceStore();

  const navigateToManagedDomain = async ({
    hDomainResource,
    domain,
    subscriptionId,
  }: {
    hDomainResource?: IHDomainResource;
    domain?: string;
    subscriptionId?: string;
  }) => {
    const resourceDomain = domain || '';

    let resource =
      hDomainResource || getHDomainResourceByDomain(resourceDomain);

    if (!resource) {
      await fetchHDomainsResources();

      resource = getHDomainResourceByDomain(resourceDomain);
    }

    if (!resource) {
      router.push({
        name: Route.Domain.MY_DOMAINS,
      });

      return;
    }

    if (getIsManagedDomain(resource)) {
      await enterDomainImpersonationMode(
        resource,
        Route.Domain.DOMAIN_MANAGEMENT,
      );

      return;
    }

    router.push({
      name: Route.Domain.DOMAIN_MANAGEMENT,
      params: {
        domain: resource.title,
        subscriptionId,
      },
    });
  };

  const navigateToDnsManagement = (hDomainResource: IHDomainResource) => {
    if (getIsManagedDomain(hDomainResource)) {
      enterDomainImpersonationMode(hDomainResource, Route.Domain.DOMAIN_DNS);

      return;
    }

    router.replace({
      name: Route.Domain.DOMAIN_DNS,
      params: {
        domain: hDomainResource.title,
      },
    });
  };

  const enterDomainImpersonationMode = async (
    hDomainResource: IHDomainResource,
    routeName: Route.Domain,
  ) => {
    await enterImpersonationMode({
      accessId: getAccessIdByClientId(hDomainResource.customerCustomId),
      queryObject: {
        name: routeName,
        redirectParams: {
          name: Route.Domain.MY_DOMAINS,
        },
        params: { domain: hDomainResource.title },
      },
    });
  };

  const navigateToChangeContactDetails = (
    hDomainResource: IHDomainResource,
  ) => {
    if (getIsManagedDomain(hDomainResource)) {
      enterDomainImpersonationMode(
        hDomainResource,
        Route.Domain.DOMAIN_SETTINGS,
      );

      return;
    }

    router.replace({
      name: Route.Domain.DOMAIN_SETTINGS,
      params: {
        domain: hDomainResource.title,
      },
    });
  };

  const navigateToDomainSetup = async (hDomainResource: IHDomainResource) => {
    if (getIsManagedDomain(hDomainResource)) {
      await enterDomainImpersonationMode(
        hDomainResource,
        Route.Domain.MY_DOMAINS,
      );
    }

    await handleDomainResourceSetup(hDomainResource);
  };

  return {
    navigateToManagedDomain,
    navigateToDnsManagement,
    navigateToChangeContactDetails,
    navigateToDomainSetup,
  };
};
