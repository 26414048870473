import type { NavigationGuard } from 'vue-router';

import { useFeatureFlag } from '@/composables/useFeatureFlag';
import { Route, FeatureFlag } from '@/types';

const autoRefundGuard: NavigationGuard = (to, from, next) => {
  const { isFeatureEnabled } = useFeatureFlag(FeatureFlag.ID.REFUNDS_FLOW_V2);

  if (isFeatureEnabled.value) {
    return next({
      name: Route.HRefunds.AUTO_REFUND_V2,
      params: { ...to.params },
      query: { ...to.query },
    });
  }

  return next();
};

export default autoRefundGuard;
