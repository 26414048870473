import { defineStore } from 'pinia';
import { ref, computed } from 'vue';
import { useRouter } from 'vue-router';

import wordpressRepo from '@/repositories/wordpressRepo';
import type {
  CMSDetails,
  WordPressPreset,
  WordpressPluginSelection,
} from '@/types';
import { Route } from '@/types';
import { getParsedJwtToken } from '@/utils/helpers/authHelpers';
import { getOnboardingAccountStatus } from '@/utils/services/onboarding';

export const useHostingSetupStore = defineStore(
  'hostingSetupStoreV2',
  () => {
    const router = useRouter();
    const selectedCms = ref<CMSDetails>({ email: '', password: '' });
    const selectedWebsiteType = ref('');
    const selectedDomain = ref<string>('');
    const setupStatus = ref('');
    const proTxtToVerify = ref('');
    const builderResourceId = ref();
    const agenciesWordpressPresets = ref<WordPressPreset[]>([]);
    const selectedWordPressPreset = ref<WordPressPreset | null>(null);
    const selectedWordPressPlugins = ref<WordpressPluginSelection[]>([]);
    const selectedWordPressTheme = ref('');
    const isActivePresetsExperiment = ref(false); // temporary, will be here till experiment is done: https://hostingers.atlassian.net/browse/WP-589

    const selectedWordPressPluginsFlat = computed(() =>
      selectedWordPressPlugins.value
        ?.filter((plugin) => Boolean(plugin.slug))
        .map((plugin) => plugin.slug as string),
    );

    const getOnboardingStatus = async (orderId: string) => {
      const [{ data }, err] = await getOnboardingAccountStatus(
        orderId,
        selectedDomain.value,
      );

      if (err || data === 0) {
        router.push({ name: Route.Base.HOME });

        return [data, err];
      }
      builderResourceId.value = data.websiteBuilderResourceId;
      setupStatus.value = data.status;

      return [data, err];
    };

    const getAgenciesWordpressPresets = async () => {
      const managerClientId =
        getParsedJwtToken()?.access?.managerClientId || '';

      const [{ data }, err] = await wordpressRepo.getManagerWordpressCollection(
        managerClientId,
      );

      if (!err) {
        agenciesWordpressPresets.value = data;
      }

      return [data, err];
    };

    const resetStore = () => {
      selectedCms.value = { email: '', password: '' };
      selectedWebsiteType.value = '';
      selectedDomain.value = '';
      setupStatus.value = '';
      proTxtToVerify.value = '';
      selectedWordPressPlugins.value = [];
      selectedWordPressTheme.value = '';
    };

    const clearCms = () => {
      selectedCms.value = { email: '', password: '' };
    };

    return {
      resetStore,
      clearCms,
      selectedCms,
      proTxtToVerify,
      getOnboardingStatus,
      setupStatus,
      selectedWebsiteType,
      builderResourceId,
      selectedDomain,
      selectedWordPressPreset,
      selectedWordPressPlugins,
      selectedWordPressTheme,
      agenciesWordpressPresets,
      getAgenciesWordpressPresets,
      isActivePresetsExperiment,
      selectedWordPressPluginsFlat,
    };
  },
  {
    persist: { key: 'hosting-setup-store' },
  },
);
