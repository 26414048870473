import { isEqual, uniqBy } from 'lodash';
import { defineStore } from 'pinia';
import { ref } from 'vue';

import { hDomainsRepo } from '@/repositories';
import store from '@/store/index';
import type {
  IDomainData,
  IHDomain,
  IDnsSecRequirements,
  IDnsSecRequirement,
  IHDomainsDNSSECDetails,
  IHDomainTransfer,
} from '@/types';
import { DOMAIN_VERIFICATION_STATUS } from '@/types';
import { toASCII, toTitleCase } from '@/utils/helpers';
import { snakeToCamel } from '@/utils/services/namingConventionsService';

export const useDomainManagementStore = defineStore(
  'domainManagementStore',
  () => {
    const domainsData = ref<IDomainData[]>([]);
    const domainsTransferData = ref<IHDomainTransfer[]>([]);
    const dnsSecRequirements = ref<IDnsSecRequirements | null>(null);
    const isDomainsLoadingCompleted = ref(false);
    const isNewDomainOverviewBannerShown = ref(true);
    const domainTransferDisabledRetryDic = ref<{ [key: number]: number }>({});

    const mapDomainData = (data: IHDomain): IDomainData => {
      const expandedDetails = data.expandedDetails || {};

      const lockExpirationDate = data['60DaysLockExpiresAt']
        ? new Date(data['60DaysLockExpiresAt'].replace(' ', 'T')).toISOString()
        : undefined;

      return {
        lockExpiresAt: lockExpirationDate,
        domain: data.domain,
        message: data.message,
        isLocked: !!expandedDetails.isLocked,
        isLockable: !!expandedDetails.isLockable,
        isPrivacyProtected: !!expandedDetails.isPrivacyProtected,
        domainSecret: expandedDetails.domainSecret,
        isPrivacyProtectionAllowed: expandedDetails.isPrivacyProtectionAllowed,
        ownerContactId: expandedDetails.ownerContactId,
        ns: expandedDetails.ns,
        childNs: expandedDetails.childNs,
        dnsSec: expandedDetails.dnsSec,
        forwardingDetails: data.forwardingDetails,
        defaultNameServers: data.defaultNameServers,
        techWhoisProfile: data.techWhoisProfile,
        ownerWhoisProfile: data.ownerWhoisProfile,
        billingWhoisProfile: data.billingWhoisProfile,
        adminWhoisProfile: data.adminWhoisProfile,
        status: data.status,
        expiresAt: data.expiresAt,
        updatedAt: data.updatedAt,
        registeredAt: data.registeredAt,
        tld: data.tld,
        registrar: data.registrar,
        isVerified: expandedDetails.registrantEmailVerificationStatus,
        tldSettings: data.tldSettings,
        isOutdated: false,
      };
    };
    const formatDnsSecRecord = (
      record: IHDomainsDNSSECDetails,
      registrar: string,
      requirements: IDnsSecRequirements,
    ): { labels: Record<string, string>; values: Record<string, string> } => {
      const formattedRecord = {
        labels: {} as Record<string, string>,
        values: {} as Record<string, string>,
      };

      for (const [key, value] of Object.entries(record)) {
        let formattedKey = key;

        if (!key.startsWith('dns')) {
          formattedKey = `dns${toTitleCase(key)}${toTitleCase(
            snakeToCamel(registrar),
          )}`;
        }

        const req = Object.values(requirements).find(
          ({ slug }) => slug === formattedKey,
        );

        let label = value;

        if (req?.possibleValues && req.possibleValues[value]) {
          label = req.possibleValues[value];
        }

        formattedRecord.labels[formattedKey] = label;
        formattedRecord.values[formattedKey] = value;
      }

      return formattedRecord;
    };

    const getIsDomainCallForced = ({
      isOutdated,
      status,
      tldSettings,
      isVerified,
    }: IDomainData) => {
      const isDomainDataOutdated = isOutdated;
      const isDomainStatusRequested = status === 'Requested';
      const isMissingEmailVerification =
        !!tldSettings?.isEmailVerificationRequired &&
        isVerified !== DOMAIN_VERIFICATION_STATUS.VERIFIED;

      return (
        isDomainDataOutdated ||
        isDomainStatusRequested ||
        isMissingEmailVerification
      );
    };

    const setHDomainsDnsSecRequirements = ({
      domain,
      requirements,
    }: {
      domain: string;
      requirements: IDnsSecRequirement;
    }) => {
      if (!dnsSecRequirements.value) {
        dnsSecRequirements.value = {};
      }

      dnsSecRequirements.value[domain] = requirements;
    };

    const setDomainDataDnsSec = ({
      domain,
      dnsSec,
    }: {
      domain: string;
      dnsSec: IHDomainsDNSSECDetails;
    }) => {
      const domainInfo = getDomainData(domain);
      if (!domainInfo?.dnsSec) return;

      domainInfo.dnsSec.push(dnsSec);
      domainInfo.isOutdated = true;
    };

    const removeDomainDataDnsSec = ({
      domain,
      dnsSec,
    }: {
      domain: string;
      dnsSec: IHDomainsDNSSECDetails;
    }) => {
      const domainInfo = getDomainData(domain);
      if (!domainInfo?.dnsSec) return;

      const index = domainInfo.dnsSec?.findIndex((record) =>
        isEqual(record, dnsSec),
      );
      domainInfo.dnsSec.splice(index, 1);
      domainInfo.isOutdated = true;
    };

    const setDomainData = (data: IHDomain) => {
      const domainData = mapDomainData(data);
      const index = domainsData.value.findIndex(
        (item) => item.domain === domainData.domain,
      );

      if (index > -1) {
        domainsData.value[index] = domainData;
      } else {
        domainsData.value.push(domainData);
      }
    };

    const setDomainTransferData = (data: IHDomainTransfer) => {
      const index = domainsTransferData.value.findIndex(
        (item) => item.domain === data.domain,
      );

      if (index > -1) {
        domainsTransferData.value[index] = data;
      } else {
        domainsTransferData.value.push(data);
      }
    };

    const setDomainsDataOutdated = () => {
      domainsData.value = domainsData.value.map((domain) => ({
        ...domain,
        isOutdated: true,
      }));
    };

    const setDomainDataProperty = ({
      domain,
      field,
      value,
    }: {
      domain: string;
      field: string;
      value: any;
    }) => {
      const domainInfo = getDomainData(domain);

      if (!domainInfo) return;

      (domainInfo as Record<string, any>)[field] = value;
      domainInfo.isOutdated = true;
    };

    const removeDomainDataChildNs = ({
      domain,
      cns,
    }: {
      domain: string;
      cns: string;
    }) => {
      const domainInfo = getDomainData(domain);
      if (!domainInfo?.childNs) return;

      delete domainInfo.childNs[cns as keyof typeof domainInfo.childNs];
      domainInfo.isOutdated = true;
    };

    const setDomainDataChildNs = ({
      domain,
      cns,
      ip,
    }: {
      domain: string;
      cns: string;
      ip: string;
    }) => {
      const domainInfo = getDomainData(domain);
      if (!domainInfo?.childNs) return;

      domainInfo.childNs[cns as keyof typeof domainInfo.childNs] = [ip];
      domainInfo.isOutdated = true;
    };

    const setDomainsLoadingCompleted = (loaded: boolean) => {
      isDomainsLoadingCompleted.value = loaded;
    };

    const setDomainTransferRetriedDate = (hDomainResourceId: number) => {
      domainTransferDisabledRetryDic.value[hDomainResourceId] =
        new Date().getTime();
    };

    const getDomainTransferRetriedDate = (hDomainResourceId: number) =>
      domainTransferDisabledRetryDic.value[hDomainResourceId];

    const getDomainData = (domain: string) => {
      if (!domain) return null;

      return domainsData.value.find(
        (item) =>
          toASCII(item.domain).toLowerCase() === toASCII(domain).toLowerCase(),
      );
    };

    const getDomainTransferData = (domain: string) => {
      if (!domain) return null;

      return domainsTransferData.value.find(
        (item) =>
          toASCII(item.domain).toLowerCase() === toASCII(domain).toLowerCase(),
      );
    };

    const getDomainActiveWhoIsProfiles = (domain: string) => {
      const domainInfo = getDomainData(domain);
      if (!domainInfo) return [];

      return uniqBy(
        [
          domainInfo.ownerWhoisProfile,
          domainInfo.adminWhoisProfile,
          domainInfo.billingWhoisProfile,
          domainInfo.techWhoisProfile,
        ],
        'id',
      ).filter(Boolean);
    };

    const getIsDomainUnverified = (domain: string) => {
      const domainInfo = getDomainData(domain);

      const isEmailVerificationRequired =
        domainInfo?.tldSettings?.isEmailVerificationRequired;
      const isEmailVerified =
        domainInfo?.isVerified === DOMAIN_VERIFICATION_STATUS.VERIFIED;

      return !!(isEmailVerificationRequired && !isEmailVerified);
    };

    const getDomainContactInformationEmail = (domain: string) => {
      const domainInfo = getDomainData(domain);

      return domainInfo?.ownerWhoisProfile?.whoisDetails?.email?.value || '';
    };

    const getDomainsManagementDnsSec = (domain: string) => {
      const domainInfo = getDomainData(domain);
      if (!domainInfo) return [];

      const records = domainInfo.dnsSec || [];
      const registrar = domainInfo.registrar;
      const requirements =
        dnsSecRequirements[domain as keyof typeof dnsSecRequirements] || {};

      return records.map((record, i) => ({
        id: i,
        ...formatDnsSecRecord(
          record,
          registrar,
          requirements as IDnsSecRequirements,
        ),
      }));
    };

    const getDomainManagementTldSettings = (domain: string) =>
      getDomainData(domain)?.tldSettings || {};

    const getIsDomainConnectedToHosting = (domain: string) =>
      !!store.getters.getAccountByDomain(domain);

    const getDomainDataNs = (domain: string) => getDomainData(domain)?.ns || [];

    const getDomainDataChildNs = (domain: string) =>
      getDomainData(domain)?.childNs || [];

    const getDomainDataDefaultNs = (domain: string) =>
      getDomainData(domain)?.defaultNameServers || [];

    const getDomainDataForwardingDetails = (domain: string) =>
      getDomainData(domain)?.forwardingDetails || null;

    const fetchDnsSecRequirements = async (domain: string) => {
      const [{ data }, error] = await hDomainsRepo.getDnsSecRequirements(
        domain,
      );

      if (error) return;

      setHDomainsDnsSecRequirements({
        domain,
        requirements: data,
      });
    };

    const storeDnsSecRecord = async ({
      domain,
      details,
    }: {
      domain: string;
      details: IHDomainsDNSSECDetails;
    }) => {
      const [{ data }, error] = await hDomainsRepo.storeDnsSecRecord(
        domain,
        details,
      );
      if (!error) {
        setDomainDataDnsSec({
          domain,
          dnsSec: details,
        });
      }

      return [data, error];
    };

    const deleteDnsSecRecord = async ({
      domain,
      details,
    }: {
      domain: string;
      details: IHDomainsDNSSECDetails;
    }) => {
      const [data, error] = await hDomainsRepo.deleteDnsSecRecord(
        domain,
        details,
      );

      if (!error) {
        removeDomainDataDnsSec({
          domain,
          dnsSec: details,
        });
      }

      return [data, error];
    };

    const storeHDomainChildNs = async ({
      domain,
      nameserver,
      ip,
    }: {
      domain: string;
      nameserver: string;
      ip: string;
    }) => {
      const [data, error] = await hDomainsRepo.storeChildDnsRecord(
        domain,
        nameserver,
        ip,
      );

      if (!error) {
        setDomainDataChildNs({
          domain,
          cns: nameserver,
          ip,
        });
      }

      return [data, error];
    };

    const deleteHDomainChildNs = async ({
      domain,
      nameserver,
      ip,
    }: {
      domain: string;
      nameserver: string;
      ip: string;
    }) => {
      const [data, error] = await hDomainsRepo.deleteChildDnsRecord(
        domain,
        nameserver,
        ip,
      );

      if (!error) {
        removeDomainDataChildNs({
          domain,
          cns: nameserver,
        });
      }

      return [data, error];
    };

    const fetchTransferData = async (domain: string, cache: number = 0) => {
      const [{ data }, error] = await hDomainsRepo.getTransfer(
        domain,
        true,
        cache,
      );

      if (!error) {
        setDomainTransferData(data);
      }

      return [{ data }, error];
    };

    const fetchDomainData = async (domain: string, isForced?: boolean) => {
      const CACHE_15_MIN = 15 * 60;
      const domainToManage = getDomainData(domain);

      const isCallForced =
        !!(domainToManage && getIsDomainCallForced(domainToManage)) || isForced;

      const [{ data }, error] = await hDomainsRepo.getDomain(domain, true, {
        cache: CACHE_15_MIN,
        overrideCache: isCallForced,
      });

      if (error) {
        return;
      }

      setDomainData(data);
    };

    const enableDomainLock = async (domain: string) => {
      const [data, error] = await hDomainsRepo.enableDomainLock(domain);

      if (!error) {
        setDomainDataProperty({
          domain,
          field: 'isLocked',
          value: true,
        });
      }

      return [data, error];
    };

    const disableDomainLock = async (domain: string) => {
      const [data, error] = await hDomainsRepo.disableDomainLock(domain);

      if (!error) {
        setDomainDataProperty({
          domain,
          field: 'isLocked',
          value: false,
        });
      }

      return [data, error];
    };

    const enableWhoisPrivacy = async (domain: string) => {
      const [data, error] = await hDomainsRepo.enablePrivacyProtection(domain);

      if (!error) {
        setDomainDataProperty({
          domain,
          field: 'isPrivacyProtected',
          value: true,
        });
      }

      return [data, error];
    };

    const disableWhoisPrivacy = async (domain: string) => {
      const [data, error] = await hDomainsRepo.disablePrivacyProtection(domain);

      if (!error) {
        setDomainDataProperty({
          domain,
          field: 'isPrivacyProtected',
          value: false,
        });
      }

      return [data, error];
    };

    const updateNameservers = async ({
      domain,
      nameservers,
    }: {
      domain: string;
      nameservers: string[];
    }) => {
      const [data, error] = await hDomainsRepo.updateNameservers(
        domain,
        nameservers,
      );

      if (!error) {
        setDomainDataProperty({
          domain,
          field: 'ns',
          value: nameservers,
        });
      }

      return [data, error];
    };

    return {
      dnsSecRequirements,
      setDomainsDataOutdated,
      setDomainDataProperty,
      setDomainsLoadingCompleted,
      setHDomainsDnsSecRequirements,
      setDomainData,
      setDomainTransferData,
      getDomainActiveWhoIsProfiles,
      getDomainsManagementDnsSec,
      getDomainManagementTldSettings,
      getIsDomainConnectedToHosting,
      getDomainDataNs,
      getDomainDataChildNs,
      getDomainDataDefaultNs,
      getDomainDataForwardingDetails,
      fetchDnsSecRequirements,
      storeDnsSecRecord,
      deleteDnsSecRecord,
      storeHDomainChildNs,
      deleteHDomainChildNs,
      fetchTransferData,
      fetchDomainData,
      enableDomainLock,
      disableDomainLock,
      enableWhoisPrivacy,
      disableWhoisPrivacy,
      updateNameservers,
      getDomainData,
      getDomainTransferData,
      isDomainsLoadingCompleted,
      getIsDomainUnverified,
      domainsData,
      domainsTransferData,
      isNewDomainOverviewBannerShown,
      getDomainContactInformationEmail,
      domainTransferDisabledRetryDic,
      getDomainTransferRetriedDate,
      setDomainTransferRetriedDate,
    };
  },
  {
    persist: { key: 'domain-management-store' },
  },
);
