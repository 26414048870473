import { hAsync } from '@/utils/helpers';
import http from '@/utils/services/http/httpService';

export default {
  url: `${process.env.VITE_API_WH_API}/api/hapi/v1`,

  async rejectOwnershipTransfer(token: string) {
    return await hAsync<void>(
      http.post(`${this.url}/website-ownership-transfers/${token}/reject`, {
        plain: true,
      }),
    );
  },
};
