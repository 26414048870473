export namespace HostingerProPanel {
  export enum ProRewardStatus {
    APPROVED = 'Approved',
    PENDING = 'Pending',
    QUALIFIED = 'Qualified',
    DENIED = 'Denied',
    EXPIRED = 'Expired',
  }

  export enum EnteredDomainState {
    AVAILABLE = 'available',
    UNAVAILABLE = 'unavailable',
  }

  export enum ErrorStatusCode {
    CLIENT_NOT_FOUND = 6202,
    LOCALE_NOT_MATCHING = 6203,
    MAXIMUM_NUMBER_OF_MANAGERS = 6204,
  }

  export enum ErrorStatusCodeHSSO {
    CLIENT_NOT_FOUND = 2042,
    LOCALE_NOT_MATCHING = 2044,
    MAXIMUM_NUMBER_OF_MANAGERS = 2043,
  }
}
