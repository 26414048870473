//JSON-slugs-migrated
import type { RouteRecordRaw } from 'vue-router';

import domainResourceRegisterGuard from '@/guards/domains/domainResourceRegisterGuard';
import onboardingV2Guard from '@/guards/onboardingV2Guard';
import { Route } from '@/types';
import { RouteOwner } from '@/types/enums/routeOwnerEnum';
import { ONBOARDING_STEPS } from '@/types/models/onboardingV2Models';

const meta = {
  isPageWrapperV2: true,
  routeOwner: RouteOwner.HPANEL,
  logOldTranslations: true,
};

export default [
  {
    path: '/onboarding-v2/:order_id/:username?/:scenario?',
    name: Route.OnboardingV2.BASE_PATH,
    meta,
    component: () => import('@/views/OnboardingV2.vue'),
    beforeEnter: onboardingV2Guard,
    children: [
      {
        path: 'for-who',
        name: ONBOARDING_STEPS.SURVEY_FOR_WHO,
        meta: {
          ...meta,
          title: 'v2.who.are.you.building.website.for',
        },
        component: () =>
          import('@/views/OnboardingV2/OnboardingSurveyForWho.vue'),
      },

      {
        path: 'create-or-migrate',
        name: ONBOARDING_STEPS.CREATE_OR_MIGRATE,
        meta: {
          ...meta,
          title: 'v2.create.or.migrate',
        },
        component: () =>
          import('@/views/OnboardingV2/OnboardingCreateOrMigrate.vue'),
      },
      {
        path: 'platform-select',
        name: ONBOARDING_STEPS.PLATFORM_SELECT,
        meta: {
          ...meta,
          title: 'v2.select.platform',
        },
        component: () =>
          import('@/views/OnboardingV2/OnboardingPlatformSelect.vue'),
      },
      {
        path: 'wordpress-theme-select',
        name: ONBOARDING_STEPS.WORDPRESS_THEME_SELECT,
        meta: {
          ...meta,
          title: 'v2.select.theme',
        },
        component: () =>
          import('@/views/OnboardingV2/OnboardingWordPressThemeSelect.vue'),
      },
      {
        path: 'preview-wordpress-template',
        name: ONBOARDING_STEPS.WORDPRESS_PREVIEW_ASTRA_TEMPLATE,
        meta: {
          ...meta,
          title: 'v2.preview.your.template',
        },
        component: () =>
          import('@/views/OnboardingV2/OnboardingPreviewWordPressTemplate.vue'),
      },
      {
        path: 'plugins-select',
        name: ONBOARDING_STEPS.WORDPRESS_PLUGINS_SELECT,
        meta: {
          ...meta,
          title: 'v2.select.plugins',
        },
        component: () =>
          import(
            '@/views/OnboardingV2/OnboardingWordPressManualPluginsSelect.vue'
          ),
      },
      {
        path: 'wordpress-theme-preview',
        name: ONBOARDING_STEPS.WORDPRESS_THEME_PREVIEW,
        meta: {
          ...meta,
          title: 'v2.preview.theme',
        },
        component: () =>
          import('@/views/OnboardingV2/OnboardingWordPressThemePreview.vue'),
      },
      {
        path: 'server-select',
        name: ONBOARDING_STEPS.SERVER_SELECT,
        meta: {
          ...meta,
          title: 'v2.select.server',
        },
        component: () =>
          import('@/views/OnboardingV2/OnboardingServerSelect.vue'),
      },
      {
        path: 'hostinger-theme-select',
        name: ONBOARDING_STEPS.HOSTINGER_THEME_SELECT,
        meta: {
          ...meta,
          title: 'v2.choose.your.theme.variant',
        },
        component: () =>
          import('@/views/OnboardingV2/OnboardingHostingerThemeSelect.vue'),
      },
      {
        path: 'domain',
        name: ONBOARDING_STEPS.DOMAIN,
        meta: {
          ...meta,
          title: 'v2.choose.domain',
        },
        component: () => import('@/views/OnboardingV2/OnboardingDomain.vue'),
      },
      {
        path: 'wordpress-admin-setup',
        name: ONBOARDING_STEPS.WORDPRESS_ADMIN_SETUP,
        meta: {
          ...meta,
          title: 'v2.set.up.wordpress',
        },
        component: () =>
          import('@/views/OnboardingV2/OnboardingWordPressAdminSetup.vue'),
      },
      {
        path: 'grant-developer-access',
        name: ONBOARDING_STEPS.GRANT_DEVELOPER_ACCESS,
        meta: {
          ...meta,
          title: 'v2.grant.your.developer.access.manage.this.plan',
        },
        component: () =>
          import('@/views/OnboardingV2/OnboardingGrantDeveloperAccess.vue'),
      },
      {
        path: 'developer-access-granted',
        name: ONBOARDING_STEPS.DEVELOPER_ACCESS_GRANTED,
        meta: {
          ...meta,
          title: 'v2.developer.access.granted',
        },
        component: () =>
          import('@/views/OnboardingV2/OnboardingDeveloperAccessGranted.vue'),
      },

      {
        path: 'wordpress-website-type',
        name: ONBOARDING_STEPS.WORDPRESS_WEBSITE_TYPE,
        meta: {
          ...meta,
          title: 'v2.what.type.of.website.are.you.creating',
        },
        component: () =>
          import('@/views/OnboardingV2/OnboardingWordPressWebsiteType.vue'),
      },
      {
        path: 'wordpress-content-ai-generation',
        name: ONBOARDING_STEPS.WORDPRESS_AI_CONTENT_GENERATION,
        meta: {
          ...meta,
          title: 'v2.generate.content',
        },
        component: () =>
          import(
            '@/views/OnboardingV2/OnboardingWordPressAIContentGeneration.vue'
          ),
      },
      {
        path: 'wordpress-presets',
        name: ONBOARDING_STEPS.WORDPRESS_PRESETS,
        meta: {
          ...meta,
          title: 'v2.choose.preset',
        },
        component: () =>
          import('@/views/OnboardingV2/OnboardingWordPressPresets.vue'),
      },
      {
        path: 'domain-registration/:domain',
        name: ONBOARDING_STEPS.DOMAIN_REGISTRATION,
        meta: {
          ...meta,
          title: 'v2.register.domain',
        },
        beforeEnter: (to, from, next) =>
          domainResourceRegisterGuard(to, from, next),
        component: () =>
          import('@/views/OnboardingV2/OnboardingDomainRegistration.vue'),
      },
      {
        path: 'wordpress-build-type',
        name: ONBOARDING_STEPS.WORDPRESS_BUILD_TYPE,
        meta: {
          ...meta,
          title: 'v2.onboarding.platform.select.route.title',
        },
        component: () =>
          import('@/views/OnboardingV2/OnboardingWordPressBuildSelect.vue'),
      },
      {
        path: 'building-with-ai',
        name: ONBOARDING_STEPS.COMPLETE_WITH_AI,
        meta: {
          ...meta,
          title: 'v2.build.select.route.title',
        },
        component: () =>
          import('@/views/OnboardingV2/OnboardingBeingBuiltWithAI.vue'),
      },
      {
        path: 'building',
        name: ONBOARDING_STEPS.COMPLETE,
        meta: {
          ...meta,
          title: 'v2.building.your.website',
        },
        component: () =>
          import('@/views/OnboardingV2/OnboardingBeingBuilt.vue'),
      },
    ],
  },
] as RouteRecordRaw[];
