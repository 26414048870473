import { ref, computed } from 'vue';

import WebsiteOwnershipTransferModal from '@/components/Modals/HModal/WebsiteOwnershipTransferModal.vue';
import {
  useCookies,
  useHostingStatus,
  useModal,
  useWebsite,
} from '@/composables';
import { useDeveloperTools } from '@/composables/developerTools/useDeveloperTools';
import { useProfileStore } from '@/stores/profile/profileStore';
import type { ProWebsiteType } from '@/types';
import { Cookie, DeveloperToolType, Hosting } from '@/types';
import { getDaysUntil } from '@/utils/helpers';

export const useWebsiteOwnershipTransfer = (websiteObject: ProWebsiteType) => {
  const { isDeveloperToolsEnabled, hasDeveloperToolsEnabledThroughCloudPlan } =
    useDeveloperTools();
  const { isSuspended, getIsExpiryWithinDays } =
    useHostingStatus(websiteObject);
  const { isWordPressType, isOwnWebsite, isBeingDeleted } =
    useWebsite(websiteObject);
  const { openModal } = useModal();
  const profileStore = useProfileStore();

  const isWebsiteOwnershipTransferEnabled = computed(() => {
    if (profileStore.isAccessManager) return false;

    if (isDeveloperToolsEnabled.value) {
      return isTransferEnabledForDeveloperToolsClient.value;
    }

    return false;
  });

  const isTransferEnabledForDeveloperToolsClient = computed(() => {
    if (hasDeveloperToolsEnabledThroughCloudPlan.value) {
      return true;
    }

    return (
      Array.isArray(website.value.enabledAddons) &&
      website.value.enabledAddons.includes(DeveloperToolType.OWNERSHIP_TRANSFER)
    );
  });

  const { cookie: hasAllowToTransferNewCookie } = useCookies(
    Cookie.WEBSITES_OWNERSHIP_ALLOW_TO_TRANSFER_NEW,
  );

  const website = ref(websiteObject);

  const isPendingOrActiveTransferRequest = computed(
    () =>
      website.value.ownershipTransferState ===
      Hosting.OwnershipTransferWebsiteState.PENDING,
  );

  const isWebsiteInTransfer = computed(
    () =>
      website.value.ownershipTransferState ===
      Hosting.OwnershipTransferWebsiteState.IN_PROGRESS,
  );

  const isWebsiteOlderThan1Day = computed(
    () =>
      !!(
        Math.abs(getDaysUntil(website.value.createdAt)) >= 1 ||
        hasAllowToTransferNewCookie.value
      ),
  );

  const canTransferOwnership = computed(
    () =>
      isWebsiteOlderThan1Day.value &&
      isOwnWebsite.value &&
      isWordPressType.value &&
      !isSuspended.value &&
      !isPendingOrActiveTransferRequest.value &&
      !getIsExpiryWithinDays(7) &&
      !isBeingDeleted.value &&
      !isWebsiteInTransfer.value &&
      websiteObject.vhostType !== Hosting.Vhost.SUBDOMAIN,
  );

  const transferDenialReason = computed(() => {
    if (canTransferOwnership.value) {
      return '';
    }
    if (!isWebsiteOlderThan1Day.value) {
      return 'v2.website.not.older.than.1.day';
    }

    if (isSuspended.value) {
      return 'v2.website.is.suspended';
    }

    if (isPendingOrActiveTransferRequest.value) {
      return 'v2.there.is.an.active.or.pending.transfer.request';
    }

    if (getIsExpiryWithinDays(7)) {
      return 'v2.website.is.expiring.within.7.days';
    }

    if (isBeingDeleted.value) {
      return 'v2.website.is.being.deleted';
    }

    return 'v2.website.is.not.eligible.for.ownership.transfer';
  });

  const openOwnershipTransferModal = (additionalData = {}) => {
    openModal({
      component: { WebsiteOwnershipTransferModal },
      data: {
        website: website.value,
        ...additionalData,
      },
    });
  };

  return {
    canTransferOwnership,
    transferDenialReason,
    openOwnershipTransferModal,
    isWebsiteOwnershipTransferEnabled,
    isPendingOrActiveTransferRequest,
  };
};
