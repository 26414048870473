import { useRouter } from 'vue-router';

import { useGlobals } from '@/composables/useGlobals';
import { wordpressRepo } from '@/repositories';
import type {
  IWordPressAdminPanelLoginResponse,
  WordPressAdminSource,
} from '@/types';
import { WORDPRESS_ADMIN_LINK_NAME, Route } from '@/types';
import { errorLogger } from '@/utils/services/errorLogging';

// Sequence of links to try to redirect to
const WORDPRESS_LINK_CALLS_SEQUENCE = [
  {
    linkName: WORDPRESS_ADMIN_LINK_NAME.LINK,
    protocols: ['https://', 'http://'],
  },
  {
    linkName: WORDPRESS_ADMIN_LINK_NAME.FALLBACK_LINK,
    protocols: ['https://'],
  },
  {
    linkName: WORDPRESS_ADMIN_LINK_NAME.DEFAULT,
    protocols: ['https://'],
  },
];

type WordPressAmplitudeData = {
  directory: string;
  source: WordPressAdminSource;
};

// Function to remove specific query parameters from a link
const removeCheckAndFormatTimestamp = (link: string) =>
  link.replace(/(\?is_check=1)/, '').replace(/(&timestamp)/, '?timestamp');

export const useWordPressRedirect = () => {
  const { toastr, t } = useGlobals();
  const router = useRouter();

  // Handles error case by logging the error and redirecting to a base route
  const handleErrorCase = () => {
    errorLogger.logError(new Error('WordPress precheck redirect failed'));
    toastr.e(t('v2.wordpress.redirect.error'));
    router.push({ name: Route.Base.WEBSITES });
  };

  // Logs an error case to the WordPress repository
  const logErrorCase = ({
    amplitudeData,
    link,
    domain,
  }: {
    amplitudeData: WordPressAmplitudeData;
    link: string;
    domain: string;
  }) => {
    wordpressRepo.postWordPressAdminPanelError(
      {
        directory: amplitudeData.directory,
        source: amplitudeData.source,
        link,
        isSuccessful: false,
      },
      { domain },
    );
  };

  // Builds a redirect link with appropriate query parameters
  const buildRedirectLink = (
    linkAddress: string,
    protocol: string,
    isDefaultLink: boolean,
  ) => {
    const timestamp = new Date().getTime();
    const linkQueries = isDefaultLink
      ? `?timestamp=${timestamp}`
      : `?is_check=1&timestamp=${timestamp}`;
    const redirectLink = `${linkAddress}${linkQueries}`;

    return redirectLink.startsWith('http')
      ? redirectLink.replace(/^(https?:\/\/)/, protocol)
      : `${protocol}${redirectLink}`;
  };

  // Checks if the response from a redirect link is successful
  const checkResponseSuccess = async (
    formattedRedirectLink: string,
    isDefaultLink: boolean,
  ) => {
    try {
      const response = await fetch(formattedRedirectLink, {
        mode: isDefaultLink ? 'no-cors' : 'cors',
      });
      const textData = await response?.text();
      const isTextDataSuccess = textData?.toLowerCase()?.includes('success');

      return isTextDataSuccess || response.ok;
    } catch {
      return false;
    }
  };

  /**
   * Attempts to redirect to a WordPress admin panel by trying a sequence of links and protocols.
   * If a successful response is received, it redirects to the link.
   * If all attempts fail, it logs the error and redirects to a base website route.
   *
   * @param {Object} params - The parameters for the function.
   * @param {IWordPressAdminPanelLoginResponse} params.data - The response data containing potential redirect links.
   * @param {Function} [params.navigationFunction] - Optional custom navigation function.
   * @param {WordPressAmplitudeData} params.amplitudeData - Data for amplitude logging purposes.
   * @param {string} params.domain - The domain that might be used in http service to get headers data.
   */
  const attemptRedirect = async ({
    data,
    navigationFunction,
    amplitudeData,
    domain,
  }: {
    data: IWordPressAdminPanelLoginResponse;
    navigationFunction?: (redirectLink: string) => void;
    amplitudeData: WordPressAmplitudeData;
    domain: string;
  }) => {
    // We want to send fallback_link first and only then others if this one doesn't exist
    const linkToSendToErorLogger =
      data[WORDPRESS_ADMIN_LINK_NAME.FALLBACK_LINK] ||
      data[WORDPRESS_ADMIN_LINK_NAME.LINK] ||
      data[WORDPRESS_ADMIN_LINK_NAME.DEFAULT];

    for (const { linkName, protocols } of WORDPRESS_LINK_CALLS_SEQUENCE) {
      const redirectLinkAddress = data[linkName];
      if (!redirectLinkAddress) continue;

      const isDefaultLink = linkName === WORDPRESS_ADMIN_LINK_NAME.DEFAULT;

      for (const protocol of protocols) {
        const formattedRedirectLink = buildRedirectLink(
          redirectLinkAddress,
          protocol,
          isDefaultLink,
        );
        const strippedFormattedRedirectLink = removeCheckAndFormatTimestamp(
          formattedRedirectLink,
        );

        if (isDefaultLink) {
          await logErrorCase({
            amplitudeData,
            link: linkToSendToErorLogger,
            domain,
          }); // if we reach till this point, we treat this as failure, will need to improve this when we have proxi api where we can check the default link status
        }

        const isSuccessResponse = await checkResponseSuccess(
          formattedRedirectLink,
          isDefaultLink,
        );

        if (isSuccessResponse || isDefaultLink) {
          // We currently don't have an option to check `wp-admin` response status (default link case), will be implemented later

          if (navigationFunction) {
            // Custom redirect function

            navigationFunction(strippedFormattedRedirectLink);

            return;
          }
          window.location.href = strippedFormattedRedirectLink; // If no navigation function, redirect to the link

          return;
        }
      }
    }
    throw new Error('WordPress precheck redirect failed');
  };

  const handleWordPressPrecheckRedirect = async (params: {
    data: IWordPressAdminPanelLoginResponse;
    navigationFunction?: (redirectLink: string) => void;
    amplitudeData: WordPressAmplitudeData;
    domain: string;
  }) => {
    try {
      await attemptRedirect(params);
    } catch (error) {
      handleErrorCase(); // If all links fail, handle the error
    }
  };

  return { handleWordPressPrecheckRedirect };
};
