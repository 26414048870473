export namespace FeatureFlag {
  export enum ID {
    CVC_REFRESH = 'cvc-refresh',
    SALES_CTA = 'hpanel-sales-cta',
    WEBPRO_CLIENT_CACHE_WARMUP = 'webpro-client-cache-warmup',
    NEW_WEBSITE_MIGRATIONS_UI = 'new-website-migrations-ui',
    ONBOARDING_H5G = 'onboarding-h5g-flag',
    BE_SLOWDOWN_HANDLER = 'be-slowdown-handler',
    HPANEL_VERSION_UPDATE = 'hpanel-version-update',
    DASHBOARD_DOMAIN_STATUS = 'dashboard-domain-status',
    NEW_HELP_PAGE_SELECTION = 'new-help-page-selection',
    WEBSITES_PAGINATION = 'websites-pagination',
    GW_MDF_SALE = 'google-workspace-50-discount-hpanel-home-page',
    REFUNDS_FLOW_V2 = 'refunds-flow-v2',
    UDS_AMPLITUDE_NBA_LOGGING = 'uds-amplitude-nba-logging',
    UDS_FALLBACK_NBA_LOGGING = 'uds-fallback-nba-logging',
    AI_SOCIAL_MEDIA_TOOL_PROMO_BANNER = 'hpanel-ai-social-tool-promo-banner-v2',
    EMAIL_MARKETING_TOOL_PROMO_BANNER = 'hpanel-email-marketing-tool-promo-banner',
    DEVELOPER_TOOLS = 'wordpress-addons-non-cloud-webpros-v2',
    REFUNDS_PAID_INVOICES = 'refunds-paid-invoices',
    KOREAN_LANGUAGE = 'korean-language',
    WORDPRESS_IMPROVED_PREFLIGHT_CHECK_REDIRECT = 'wordpress-improved-preflight-check-redirect',
    ACCOUNT_SHARING_NAVIGATION_TEST = 'account-sharing-navigation-test-v2',
    HPANEL_OLD_TRANSLATIONS_LOGGING = 'hpanel-old-translations-logging',
  }

  export enum Value {
    ENABLED = 'on',
  }
}
