import { HDOMAIN_RESOURCE_STATUSES } from '@hostinger/hdomains-status';
import type { NavigationGuard } from 'vue-router';

import { useHDomainState } from '@/composables/hDomains/useHDomainState';
import { useHDomainResourceStore } from '@/stores/domain/hDomainResourceStore';
import { useFrontendSettingsStore } from '@/stores/frontendSettingsStore';
import { useOnboardingStore } from '@/stores/onboardingStore';
import { Route } from '@/types';
import { retryAsyncFunction } from '@/utils/helpers';
import { errorLogger } from '@/utils/services/errorLogging';
import { hToastrService as toastr } from '@/utils/services/hToastrService';
import { i18n } from '@/utils/services/i18nService';

const domainResourceRegisterGuard: NavigationGuard = async (to, from, next) => {
  const { inPendingVerification } = useHDomainState();

  // don't check if client has domain if it's meant to be claimed
  const isClaimable = to.query.isClaimable === '1';

  if (isClaimable) {
    return next();
  }

  const domain = to.params.domain as string;
  const hDomainResource = await loadHDomainResource(domain);
  const onboardingStore = useOnboardingStore();

  if (hDomainResource && inPendingVerification(hDomainResource)) {
    toastr.e(i18n.t('Domain is being reviewed'));
    onboardingStore.setIsLeaveOnboardingEventShouldBeSent(false);

    return next({ name: Route.Domain.MY_DOMAINS, query: { forceLeave: '1' } });
  }

  if (!hDomainResource) {
    errorLogger.logInfo(
      `Pending or Failed hDomain resource for domain ${domain} was not found in domainResourceRegisterGuard`,
    );

    toastr.e(i18n.t('Order not found'));
    onboardingStore.setIsLeaveOnboardingEventShouldBeSent(false);

    return next({ name: Route.Domain.MY_DOMAINS, query: { forceLeave: '1' } });
  }

  next();
};

const loadHDomainResource = async (domain: string) => {
  const { fetchHDomainsResources } = useHDomainResourceStore();
  const frontendSettingsStore = useFrontendSettingsStore();

  const hDomainResource = getHDomainResourceToSetup(domain);

  if (hDomainResource) {
    return hDomainResource;
  }

  frontendSettingsStore.setState('pageLoading', true);

  await retryAsyncFunction({
    functionToRetry: fetchHDomainsResources,
    getIsRetryNeeded: () => !getHDomainResourceToSetup(domain),
    triesCount: 3,
    timeoutInMilliseconds: 2000,
  });

  frontendSettingsStore.setState('pageLoading', false);

  return getHDomainResourceToSetup(domain);
};

const getHDomainResourceToSetup = (domain: string) => {
  const { getHDomainResourceByDomainAndStatus } = useHDomainResourceStore();

  return getHDomainResourceByDomainAndStatus(domain, [
    HDOMAIN_RESOURCE_STATUSES.PENDING_SETUP,
    HDOMAIN_RESOURCE_STATUSES.FAILED,
  ]);
};

export default domainResourceRegisterGuard;
