import {
  HButton,
  HForm,
  HFormField,
  HSnackbar,
  HHyperlink,
  HSkeletonLoader,
  HProgressStepper,
  HNotificationRow,
  HLabel,
} from '@hostinger/hcomponents';
import type { App } from 'vue';

import ErrorBoundaryComponent from '@/components/ErrorBoundary/ErrorBoundaryComponent.vue';
import HActionMenu from '@/components/HActionMenu/HActionMenu.vue';
import HList from '@/components/HActionMenu/HList.vue';
import HListItem from '@/components/HActionMenu/HListItem.vue';
import HButtonV1 from '@/components/HButtons/HButtonV1.vue';
import HCheckbox from '@/components/HCheckbox.vue';
import HFormDeprecated from '@/components/HFormDeprecated/HFormDeprecated.vue';
import HFormFieldDeprecated from '@/components/HFormDeprecated/HFormFieldDeprecated.vue';
import HImage from '@/components/HImage.vue';
import HVideo from '@/components/HVideo.vue';
import HpIcon from '@/components/Icons/HpIcon.vue';
import HPortlet from '@/components/Portlet/HPortlet.vue';
import StatusIcon from '@/components/StatusIcon.vue';
import Trans from '@/components/Trans.vue';

export const setGlobalComponents = (app: App) => {
  app.component('Trans', Trans);
  app.component('HButtonV1', HButtonV1);
  app.component('HButton', HButton);
  app.component('HPortlet', HPortlet);
  app.component('StatusIcon', StatusIcon);
  app.component('HActionMenu', HActionMenu);
  app.component('HList', HList);
  app.component('HListItem', HListItem);
  app.component('HFormDeprecated', HFormDeprecated);
  app.component('HFormFieldDeprecated', HFormFieldDeprecated);
  app.component('HForm', HForm);
  app.component('HFormField', HFormField);
  app.component('HSnackbar', HSnackbar);
  app.component('HNotificationRow', HNotificationRow);
  app.component('HHyperlink', HHyperlink);
  app.component('HLabel', HLabel);
  app.component('HpIcon', HpIcon);
  app.component('HImage', HImage);
  app.component('HVideo', HVideo);
  app.component('HCheckbox', HCheckbox);
  app.component('HSkeletonLoader', HSkeletonLoader);
  app.component('HProgressStepper', HProgressStepper);
  app.component('ErrorBoundaryComponent', ErrorBoundaryComponent);
};
