import type { VpsLicense } from './licenseModels';

import type { Hosting, IHResource } from '@/types';
import type { DataCenterName, HVps } from '@/types/enums';

export interface VpsServer {
  id: number;
  template: ServerOS;
  node: {
    id: number;
    dataCenterId: string;
    hostname: string;
    ns1: string;
    ns2: string;
    ns3: string;
    ns4: string;
    state: string;
    providerCode: string;
    kernelVersion: string;
    config: {
      statusPage: boolean;
    };
  };
  nodeState: string;
  memory: number;
  cpuMhz: number;
  cpus: number;
  cpuLimit: number;
  diskSpace: number;
  bandwidth: number;
  bandwidthUsedPercent?: number;
  rateLimit: number;
  ioLimit: number;
  dataCenterId: number;
  actionsLock: string;
  ns1: string;
  ns2: string;
  state: HVps.ServerState;
  hostname: string;
  ipv4: IServerIpAddress[];
  ipv6: IServerIpAddress[];
  features: Record<string, boolean>[];
  note: string;
  dailyBackup: boolean;
  metadata: {
    [key: string]: string;
  };
  clientId: string;
  customerId: string;
  createdAt: string;
  updatedAt: string;
  isTransferAvailable: boolean;
  license?: VpsLicense;
  monarx?: IMalwareScanner;
  providerCode?: string;
  firewallGroupId: number | null;
  rootPassword?: string;
}

export interface IServerIpAddress {
  id: string;
  ptr: string;
  address: string;
  subnetmask: string;
  state: string;
  dataCenterId: string;
  virtualMachineId: string;
  createdAt: string;
  updatedAt: string;
  type?: HVps.IPType;
}

export interface IMalwareScanner {
  agent: string;
  autoProtect: number;
  compromisedPolicy: string;
  id: number;
  licensed: number;
  maliciousPolicy: string;
}

export interface IServerTable {
  id: number;
  title: string;
  subtitle: string;
  status: string;
  resource?: IHResource;
  orderId: string;
  expiresAt: string;
  inMaintenance?: boolean;
  actionsLock?: string;
  ipv4?: string;
  os?: string;
  reason?: string;
}

export interface ServerOSLogin {
  note: string;
  password: string;
  username: string;
  loginUrl: string;
  description: string;
}

export interface ServerOS {
  description: string;
  group: HVps.OsGroup;
  id: number;
  isPanelPassResetSupported: boolean;
  isRecommended: boolean;
  kblink: string;
  forumLink: string;
  label: string;
  loginDetails: {
    logins: ServerOSLogin[];
  } | null;
  panelType: string;
  panelName: string;
  providerCode: string;
  installTime?: number;
  documentation: string;
}

export interface IDataCenter {
  id: number;
  name: string;
  fullName: string;
  location: string;
  state: string;
  providerCode: string;
  country?: string;
  city?: string;
}

export type DataCenterLatencies = Record<DataCenterName, number>;

export interface IDataCenterPingRequest {
  plan: Hosting.Plan | undefined;
  orderId: string;
  skipPinger?: boolean;
}

export interface IDataCenterPingResponse {
  sourceIp: string;
  latencyMs: number;
  time: string;
  dataCenter: string;
}

export interface IPusherServerNotification {
  id: string;
  name: string;
  state: string;
  serverId: string;
}

export interface IServerNotification {
  id: string;
  status: string;
  title: string;
}

export interface ServerAction {
  id: number;
  virtualMachineId: number;
  name: string;
  state: string;
  createdAt: string;
}

export interface ServerBackup {
  id: number;
  scheduleId: number;
  virtualMachineId: number;
  uuid: string;
  nodeId: number;
  state: string;
  location: string;
  executionTime: number;
  sizeKbytes: number;
  actionLogId: number;
  result: {
    jobId: string;
    jobStatus: string;
    executionTime: number;
    result: {
      vm: {
        status: string;
        updated_at: string;
      };
      backup: {
        uuid: string;
        location: string;
        size_kbytes: number;
      };
      message: string;
    };
  };
  postponeCount: number;
  createdAt: string;
}

export interface ServerBackupSchedule {
  id: number;
  state: string;
  frequence: number;
  lastBackupStart: string;
  lastBackupFinish: string;
  createdAt: string;
}

export interface IServerBackup {
  id: number;
  location: string;
  createdAt: string;
  updatedAt: string;
}

export interface IVpsFirewall {
  createdAt: string;
  id: number;
  name: string;
  rules: IVpsFirewallRules[];
  synced: boolean;
  updatedAt: string;
}

export interface IServerSnapshot {
  id: number;
  createdAt: string;
  updatedAt: string;
  expiredAt: string;
}

export interface IUpgradingServer {
  serverId: number;
  upgradePlanTitle: string;
}

export interface ISshKey {
  id: number;
  name: string;
  key: string;
  attachedAt: string;
  createdAt: string;
  updatedAt: string;
}

export const FIREWALL_PROTOCOL = {
  PROTO_UDP: 'UDP',
  PROTO_TCP: 'TCP',
  PROTO_ICMP: 'ICMP',
  PROTO_ICMPV6: 'ICMPv6',
  PROTO_GRE: 'GRE',
  PROTO_ESP: 'ESP',
  PROTO_AH: 'AH',
  SERVICE_SSH: 'SSH',
  SERVICE_HTTP: 'HTTP',
  SERVICE_HTTPS: 'HTTPS',
  SERVICE_MYSQL: 'MySQL',
  SERVICE_POSTGRESQL: 'PostgreSQL',
} as const;

export const FIREWALL_ACTION = {
  ACCEPT: 'accept',
  DROP: 'drop',
  REJECT: 'reject',
} as const;

export const PROTOCOL_PORT_PRESELECT_MAP = {
  [FIREWALL_PROTOCOL.PROTO_UDP]: 'editable',
  [FIREWALL_PROTOCOL.PROTO_TCP]: 'editable',
  [FIREWALL_PROTOCOL.PROTO_ICMP]: 'disabled',
  [FIREWALL_PROTOCOL.PROTO_ICMPV6]: 'disabled',
  [FIREWALL_PROTOCOL.PROTO_GRE]: 'disabled',
  [FIREWALL_PROTOCOL.PROTO_ESP]: 'disabled',
  [FIREWALL_PROTOCOL.PROTO_AH]: 'disabled',
  [FIREWALL_PROTOCOL.SERVICE_SSH]: '22',
  [FIREWALL_PROTOCOL.SERVICE_HTTP]: '80',
  [FIREWALL_PROTOCOL.SERVICE_HTTPS]: '443',
  [FIREWALL_PROTOCOL.SERVICE_MYSQL]: '3306',
  [FIREWALL_PROTOCOL.SERVICE_POSTGRESQL]: '5432',
} as const;

export const FIREWALL_SOURCE = {
  ANY: 'any',
  CUSTOM: 'custom',
} as const;

export type FirewallProtocol =
  (typeof FIREWALL_PROTOCOL)[keyof typeof FIREWALL_PROTOCOL];

export type FirewallAction =
  (typeof FIREWALL_ACTION)[keyof typeof FIREWALL_ACTION];

export type FirewallSource =
  (typeof FIREWALL_SOURCE)[keyof typeof FIREWALL_SOURCE];

export interface IVpsFirewallRules {
  id?: string;
  action: FirewallAction;
  protocol: FirewallProtocol | 'any' | string;
  port: string | 'any';
  source: FirewallSource | string;
  sourceDetail: string | 'any';
}

export type VpsFirewallRuleKeys = keyof IVpsFirewallRules;

export interface IMalwareScannerMetrics {
  records: number;
  compromised: number;
  malicious: number;
  scannedFiles: number;
  scanStart: string;
  scanEnd: string;
}
export interface IMalwareAutoProtectionRequest {
  enabled: boolean;
  malicious: 'quarantine' | 'clean' | 'ignore';
  compromised: 'log' | 'clean' | 'ignore';
}

export interface IMalwareScannerCredentials {
  clientId: string;
  clientSecret: string;
}

export interface IServerOngoingAction {
  name: string;
  time: number;
}

export interface IServerUsageUnit {
  unit: string;
  usage: object;
  limit?: number;
  showLimit?: number;
}

export interface IServerUsage {
  bytesOut: IServerUsageUnit;
  bytesIn: IServerUsageUnit;
  physpages: IServerUsageUnit;
  numproc: IServerUsageUnit;
  diskinodes: IServerUsageUnit;
  laverage: IServerUsageUnit;
  cpuUsage: IServerUsageUnit;
  uptime: IServerUsageUnit;
}

export interface IFormatterServerUsage {
  unit: string;
  usage: Record<string, number>;
  originalUsage: { [key: number]: number };
}

export const SERVER_OS = {
  CENTOS7_CYBERPANEL: 243,
} as const;

export interface IMalwareScannerStatistics {
  records: number;
  malicious: number;
  compromised: number;
  quarantined: number;
  cleaned: number;
}

export type MalwareScannerTimeline = Record<string, IMalwareScannerStatistics>;

export interface IMalwareScannerFileResponse {
  items: IMalwareScannerFile[];
  pagination: {
    size: number;
    totalElements: number;
    totalPages: number;
    number: number;
    truncated: boolean;
  };
}

export interface IMalwareScannerFile {
  discoveredAt: string;
  fileName: string;
  malwareType: string;
  action: string;
  path: string;
}

export interface IUpdatingServers {
  updatingServerId: number;
  duration: number | null;
  started: number;
  isCreating: boolean;
  isPersistent?: boolean;
  name?: string;
}

export interface ISSHKeyValues {
  name: string;
  key: string;
}

export interface IOSTab {
  name: string;
  title: string;
  description: string;
  features: string[];
  selected: boolean;
  id: string;
  type: HVps.OsGroup;
  groupedOs: Record<string, ServerOS[]>;
}

export interface IOsSelectionGroup {
  id: HVps.OsGroup;
  name: string;
  selected: boolean;
  groupedOs: Record<string, ServerOS[]>;
  type: HVps.OsGroup;
}

export interface IRequestAccapted {
  message: string;
}

export interface ITransferAvailability {
  isAvailable: boolean;
}

export interface IServerSetupRequest {
  templateId: number;
  rootPassword: string;
  panelPassword?: string;
  publicKey?: {
    name: string;
    key: string;
  };
  dataCenterId: number;
  hostname: string;
  installMonarx?: boolean;
  enableBackups?: boolean;
}

export const GROUP_OS_NAMES = {
  ALMA_LINUX: 'AlmaLinux',
  CENTOS: 'CentOS',
  CLOUD_LINUX: 'CloudLinux',
  DEBIAN: 'Debian',
  FEDORA: 'Fedora Cloud',
  OPENSUSE: 'openSUSE',
  ROCKY_LINUX: 'Rocky Linux',
  UBUNTU: 'Ubuntu',
  ALPHINE_LINUX: 'Alpine Linux',
  ARCH_LINUX: 'Arch Linux',
  KALI_LINUX: 'Kali Linux',
} as const;
