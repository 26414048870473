import {
  type NavigationGuard,
  type NavigationGuardNext,
  type RouteLocationNormalized,
} from 'vue-router';

import {
  useIntercom,
  useGlobals,
  useOnboardingV2,
  useOnboardingSubmit,
} from '@/composables';
import {
  useFrontendSettingsStore,
  useResourcesStore,
  useSubscriptionsStore,
} from '@/stores';
import type { AddonOnboardingScenario, OnboardingScenario } from '@/types';
import { AmplitudeEvent, Route, ONBOARDING_SCENARIO } from '@/types';

const onboardingV2Guard: NavigationGuard = async (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext,
) => {
  const subscriptionId = to.params.order_id as string;
  const scenario = to.params.scenario as OnboardingScenario;
  const subscriptionsStore = useSubscriptionsStore();
  const { closeIntercom } = useIntercom();
  const { amplitudeV2 } = useGlobals();
  const isAddon = [
    ONBOARDING_SCENARIO.ADDON,
    ONBOARDING_SCENARIO.QUICK_INSTALL_MIGRATION_ADDON,
    ONBOARDING_SCENARIO.QUICK_INSTALL_WEBSITE_BUILDER_ADDON,
    ONBOARDING_SCENARIO.QUICK_INSTALL_WORDPRESS_ADDON,
  ].includes(scenario as AddonOnboardingScenario);

  if (from.name === Route.Websites.ADD_MIGRATION) {
    return next({ name: Route.Base.WEBSITES });
  }

  const isSubscriptionMatching =
    (subscriptionsStore.subscriptionOrder?.subscriptionId || '') ===
    subscriptionId;

  if (!isSubscriptionMatching) {
    await subscriptionsStore.fetchSubscriptionOrder(subscriptionId);
  }

  const { selectedPlatformByRedirect } = useOnboardingV2();
  const isWebsiteBuilderQuickInstall =
    to.query.websiteBuilderQuickInstall === '1';
  const isRedirectFromWordPress =
    selectedPlatformByRedirect.value === 'wordpress';
  const isRedirectFromWebsiteBuilder =
    selectedPlatformByRedirect.value === 'website-builder' ||
    isWebsiteBuilderQuickInstall;
  // TODO: Remove this check after backend fixes `onboarding-builder-prefill` cookie assignment after purchase from www/website-builder
  const shouldPreselect = !!to.query?.preselect;

  closeIntercom();

  // TODO: Remove this logic after backend fixes `onboarding-builder-prefill` cookie assignment after purchase from www/website-builder
  // ! Cannot be removed after backend fixes, this place is used to redirect to the builder from quick install flow as well
  if (isRedirectFromWebsiteBuilder && shouldPreselect) {
    const resourcesStore = useResourcesStore();
    const frontendSettingsStore = useFrontendSettingsStore();
    const { redirectToBuilder } = useOnboardingSubmit();

    const resource = resourcesStore.getResourceBySubscriptionId(subscriptionId);

    if (!resource) {
      return next({ name: Route.Base.HOME });
    }

    frontendSettingsStore.setState('pageLoading', true);

    await Promise.all([
      amplitudeV2(AmplitudeEvent.Onboarding.ONBOARDING_START, {
        subscriptionId,
        isBuilderRedirect: true,
        isAddon: false,
        location: to.query?.location,
      }),
      amplitudeV2(AmplitudeEvent.Onboarding.SUMMARY_FINISHED, {
        isAddon: false,
        isBuilderRedirect: true,
      }),
      amplitudeV2(AmplitudeEvent.Onboarding.ONBOARDING_COMPLETED, {
        completionStatus: 'successfully',
        isBuilderRedirect: true,
        isAddon: false,
      }),
    ]);

    return await redirectToBuilder({
      hostingReferenceId: resource?.referenceId || '',
    });
  }

  amplitudeV2(AmplitudeEvent.Onboarding.ONBOARDING_START, {
    isAddon,
    subscriptionId,
    location: to.query?.location,
  });

  // TODO: Remove this logic after backend fixes `onboarding-builder-prefill` cookie assignment after purchase from www/website-builder
  if (isRedirectFromWordPress && shouldPreselect) {
    return next({
      name: Route.OnboardingV2.BASE_PATH,
      params: {
        order_id: subscriptionId,
        scenario: ONBOARDING_SCENARIO.SETUP_WORDPRESS_PRESELECTED,
      },
    });
  }

  next();
};

export default onboardingV2Guard;
