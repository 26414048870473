import { Hosting } from '@/types';

export const SUCCESS_MODAL_VISIBILITY_TIMEOUT = 2000;
export const ONE_MINUTE_IN_SECONDS = 60;

export const FIVE_MINUTES_IN_SECONDS = 300;
export const TEN_SECONDS_IN_MS = 10000;
export const ONE_MINUTE_IN_MS = 60000;
export const ONE_WEEK_IN_MS = 604800000;
export const CARD_PAYMENT_METHOD_TITLES_ARRAY = ['Card', 'Credit Card'];
export const NIAGAHOSTER_CLIENT_ID_PREFIX = 'nh_';

export const COMPLETION_PERCENTAGE = 100;
export const NEAR_COMPLETION_PERCENTAGE = 99;

export const NON_UPGRADABLE_HOSTING_PLANS = [
  Hosting.Plan.CLOUD_ENTERPRISE,
  Hosting.Plan.WOO_BUSINESS,
  Hosting.Plan.WORDPRESS_CLOUD_PROFESSIONAL,
];

export const ALL_CLOUD_PLANS = [
  Hosting.Plan.CLOUD_ECONOMY,
  Hosting.Plan.CLOUD_ENTERPRISE,
  Hosting.Plan.CLOUD_PROFESSIONAL,
];

export const ERROR_STATUS = {
  NOT_FOUND: 404,
} as const;

export const HTTP_STATUS_CODE_200 = 200;

export const MAXIMUM_COOKIE_LIFETIME = 400;

export const WHOIS_FIELD_VALUE_PLACEHOLDER = 'Not Applicable';

export const TEMPLATE_SLUGS_WITH_PALETTES = ['hostinger-blog'];

export const TEMPLATES_TO_HIDE = [
  Hosting.WordpressTemplateSlugs.HOSTINGER_BLOG,
];

export const TEMPLATES_WITH_NO_PREVIEW = [
  Hosting.WordpressTemplateSlugs.HOSTINGER_BLOG,
  Hosting.WordpressTemplateSlugs.HOSTINGER_AFFILIATE_THEME,
];

export const PAYPAL_COMMISSION_THRESHOLD = 50;
export const WIRE_TRANSFER_COMMISSION_THRESHOLD = 200;
export const DAYS_FOR_REFERRAL_APPROVAL = 45;
export const WEBSITE_OWNERSHIP_TRANSFER_EXPIRATION_LEN = 7;

export const BACKUP_FILE_LINK =
  'https://wetransfer.com/hostinger-files-uploaded-to-server';

export const YEAR_IN_DAYS = 365;
export const YEAR_IN_MONTHS = 12;
export const WEEK_IN_DAYS = 7;
