import type { FieldOption } from '@hostinger/hcomponents';
import dayjs from 'dayjs';
import { defineStore } from 'pinia';
import { computed, ref } from 'vue';

import { useVpsSubscriptions } from '@/composables';
import { hVpsRepo } from '@/repositories';
import type { IUpdatingServers, IUpgradingServer, VpsServer } from '@/types';
import { HVps, STORE_PERSISTENT_KEYS } from '@/types';
import { toUnicode } from '@/utils/helpers';

export const useVpsServerStore = defineStore(
  'vpsServerStore',
  () => {
    const { isVpsExpired } = useVpsSubscriptions();

    const managedId = ref<number | null>(null);
    const servers = ref<VpsServer[]>([]);
    const updatingServers = ref<IUpdatingServers[]>([]);
    const upgradingServers = ref<IUpgradingServer[]>([]);
    const isServersLoaded = ref(false);
    const purchasedLicenses = ref<number[]>([]);
    const isLicensesLoaded = ref(false);

    const fixingServers = ref<number[]>([]);

    const disabledManagementStatuses = [
      HVps.ServerState.CREATING,
      HVps.ServerState.SUSPENDED,
      HVps.ServerState.DESTROYED,
      HVps.ServerState.DESTROYING,
      HVps.ServerState.TRANSFERRING,
    ];

    const currentServer = computed(
      () =>
        servers.value.find(({ id }) => id === managedId.value) ||
        ({} as VpsServer),
    );

    const currentServerMainIpv4 = computed(
      () => currentServer.value.ipv4?.[0]?.address,
    );

    const isServerManagementDisabled = computed(
      () =>
        currentServer.value.state === HVps.ServerState.RECOVERY ||
        currentServer.value.actionsLock === HVps.ServerLock.LOCKED ||
        currentServer.value.nodeState === HVps.NodeStates.NOT_EXIST,
    );

    const setManagedId = (id: number | null) => {
      managedId.value = id;
    };
    const setServersLoaded = (value: boolean) => {
      isServersLoaded.value = value;
    };

    const setServerData = (serverId: number, data: VpsServer) => {
      const existingServers = servers.value.filter(({ id }) => id !== serverId);
      servers.value = [...existingServers, data];
    };

    const setServerLock = (serverId: number, isLocked: boolean) => {
      const server = servers.value.find(({ id }) => id === serverId);
      if (!server) return;

      const value = isLocked ? 'locked' : 'unlocked';
      server.actionsLock = value;
    };

    const setServerState = (serverId: number, state: HVps.ServerState) => {
      const server = servers.value.find(({ id }) => id === serverId);
      if (!server) return;
      server.state = state;
    };

    const setServerFeature = (
      serverId: number,
      feature: string,
      value: boolean,
    ) => {
      const server = servers.value.find(({ id }) => id === serverId);
      if (!server) return;

      const features = server.features || {};
      // @ts-ignore
      features[feature] = value;
      server.features = features;
    };

    const setTransferAvailability = (
      serverId: number,
      isTransferAvailable: boolean,
    ) => {
      const server = servers.value.find(({ id }) => id === serverId);
      if (!server) return;

      server.isTransferAvailable = isTransferAvailable;
    };

    const setUpdatingServer = ({
      serverId,
      duration,
      isCreating,
      isPersistent,
    }: {
      serverId: number;
      duration?: number;
      isCreating?: boolean;
      isPersistent?: boolean;
    }) => {
      updatingServers.value = updatingServers.value.filter(
        ({ updatingServerId }) => updatingServerId !== serverId,
      );

      updatingServers.value.push({
        updatingServerId: serverId,
        duration: duration ? duration * 1.25 : null,
        started: dayjs().unix(),
        isCreating: isCreating || false,
        isPersistent,
      });
    };

    const removeUpdatingServer = (serverId: number) => {
      updatingServers.value = updatingServers.value.filter(
        ({ updatingServerId }) => updatingServerId !== serverId,
      );
    };

    const setUpdatingServerData = (
      serverId: number,
      data: Partial<IUpdatingServers>,
    ) => {
      const updatingServer = updatingServers.value.find(
        ({ updatingServerId }) => updatingServerId === serverId,
      );

      if (!updatingServer) return;

      Object.assign(updatingServer, data);
    };

    const showOngoingAction = async (serverId: number) => {
      const [{ data }, error] = await hVpsRepo.getOngoingAction(serverId);

      if (error || !data.name) return;

      setServerLock(serverId, true);
      setUpdatingServer({ serverId, duration: data.time });
    };

    const addUpgradingServer = (payload: IUpgradingServer) => {
      upgradingServers.value.push(payload);
    };

    const removeUpgradingServer = (id: number) => {
      upgradingServers.value = upgradingServers.value.filter(
        ({ serverId }) => serverId !== id,
      );
    };

    const addPurchasedLicense = (serverId: number) => {
      purchasedLicenses.value.push(serverId);
    };

    const removePurchasedLicense = (serverId: number) => {
      purchasedLicenses.value = purchasedLicenses.value.filter(
        (id) => id !== serverId,
      );
    };

    const addFixingServer = (serverId: number) => {
      fixingServers.value.push(serverId);
    };

    const removeFixingServer = (serverId: number) => {
      fixingServers.value = fixingServers.value.filter((id) => id !== serverId);
    };

    const getServerOptions = () => {
      const options: FieldOption[] = [];

      const HIDDEN_STATE = [
        HVps.ServerState.INITIAL,
        HVps.ServerState.DESTROYED,
      ];

      servers.value.forEach((server) => {
        if (
          HIDDEN_STATE.includes(server.state) ||
          !server.hostname ||
          isVpsExpired(server.metadata.orderId)
        ) {
          return;
        }

        options.push({
          label: toUnicode(server.hostname),
          value: String(server.id),
        });
      });

      return options;
    };

    const getUpdatingServerById = (serverId: number) =>
      updatingServers.value.find(
        ({ updatingServerId }) => updatingServerId === serverId,
      );

    const isServerFixing = (serverId: number) =>
      !!fixingServers.value.find((id) => id === serverId);

    const getServerById = (serverId: number) =>
      servers.value.find(({ id }) => id === serverId);

    const getServerByOrderId = (orderId: string) =>
      servers.value.find((server) => server.metadata.orderId === orderId);

    const delay = (time: number) =>
      new Promise((resolve) => {
        setTimeout(resolve, time);
      });

    const fetchServers = async () => {
      const [{ data }, error] = await hVpsRepo.getServersData();
      if (error) return;
      servers.value = data;

      return [{ data }, error];
    };

    const fetchServerById = async ({
      serverId,
      iteration,
      callback,
      immediate,
    }: {
      serverId: number;
      iteration?: number;
      callback?: () => void;
      immediate?: boolean;
    }) => {
      const iterationDefault = iteration || 1;
      const toDelay = 30000;

      if (!immediate) await delay(toDelay);

      const serverLock = servers.value.find(
        ({ id }) => id === serverId,
      )?.actionsLock;

      if (
        (!immediate && serverLock === HVps.ServerLock.UNLOCKED) ||
        iterationDefault > 20
      ) {
        return;
      }

      const [{ data }, error] = await hVpsRepo.getServerData(serverId);
      if (error) {
        return { data, error };
      }

      setServerData(serverId, data);

      if (data.actionsLock === HVps.ServerLock.LOCKED) {
        await delay(toDelay);

        fetchServerById({
          serverId,
          iteration: iterationDefault + 1,
          callback,
        });

        return;
      }

      removeUpdatingServer(serverId);
      if (callback) callback();

      return { data, error };
    };

    const stopServer = async () => {
      const serverId = managedId.value;
      if (!serverId) return;

      setServerLock(serverId, true);
      const [_, error] = await hVpsRepo.stopServer(serverId);

      if (error) {
        setServerLock(serverId, false);

        return;
      }

      setServerState(serverId, HVps.ServerState.STOPPING);
      setUpdatingServer({ serverId });

      fetchServerById({ serverId });
    };

    const restartServer = async () => {
      const serverId = managedId.value;
      if (!serverId) return;

      setServerLock(serverId, true);
      const [_, error] = await hVpsRepo.restartServer(serverId);

      if (error) {
        setServerLock(serverId, false);

        return;
      }

      setServerState(serverId, HVps.ServerState.STOPPING);
      setUpdatingServer({ serverId });

      fetchServerById({ serverId });
    };

    const startServer = async () => {
      const serverId = managedId.value;
      if (!serverId) return;

      setServerLock(serverId, true);
      const [_, error] = await hVpsRepo.startServer(serverId);

      if (error) {
        setServerLock(serverId, false);

        return;
      }

      setServerState(serverId, HVps.ServerState.STARTING);
      setUpdatingServer({ serverId });

      fetchServerById({ serverId });
    };

    const resetServer = async (details: object) => {
      const serverId = managedId.value;
      if (!serverId) return;

      setServerLock(serverId, true);
      const [_, error] = await hVpsRepo.resetServer(serverId, details);

      if (error) {
        setServerLock(serverId, false);

        return;
      }

      if (currentServer.value.state === HVps.ServerState.ERROR) {
        addFixingServer(serverId);
      }

      setServerState(serverId, HVps.ServerState.RECREATING);
      setUpdatingServer({ serverId });

      fetchServerById({ serverId });
    };

    const updateServer = async (details: Partial<VpsServer>) => {
      const serverId = managedId.value;
      if (!serverId) return;

      setServerLock(serverId, true);
      const [{ data }, error] = await hVpsRepo.updateServer(serverId, details);

      if (error) {
        setServerLock(serverId, false);

        return;
      }

      setServerState(serverId, HVps.ServerState.UPDATING);
      setUpdatingServer({ serverId });

      fetchServerById({ serverId });

      return data;
    };

    const updateServerFeatures = async (details: object) => {
      const serverId = managedId.value;
      if (!serverId) return;

      setServerLock(serverId, true);
      const [_, error] = await hVpsRepo.updateFeatures(serverId, details);

      if (error) {
        setServerLock(serverId, false);

        return;
      }

      setUpdatingServer({ serverId });
      fetchServerById({ serverId });
    };

    const resetServerFeatures = async (details: object) => {
      const serverId = managedId.value;
      if (!serverId) return;

      setServerLock(serverId, true);
      const [_, error] = await hVpsRepo.resetFeatures(serverId, details);

      if (error) {
        setServerLock(serverId, false);

        return;
      }

      setUpdatingServer({ serverId });
      fetchServerById({ serverId });
    };

    const createReverseIp = async (details: object) => {
      const serverId = managedId.value;
      if (!serverId) return;

      setServerLock(serverId, true);
      const [_, error] = await hVpsRepo.createReverseIp(serverId, details);

      if (error) {
        setServerLock(serverId, false);

        return;
      }

      setUpdatingServer({
        serverId,
        isPersistent: true,
      });
      fetchServerById({ serverId });
    };

    const deleteReverseIp = async (details: object) => {
      const serverId = managedId.value;
      if (!serverId) return;

      setServerLock(serverId, true);
      const [_, error] = await hVpsRepo.deleteReverseIp(serverId, details);

      if (error) {
        setServerLock(serverId, false);

        return;
      }

      setUpdatingServer({
        serverId,
        isPersistent: true,
      });
      fetchServerById({ serverId });
    };

    const turnOnRecoveryMode = async (password: string) => {
      const serverId = managedId.value;
      if (!serverId) return;

      setServerLock(serverId, true);
      const [_, error] = await hVpsRepo.turnOnRecoveryMode(serverId, {
        rootPassword: password,
      });

      if (error) {
        setServerLock(serverId, false);

        return;
      }

      if (currentServer.value.state === HVps.ServerState.ERROR) {
        addFixingServer(serverId);
      }

      setUpdatingServer({ serverId });
      fetchServerById({ serverId });
    };

    const turnOffRecoveryMode = async () => {
      const serverId = managedId.value;
      if (!serverId) return;

      setServerLock(serverId, true);
      const [_, error] = await hVpsRepo.turnOffRecoveryMode(serverId);

      if (error) {
        setServerLock(serverId, false);

        return;
      }

      setUpdatingServer({ serverId });
      fetchServerById({ serverId });
    };

    const diskCleanup = async (details?: object) => {
      const serverId = managedId.value;
      if (!serverId) return;

      setServerLock(serverId, true);
      const [_, error] = await hVpsRepo.diskCleanup(serverId, details);

      if (error) {
        setServerLock(serverId, false);

        return;
      }

      setUpdatingServer({ serverId });
      fetchServerById({ serverId });
    };

    const fetchTransferAvailability = async () => {
      const serverId = managedId.value;
      if (!serverId) return;

      const [{ data }, error] = await hVpsRepo.getTransferAvailability(
        serverId,
      );

      if (error) return;

      setTransferAvailability(serverId, data.isAvailable);
    };

    const initiateServerTransfer = async (dataCenterId: number) => {
      const serverId = managedId.value;
      if (!serverId) return;

      setServerLock(serverId, true);
      const [_, error] = await hVpsRepo.transferServer(serverId, dataCenterId);

      if (error) {
        setServerLock(serverId, false);

        return;
      }

      setServerState(serverId, HVps.ServerState.TRANSFERRING);
      setUpdatingServer({ serverId });

      return !error;
    };

    const initiateReinstallServer = async (dataCenterId: number) => {
      const serverId = managedId.value;
      if (!serverId) return;

      setServerLock(serverId, true);
      const [_, error] = await hVpsRepo.moveServer(serverId, dataCenterId);

      if (error) {
        setServerLock(serverId, false);

        return;
      }

      setServerState(serverId, HVps.ServerState.RECREATING);
      setUpdatingServer({ serverId });

      return !error;
    };

    const deleteCpuLimit = async () => {
      const serverId = managedId.value;
      if (!serverId) return;

      setServerLock(serverId, true);
      const [_, error] = await hVpsRepo.deleteCpuLimit(serverId);

      if (error) {
        setServerLock(serverId, false);

        return;
      }

      setUpdatingServer({ serverId });
    };

    const reactivateLicense = async () => {
      const serverId = managedId.value;
      if (!serverId) return;

      setServerLock(serverId, true);
      const [_, error] = await hVpsRepo.reactivateLicense(serverId);

      if (error) {
        setServerLock(serverId, false);

        return;
      }

      setUpdatingServer({ serverId });
    };

    return {
      managedId,
      servers,
      purchasedLicenses,
      isLicensesLoaded,
      disabledManagementStatuses,
      currentServer,
      currentServerMainIpv4,
      isServerManagementDisabled,
      upgradingServers,
      isServersLoaded,
      updatingServers,
      setServersLoaded,
      setServerData,
      setServerLock,
      setServerState,
      setServerFeature,
      setTransferAvailability,
      setUpdatingServer,
      setManagedId,
      showOngoingAction,
      removeUpdatingServer,
      addUpgradingServer,
      removeUpgradingServer,
      addPurchasedLicense,
      removePurchasedLicense,
      addFixingServer,
      removeFixingServer,
      getServerOptions,
      getUpdatingServerById,
      isServerFixing,
      getServerById,
      getServerByOrderId,
      fetchServers,
      fetchServerById,
      stopServer,
      restartServer,
      startServer,
      resetServer,
      updateServer,
      updateServerFeatures,
      resetServerFeatures,
      createReverseIp,
      deleteReverseIp,
      turnOnRecoveryMode,
      turnOffRecoveryMode,
      diskCleanup,
      fetchTransferAvailability,
      initiateServerTransfer,
      initiateReinstallServer,
      setUpdatingServerData,
      deleteCpuLimit,
      reactivateLicense,
    };
  },
  {
    persist: { key: STORE_PERSISTENT_KEYS.VPS_SERVER },
  },
);
