<template>
  <div class="loader-body">
    <template v-for="n in rows" :key="n">
      <div class="body-rows">
        <div class="body-column">
          <template v-for="j in columns" :key="`${n}-${j}`">
            <HSkeletonLoader
              rounded-xs
              :width="120"
              :height="18"
              class="h-mt-4 h-mb-8"
            />
          </template>
        </div>
      </div>
    </template>
  </div>
</template>
<script>
export default {
  name: 'HDataTableRowSkeletonLoader',
  props: {
    rows: {
      type: Number,
      default: 3,
    },
    columns: {
      type: Number,
      default: 4,
    },
  },
};
</script>
<style lang="scss" scoped>
.body-rows {
  padding: 16px 24px;
  &:not(:last-child) {
    border-bottom: 1px solid var(--gray-border);
  }
}
.body-column {
  display: flex;
  justify-content: space-between;
}
</style>
