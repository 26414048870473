import { defineStore } from 'pinia';
import { ref, computed } from 'vue';
import { useStore } from 'vuex';

import { useGlobals } from '@/composables';
import { hMailIntegrationRepo, ordersRepo } from '@/repositories';
import { useResourcesStore } from '@/stores/resourcesStore';
import type {
  FreeEmailAvailableDomain,
  IHResource,
  OrderWithLimits,
  PusherEmailConfirmation,
} from '@/types';
import { AmplitudeEvent } from '@/types';
import { extractHAsyncErrorMessage } from '@/utils/helpers';
import { hToastrService } from '@/utils/services/hToastrService';
import { snakeToCamelObj } from '@/utils/services/namingConventionsService';

const FREE_EMAIL_SETUP_INTERVAL_TIMEOUT = 3000;
const FREE_EMAIL_SETUP_MAX_LOOP_REPETITIONS = 15;

class WaitingTimeoutError extends Error {
  constructor() {
    super('Max loop repetitions reached');
    this.name = 'WaitingTimeoutError';
  }
}

const domain_types = ['domain', 'free_domain'];
const email_types = ['email', 'titan_mail', 'google_workspace'];
const email_statuses = ['active', 'suspended', 'expired'];

export const useFreeEmailServiceStore = defineStore(
  'freeEmailServiceStore',
  () => {
    const { amplitudeV2 } = useGlobals();
    const store = useStore();
    const resourceStore = useResourcesStore();

    const freeAvailableDomains = ref<FreeEmailAvailableDomain[]>([]);
    const createdEmailPusherResponse = ref<PusherEmailConfirmation | null>(
      null,
    );
    const availableHostingOrders = ref<OrderWithLimits[]>([]);
    const isLoaded = ref(false);
    const isLoading = ref(false);
    const isSetupLoading = ref(false);
    const loopRepetitions = ref(1);

    const hasFreeAvailableDomains = computed(
      () => !!freeAvailableDomains.value.length,
    );

    const mergeAndDeduplicateDomains = (
      domains: FreeEmailAvailableDomain[],
      allDomainsData: IHResource[],
    ) => {
      const domainMap = new Map();

      domains.forEach(({ domain, hostingId }) => {
        domainMap.set(domain, { domain, hostingId });
      });

      allDomainsData.forEach(({ title }) => {
        if (!domainMap.has(title)) {
          domainMap.set(title, { domain: title, hostingId: null });
        }
      });

      return Array.from(domainMap.values());
    };

    const fetchDomainsList = async () => {
      isLoading.value = true;
      isLoaded.value = false;

      const [{ data }, err] =
        await hMailIntegrationRepo.getFreeAvailableDomains();

      const usedDomains = resourceStore.resources
        .filter(
          ({ state, type }) =>
            email_statuses.includes(state) && email_types.includes(type),
        )
        .map(({ title }) => title);

      const allDomainsData = resourceStore.resources.filter(
        ({ title, state, type }) =>
          state === 'active' &&
          domain_types.includes(type) &&
          !usedDomains.includes(title),
      );

      isLoading.value = false;

      if ((!data && !allDomainsData) || err) {
        return;
      }

      isLoaded.value = true;

      freeAvailableDomains.value = mergeAndDeduplicateDomains(
        data,
        allDomainsData,
      );
    };

    const getHostingOrders = async () => {
      isLoading.value = true;
      isLoaded.value = false;

      const [{ data }, err] = await ordersRepo.getListWithLimits(false);
      if (!data && err) {
        return;
      }

      const orders = resourceStore.activeHostingResources;

      const filteredData = data.reduce((result, item) => {
        if (
          item.limits.addons.count >= item.limits.addons.limit ||
          !item.username
        ) {
          return result;
        }

        const matchingOrder = orders.find(
          ({ referenceId }) => referenceId === String(item.orderId),
        );

        if (matchingOrder) {
          result.push({
            ...item,
            orderId: matchingOrder.chargebeeSubscriptionId as string,
          });
        }

        return result;
      }, [] as Array<(typeof data)[number] & { orderId: string }>);

      isLoading.value = false;
      isLoaded.value = true;

      availableHostingOrders.value = filteredData;
    };

    const fetchFreeAvailableDomains = async () => {
      isLoading.value = true;

      const [{ data }, err] =
        await hMailIntegrationRepo.getFreeAvailableDomains();

      isLoading.value = false;

      if (err || !data) return;

      freeAvailableDomains.value = data;
      isLoaded.value = true;
    };

    const setCreatedEmailPusherResponse = (data: PusherEmailConfirmation) => {
      createdEmailPusherResponse.value = snakeToCamelObj(data);
    };

    const waitForCreatedEmailPusherResponse = () =>
      new Promise((resolve, reject) => {
        const interval = setInterval(() => {
          if (createdEmailPusherResponse.value) {
            clearInterval(interval);

            resolve(true);

            return;
          }

          if (loopRepetitions.value >= FREE_EMAIL_SETUP_MAX_LOOP_REPETITIONS) {
            clearInterval(interval);
            reject(new WaitingTimeoutError());

            return;
          }

          loopRepetitions.value += 1;
        }, FREE_EMAIL_SETUP_INTERVAL_TIMEOUT);
      });

    const resetWaiterState = () => {
      createdEmailPusherResponse.value = null;
      loopRepetitions.value = 1;
    };

    const claimFreeEmail = async (
      domain: FreeEmailAvailableDomain,
      refetch: boolean = true,
    ) => {
      isSetupLoading.value = true;

      const [_, err] = await hMailIntegrationRepo.postClaimFreeEmail(domain);

      if (err) {
        isSetupLoading.value = false;

        return;
      }

      try {
        await waitForCreatedEmailPusherResponse();

        const [resourcesResponse] = await Promise.all([
          resourceStore.fetchResources(),
          store.dispatch('emails/fetchEmails'),
        ]);
        const [_, err] = resourcesResponse;

        if (err) {
          hToastrService.e(extractHAsyncErrorMessage(err));
        }

        return {
          ...createdEmailPusherResponse.value,
        } as PusherEmailConfirmation;
      } catch (error) {
        if (error instanceof WaitingTimeoutError) {
          amplitudeV2(
            AmplitudeEvent.Email.EMAIL_CLAIM_FREE_EMAIL_TIMEOUT_ERROR_SHOWN,
          );

          // it is very likely that the email was created but the pusher event was not received
          // so we reload the page to get the data with latest state
          window.location.reload();
        }
      } finally {
        isSetupLoading.value = false;
        resetWaiterState();
        refetch && fetchFreeAvailableDomains();
      }
    };

    return {
      getHostingOrders,
      claimFreeEmail,
      fetchFreeAvailableDomains,
      setCreatedEmailPusherResponse,
      fetchDomainsList,
      createdEmailPusherResponse,
      freeAvailableDomains,
      isSetupLoading,
      isLoaded,
      isLoading,
      hasFreeAvailableDomains,
      availableHostingOrders,
    };
  },
);
