import type { NavigationGuardNext, RouteLocationNormalized } from 'vue-router';

import cpanelGuard from '@/guards/cpanelGuard';
import { Route } from '@/types';
import { RouteOwner } from '@/types/enums/routeOwnerEnum';

const sidemenu = {
  sideMenuExists: true,
  sideMenuComponent: {
    component: 'CPanelSidebarMenu',
    menu: 'emailCpanelMenu',
  },
};

const emailCpanelRoutes = [
  {
    path: '/email-cpanel/:domain',
    name: Route.EmailCpanel.INDEX,
    meta: {
      title: 'Email',
      menu: ['horizontal'],
      routeOwner: RouteOwner.EMAILS,
      ...sidemenu,
    },
    component: () => import('@/views/EmailCpanel/EmailCpanelWrapper.vue'),
    beforeEnter: (
      to: RouteLocationNormalized,
      from: RouteLocationNormalized,
      next: NavigationGuardNext,
    ) => cpanelGuard(to, from, next),
    redirect: (to: RouteLocationNormalized) => ({
      query: to.query,
      params: to.params,
      name: Route.Base.HOSTING,
    }),
    children: [
      {
        path: 'dashboard',
        name: Route.EmailCpanel.DASHBOARD,
        meta: {
          title: 'Dashboard',
          breadcrumbs: ['emailcpanel'],
          breadcrumbsTitle: {
            title: '{domain} - Dashboard',
            translate: true,
          },
          menu: ['emailCpanelMenu'],
          icon: 'overview',
          ...sidemenu,
        },
        component: () => import('@/views/EmailCpanel/EmailCpanelDashboard.vue'),
      },
      {
        path: 'details',
        name: Route.EmailCpanel.DETAILS,
        meta: {
          title: 'Details',
          breadcrumbs: ['emailcpanel'],
          breadcrumbsTitle: {
            title: '{domain} - Details',
            translate: true,
          },
          menu: ['emailCpanelMenu'],
          icon: 'icon-info',
          ...sidemenu,
        },
        component: () =>
          import('@/views/EmailCpanel/EmailCpanelDetailsPage.vue'),
      },
      {
        path: 'upgrade',
        name: Route.EmailCpanel.UPGRADE,
        meta: {
          title: 'Upgrade',
          hideTitle: true,
          sideMenuExists: false,
        },
        component: () => import('@/views/EmailCpanel/EmailCpanelUpgrade.vue'),
      },
    ],
  },
];

export default emailCpanelRoutes;
