import { isocountries } from '@/data/isocountries';
import { useWhoisStore } from '@/stores/domain/whoisStore';
import { TLDS } from '@/types';

const ORGANIZATION_TYPE_TLDS = [
  'com.au',
  'ind.br',
  'org.br',
  'tur.br',
  'srv.br',
  'agr.br',
  'inf.br',
  'tv.br',
  'imb.br',
  'etc.br',
  'esp.br',
];

const INDIVIDUAL_TYPE_TLDS = [
  'arq.br',
  'adm.br',
  'adv.br',
  'pro.br',
  'med.br',
  'bio.br',
  'psc.br',
  'cnt.br',
  'eti.br',
  'fot.br',
  'mus.br',
  'ntr.br',
  'ppg.br',
  'vet.br',
  'cim.br',
  'cng.br',
  'eng.br',
  'jor.br',
];
const REQUIRES_ES_TERMS_TLDS = ['es', 'com.es', 'nom.es', 'org.es'];

const REQUIRES_EU_RESIDENT_TLDS = ['fr', 're', 'yt', 'pm', 'tf', 'wf'];

export const isOrganizationTypeTld = (tld: string) =>
  ORGANIZATION_TYPE_TLDS.includes(tld);

export const isIndividualTypeTld = (tld: string) =>
  INDIVIDUAL_TYPE_TLDS.includes(tld);

export const isEuResidentRequiredTld = (tld: string) =>
  REQUIRES_EU_RESIDENT_TLDS.includes(tld);

export const isWhoisDetailsConfirmRequiredTld = (tld: string) =>
  isLtTld(tld) || isUkTld(tld) || isInTld(tld);

export const isEsTermsRequiredTld = (tld: string) =>
  REQUIRES_ES_TERMS_TLDS.includes(tld);

export const compareTlds = (domainTld: string, comparedAgainstTld: string) =>
  domainTld === comparedAgainstTld;

export const isLtTld = (tld: string) => tld === 'lt';

export const isUkTld = (tld: string) =>
  tld === TLDS.UK || tld.endsWith(`.${TLDS.UK}`);

export const isInTld = (tld: string) =>
  tld === TLDS.IN || tld.endsWith(`.${TLDS.IN}`);

export const getCountryOptions = (tld: string, country: string) => {
  const whoisStore = useWhoisStore();
  const allowedCountries = whoisStore.getTldAllowedCountries(tld);
  const notAllowedCountries = ['UK', 'XK', 'SS', 'IC'];

  return isocountries
    .flatMap(({ label, value }) => {
      if (
        notAllowedCountries.includes(value) ||
        (allowedCountries && !allowedCountries.includes(value))
      ) {
        return [];
      }

      return {
        label,
        value,
        selected: value === country || allowedCountries?.length === 1,
      };
    })
    .sort((a, b) => (a.label > b.label ? 1 : -1));
};
