export namespace HelpPage {
  export enum TopicId {
    HOSTING_SETUP = 'hosting_setup',
    WEBSITE_ISSUES = 'website_issues',
    WEBSITE_BUILDER = 'website_builder',
    DOMAIN = 'domain',
    BILLING = 'billing',
    EMAIL = 'email',
    HOSTING_MANAGE = 'hosting_manage',
    SSL = 'ssl',
    VPS = 'vps',
    CPANEL = 'cpanel_hosting',
  }

  export enum SubtopicId {
    INSTALL_APP = 'install_app',
    ADD_DOMAIN = 'add_domain',
    BACKUP = 'backup',
    HOSTING_DETAILS = 'hosting_details',
    RESTORE = 'restore',
    TRANSFER_WEBSITE = 'transfer_website',
    TRANSFER_DOMAIN = 'transfer_domain',
    REFUND = 'refund',
  }

  export enum TeamLabels {
    ACCOUNT = 'account',
    DOMAIN = 'domain',
    WEBSITE = 'website',
    BILLING = 'billing',
    EMAIL = 'email',
    WEBSITE_BUILDER = 'website_builder',
    VPS_PAGE = 'vps_page',
  }

  export enum ActiveProblemStatus {
    CRITICAL = 'critical',
    WARNING = 'warning',
  }

  export enum ActiveProblemType {
    AI_TROUBLESHOOT = 'ai_troubleshoot',
    DOMAIN_NOT_POINTING = 'domain_not_pointing',
    DOMAIN_NOT_VERIFIED = 'domain_not_verified',
    DOMAIN_NOT_POINTING_BUILDER_CNAME = 'domain_not_pointing_builder_cname',
    NONE = 'none', // no issues found
    UNKNOWN = 'unknown', // found an unknown or not yet covered case
  }

  export enum LearnMoreLink {
    DOMAIN_NOT_POINTING = 'https://support.hostinger.com/en/articles/1863967-how-to-point-domain-to-hostinger',
    DOMAIN_NOT_VERIFIED = 'https://support.hostinger.com/en/articles/1583443-how-to-verify-a-domain',
    DOMAIN_NOT_POINTING_BUILDER = 'https://support.hostinger.com/en/articles/6627577-how-to-point-a-domain-to-hostinger-website-builder',
  }

  export enum LearnMoreLinkNiagahoster {
    DOMAIN_NOT_POINTING = 'https://support.niagahoster.co.id/cara-mengarahkan-domain-ke-niagahoster',
    DOMAIN_NOT_VERIFIED = 'https://support.niagahoster.co.id/bagaimana-cara-verifikasi-kepemilikan-domain',
    DOMAIN_NOT_POINTING_BUILDER = 'https://support.niagahoster.co.id/cara-mengarahkan-domain-ke-niagahoster',
  }

  export enum StatusPageLink {
    hostinger = 'https://statuspage.hostinger.com',
    niagahoster = 'https://status.niagahoster.co.id',
  }

  export enum PrecheckFunction {
    HostingExpiryCheck = 'hostingExpiryCheck',
    HostingSuspendedCheck = 'hostingSuspendedCheck',
    CPanelHostingExpiryCheck = 'cPanelHostingExpiryCheck',
    CPanelHostingSuspendedCheck = 'cPanelHostingSuspendedCheck',
    DomainExpiryCheck = 'domainExpiryCheck',
    DomainAbuseSuspendedCheck = 'domainAbuseSuspendedCheck',
    ServerClientHoldStatusCheck = 'serverClientHoldStatusCheck',
    DomainVerificationCheck = 'domainVerificationCheck',
    DomainRegistrationIssueCheck = 'domainRegistrationIssueCheck',
    DomainPointingCheck = 'domainPointingCheck',
    WebsiteSuspensionCheck = 'websiteSuspensionCheck',
    EmailSuspendedCheck = 'emailSuspendedCheck',
    EmailExpiryCheck = 'emailExpiryCheck',
    WordpressErrorCheck = 'wordpressErrorCheck',
  }

  export enum PrecheckType {
    WEBSITE = 'website',
    DOMAIN = 'domain',
    EMAIL = 'email',
    CPANEL = 'cpanel',
  }

  export enum AssistanceType {
    LIVE_CHAT = 'live-chat',
    TICKET = 'ticket',
  }

  export enum DowntimeType {
    DOMAIN_REGISTRATION = 'domain registration',
    MAIL = 'mail',
    CDN = 'cdn',
    DNS = 'dns',
    NETWORK = 'network',
    MAINTENANCE = 'maintenance',
    EMERGENCY = 'emergency',
    DEGRADED = 'degraded',
  }

  export interface Emits {
    (eventName: 'open-live-chat'): void;
    (eventName: 'submit-ticket'): void;
    (eventName: 'jump-to-ticket', ticketId: string): void;
  }

  export enum WebsiteState {
    DISABLED = '0',
    ACTIVE = '1',
  }

  export const NewResource = {
    Domain: {
      Slug: 'v2.precheck.subscriptionSelection.newDomain',
      Text: 'New domain',
    },
    Website: {
      Slug: 'v2.precheck.subscriptionSelection.newWebsite',
      Text: 'New Website',
    },
    Vps: {
      Slug: 'v2.precheck.subscriptionSelection.newVps',
      Text: 'New VPS',
    },
    Email: {
      Slug: 'v2.precheck.subscriptionSelection.newEmail',
      Text: 'New Email',
    },
    Cpanel: {
      Slug: 'v2.precheck.subscriptionSelection.newCpanel',
      Text: 'New cPanel Service',
    },
  };

  export const SelectorPlaceholder = {
    Cpanel: 'v2.precheck.subscriptionSelector.cpanel.placeholder',
  };

  export const UnsupportedTldLookup = [
    '.edu.ar',
    '.com.bo',
    '.org.bo',
    '.edu.bo',
    '.bo',
    '.hu',
    '.sn',
    '.idn.cn',
    '.gov.cm',
    '.pics',
    '.tk',
    '.ga',
    '.pf',
    '.cf',
    '.gq',
    '.africa.com',
    '.com.tr',
    '.kg',
    '.co.ug',
    '.ug',
    '.ae',
    '.ml',
    '.ja',
    '.lol',
    '.ac.ug',
    '.to',
    '.inc',
    '.eu.org',
    '.edu.eu',
    '.sc.ug',
    '.mom',
    '.can.re',
    '.com.tw',
    '.no',
    '.es',
  ];
}
