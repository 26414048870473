import { defineStore } from 'pinia';
import { ref, computed } from 'vue';
import { useStore } from 'vuex';

import { serverMaintenanceRepo } from '@/repositories';
import type { IServerMaintenance } from '@/types';

export const useServerMaintenanceStore = defineStore(
  'serverMaintenanceStore',
  () => {
    const store = useStore();
    const activeMaintenances = ref<IServerMaintenance[]>([]);
    const scheduledMaintenances = ref<IServerMaintenance[]>([]);
    const activeBuilderMaintenances = ref<IServerMaintenance[]>([]);
    const scheduledBuilderMaintenances = ref<IServerMaintenance[]>([]);
    const scheduledGlobalMaintenances = ref<IServerMaintenance[]>([]);
    const activeGlobalMaintenances = ref<IServerMaintenance[]>([]);
    const allBuilderAccountsReferenceIds = computed(
      () =>
        store.getters.getAllBuilderAccounts.map(
          (account: any) => account.referenceId,
        ) || [],
    );
    const CDN_MAINTENANCE_ID = '084p23v9l494';
    const SERVICES_MAINTENANCE_ID = 'xbjzqxx4h5lj';

    const getOrderActiveMaintenances = (orderId: string) =>
      activeMaintenances.value.filter((maintenance) =>
        maintenance.orders.includes(orderId),
      );

    const getOrderScheduledMaintenances = (orderId: string) =>
      scheduledMaintenances.value.filter(({ orders }) =>
        orders.includes(orderId),
      );

    const latestMaintenanceBanner = computed(() => {
      const maintenances = [
        ...activeMaintenances.value,
        ...scheduledMaintenances.value,
      ];

      return maintenances.reduce((latest, maintenance) => {
        if (
          new Date(latest.scheduledFor) > new Date(maintenance.scheduledFor)
        ) {
          return maintenance;
        }

        return latest;
      }, maintenances[0]);
    });

    const getBuilderMaintenance = (serverMaintenance: IServerMaintenance[]) =>
      serverMaintenance.filter((maintenance) => {
        const hasWebsiteBuilder = allBuilderAccountsReferenceIds.value.some(
          (value: number) => maintenance?.orders?.includes(value?.toString()),
        );

        return (
          maintenance.name
            ?.toLocaleLowerCase()
            .includes('hostinger website builder') && hasWebsiteBuilder
        );
      });
    const hasFranceDatacenter = computed(() =>
      store.getters.hasDatacenterByName('Europe (France)'),
    );
    const isCdnMaintenanceActive = computed(() => {
      if (!hasFranceDatacenter.value) {
        return false;
      }

      return activeGlobalMaintenances.value.some(
        (maintenance) => maintenance.id === CDN_MAINTENANCE_ID,
      );
    });
    const isCdnMaintenanceScheduled = computed(() =>
      scheduledGlobalMaintenances.value.some(
        (maintenance) => maintenance.id === CDN_MAINTENANCE_ID,
      ),
    );

    const fetchServerMaintenances = async () => {
      const [{ data }, error] =
        await serverMaintenanceRepo.getServerMaintenanceIndex();

      if (error) return;

      activeMaintenances.value = data.activeMaintenances || [];
      scheduledMaintenances.value = data.scheduledMaintenances || [];

      activeBuilderMaintenances.value = getBuilderMaintenance(
        data.globalMaintenances?.activeMaintenances || [],
      );
      scheduledBuilderMaintenances.value = getBuilderMaintenance(
        data.globalMaintenances?.scheduledMaintenances || [],
      );

      scheduledGlobalMaintenances.value =
        data.globalMaintenances?.scheduledMaintenances || [];
      activeGlobalMaintenances.value =
        data.globalMaintenances?.activeMaintenances || [];
    };

    return {
      latestMaintenanceBanner,
      activeMaintenances,
      scheduledMaintenances,
      activeBuilderMaintenances,
      scheduledBuilderMaintenances,
      activeGlobalMaintenances,
      scheduledGlobalMaintenances,
      fetchServerMaintenances,
      getOrderActiveMaintenances,
      getOrderScheduledMaintenances,
      isCdnMaintenanceActive,
      isCdnMaintenanceScheduled,
      CDN_MAINTENANCE_ID,
      SERVICES_MAINTENANCE_ID,
    };
  },
);
