import { defineStore } from 'pinia';
import { ref, computed } from 'vue';

import { websiteRepo } from '@/repositories';
import whOwnershipTransferRepo from '@/repositories/whApi/whOwnershipTransferRepo';
import { useProfileStore } from '@/stores/profile/profileStore';
import { Hosting, STORE_PERSISTENT_KEYS } from '@/types';
import type { IOwnershipTransfer, IOwnershipTransferExtended } from '@/types';
import { getDaysUntil, toUnicode } from '@/utils/helpers';
import { generateRandomDomainNameFromDomain } from '@/utils/helpers/domainsHelper';

export const useWebsiteOwnershipTransferStore = defineStore(
  'websiteOwnershipTransferStore',
  () => {
    const profileStore = useProfileStore();

    const isLoadingTransferList = ref(false);
    const transfers = ref<IOwnershipTransferExtended[] | null>(null);
    const acceptingTransfer = ref<IOwnershipTransferExtended | null>(null);

    const $reset = () => {
      isLoadingTransferList.value = false;
      transfers.value = null;
      acceptingTransfer.value = null;
    };

    const incomingTransferList = computed(() =>
      (transfers.value || []).filter(({ toClientId }) =>
        profileStore.getIsSameClientId(toClientId),
      ),
    );

    const activeIncomingTransferList = computed(() =>
      incomingTransferList.value.filter(
        ({ expiresAt, transferRequestState }) =>
          getDaysUntil(expiresAt) >= 0 &&
          transferRequestState ===
            Hosting.OwnershipTransferRequestState.PENDING,
      ),
    );

    const outgoingTransferList = computed(() =>
      transfers.value?.filter(
        ({ toClientId }) => !profileStore.getIsSameClientId(toClientId),
      ),
    );

    const fetchOwnershipTransferList = async () => {
      isLoadingTransferList.value = true;
      const [{ data }, err] = await websiteRepo.getOwnershipTransferList();
      isLoadingTransferList.value = false;
      if (err) return;

      transfers.value =
        data
          .map((transfer) => ({
            ...transfer,
            temporaryDomain: generateRandomDomainNameFromDomain(
              toUnicode(transfer.fromDomain),
            ),
          }))
          .sort(
            (a: IOwnershipTransfer, b: IOwnershipTransfer) =>
              new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
          ) || [];
    };

    const findTransferByToken = (token: string) =>
      transfers.value?.find(({ transferToken }) => transferToken === token);

    const findTransferByDomain = (domain: string) =>
      transfers.value?.find(({ fromDomain }) => fromDomain === domain);

    const cancelOwnershipTransfer = async (token: string) => {
      const [, err] = await whOwnershipTransferRepo.rejectOwnershipTransfer(
        token,
      );
      if (err) return err;

      fetchOwnershipTransferList();

      return null;
    };

    return {
      transfers,
      incomingTransferList,
      activeIncomingTransferList,
      outgoingTransferList,
      isLoadingTransferList,
      acceptingTransfer,
      $reset,
      fetchOwnershipTransferList,
      findTransferByToken,
      findTransferByDomain,
      cancelOwnershipTransfer,
    };
  },
  { persist: { key: STORE_PERSISTENT_KEYS.WEBSITE_OWNERSHIP_TRANSFER_STORE } },
);
