import type { Header, TLanguageCode } from '@/types';

export const REPORTS_TABS = {
  SCHEDULES: 'schedules',
  REPORTS: 'reports',
  TEMPLATES: 'templates',
} as const;

export type ReportTab = (typeof REPORTS_TABS)[keyof typeof REPORTS_TABS];

export enum SendOrScheduleFormFields {
  RECEIVER = 'receiver',
  SCHEDULE_WEEK_DAY = 'scheduleWeekDay',
  SCHEDULE_HOUR = 'scheduleHour',
  SCHEDULE_DAY = 'scheduleDay',
  TEMPLATE_NAME = 'templateName',
}

export type SendOrScheduleForm = {
  [SendOrScheduleFormFields.RECEIVER]: string;
  [SendOrScheduleFormFields.SCHEDULE_WEEK_DAY]: string;
  [SendOrScheduleFormFields.SCHEDULE_HOUR]: string;
  [SendOrScheduleFormFields.SCHEDULE_DAY]: string;
  [SendOrScheduleFormFields.TEMPLATE_NAME]: string;
};

export enum CreateReportStep {
  SELECT_WEBSITE = 'website-selection',
  REPORT_DETAILS = 'report-details',
  REPORT_BRANDING = 'report-branding',
  REPORT_LAYOUT = 'report-layout',
  SEND_OR_SCHEDULE = 'send-or-schedule',
}

export enum ReportState {
  NOT_STARTED = 'not_started',
  PREPARING = 'preparing',
  BACKUPS_IN_PROGRESS = 'backups_in_progress',
  PAGE_SPEED_IN_PROGRESS = 'page_speed_in_progress',
  SECURITY_IN_PROGRESS = 'security_in_progress',
  READY = 'ready',
  FAILED = 'failed',
}

export enum ScheduleType {
  NONE = 'none',
  MONTHLY = 'monthly',
  WEEKLY = 'weekly',
}

export type ReportRequestHeaders = {
  [Header.ORDER_ID]: string;
  [Header.USERNAME]: string;
  [Header.DOMAIN]: string;
};

export type ReportStateResponse = {
  state: ReportState;
  documentKey: string;
};

export type ReportStateRequest = {
  params: { directory: string };
  headers: ReportRequestHeaders;
};

export type ReportBase = {
  title: string;
  introMessage: string;
  contactDetails: ReportContactDetails;
  structure: ReportStructure;
  locale: TLanguageCode;
};

export type ReportContactDetails = {
  companyName: string | null;
  email: string | null;
  phone: string | null;
};

export type ReportStructure = {
  overview?: boolean;
  performance?: boolean;
  security?: boolean;
  backup?: boolean;
};

export type ReportTitleMessage = Pick<
  ReportBase,
  'locale' | 'title' | 'introMessage'
>;
export type MainDetailsKeys = keyof ReportTitleMessage;

export type ContactDetailsKeys = keyof ReportContactDetails;

export interface ReportBaseSchedule {
  scheduleType: ScheduleType;
  scheduleValue?: string | null;
  scheduleHour?: string | null;
}

export interface ReportScheduleRequest {
  scheduleType: ScheduleTypeParam | null;
  scheduleValue: string | null;
  scheduleHour: string | null;
}

export type ReportDetails = ReportBase & {
  domain: string;
  directory?: string;
  deliverToEmail: string | null;
  schedule: ReportBaseSchedule;
  whiteLabel?: WhiteLabel;
};

export type CreateReportRequest = {
  params: ReportBase & {
    directory?: string;
    deliverToEmail: string | null;
  };
  headers: ReportRequestHeaders;
};

export enum ScheduleTypeParam {
  DAY_OF_WEEK = 'day_of_week',
  DAY_OF_MONTH = 'day_of_month',
}
export type ReportScheduleParams = {
  scheduleType: ScheduleTypeParam;
  scheduleValue: string | null;
  scheduleHour: string;
};

export type ScheduleParamsWithEmail = ReportScheduleRequest & {
  deliverToEmail: string;
};

type ScheduleReportBase = ReportBase &
  ReportScheduleParams & {
    deliverToEmail: string | null;
  };

export type ScheduleReportRequest = {
  params: ScheduleReportBase;
  headers: ReportRequestHeaders;
};

export type ReportScheduledListItem = ScheduleReportBase & {
  domain: string;
  id: number;
  nextExecutionAt: string;
  username?: string;
  whiteLabel?: WhiteLabel;
};

export type ScheduleListResponse = {
  resources: ReportScheduledListItem[];
  pagination: {
    page: number;
    perPage: number;
    total: number;
    totalPages: number;
  };
};

export type ReportListItem = {
  id: string;
  createdAt: string;
  documentKey: string;
  state: ReportState;
  title: string;
  domain: string;
};

export type ReportListResponse = {
  data: ReportListItem[];
  total: number;
  totalPages: number;
  perPage: number;
  page: number;
};

export type TemplateContactDetails = {
  companyName: string | null;
  email: string | null;
  phone: string | null;
};

export type ReportTemplateBase = {
  locale: TLanguageCode;
  name: string;
  title: string;
  introMessage: string;
  contactDetails: TemplateContactDetails;
  structure: ReportStructure;
  whiteLabel?: WhiteLabel;
};

export type ReportTemplateRequest = ReportTemplateBase & ReportScheduleRequest;

export type GetReportTemplateResponse = ReportTemplateBase &
  ReportTemplateSchedule & {
    id: number;
    clientId: number;
  };

export type ReportTemplateSchedule = {
  scheduleType: ScheduleTypeParam | null;
  scheduleValue: string | null;
  scheduleHour: string | null;
};

export type WhiteLabel = {
  domain?: string | null;
  colors?: {
    primary: string | null;
    secondary: string | null;
  };
  logo?: {
    url: string | null;
    uuid: string | null;
  };
};
