import { statisticsRepo, lveRepo } from '@/repositories';
import { getStateVariable, setForDomain } from '@/store/storeHelper';
import { $t } from '@/utils/services/i18nService';

const explanations = {
  CPU: {
    explanation:
      'CPU usage is the percentage of time that the CPU is being used to complete its processes',
  },
  Memory: {
    explanation: "Amount of short-term memory used by hosting plan's processes",
  },
  'Max Processes': {
    explanation: $t('v2.max.processes.explanation'),
  },
  'PHP Workers': {
    explanation: $t('v2.php.workers.explanation'),
  },
  'Total requests': {
    explanation:
      'Total Requests show HTTP or/and HTTPS requests to all hosting account.',
  },
  'Unique IP addresses': {
    explanation:
      'The number of distinct IP addresses that accessed your websites.',
  },
  'Emails sent over SMTP': {
    explanation: 'Email over SMTP shows how many emails were sent using SMTP.',
  },
  'Emails sent over PHP': {
    explanation:
      'Email over PHP shows how many emails were sent using PHP mail() function.',
  },
  Bandwidth: {
    explanation:
      'Bandwidth is the amount of data that was transmitted in a fixed amount of time.',
  },
  'Throughput (I/O)': {
    explanation: `I/O (input/output) is the “throughput” or speed of data transfer between a hard disk and RAM`,
  },
  'Storage performance (IOPS)': {
    explanation: `IOPS (input/output operations per second) is the standard unit of measurement for the maximum number of reads and writes to non-contiguous storage locations.`,
  },
  'Detailed list of resource usage': {
    explanation:
      'Find out which process used the most resources in the last 24 hours',
  },
};
const explanationsLve = {
  cpu: {
    explanation:
      'CPU usage is the percentage of time that the CPU is being used to complete its processes',
  },
  memphy: {
    explanation: "Amount of short-term memory used by hosting plan's processes",
  },
  ep: {
    explanation: $t('v2.php.workers.explanation'),
  },
  nproc: {
    explanation: $t('v2.max.processes.explanation'),
  },
  Bandwidth: {
    explanation:
      'Bandwidth is the amount of data that was transmitted in a fixed amount of time.',
  },
  io: {
    explanation: `I/O (input/output) is the “throughput” or speed of data transfer between a hard disk and RAM`,
  },
  iops: {
    explanation: `IOPS (input/output operations per second) is the standard unit of measurement for the maximum number of reads and writes to non-contiguous storage locations.`,
  },
  'Detailed list of resource usage': {
    explanation:
      'Find out which process used the most resources in the last 24 hours',
  },
};
const initial = {
  statistics: {
    CPU: {},
    Memory: {},
    'Max Processes': {},
    'PHP Workers': {},
    'Detailed list of resource usage': [],
    'Total requests': {},
    'Unique IP addresses': {},
    'Emails sent over SMTP': {},
    'Emails sent over PHP': {},
    Bandwidth: {},
  },
  monthlyStatistics: {
    CPU: {},
    Memory: {},
    'Max Processes': {},
    'PHP Workers': {},
    'Throughput (I/O)': {},
    'Storage performance (IOPS)': {},
  },
  snapshot: {},
  loaded: false,
  snapshotLoaded: false,
  statisticsLve: {},
};

export default {
  state: {
    initial,
    data: [],
  },
  mutations: {
    domainChange: (state) => (state.data = [...state.data]),
    SET_HOSTING_STATISTICS(state, { data, requestDomain }) {
      Object.keys(data).forEach((key) => {
        data[key].name = key;
        data[key].explanation = explanations[key]?.explanation;
      });

      setForDomain(state, requestDomain, 'statistics', data);
    },
    SET_HOSTING_STATISTICS_LVE(state, { data, requestDomain }) {
      Object.keys(data).forEach((key) => {
        data[key].explanation = explanationsLve[key]?.explanation;
      });

      setForDomain(state, requestDomain, 'statisticsLve', data);
    },
    SET_HOSTING_MONTHLY_STATISTICS(state, { data, requestDomain }) {
      const dataObj = {};

      Object.keys(data).forEach((key) => {
        const stat = data[key];

        dataObj[stat.name] = {
          ...stat,
          explanation: explanations[stat.name]?.explanation,
          Unit: stat.unit,
          Limit: stat.limit,
        };
      });

      setForDomain(state, requestDomain, 'monthlyStatistics', dataObj);
    },
    SET_HOSTING_SNAPSHOT(state, { data, requestDomain }) {
      setForDomain(state, requestDomain, 'snapshot', data);
    },
    SET_HOSTING_ORDER_STATISTICS_LOADED(state, { data, requestDomain }) {
      setForDomain(state, requestDomain, 'loaded', data);
    },
    SET_HOSTING_ORDER_STATISTICS_SNAPSHOT_LOADED(
      state,
      { data, requestDomain },
    ) {
      setForDomain(state, requestDomain, 'snapshotLoaded', data);
    },
  },
  getters: {
    getHostingOrderStatistics: (state) => getStateVariable(state, 'statistics'),
    getHostingOrderStatisticsLve: (state) =>
      getStateVariable(state, 'statisticsLve'),
    getHostingOrderSnapshot: (state) => getStateVariable(state, 'snapshot'),
    getHostingOrderMonthlyStatistics: (state) =>
      getStateVariable(state, 'monthlyStatistics'),
    getHostingOrderStatisticsLoaded: (state) =>
      getStateVariable(state, 'loaded'),
    getHostingOrderStatisticsSnapshotLoaded: (state) =>
      getStateVariable(state, 'snapshotLoaded'),
  },
  actions: {
    async hostingOrderSnapshotIndex({ commit, getters }, requestDomain) {
      commit('SET_HOSTING_ORDER_STATISTICS_SNAPSHOT_LOADED', {
        requestDomain,
        data: false,
      });

      const [{ data }, err] = await statisticsRepo.getDetailsSnapshot(
        getters.currentOrderDetails.username,
      );

      if (data && data.length && !err) {
        const tableData = data.flatMap(({ dumpTime, snapProc }) =>
          Object.values(snapProc).map(({ CPU, MEM, CMD }) => ({
            date: dumpTime,
            CPU,
            MEM,
            name: CMD,
          })),
        );

        commit('SET_HOSTING_SNAPSHOT', {
          data: { 'Detailed list of resource usage': tableData },
          requestDomain,
        });
      }

      commit('SET_HOSTING_ORDER_STATISTICS_SNAPSHOT_LOADED', {
        requestDomain,
        data: true,
      });
    },
    async hostingOrderStatisticsLve({ commit }, params) {
      commit('SET_HOSTING_ORDER_STATISTICS_LOADED', {
        requestDomain: params.requestDomain,
        data: false,
      });

      const [{ data }] = await lveRepo.getLveMetricsByRangeAndInterval({
        params,
      });

      commit('SET_HOSTING_STATISTICS_LVE', {
        data: data || {},
        requestDomain: params.requestDomain,
      });

      commit('SET_HOSTING_ORDER_STATISTICS_LOADED', {
        requestDomain: params.requestDomain,
        data: true,
      });
    },
    async hostingOrderStatisticsIndex({ commit, getters }, requestDomain) {
      commit('SET_HOSTING_ORDER_STATISTICS_LOADED', {
        requestDomain,
        data: false,
      });

      const [{ data }, err] = await statisticsRepo.getStatistics({
        id: getters.getCurrentOrder?.id,
      });
      const newData = {};

      if (data) {
        Object.keys(data).forEach((key) => {
          newData[key] = data[key];
          if (key === 'Storage performance (IOPS)') {
            newData['Detailed list of resource usage'] = [];
          }
        });
      }

      commit('SET_HOSTING_STATISTICS', {
        data: !err ? newData : {},
        requestDomain,
      });

      commit('SET_HOSTING_ORDER_STATISTICS_LOADED', {
        requestDomain,
        data: true,
      });
    },
    async hostingMonthlyOrderStatisticsIndex(
      { commit, getters },
      requestDomain,
    ) {
      commit('SET_HOSTING_ORDER_STATISTICS_LOADED', {
        requestDomain,
        data: false,
      });
      const [{ data }, err] = await statisticsRepo.getStatisticsMonthly({
        id: getters.getCurrentOrder?.id,
      });

      commit('SET_HOSTING_MONTHLY_STATISTICS', {
        data: !err ? data : {},
        requestDomain,
      });

      commit('SET_HOSTING_ORDER_STATISTICS_LOADED', {
        requestDomain,
        data: true,
      });
    },
  },
};
