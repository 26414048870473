import type { IHDomainResource } from '@hostinger/hdomains-status';
import { computed, ref } from 'vue';

import { useHDomainState } from '@/composables/hDomains/useHDomainState';
import { useManagedDomains } from '@/composables/hostingerPro/useManagedDomains';
import { useGlobals } from '@/composables/useGlobals';
import { useDomainsTableStore } from '@/stores/domain/domainsTableStore';
import { AmplitudeEvent, SelectedDomainsList } from '@/types';

const isUserInitiatedTableActionInProgress = ref(false);

// This composable combines both managed and your own domains for the domains table
export const useCombinedDomainsTable = (sortedDomains?: IHDomainResource[]) => {
  const domainsTableStore = useDomainsTableStore();

  const { amplitudeV2 } = useGlobals();
  const { getIsManagedDomain } = useManagedDomains();
  const { isActive, isDomainResource } = useHDomainState();

  const domains = ref(sortedDomains || []);

  const isManagedExternalDomainsLoading = ref(false);

  const onHeaderDropdownChange = (value: SelectedDomainsList) => {
    amplitudeV2(AmplitudeEvent.Domain.FILTER_CHOSEN, { filterChosen: value });
    domainsTableStore.selectedDomainsList = value;
  };

  const getIsDomainVisible = (hDomainResource: IHDomainResource) => {
    const { selectedDomainsList } = domainsTableStore;

    if (selectedDomainsList === SelectedDomainsList.CLIENT_DOMAINS) {
      return getIsManagedDomain(hDomainResource);
    }

    if (selectedDomainsList === SelectedDomainsList.MY_DOMAINS) {
      return !getIsManagedDomain(hDomainResource);
    }

    return true;
  };

  const hasDomainsFilter = computed(() => {
    const hasManagedDomains = !!domains.value.some((item) =>
      getIsManagedDomain(item),
    );

    const hasOwnDomains = domains.value.some(
      (item) => !getIsManagedDomain(item),
    );

    return hasManagedDomains && hasOwnDomains;
  });

  const getIsDomainSelectCheckboxDisabled = (
    hDomainResource: IHDomainResource,
  ) => !isActive(hDomainResource) || !isDomainResource(hDomainResource);

  return {
    isManagedExternalDomainsLoading,
    hasDomainsFilter,
    getIsDomainVisible,
    onHeaderDropdownChange,
    isUserInitiatedTableActionInProgress,
    getIsDomainSelectCheckboxDisabled,
  };
};
