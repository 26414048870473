import type { RouteRecordRaw } from 'vue-router';

import cdnAndResourcesMaintenanceGuard from '@/guards/cdnAndResourcesMaintenanceGuard';
import { Route } from '@/types';

const sideMenu = {
  sideMenuExists: true,
  sideMenuComponent: 'SectionSideMenu',
};

export default [
  {
    path: 'performance',
    name: Route.HostingPerformance.PERFORMANCE,
    meta: {
      title: 'Performance',
      breadcrumbs: [Route.Base.WEBSITES, Route.Websites.WEBSITE_DASHBOARD],
      ...sideMenu,
    },
    redirect: { name: Route.Websites.WEBSITE_DASHBOARD, hash: '#performance' },
    component: () => import('@/views/Hosting/DashboardSection.vue'),
    children: [
      {
        path: 'object-cache',
        name: Route.HostingPerformance.OBJECT_CACHE,
        redirect: { name: Route.HostingWordpress.DASHBOARD },
      },
      {
        path: 'website-seo',
        name: Route.HostingPerformance.SEO,
        meta: {
          title: 'SEO Toolkit',
          breadcrumbs: [
            Route.Base.WEBSITES,
            Route.Websites.WEBSITE_DASHBOARD,
            Route.HostingPerformance.PERFORMANCE,
          ],
          ...sideMenu,
        },
        component: () => import('@/views/Hosting/Performance/SeoToolkit.vue'),
      },
      {
        path: 'cloudflare',
        name: Route.HostingPerformance.CLOUDFLARE,
        meta: {
          title: 'Cloudflare CDN',
          breadcrumbs: [
            Route.Base.WEBSITES,
            Route.Websites.WEBSITE_DASHBOARD,
            Route.HostingPerformance.PERFORMANCE,
          ],
          ...sideMenu,
        },
        component: () => import('@/views/Hosting/Performance/Cloudflare.vue'),
      },
      {
        path: 'page-speed',
        name: Route.HostingPerformance.PAGE_SPEED,
        meta: {
          title: 'Page Speed',
          breadcrumbs: [
            Route.Base.WEBSITES,
            Route.Websites.WEBSITE_DASHBOARD,
            Route.HostingPerformance.PERFORMANCE,
          ],
          ...sideMenu,
        },
        component: () =>
          import('@/views/Hosting/Performance/PageSpeed/PageSpeed.vue'),
      },
      {
        path: 'cdn',
        meta: {
          title: 'CDN',
          breadcrumbs: [
            Route.Base.WEBSITES,
            Route.Websites.WEBSITE_DASHBOARD,
            Route.HostingPerformance.PERFORMANCE,
          ],
          ...sideMenu,
          fullHeight: true,
        },
        component: () =>
          import('@/views/Hosting/Performance/Cdn/CdnWrapper.vue'),
        children: [
          {
            path: '',
            name: Route.HostingPerformance.CDN,
            meta: {
              title: 'CDN',
              breadcrumbs: [
                Route.Base.WEBSITES,
                Route.Websites.WEBSITE_DASHBOARD,
                Route.HostingPerformance.PERFORMANCE,
              ],
              ...sideMenu,
              fullHeight: true,
            },
            component: () => import('@/views/Hosting/Performance/Cdn/Cdn.vue'),
            beforeEnter: cdnAndResourcesMaintenanceGuard,
          },
          {
            path: 'controls/:domainToManage',
            name: Route.HostingPerformance.CDN_CONTROLS,
            meta: {
              subHeaderTitle: {
                title: '{domainToManage}',
              },
              sideMenuExists: false,
              breadcrumbs: false,
            },
            component: () =>
              import('@/views/Hosting/Performance/Cdn/CdnControls.vue'),
          },
        ],
      },
    ],
  },
] as RouteRecordRaw[];
