<script setup lang="ts">
import { FieldType, type FormValues } from '@hostinger/hcomponents';
import { useDebounceFn } from '@vueuse/core';
import cookies from 'js-cookie';
import { computed, ref, useSlots, watch } from 'vue';
import { useRoute } from 'vue-router';

import HMenu from '@/components/HMenuV2/HMenu.vue';
import HMenuBackItem from '@/components/HMenuV2/HMenuBackItem.vue';
import HMenuFeedback from '@/components/HMenuV2/HMenuFeedback.vue';
import HMenuList from '@/components/HMenuV2/HMenuList.vue';
import { useGlobals } from '@/composables';
import {
  useFeedbackStore,
  useFrontendSettingsStore,
  useHPanelControlsStore,
  useProfileStore,
} from '@/stores';
import type {
  IFeedbackProps,
  NavigationMenuItem,
  SideMenuBackButton,
  SideMenuRoute,
} from '@/types';
import { AmplitudeEvent, Cookie } from '@/types';

type Props = {
  routes?: SideMenuRoute[];
  navigationItems?: NavigationMenuItem[];
  footerNavigationItems?: NavigationMenuItem[];
  backButton?: SideMenuBackButton;
  backNavigationItem?: NavigationMenuItem;
  noPadding?: boolean;
  hasFeedback?: boolean;
  feedbackData?: IFeedbackProps;
  isSearchable?: boolean;
};

const props = withDefaults(defineProps<Props>(), {
  noPadding: true,
  hasFeedback: true,
  isSearchable: false,
});

const route = useRoute();
const slots = useSlots();
const frontendSettingsStore = useFrontendSettingsStore();
const hpanelControlsStore = useHPanelControlsStore();
const profileStore = useProfileStore();
const feedbackStore = useFeedbackStore();

const search = ref<string>('');

const includesSearchString = (title?: string) =>
  title?.toLowerCase().includes(search.value.toLowerCase());

const hasFooterNavigationItems = computed(
  () => !!props.footerNavigationItems?.length,
);

const filteredNavigationItems = computed(() => {
  if (!search.value) {
    const visibleItems =
      props.navigationItems?.filter(
        (item) =>
          !frontendSettingsStore.state.sideMenuHiddenLinks.includes(item.slug),
      ) ?? [];

    return visibleItems;
  }

  const newNavItems: NavigationMenuItem[] = [];
  props.navigationItems?.forEach((navigationItem) => {
    const children = includesSearchString(t(navigationItem.title))
      ? navigationItem.children
      : navigationItem.children?.filter(({ title }) =>
          includesSearchString(t(title)),
        );

    const parentWithFilteredChildren = {
      ...navigationItem,
      children,
    };

    if (
      parentWithFilteredChildren.children?.length ||
      (includesSearchString(t(parentWithFilteredChildren?.title)) &&
        (parentWithFilteredChildren?.to || parentWithFilteredChildren?.action))
    ) {
      newNavItems.push(parentWithFilteredChildren);
    }
  });

  return newNavItems ?? [];
});

const isMultiExpand = ref(false);

const { t, amplitudeV2 } = useGlobals();

const currentRouteName = computed(() => route.name as string);

const feedbackOpen = computed(() => frontendSettingsStore.state.feedbackOpen);

const showFeedbackButton = computed(
  () =>
    // Cookie.DISABLED_FEEDBACK is used by auto-tests
    !cookies.get(Cookie.DISABLED_FEEDBACK) &&
    props.hasFeedback &&
    !profileStore.isStaff &&
    !profileStore.isAccessManager &&
    feedbackStore.isCSATVisible,
);

const searchSchema = computed(() => ({
  type: FieldType.text,
  icon: { left: 'ic-search-24' },
  label: t('Search'),
  skipDisplayReq: true,
  custom: {
    hideOptionalLabel: true,
  },
}));

const sidemenuRef = ref<HTMLElement | undefined>(undefined);

const openFeedback = () => {
  feedbackStore.openCSATFeedback(props.feedbackData || ({} as IFeedbackProps));
  handleMenuSelect();
};

const handleMenuSelect = () => {
  if (!hpanelControlsStore.isMobileNavigationOpen) {
    return;
  }

  hpanelControlsStore.toggleMobileNavigation();
};

const handleNavigationSearch = useDebounceFn((formValues: FormValues) => {
  search.value = formValues.values.search;
}, 200);

const backButtonItemDataQa = computed(
  () => props.backNavigationItem?.dataQa || 'navigate-main-menu',
);

const backToSubmenu = () => {
  hpanelControlsStore.setNavigationLevel(1);
};

watch(
  () => search.value,
  (value) => {
    isMultiExpand.value = !!value;

    amplitudeV2(AmplitudeEvent.SideBar.SEARCHED, {
      searchInput: value,
    });
  },
);

(() => {
  if (!feedbackStore.isCSATLoaded) {
    feedbackStore.fetchCSATData();
  }
})();
</script>

<template>
  <div class="side-menu">
    <HMenu ref="sidemenuRef">
      <template
        v-if="slots.header || hpanelControlsStore.hasSecondLevelNavigation"
        #header
      >
        <slot name="header">
          <HMenuBackItem
            :navigation-item="{
              slug: 'overview',
              title: t('Main menu'),
              dataQa: backButtonItemDataQa,
            }"
            @click="backToSubmenu"
          />
        </slot>
      </template>

      <template v-if="slots['sub-header'] || isSearchable" #sub-header>
        <slot name="sub-header" />
        <HForm v-if="isSearchable" @on-change="handleNavigationSearch">
          <HFormField
            class="h-mb-0"
            :class="{
              'h-mt-16': isSearchable && slots['sub-header'],
            }"
            name="search"
            :schema="searchSchema"
          />
        </HForm>
      </template>

      <HMenuList
        v-if="filteredNavigationItems"
        :is-multi-expand="isMultiExpand"
        :navigation-items="filteredNavigationItems"
        :current-route="currentRouteName"
        @click="handleMenuSelect"
      />

      <template v-if="hasFooterNavigationItems" #footer-menu>
        <HMenuList
          v-if="hasFooterNavigationItems"
          :is-multi-expand="isMultiExpand"
          :navigation-items="footerNavigationItems || []"
          :current-route="currentRouteName"
          @click="handleMenuSelect"
        />
      </template>
      <template v-if="showFeedbackButton" #footer>
        <HMenuFeedback
          v-if="showFeedbackButton"
          :feedback-open="feedbackOpen"
          :menu="sidemenuRef"
          @toggle-feedback="openFeedback"
        />
      </template>
    </HMenu>
  </div>
</template>

<style lang="scss" scoped>
.side-menu {
  height: 100%;
}
</style>
