import type { FormValues } from '@hostinger/hcomponents';

export enum FieldType {
  text = 'text',
  textarea = 'textarea',
  password = 'password',
  number = 'number',
  date = 'date',
  select = 'select',
  color = 'color',
}

export interface TypedFormValues<T> extends FormValues {
  values: T;
}
