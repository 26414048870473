<script setup lang="ts">
//JSON-slugs-migrated
import type { HButtonColor } from '@hostinger/hcomponents';
import { computed } from 'vue';

import HpIcon from '@/components/Icons/HpIcon.vue';
import { useGlobals, useLoading, useModal } from '@/composables';
import type { ComponentProps } from '@/types';

type Props = {
  data: {
    icon?: Omit<ComponentProps<typeof HpIcon>, 'width' | 'height'>;
    title: string;
    subtitle?: string;
    translateParams: Record<string, string> | null;
    confirmActionLabel?: string;
    cancelActionLabel?: string;
    buttonColor?: HButtonColor;
    cancelButtonColor?: HButtonColor;
    shouldLoadOnConfirmAction?: boolean;
    isCancelButtonVisible?: boolean;
    shouldCloseAfterConfirmAction?: boolean;
    snackbarText: string;
    snackbarVariant: 'success' | 'warning' | 'error' | 'info';
    onConfirmAction: () => void;
    onCancelAction?: () => void;
    onSuccess?: () => void;
  };
};

const { closeModal } = useModal();
const {
  isLoading: isLoadingConfirmAction,
  setIsLoading: setIsLoadingConfirmAction,
} = useLoading();
const { t } = useGlobals();

const props = defineProps<Props>();

const modalData = computed(() => ({
  icon: props.data.icon,
  title: props.data.title,
  subtitle: props.data.subtitle,
  confirmActionLabel: props.data.confirmActionLabel || 'v2.confirm',
  cancelActionLabel: props.data.cancelActionLabel || 'v2.cancel',
  isCancelButtonVisible: props.data.isCancelButtonVisible ?? true,
  buttonColor: props.data.buttonColor || 'primary',
  cancelButtonColor: props.data.cancelButtonColor || 'primary',
  snackbarText: props.data.snackbarText,
  snackbarVariant: props.data.snackbarVariant,
  onConfirmAction: async () => {
    if (props.data.shouldLoadOnConfirmAction ?? true) {
      setIsLoadingConfirmAction(true);
    }
    if (props.data.onConfirmAction) {
      await props.data.onConfirmAction();

      if (props.data.shouldCloseAfterConfirmAction ?? true) {
        closeModal();
      }
    }
    setIsLoadingConfirmAction(false);
  },
  onCancelAction: props.data.onCancelAction || closeModal,
  onSuccess: props.data.onSuccess,
}));
</script>

<template>
  <div>
    <h2 class="h-mb-8">
      <HpIcon
        v-if="modalData.icon"
        v-bind="modalData.icon"
        :width="24"
        :height="24"
        class="general-modal__title-icon"
      />
      {{ t(modalData.title, props.data.translateParams) }}
    </h2>
    <Trans tag="p" class="h-mb-24">
      {{ modalData.subtitle }}
    </Trans>
    <p v-if="modalData.snackbarText" class="h-mb-24">
      <HSnackbar class="h-mt-16 h-mb-24" :variant="modalData.snackbarVariant">
        <span class="text-dark">
          {{ t(modalData.snackbarText) }}
        </span>
      </HSnackbar>
    </p>
    <div class="text-right general-modal__cta-wrapper">
      <HButton
        v-if="modalData.isCancelButtonVisible"
        v-qa-generate
        variant="text"
        class="h-mr-16"
        :is-disabled="isLoadingConfirmAction"
        :color="modalData.cancelButtonColor"
        @click="modalData.onCancelAction"
      >
        {{ t(modalData.cancelActionLabel) }}
      </HButton>
      <HButton
        v-if="modalData.onConfirmAction"
        v-qa-generate
        :is-loading="isLoadingConfirmAction"
        :color="modalData.buttonColor"
        @click="modalData.onConfirmAction"
      >
        {{ t(modalData.confirmActionLabel) }}
      </HButton>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.general-modal {
  &__title-icon {
    padding: 4px;
    margin-right: 4px;
    vertical-align: bottom;
  }
  &__cta-wrapper {
    @media (max-width: 576px) {
      display: flex;
      flex-direction: column-reverse;
      gap: 16px;
    }
  }
}
</style>
