import type { RouteRecordRaw } from 'vue-router';

import { Route, HIcon } from '@/types';
import { RouteOwner } from '@/types/enums/routeOwnerEnum';

const sideMenu = {
  sideMenuExists: true,
  sideMenuFixed: false,
  sideMenuComponent: {
    component: 'ProfileSidebar',
    menu: 'ProfileSidebar',
  },
  feedback: true,
  wrapperName: 'account',
};

export default [
  {
    path: '/profile/account-access',
    name: Route.Profile.ACCOUNT_ACCESS,
    redirect: { name: Route.Profile.ACCOUNT_SHARING },
    meta: {
      title: 'Account Sharing',
      menu: ['ProfileSidebar'],
      icon: HIcon.ICON_PEOPLE,
      iconHeader: HIcon.ICON_USER_DOUBLE,
      breadcrumbs: ['profile'],
      routeOwner: RouteOwner.WEB_PRO,
      ...sideMenu,
    },
    component: () => import('@/views/Profile/AccountSharing.vue'),
    children: [
      {
        path: 'account-sharing',
        name: Route.Profile.ACCOUNT_SHARING,
        meta: {
          title: 'Account Sharing',
          breadcrumbs: ['profile'],
          ...sideMenu,
        },
        component: () => import('@/views/Profile/Access/AccessSelect.vue'),
      },
      {
        path: 'grant-access',
        name: Route.Profile.GRANT_ACCESS,
        meta: {
          title: 'Grant Access',
          breadcrumbs: false,
          ...sideMenu,
        },
        component: () => import('@/views/Profile/Access/GrantAccessForm.vue'),
      },
      {
        path: 'request-access',
        name: Route.Profile.REQUEST_ACCESS,
        meta: {
          title: 'Request access',
          breadcrumbs: false,
          ...sideMenu,
        },
        component: () => import('@/views/Profile/Access/RequestAccessForm.vue'),
      },
    ],
  },
] as RouteRecordRaw[];
