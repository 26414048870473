export namespace Experiment {
  export enum ID {
    BEAVER_BUILDER_VS_GUTENBERG = 'wordpress-beaver-builder-vs-gutenberg',
    WORDPRESS_PREVIEW_DOMAIN = 'wordpress-preview-domain',
    RENEW_UPGRADE_MODAL = 'hpanel-renew-vs-upgrade-v3',
    WEBPRO_WP_PRESETS = 'webpro-wp-presets',
    HPANEL_REFERRALS_REDIRECT_TO_HOMEPAGE = 'hpanel-referrals-redirect-homepage',
    HPANEL_PLATFORM_SELECTION_TEST_V2 = 'hpanel-platform-selection-test-v2',
    EMAILS_HMAIL_UPSELL_TEST = 'emails-hmail-upsell-page-a-b',
    ONBOARDING_WEBPRO_SHORT = 'onboarding-webpro-short',
    WEBPRO_REFERRALS = 'webpro-referrals',
    WEBPRO_WORDPRESS_PRESETS_OFFER = 'webpro-wordpress-presets-offer',
    BE_SLOWDOWN_HANDLER = 'be-slowdown-handler',
    WORDPRESS_REDIRECT_TO_WP_ADMIN = 'wordpress-redirected-to-wp-admin-after-onboarding',
    FRIDAY_SUSPENDED_GUARD_RELEASE = 'friday-suspended-guard-release',
    REFERRALS_LAST_REFERRED_PRODUCT = 'webpro-different-referrals-plan-selection',
    EMAILS_DURATION_CHOICE_IN_EMAIL_PRICING_PAGE = 'emails-duration-choice-in-email-pricing-page-v3',
    HPANEL_HOME_PAGE_NUDGES = 'hpanel-home-page-nudges-v2',
    ACCOUNT_SHARING_NAVIGATION = 'account-sharing-navigation-test-v2',
    HPANEL_AI_SOCIAL_TOOL_PROMO_BANNER = 'hpanel-ai-social-tool-promo-banner',
    FAKE_DOOR_ACCESS_PER_WEBSITE = 'fake-door-access-per-website',
    REFUND_BUTTON_PAYMENT_HISTORY = 'refund-visibility',
  }

  export enum Variant {
    CURRENT_VERSION = 0,
    EXPERIMENT_VERSION_1 = 1,
    EXPERIMENT_VERSION_2 = 2,
  }
}
