export namespace Referral {
  export enum RewardMethod {
    PAYPAL = 'paypal',
    WIRE_TRANSFER = 'wire-transfer',
  }

  export enum PayoutType {
    PAYPAL = 'paypal',
    MANUAL = 'manual',
  }

  export enum PromotionalBannerVariant {
    CLOUD = 'cloud',
    VPS = 'vps',
    WEBSITE_BUILDER = 'website-builder',
    WORDPRESS = 'wordpress',
    ACTIVE_REFERRAL = 'active-referral',
    IN_BR_OFFER = 'in-br-offer',
    DEFAULT = 'default',
  }

  export enum PromotionalTopBannerKey {
    ALL_PLANS = 'all-plans',
    WORDCAMP = 'WORDCAMP',
    WORDCAMP_30 = 'WORDCAMP_30',
    WORDCAMP_40 = 'WORDCAMP_40',
    BLACK_FRIDAY_30 = 'BLACKFRIDAY_30',
    BLACK_FRIDAY_40 = 'BLACKFRIDAY_40',
    CYBER_MONDAY = 'CYBER_MONDAY',
    Q4_DEC_9_2024 = 'Q4_DEC_9_2024',
  }

  export enum PromotionType {
    CLOUD_VPS = 'cloud-vps',
    ALL_PLANS = 'all-plans',
  }

  export enum EligiblePlan {
    WEB_HOSTING = 'web_hosting',
    CLOUD_HOSTING = 'cloud_hosting',
    VPS_HOSTING = 'vps_hosting',
  }

  export enum PlanDuration {
    MONTHS_12 = '1',
    MONTHS_24 = '2',
    MONTHS_48 = '4',
  }

  export enum PayoutStatus {
    COMPLETED = 'completed',
    PENDING = 'pending',
    FAILED = 'failed',
  }

  export type PayPalPayoutDenialReason =
    | 'RISK_DECLINE'
    | 'REGULATORY_BLOCKED'
    | 'RECEIVER_UNCONFIRMED'
    | 'RECEIVER_COUNTRY_NOT_ALLOWED';

  export enum RewardDenialReason {
    INVALID_PERIOD = 'invalid period',
    INVALID_PRODUCT = 'invalid product',
    SUBSCRIPTION_CANCELED = 'subscription canceled',
    NOT_FIRST_HOSTING_PURCHASE = 'not first hosting purchase',
  }

  export enum DiscountRate {
    DISCOUNT_20 = 0.2,
    DISCOUNT_10 = 0.1,
  }

  export enum CommissionRate {
    COMMISSION_20 = 0.2,
    COMMISSION_30 = 0.3,
    COMMISSION_35 = 0.35,
    COMMISSION_40 = 0.4,
  }

  export enum CommissionAmount {
    USD_200 = 200,
    USD_300 = 300,
    USD_345 = 345,
    USD_400 = 400,
    USD_450 = 450,
  }

  export enum ReferredProductType {
    WEB_HOSTING = 'web-hosting',
    CLOUD = 'cloud',
    VPS = 'vps',
  }

  export enum LastReferredPlan {
    HOSTING_STARTER = 'hosting-starter',
    HOSTING_PREMIUM = 'hosting-premium',
    HOSTING_BUSINESS = 'hosting-business',
    CLOUD_ECONOMY = 'hosting-cloudeconomy',
    CLOUD_PROFESSIONAL = 'hosting-cloudprofessional',
    CLOUD_ENTERPRISE = 'hosting-cloudenterprise',
    VPS_KVM1 = 'vps-kvm1',
    VPS_KVM2 = 'vps-kvm2',
    VPS_KVM4 = 'vps-kvm4',
    VPS_KVM8 = 'vps-kvm8',
  }
}
