<template>
  <div class="server-transfer">
    <ChangeYourServerLocation
      v-if="currentStep === 0 && !preselectedServer"
      class="choose-server-location"
      :is-disabled="nextButtonDisabled"
      :vhosts-with-deprecated-php-version="vhostsWithDeprecatedPhpVersion"
      subtitle="Choose a server location to transfer your hosting"
      @close="close"
      @next="goToNextStep"
    >
      <HSkeletonLoader v-if="isLoading" :height="40" rounded />
      <HFormFieldDeprecated
        v-else
        class="field h-mt-8"
        no-trans
        :schema="datacentersSelect"
        @change="onOptionsChange"
      />
      <p v-if="showRecommendationFields" v-trans class="h-mt-24 text-dark">
        We have identified your visitors in the last 30 days
      </p>
      <VisitorAndSeverInfo
        v-if="showRecommendationFields"
        class="h-mt-16"
        :traffic="traffic"
      />
      <TransferRecommendationSuggestion
        v-if="showRecommendationFields"
        :country="country"
        :decision="decision"
      />
    </ChangeYourServerLocation>
    <BeforeYouContinue
      v-else-if="currentStep === 1"
      :is-loading="transferRequestLoading"
      :preselected-server="preselectedServer"
      :selected-data-center="selectedDataCenter"
      :current-data-center="currentServerTitle"
      :current-plan="getHostingAccountDetails.plan"
      @go-back="goToPreviousStep"
      @close="close"
      @submit="submitChange"
    />
    <div v-else-if="currentStep === 2" class="loader-container">
      <CircleLoader class="h-mb-24 color" />
      <h3 v-trans class="h-mb-0">Processing</h3>
      <p v-trans class="h-mb-0">The transfer process is initiated</p>
    </div>
    <ServerTransferStarted
      v-else-if="currentStep === 3"
      :success="transferStartedSuccess"
      :err="transferError"
      @close="close"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import BeforeYouContinue from '@/components/Hosting/accountDetails/BeforeYouContinue';
import ChangeYourServerLocation from '@/components/Hosting/accountDetails/ChangeYourServerLocation';
import ServerTransferStarted from '@/components/Hosting/accountDetails/ServerTransferStarted';
import TransferRecommendationSuggestion from '@/components/Hosting/accountDetails/TransferRecommendationSuggestion';
import VisitorAndSeverInfo from '@/components/Hosting/accountDetails/VisitorAndServerInfo';
import CircleLoader from '@/components/Loaders/CircleLoader';
import { ordersRepo } from '@/repositories';
import { countryByCountryCode, timeout } from '@/utils/helpers';
import hostingServerMixin from '@/utils/mixins/hosting/hostingServerMixin';
import modalsMixin from '@/utils/mixins/modalsMixin';

export default {
  name: 'ChangeServerLocationModal',
  components: {
    ChangeYourServerLocation,
    BeforeYouContinue,
    ServerTransferStarted,
    VisitorAndSeverInfo,
    TransferRecommendationSuggestion,
    CircleLoader,
  },
  mixins: [modalsMixin, hostingServerMixin],
  data() {
    return {
      transferStartedSuccess: false,
      transferStartedError: 'ERROR: Something went wrong',
      dataCentersList: null,
      selectedDataCenter: null,
      isLoading: false,
      transferRequestLoading: false,
      preselectedServer: this.data.preselectedServer,
      onFinish: this.data.onFinish,
    };
  },
  async created() {
    this.isLoading = true;
    if (this.preselectedServer) {
      this.goToNextStep();
    }
    if (!this.getTransferDatacentersLoaded) {
      await this.$store.dispatch('transferDatacentersIndex');
    }
    if (!this.recommendationLoaded) {
      await this.$store.dispatch('transferRecommendationIndex');
    }
    if (this.getTransferDatacenters) {
      const bestDatacenter = this.processDataCenters(
        this.getTransferDatacenters.bestDataCenter,
      );
      const datacenters = this.processDataCenters(
        this.getTransferDatacenters.dataCenters,
      );
      const currentDatacenter = datacenters.find(
        ({ label }) => label === this.currentServerTitle,
      );
      this.selectedDataCenter = currentDatacenter || bestDatacenter[0];
      this.dataCentersList = this.processDataCenterLabels(datacenters);
    }
    if (this.isChangeSelectedServer) {
      this.selectedDataCenter = this.dataCentersList.filter((center) =>
        this.recommendedDataCenters.includes(center.value),
      )[0];
    }
    this.isLoading = false;
  },
  computed: {
    nextButtonDisabled() {
      return this.isLoading || !this.dataCentersList.length;
    },
    transferError() {
      return this.$t('Whoops, {error}, please try again', {
        error: this.transferStartedError,
      });
    },
    isChangeSelectedServer() {
      return (
        this.recommendedDataCenters?.length &&
        !this.recommendedDataCenters.includes(this.selectedDataCenter) &&
        this.dataCentersList.filter(
          (center) =>
            this.recommendedDataCenters.includes(center.value) &&
            this.currentServerTitle !== center.label,
        ).length
      );
    },
    vhostsWithDeprecatedPhpVersion() {
      const DEPRECATED_PHP_VERSION = 5.6;

      return this.getHostingAccountDetails.vhosts.filter(
        (vhost) => vhost.phpVersion < DEPRECATED_PHP_VERSION,
      );
    },
    datacentersSelect() {
      return {
        type: 'select',
        name: 'datacenter',
        value: this.selectedDataCenter,
        values: this.dataCentersList,
        required: false,
        noTrans: false,
        extra: false,
        label: 'Choose server location',
      };
    },
    showRecommendationFields() {
      return !!(
        this.traffic?.length &&
        this.recommendedDataCenters &&
        this.recommendedDataCenters?.length
      );
    },
    currentServerTitle() {
      return (
        this.currentOrderDetails?.server?.title ||
        this.getHostingAccountDetails?.server?.title
      );
    },
    currentOrderId() {
      return this.currentOrderDetails.orderId;
    },
    country() {
      return this.$t(countryByCountryCode(this.traffic[0].nearestServer));
    },
    ...mapGetters({
      recommendationLoaded: 'getTransferRecommendationLoaded',
      decision: 'getTransferRecommendationDecision',
      traffic: 'getTransferRecommendationTraffic',
    }),
    ...mapGetters([
      'currentOrderDetails',
      'getTransferDatacentersLoaded',
      'getTransferDatacenters',
      'getAccountsByOrder',
    ]),
  },
  methods: {
    onOptionsChange(data) {
      this.selectedDataCenter = data.values.find(
        ({ value }) => value === data.value,
      );
    },
    processDataCenters(dataCenters) {
      return Object.entries(dataCenters).map(([key, value]) => ({
        value: key,
        label: value,
      }));
    },
    processDataCenterLabels(dataCenters) {
      let processedDataCenters = dataCenters;
      const recommendedServers = [];
      const notReccommendedServers = [];
      switch (this.decision) {
        case 'move_to_nearest_dc':
          dataCenters.forEach((center) => {
            if (this.recommendedDataCenters?.includes(center.value)) {
              recommendedServers.push({
                value: center.value,
                label: `${center.label} ${this.$t('- Recommended')}`,
              });
            } else {
              notReccommendedServers.push(center);
            }
          });
          processedDataCenters = [
            ...recommendedServers,
            ...notReccommendedServers,
          ];
          break;
        case 'requires_human_decision':
          dataCenters.forEach((center) => {
            if (this.recommendedDataCenters?.includes(center.value)) {
              const countries = [];
              this.traffic.forEach((trafficData) => {
                if (
                  trafficData.recommendedDatacenters?.filter(
                    (recommendedServer) =>
                      recommendedServer.code === center.value,
                  ).length
                ) {
                  countries.push(
                    this.$t(countryByCountryCode(trafficData.country)),
                  );
                }
              });
              const countriesString = countries.join(', ');
              recommendedServers.push({
                value: center.value,
                label: `${center.label} ${this.$t(
                  '- Recommended for visitors from {countries}',
                  {
                    countries: countriesString,
                  },
                )}`,
              });
            } else {
              notReccommendedServers.push(center);
            }
          });
          processedDataCenters = [
            ...recommendedServers,
            ...notReccommendedServers,
          ];
          break;
      }

      return processedDataCenters;
    },
    async submitChange() {
      this.transferRequestLoading = true;
      this.goToNextStep();
      const [{ data }, err] = await ordersRepo.transferDataCenter(
        this.selectedDataCenter.value,
      );
      if (data) {
        this.$store.dispatch('hostingOrdersDetailsInTransferIndex');
        this.$store.dispatch('hostingAccountsDetailsIndex', {
          orderId: this.$route.params.id,
          username: this.getAccountsByOrder(this.$route.params.id)[0]?.username,
        });
        this.transferStartedSuccess = true;
      }
      if (err) {
        this.transferStartedError = err.error.message;
        this.transferStartedSuccess = false;
      }
      this.transferRequestLoading = false;
      await timeout(3000);
      if (this.onFinish) this.onFinish();
      this.goToNextStep();
    },
  },
};
</script>
<style lang="scss" scoped>
.server-transfer {
  width: 100% !important;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.choose-server-location {
  width: 100% !important;
}
.text-dark {
  color: var(--text-primary);
}
.loader-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 300px;
  align-items: center;
  justify-content: center;
}
</style>
