import { Header } from '@/types';
import type {
  IWordPressThemeResponseV3,
  IWordpressApplication,
  IWordpressLoginRequest,
  IWordpressManagedWebsite,
  ITableWordPressUpdateResponse,
  IWordPressAdminPanelLoginResponse,
  RequestConfig,
  IAvailableWordPressPlugin,
  WordPressWebsiteTypeAnswer,
  WordPressPluginsBasedOnWebsiteType,
  WordPressPreset,
  WordpressAutoUpdateStatus,
  WordpressBulkUpdateParams,
  WordpressBulkUpdateStatusParams,
  WordpressBulkUpdateWebsite,
  WordpressCachePlugin,
  WordpressPresetsParams,
  WordpressRestoreRequest,
  WordPressPluginsBasedOnSearch,
  WordpressSettings,
  WordpressSettingsGETRequest,
  WordpressSettingsRequest,
  WordpressTheme,
  WordpressTranslation,
  WordpressUpdateLogs,
  WordpressVulnerabilites,
  WordpressVulnerabilitiesParams,
  IWordpressCompatibilityRequest,
  IWordpressCompatibility,
  IWordpressComponent,
  IWordPressTemplate,
  IWordPressLitespeedPresets,
  IWordPresssLitespeedLogsResponse,
  IWordpressWebsiteAsPresetParams,
  IDragAndDropWebsiteFiles,
  IWordpressWebsitePluginListItem,
  WordPressAdminSource,
} from '@/types';
import { hAsync } from '@/utils/helpers';
import http, { EXTENDED_TIMEOUT } from '@/utils/services/http/httpService';

/**
 * Swagger: https://hosting-rest-stage.hostinger.io/api/documentation
 */

export default {
  urlV1: `${process.env.VITE_API_REST}/v1/wordpress`,
  urlV2: `${process.env.VITE_API_REST}/v2/wordpress`,
  urlV3: `${process.env.VITE_API_REST}/v3/wordpress`,

  async getWordpressLanguageList() {
    return await hAsync<WordpressTranslation[]>(
      http.get(`${this.urlV2}/translations`),
    );
  },

  async postWordPressAdminPanelError(
    payload: {
      directory: string;
      source: string;
      link: string;
      isSuccessful: boolean;
    },
    config?: RequestConfig,
  ) {
    return await hAsync<boolean>(
      http.post(`${this.urlV3}/login/attempt`, payload, config),
    );
  },

  async getWordPressAdminPanelLogin(
    params: {
      directory: string;
      location?: string;
      source?: WordPressAdminSource;
      actingClientId?: number;
    },
    config: RequestConfig,
  ) {
    return await hAsync<IWordPressAdminPanelLoginResponse>(
      http.get(`${this.urlV3}/login`, { params, ...config }),
    );
  },

  async postWordPressAdminPanelLogin(
    data: {
      directory: string;
      location?: string;
      expiration?: string;
      source?: WordPressAdminSource;
      actingClientId?: number;
    },
    config?: RequestConfig,
  ) {
    return await hAsync(http.post(`${this.urlV3}/login`, data, config));
  },
  //@ts-ignore
  async postWordpress(data, options, hostingHeaderRequired = true) {
    return await hAsync(
      http.post(`${this.urlV2}`, data, {
        ...options,
        timeout: EXTENDED_TIMEOUT,
        whitelistedStatusCodes: [429],
        hostingHeaderRequired,
      }),
    );
  },
  async getWordPressLitespeedLogs(params: IWordPressLitespeedPresets) {
    return await hAsync<IWordPresssLitespeedLogsResponse>(
      http.get(`${this.urlV3}/litespeed/preset/logs`, {
        params,
        hideToastr: true,
        hostingHeaderRequired: true,
      }),
    );
  },
  async postWordPressLitespeedPresetApply(
    preset: string,
    directory = '',
    domainToManage: string,
  ) {
    return await hAsync<boolean>(
      http.post(`${this.urlV3}/litespeed/preset/apply`, {
        preset,
        directory,
        domain: domainToManage,
      }),
    );
  },
  async postWordPressLitespeedPresetRestore(
    backupKey: string,
    directory = '',
    domainToManage: string,
  ) {
    return await hAsync<boolean>(
      http.post(`${this.urlV3}/litespeed/preset/restore`, {
        backupKey,
        directory,
        domain: domainToManage,
      }),
    );
  },

  //@ts-ignore
  async getLSCacheInactiveList(params) {
    return await hAsync(
      http.get(`${this.urlV2}/plugin/inactive-litespeed`, {
        params,
      }),
    );
  },
  //@ts-ignore
  async postLSCacheWebsiteList(data) {
    return await hAsync(
      http.post(`${this.urlV2}/plugin/activate-list`, {
        applications: data,
        plugin_name: 'litespeed-cache',
      }),
    );
  },
  //@ts-ignore
  async getObjectCacheStatus({ directory, domain }) {
    return await hAsync(
      http.get(`${this.urlV2}/memcached/is-active`, {
        params: { directory, domain },
        hostingHeaderRequired: true,
      }),
    );
  },
  //@ts-ignore
  async activateObjectCache({ directory, domain }) {
    return await hAsync(
      http.post(`${this.urlV2}/memcached/activate`, { directory, domain }),
    );
  },
  //@ts-ignore
  async deactivateObjectCache({ directory, domain }) {
    return await hAsync(
      http.post(`${this.urlV2}/memcached/deactivate`, {
        directory,
        domain,
      }),
    );
  },
  //@ts-ignore
  async getUpsells(domain) {
    return await hAsync(
      http.get(`${this.urlV2}/upsells`, { params: { domain }, domain }),
    );
  },
  //@ts-ignore
  async getWordpressIsMaintenanceEnabled({ directory, domain }) {
    return await hAsync(
      http.get(`${this.urlV2}/is-maintenance-enabled`, {
        params: { directory, domain },
      }),
    );
  },

  async getIsWordPressVersionValid({
    domain,
    directory = '',
    headers,
    isForcedCheck,
    noHostingHeaders,
  }: {
    directory?: string;
    domain?: string;
    headers?: Record<string, string>;
    isForcedCheck?: boolean;
    noHostingHeaders?: boolean;
  } = {}) {
    return await hAsync<boolean>(
      http.get(`${this.urlV3}/is-valid`, {
        domain,
        params: { directory, forceCheck: isForcedCheck ? 1 : 0 },
        headers,
        noHostingHeaders,
      }),
    );
  },

  //@ts-ignore
  async postChangeWordpressMaintenanceStatus({ directory, isEnabled, domain }) {
    return await hAsync(
      http.post(`${this.urlV2}/change-maintenance-status`, {
        directory,
        domain,
        enable: isEnabled,
      }),
    );
  },
  //@ts-ignore
  async getStagingList(directory, domain) {
    return await hAsync(
      http.get(`${this.urlV2}/staging/list`, {
        params: { directory, domain },
      }),
    );
  },
  //@ts-ignore
  async postNewStaging(data) {
    return await hAsync(http.post(`${this.urlV2}/staging`, data));
  },
  async deleteHostingStaging({
    directory,
    domain,
  }: {
    directory: string;
    domain: string;
  }) {
    return await hAsync<boolean>(
      http.delete(`${this.urlV2}/staging`, {
        params: {
          domain,
          directory,
        },
      }),
    );
  },
  //@ts-ignore
  async publishHostingStaging({ directory, domain }) {
    return await hAsync(
      http.post(`${this.urlV2}/staging/deploy`, { directory, domain }),
    );
  },
  //@ts-ignore
  async revertHostingStaging({ directory, domain }) {
    return await hAsync(
      http.post(`${this.urlV2}/staging/revert`, { directory, domain }),
    );
  },
  async getIsInstalingStagingList() {
    return await hAsync(http.get(`${this.urlV2}/staging/is_installing`));
  },
  //@ts-ignore
  async postWpPlugin(data) {
    return await hAsync(http.post(`${this.urlV2}/plugin/register`, data));
  },
  //@ts-ignore
  async runPlugin(data) {
    return await hAsync(http.post(`${this.urlV2}/plugin/run`, data));
  },

  async getVulnerabilityStatus() {
    return await hAsync(http.get(`${this.urlV2}/plugin/update-status`));
  },

  async postUpdatePlugin({
    directory,
    domain,
    plugins,
  }: {
    directory: string;
    domain: string;
    plugins: string[];
  }) {
    return await hAsync<ITableWordPressUpdateResponse>(
      http.post(`${this.urlV2}`, {
        command: 'plugin_update',
        domain,
        params: {
          directory: directory || '',
          plugins,
        },
      }),
    );
  },
  //@ts-ignore
  async deletePlugin({ directory, config, name }) {
    return await hAsync(
      http.post(
        `${this.urlV2}`,
        {
          command: 'plugin_uninstall',
          params: {
            directory: directory || '',
            plugins: [name],
          },
        },
        config,
      ),
    );
  },
  //@ts-ignore
  async updateTheme({ directory, domain, themes }) {
    return await hAsync<ITableWordPressUpdateResponse>(
      http.post(`${this.urlV2}`, {
        command: 'theme_update',
        domain,
        params: {
          directory: directory || '',
          themes,
        },
      }),
    );
  },
  //@ts-ignore
  async deleteTheme({ directory, domain, name }) {
    return await hAsync(
      http.post(`${this.urlV2}`, {
        command: 'theme_delete',
        domain,
        params: {
          directory: directory || '',
          themes: [name],
        },
      }),
    );
  },
  async getCachePlugins({ directory = '' }) {
    return await hAsync<WordpressCachePlugin[]>(
      http.post(`${this.urlV2}`, {
        command: 'cache_plugin_list',
        params: {
          directory,
        },
      }),
    );
  },

  async postHostingerThemeInstall({
    directory,
    theme,
    palette,
    layout,
    font,
    config,
  }: {
    directory: string;
    theme: string;
    palette?: string;
    layout?: string;
    font?: string;
    config?: RequestConfig;
  }) {
    return await hAsync<boolean>(
      http.post(
        `${this.urlV3}/themes/hostinger`,
        {
          directory,
          theme,
          palette,
          layout,
          font,
        },
        {
          ...config,
        },
      ),
    );
  },

  async getHostingerThemes({
    orderId,
    directory,
    config,
  }: {
    orderId: string;
    directory: string;
    config?: RequestConfig;
  }) {
    return await hAsync<WordpressTheme[]>(
      http.get(`${this.urlV3}/themes/hostinger/available`, {
        params: { orderId, directory },
        ...config,
      }),
    );
  },

  async getAvailablePlugins({
    directory,
    headers,
  }: {
    directory: string;
    headers: Record<string, string>;
  }) {
    return await hAsync<IAvailableWordPressPlugin[]>(
      http.get(`${this.urlV3}/plugins/available`, {
        headers,
        noHostingHeaders: true,
        params: { directory },
      }),
    );
  },

  /**
   * Lists all WordPress plugins installed for the client
   */
  async getPluginsList() {
    return await hAsync<IWordpressWebsitePluginListItem[]>(
      http.get(`${this.urlV3}/plugins/list`),
    );
  },

  async getSuggestedPlugins() {
    return hAsync(http.get(`${this.urlV2}/plugin/suggested`));
  },

  async getAstraTemplates() {
    return await hAsync<
      Record<WordPressWebsiteTypeAnswer, IWordPressTemplate[]>
    >(http.get(`${this.urlV3}/astra-sites`));
  },

  async getSuggestedPluginsBasedOnWebsiteType() {
    return hAsync<WordPressPluginsBasedOnWebsiteType>(
      http.get(`${this.urlV3}/plugins/suggested`),
    );
  },

  async getWordPressWebsiteTypes(orderId: string) {
    return await hAsync<WordPressWebsiteTypeAnswer[]>(
      http.get(`${this.urlV3}/website-types`, { params: { orderId } }),
    );
  },

  async getWordpressThemes(params: {
    orderId: string;
    websiteType?: WordPressWebsiteTypeAnswer;
  }) {
    return await hAsync<WordpressTheme[]>(
      http.get(`${this.urlV3}/themes`, {
        params,
      }),
    );
  },

  async getWordpressThemesV3(params: { orderId: string }) {
    return await hAsync<IWordPressThemeResponseV3>(
      http.get(`${this.urlV3}/themes`, {
        params,
      }),
    );
  },

  async getWordPressList() {
    return await hAsync<Record<number, IWordpressApplication>>(
      http.get(`${this.urlV2}/list`),
    );
  },

  async getWordpressVulnerabilities({
    params,
    config,
  }: {
    params: WordpressVulnerabilitiesParams;
    config?: RequestConfig;
  }) {
    return await hAsync<WordpressVulnerabilites>(
      http.get(`${this.urlV3}/vulnerabilities`, {
        params,
        hideToastr: true,
        ...config,
      }),
    );
  },
  async postBulkUpdate(params: WordpressBulkUpdateParams) {
    return await hAsync(http.post(`${this.urlV3}/bulk/update`, params));
  },

  async postBulkUpdateStatus(params: WordpressBulkUpdateStatusParams) {
    return await hAsync<WordpressBulkUpdateWebsite[]>(
      http.post(`${this.urlV3}/bulk/update/get-status`, params, {
        hideToastr: true,
      }),
    );
  },

  async getWordPressTipsInfo(params: { directory: string }) {
    return await hAsync(
      http.get(`${this.urlV2}/tips-info`, {
        params,
        hostingHeaderRequired: true,
      }),
    );
  },

  async getSearchedThemes({
    params,
    headers,
  }: {
    params: { search: string };
    headers: { [Header.CLIENT_ID]: string };
  }) {
    return await hAsync<{ [key: string]: WordpressTheme }>(
      http.get(`${this.urlV2}/themes-search`, { params, headers }),
    );
  },

  async getSearchedPlugins({
    params,
    headers,
  }: {
    params: { search: string };
    headers: { [Header.CLIENT_ID]: string };
  }) {
    return await hAsync<WordPressPluginsBasedOnSearch>(
      http.get(`${this.urlV2}/plugin/search`, { params, headers }),
    );
  },

  async deleteWordpressCollection(data: { collectionId: string }) {
    return await hAsync<boolean>(
      http.delete(`${this.urlV2}/collection`, { data, noHostingHeaders: true }),
    );
  },

  async getWordpressCollection(headers: { [Header.CLIENT_ID]: string }) {
    return await hAsync<WordPressPreset[]>(
      http.get(`${this.urlV2}/collection`, { headers }),
    );
  },

  async getManagerWordpressCollection(clientId: string) {
    return await hAsync<WordPressPreset[]>(
      http.get(`${this.urlV2}/collection?client_id=${clientId}`),
    );
  },

  async patchWordpressCollection({
    data,
    headers,
  }: {
    data: WordpressPresetsParams;
    headers: { [Header.CLIENT_ID]: string };
  }) {
    return await hAsync<boolean>(
      http.patch(`${this.urlV2}/collection`, data, {
        headers,
        noHostingHeaders: true,
      }),
    );
  },

  async postWordpressCollection({
    data,
    headers,
  }: {
    data: WordpressPresetsParams;
    headers: { [Header.CLIENT_ID]: string };
  }) {
    return await hAsync<boolean>(
      http.post(`${this.urlV2}/collection`, data, {
        headers,
        noHostingHeaders: true,
      }),
    );
  },

  async saveWebsiteAsWordpressPreset({
    data,
    config,
  }: {
    data: IWordpressWebsiteAsPresetParams;
    config: RequestConfig;
  }) {
    return await hAsync<boolean>(
      http.post(`${this.urlV2}/collection/website`, data, config),
    );
  },

  async installHostingerPlugin(
    directory = '',
    pluginSlug: string,
    config: RequestConfig,
  ) {
    return await hAsync<boolean>(
      http.post(
        `${this.urlV3}/plugin/hostinger/update`,
        {
          directory,
          pluginSlug,
        },
        config,
      ),
    );
  },

  async getWordPressSettings(requestConfig: WordpressSettingsGETRequest) {
    return await hAsync<WordpressSettings>(
      http.get(`${this.urlV3}/settings`, requestConfig),
    );
  },

  async postWordPressSettings(
    params: WordpressSettingsRequest,
    options: RequestConfig,
  ) {
    return await hAsync<WordpressSettings>(
      http.post(`${this.urlV3}/settings`, params, options),
    );
  },

  async getWordPressUpdatesLogs(params: {
    directory: string;
    page?: number;
    perPage?: number;
  }) {
    return await hAsync<WordpressUpdateLogs>(
      http.get(`${this.urlV3}/auto-updates`, { params }),
    );
  },

  async getWordPressUpdatesLogLastStatus(params: { directory: string }) {
    return await hAsync<WordpressAutoUpdateStatus>(
      http.get(`${this.urlV3}/auto-update/last-status`, {
        params,
        hostingHeaderRequired: true,
      }),
    );
  },

  async postWordPressRestore(params: WordpressRestoreRequest) {
    return await hAsync<boolean>(http.post(`${this.urlV3}/restore`, params));
  },

  async getWordpressListManaged(params: RequestConfig) {
    return await hAsync<IWordpressManagedWebsite[]>(
      http.get(`${this.urlV3}/list/managed`, params),
    );
  },

  async postWordPressLogin(params: IWordpressLoginRequest) {
    return await hAsync<string>(
      http.post(`${this.urlV1}/login/create`, params, {
        headers: {
          [Header.USERNAME]: params.username,
          [Header.ORDER_ID]: params.orderId,
        },
      }),
    );
  },
  async getWordpressCompatibility(params: IWordpressCompatibilityRequest) {
    return await hAsync<IWordpressCompatibility>(
      http.get(`${this.urlV3}/compatibility/php-check`, {
        params,
        hideToastr: true,
        whitelistedStatusCodes: [400],
        hostingHeaderRequired: true,
        // 400 - Unsupported PHP version
      }),
    );
  },

  async getIsWordpressWebsite(url: string) {
    return await hAsync<boolean>(
      http.get(`${this.urlV3}/identify`, {
        params: { url },
      }),
    );
  },

  async getWordpressComponentsCompatibility(
    params: IWordpressCompatibilityRequest,
  ) {
    return await hAsync<IWordpressComponent[]>(
      http.get(`${this.urlV3}/compatibility/components-check`, {
        params,
        hideToastr: true,
      }),
    );
  },

  async getIsWooCommercePluginInstalled() {
    return await hAsync<boolean>(http.get(`${this.urlV3}/has-woocommerce`));
  },

  async postExtractWebsiteFiles(
    params: IDragAndDropWebsiteFiles,
    headers: { [Header.USERNAME]: string; [Header.ORDER_ID]: string },
  ) {
    return await hAsync<boolean>(
      http.post(`${this.urlV3}/extract-website-files`, params, {
        headers,
        hideToastr: true,
      }),
    );
  },
};
