import type { RouteRecordRaw } from 'vue-router';

import { Route } from '@/types';
import { RouteOwner } from '@/types/enums/routeOwnerEnum';

const websiteBuilderRoutes = [
  {
    path: '/buy-website-builder',
    name: Route.Base.BUY_WEBSITE_BUILDER,
    meta: {
      title: 'Purchase website builder',
      hideTitle: true,
      routeOwner: RouteOwner.BUILDER,
    },
    component: () =>
      import('@/views/WebsiteBuilder/WebsiteBuilderPurchase.vue'),
  },
] as RouteRecordRaw[];

export default websiteBuilderRoutes;
