import type { NavigationGuard } from 'vue-router';

import { useGlobals } from '@/composables';
import { accountRepo } from '@/repositories';
import store from '@/store';
import { useResourcesStore } from '@/stores';
import { Route } from '@/types';

export const suspendedWebsiteGuard: NavigationGuard = async (to) => {
  const { toastr, t } = useGlobals();
  const resourcesStore = useResourcesStore();
  const domain = to.params.domain;

  if (!resourcesStore.isLoaded) {
    await resourcesStore.fetchResources();
  }

  const account = store.getters.getAccountByDomainWithoutCpanel(domain);

  if (!account) {
    return { name: Route.Base.WEBSITES };
  }

  const [{ data }, err] = await accountRepo.getAccountDetails({
    domain: to.params.domain as string,
    username: account.username,
    orderId: account.referenceId,
  });

  if (err) {
    return { name: Route.Base.WEBSITES };
  }

  const isBuilderWebsite = (domain: string | string[]) =>
    resourcesStore.resources
      .filter(
        (resource) =>
          resource.type === 'website_builder' && resource?.config?.domain,
      )
      .some((resource) => resource?.config?.domain === domain);

  const isWebsiteActive =
    [...data.vhosts, ...data.orderVhosts].find(({ vhost }) => vhost === domain)
      ?.enabled === '1';

  if (!isWebsiteActive && !isBuilderWebsite(domain)) {
    toastr.e(t('Website is suspended'));

    return { name: Route.Base.WEBSITES };
  }
};
