import { computed, reactive, ref } from 'vue';

import { useBillingRequest } from '@/composables/hBilling/useBillingRequest';
import { hBillingRepo } from '@/repositories';
import { useCatalogStore } from '@/stores';
import type { CatalogItemPrice, HAsyncError } from '@/types';
import { BILLING_COUPON_ERROR_MAP } from '@/types';
import {
  extractHAsyncErrorMessage,
  mapKeyValue,
} from '@/utils/helpers/helpers';
import { mapCSCoupon } from '@/utils/mappers/csCouponMapper';

interface ILoadedEstimation {
  id: string;
  coupon: string;
  quantity: number;
  pricing: any;
}

const DEFAULT_COUPON_ERROR =
  'The coupon entered cannot be used for the selected period';

export const usePurchaseModal = () => {
  const catalogStore = useCatalogStore();
  const { billingAddress } = useBillingRequest();

  const estimation = reactive({
    loading: false,
    couponLoading: false,
    error: false,
    couponError: '',
  });

  const loadedEstimations = ref<ILoadedEstimation[]>([]);

  const activeCoupon = ref('');

  const pricingItems = ref<CatalogItemPrice[]>();
  const pricingEstimate = ref();
  const isAvailablePricingPeriodsLoading = ref(true);
  const availablePricingPeriods = ref<CatalogItemPrice[]>();

  const quantity = ref(1);

  const setQuantity = (value: number) => {
    quantity.value = value;
  };

  const getCouponRequest = (enteredCoupon: string) => {
    if (!enteredCoupon) {
      return { coupons: [] };
    }
    const { coupon, agentId } = mapCSCoupon(enteredCoupon);

    return { coupons: [coupon], agentId };
  };

  const setEstimationLoadingState = (isLoading: boolean, coupon?: string) => {
    if (coupon) {
      estimation.couponLoading = isLoading;

      return;
    }

    estimation.loading = isLoading;
  };

  const setEstimationErrorState = (
    isError: boolean,
    coupon?: string,
    error?: HAsyncError,
  ) => {
    if (!isError) {
      estimation.couponError = '';
      estimation.error = false;

      return;
    }

    if (coupon) {
      const errorMessage = mapKeyValue(
        BILLING_COUPON_ERROR_MAP,
        extractHAsyncErrorMessage(error),
      );

      estimation.couponError = isError ? errorMessage : '';

      return;
    }

    estimation.error = isError;
  };

  const addLoadedEstimation = (estimation: ILoadedEstimation) => {
    loadedEstimations.value.push(estimation);
  };

  const retryAfterCouponError = (callback: () => void) => {
    activeCoupon.value = '';
    estimation.couponError = DEFAULT_COUPON_ERROR;
    callback();
  };

  const onEstimationError = ({
    coupon,
    retryCallback,
  }: {
    error?: any;
    coupon?: string;
    retryCallback: () => void;
  }) => {
    if (activeCoupon.value) {
      return retryAfterCouponError(retryCallback);
    }

    setEstimationLoadingState(false, coupon);
    setEstimationErrorState(true, coupon);
  };

  const onCouponRemove = (callback: () => void) => {
    activeCoupon.value = '';

    callback();
  };

  const onCouponApply = async (
    coupon: string,
    callback: () => Promise<boolean>,
  ) => {
    const success = await callback();

    if (!success) return;

    activeCoupon.value = coupon;
  };

  const getCurrentPriceByPriceIdWithRetry = async (
    priceId: string,
    withArchived: boolean = true,
  ) => {
    let currentPrice = catalogStore.getPriceById(priceId);

    if (!currentPrice) {
      const [{ data }] = await hBillingRepo.getItemByPrice(priceId, {
        includeArchived: withArchived ? 1 : 0,
      });

      if (data) {
        currentPrice = data.prices.find(({ id }) => id === priceId);
      }
    }

    return currentPrice;
  };

  const fetchPricingItems = async (
    catalogItemId: string,
    currentPriceId?: string,
  ) => {
    const upgradeItem = catalogStore.getCatalogItemById(catalogItemId);

    if (!currentPriceId) {
      pricingItems.value = upgradeItem?.prices;

      return;
    }

    const currentPrice = await getCurrentPriceByPriceIdWithRetry(
      currentPriceId,
    );

    const availablePeriods = currentPrice?.metadata.upgradesTo.filter((price) =>
      price.includes(catalogItemId),
    );

    pricingItems.value = upgradeItem?.prices.filter(({ id }) =>
      availablePeriods?.includes(id),
    );
  };

  const fetchPricingEstimate = async (
    coupon: string = '',
    retryAfterCouponError: boolean = false,
    planPeriodId: string = '',
    subscriptionId: string = '',
    error?: HAsyncError,
  ) => {
    const selectedCoupon = coupon || activeCoupon.value;

    setEstimationLoadingState(true, coupon);

    const alreadyLoadedEstimation = loadedEstimations.value.find(
      ({ id, quantity, coupon: alreadyLoadedCoupon }) =>
        id === planPeriodId &&
        quantity === 1 &&
        alreadyLoadedCoupon === selectedCoupon,
    );

    if (alreadyLoadedEstimation) {
      pricingEstimate.value = alreadyLoadedEstimation.pricing;
      setEstimationLoadingState(false, coupon);

      return true;
    }

    setEstimationLoadingState(true, coupon);

    if (!retryAfterCouponError) setEstimationErrorState(false, coupon, error);

    const [{ data }, err] = await hBillingRepo.estimatePlanUpgrade(
      subscriptionId,
      {
        itemPriceId: planPeriodId,
        quantity: 1,
        ...getCouponRequest(selectedCoupon),
      },
      false,
    );

    if (err) {
      onEstimationError({
        coupon,
        retryCallback: () =>
          fetchPricingEstimate(coupon, true, planPeriodId, subscriptionId, err),
      });

      return false;
    }

    pricingEstimate.value = {
      ...data,
      id: data.items[0].product,
    };

    addLoadedEstimation({
      id: pricingEstimate.value.id,
      quantity: 1,
      pricing: pricingEstimate.value,
      coupon: activeCoupon.value,
    });

    setEstimationLoadingState(false, coupon);

    return true;
  };

  const fetchAvailablePricingPeriods = async (
    catalogItemId: string,
    currentItemPriceId?: string,
  ) => {
    isAvailablePricingPeriodsLoading.value = true;
    await fetchPricingItems(catalogItemId, currentItemPriceId);
    availablePricingPeriods.value = pricingItems.value;

    isAvailablePricingPeriodsLoading.value = false;
  };

  const isCreditPay = computed(() => {
    const creditsAndAmountPaid =
      pricingEstimate.value?.creditsApplied + pricingEstimate.value?.amountPaid;

    return (
      creditsAndAmountPaid === pricingEstimate.value?.total &&
      pricingEstimate.value?.finalAmount === 0
    );
  });

  return {
    getCouponRequest,
    estimation,
    billingAddress,
    activeCoupon,
    loadedEstimations,
    addLoadedEstimation,
    setEstimationLoadingState,
    setEstimationErrorState,
    onCouponRemove,
    onCouponApply,
    onEstimationError,
    pricingItems,
    pricingEstimate,
    fetchPricingEstimate,
    isAvailablePricingPeriodsLoading,
    availablePricingPeriods,
    fetchAvailablePricingPeriods,
    isCreditPay,
    quantity,
    setQuantity,
  };
};
