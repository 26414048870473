import type { NavigationGuard } from 'vue-router';

import { router } from '@/router';
import { useRefundStore } from '@/stores/refundStore';
import { Route, type HRefundInvoice, HREFUND_INVOICE_STATUS } from '@/types';

const refundInvoiceGuard: NavigationGuard = async (to, from, next) => {
  const refundStore = useRefundStore();

  await refundStore.getInvoice(to.params.hash as HRefundInvoice['id']);

  const invoice = refundStore.invoice;

  const isRefundable = (refundInvoice: HRefundInvoice) =>
    refundInvoice.refundable &&
    (refundInvoice.supported || refundInvoice.isRefundableToBalance);

  if (
    invoice &&
    (invoice?.refundStatus === HREFUND_INVOICE_STATUS.REFUNDED ||
      !isRefundable(invoice))
  ) {
    return router.replace({
      name: Route.Base.PAID_INVOICES,
    });
  }

  return next();
};

export default refundInvoiceGuard;
