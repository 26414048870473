import emailAccountLimitsGuard from '@/guards/emails/emailAccountLimitsGuard';
import emailCreateAccountGuard from '@/guards/emails/emailCreateAccountGuard';
import { Route } from '@/types';
import { RouteOwner } from '@/types/enums/routeOwnerEnum';

const baseMeta = {
  title: 'Set Up Your Email Account',
  showSubheader: false,
  routeOwner: RouteOwner.EMAILS,
};

export default [
  {
    path: '/email-create-account/:orderId/:domain',
    name: Route.Email.CREATE_ACCOUNT_START,
    meta: {
      ...baseMeta,
    },
    redirect: { name: Route.Email.CREATE_ACCOUNT },
    beforeEnter: emailCreateAccountGuard,
    component: () => import('@/views/Emails/EmailsCreateAccountWrapper.vue'),
    children: [
      {
        path: 'start',
        name: Route.Email.CREATE_ACCOUNT,
        meta: {
          ...baseMeta,
        },
        beforeEnter: emailAccountLimitsGuard,
        component: () => import('@/views/Emails/EmailCreateAccountStep.vue'),
      },
      {
        path: 'configure',
        name: Route.Email.CONFIGURE_ACCOUNT,
        meta: {
          ...baseMeta,
        },
        component: () => import('@/views/Emails/EmailConfigureAccountStep.vue'),
      },
    ],
  },
];
