import RegistroBrErrorModal from '@/components/Modals/HModal/HDomains/RegistroBrErrorModal.vue';
import { useModal } from '@/composables';
import {
  defaultTldsList,
  promotedTldsList,
  exclusiveTldsList,
} from '@/data/domains/promotedTldList';
import { colors, animals } from '@/data/slugStrings';
import { useProfileStore } from '@/stores';
import type {
  RegistroBrErrorType,
  WhoIsDetails,
  WhoIsPrimaryDetails,
} from '@/types';
import { DOMAIN_REGISTRAR_TITLE } from '@/types';
import { toUnicode, getSplittedDomainParts, toASCII } from '@/utils/helpers';
import { hToastrService as toastr } from '@/utils/services/hToastrService';
import { i18n } from '@/utils/services/i18nService';

const HOSTINGER_NS_REGEX = [
  /^ns[1-4]\.hostinger\.[a-z\.]{2,6}$/,
  /^ns[1-4]\.idhostinger\.com$/,
  /^ns[1-4]\.weblink\.com\.br$/,
  /^ns[1-4]\.hostmania\.es$/,
  /^ns[1-4]\.dns-parking\.com$/,
  /^dns[1-2]\.zyro\.com$/,
];

export const EXPIRATION_THRESHOLD_60_DAYS_IN_MS = 1000 * 3600 * 24 * 60;
export const EXPIRATION_THRESHOLD_45_DAYS = 45;

export const processDomainName = (
  domainName: string,
  keepDomainPath?: boolean,
) => {
  let processedDomain = domainName
    .toLowerCase()
    .trim()
    .replace(/ /g, '')
    .replace(/^(https?:\/\/)/i, '')
    .replace(/[~!@#$%^&*()_\+={}[\]|"':;?,><\\,]/g, '');

  if (!keepDomainPath) {
    processedDomain = processedDomain.replace(/\//g, '');
  }

  const splittedDomain = processedDomain.split('.');
  const firstPart = splittedDomain[0];

  // Always removes www. from the domain
  if (firstPart === 'www' || firstPart === '') {
    splittedDomain.shift();
  }
  processedDomain = splittedDomain.join('.');

  return processedDomain;
};

export const validateDomainForSpecialCharacters = (domain: string) => {
  const specialChar = /[~!@#$%^&*()_\+={}[\]|"':;?,/><,]/;

  return specialChar.test(domain);
};

// Returns Splited domamain name (without wwww. eg: domain.com) (if no tld returns null)
export const splitDomainName = (domain: string) => {
  let [domainSld, domainTld] = getSplittedDomainParts(domain);

  domainTld = domainTld
    .substring(
      1,
      domainTld.indexOf('/') !== -1 ? domainTld.indexOf('/') : domainTld.length,
    )
    .split(' ')
    .join('');

  if (!domainTld) domainTld = 'com';

  domainSld = domainSld.split(' ').join('').toLowerCase();

  if (!domain.includes('.')) {
    domainTld = '';
    domainSld = domain.split(' ').join('').toLowerCase();
  }

  return [domainSld, domainTld];
};

// @ts-ignore
export const getPromotedTldList = () => {
  const profileStore = useProfileStore();
  const brandId = profileStore.account?.brand.id;

  if (exclusiveTldsList[brandId as keyof typeof exclusiveTldsList]) {
    return exclusiveTldsList[brandId as keyof typeof exclusiveTldsList];
  }

  const promotedTlds =
    promotedTldsList[brandId as keyof typeof promotedTldsList] || [];

  return [
    ...promotedTlds,
    ...defaultTldsList.filter((tld) => !promotedTlds.includes(tld)),
  ];
};

export const isPreviewDomain = (domain: string) => {
  const previewUrls = [
    process.env.VITE_BUILDER_PREVIEW_URL,
    process.env.VITE_WORDPRESS_PREVIEW_URL,
  ] as string[];

  return previewUrls.some((url) => domain?.includes(url));
};

export const showAutorenewalEnabledToastr = (serviceName: string) => {
  toastr.s(i18n.t('Auto-renewal is enabled successfully'), {
    html: `<p><strong>${toUnicode(serviceName)}</strong> ${i18n.t(
      'will renew automatically',
    )}</p>`,
  });
};

export const formatFinalWhoIsDetails = (
  whoIsDetails: WhoIsDetails,
  primaryDetails: WhoIsPrimaryDetails,
  options: { isLtTld?: boolean } = {},
) => {
  const getAddress = ({ address, addressLt, city }: WhoIsDetails) => {
    if (addressLt) {
      return addressLt.length > 3 ? addressLt : `${addressLt} , ${city}`;
    }

    return address;
  };
  const finalDetails = {
    ...whoIsDetails,
    address: getAddress(whoIsDetails),
    countryCode: primaryDetails.country,
  };

  if (whoIsDetails.stateLt && options.isLtTld) {
    finalDetails.stateLt = 'Vilniaus';
  }

  delete finalDetails.addressLt;
  delete finalDetails.apartmentLt;

  return finalDetails;
};

export const isRegistroBrTransfer = (tld: string) =>
  tld === 'br' || tld?.includes('.br');

export const checkIsRegistroBrError = (error: RegistroBrErrorType) => {
  if (!error?.validation_messages) return false;

  const registroBrErrorText = 'Registro.BR account to HSTDOMAINS (127)';
  const vatPersonBrError = error.validation_messages.vat_person_br;
  const vatCompanyBrError = error.validation_messages.vat_company_br;

  return (
    vatPersonBrError?.includes(registroBrErrorText) ||
    vatCompanyBrError?.includes(registroBrErrorText)
  );
};

export const getRegistroBrErrorText = () =>
  `Your CPF/CNPJ is set up for another service provider at your <a href="https://registro.br/" target="blank">Registro.BR</a> account`;

export const openRegistroBrErrorModal = () => {
  const { openModal } = useModal();

  openModal({
    component: { RegistroBrErrorModal },
  });
};

export const isTldInTldList = (
  tldList: string[],
  tld: string,
  tldsRe: string = `^.${tldList.join('$|^.')}`,
) => new RegExp(tldsRe, 'g').test(tld);

export const isHostingerNameservers = (nameservers: string[] = []) =>
  !!HOSTINGER_NS_REGEX.find((regex) =>
    nameservers.every((nameserver) => regex.test(nameserver)),
  );

export const isCpanelNameservers = (nameservers: string[]) => {
  const cpanelNs = [
    'cdns1.main-hosting.eu',
    'cdns2.main-hosting.eu',
    'ns1.niagahoster.com',
    'ns2.niagahoster.com',
    'ns1.boxsecured.com',
    'ns2.boxsecured.com',
  ];

  return nameservers.every((ns) => cpanelNs.includes(ns));
};

export const isCloudflareNameservers = (nameservers: string[]) =>
  nameservers.every((ns) => ns.endsWith('.ns.cloudflare.com'));

export const isGoDaddyRegistrar = (registrar: string) =>
  registrar === DOMAIN_REGISTRAR_TITLE.GO_DADDY;

export const isZipWhoIsField = (fieldName: string = '') =>
  fieldName.startsWith('zip');

export const isStateWhoIsField = (fieldName: string = '') =>
  fieldName.startsWith('state');

export const generateRandomDomainName = (isWebsiteBuilder: boolean): string => {
  const randomCode: number = Math.floor(100000 + Math.random() * 900000);
  const color: string = colors[Math.floor(Math.random() * colors.length)];
  const animal: string = animals[Math.floor(Math.random() * animals.length)];

  const previewUrl = isWebsiteBuilder
    ? process.env.VITE_BUILDER_PREVIEW_URL
    : process.env.VITE_WORDPRESS_PREVIEW_URL;

  return `${color}-${animal}-${randomCode}${previewUrl}`.toLowerCase();
};

export const generateRandomDomainNameFromDomain = (
  domain: string,
  isWordpress = true,
): string => {
  const randomCode: number = Math.floor(100000 + Math.random() * 900000);
  const domainWithoutDots = domain.replaceAll('.', '-');

  const previewUrl = isWordpress
    ? process.env.VITE_WORDPRESS_PREVIEW_URL
    : process.env.VITE_BUILDER_PREVIEW_URL;

  return `${domainWithoutDots}-${randomCode}${previewUrl}`.toLowerCase();
};

export const isIDN = (domain: string) => {
  const domainToTest = toUnicode(domain);

  for (let i = 0; i < domainToTest.length; i++) {
    if (domainToTest.charCodeAt(i) > 127) {
      return true;
    }
  }

  return false;
};

export const isUkDomain = (domain: string = '') => {
  const [, tld] = getSplittedDomainParts(toUnicode(domain));

  return tld.endsWith('.uk');
};

/**
 * Converts a domain to a URL.
 *
 * @param domain - The domain to convert.
 * @returns The converted URL.
 */
export const convertDomainToUrl = (domain: string) => {
  if (!domain) return '';
  const splitter = '://';
  const prefix = 'http://';
  const linkParts = (
    domain.includes(splitter) ? domain : prefix + domain
  ).split(splitter, 2);

  return [linkParts[0], toASCII(linkParts[1])].join(splitter);
};

/**
 * Removes the http, https or www from a url
 * @param url - The URL to be stripped.
 * @returns The URL without the http, https or www.
 */
export const stripDomainUrl = (url: string) =>
  url.replace(/(http:\/\/|https:\/\/|www\.)/g, '');

/**
 * Converts a string to a URL.
 * If the string is a valid HTTP/HTTPS URL, returns the origin of the URL.
 * Otherwise, returns the original string.
 * @param string - The string to convert to a URL.
 * @returns The origin of the URL if it is a valid HTTP/HTTPS URL, otherwise the original string.
 */
export const convertStringToUrl = (string: string) => {
  const isValidHttpUrl = (value: string) => {
    try {
      const newUrl = new URL(value);

      return newUrl.protocol === 'http:' || newUrl.protocol === 'https:';
    } catch (err) {
      return false;
    }
  };

  return isValidHttpUrl(string) ? new URL(string).href : toASCII(string);
};

export const isCpanelUrl = (url: string) => {
  const CPANEL_URL_PARTS = [':2083', ':2082', ':2087', ':2086', 'cpanel'];

  return CPANEL_URL_PARTS.some((part) => url?.toLowerCase()?.includes(part));
};

export const MIN_SLD_LENGTH = 2;
