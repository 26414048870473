import { computed } from 'vue';
import { useRoute } from 'vue-router';

import {
  h5gWebsitesRepo,
  websiteBuilderRepo,
  websiteRepo,
} from '@/repositories';
import { Website } from '@/types';
import {
  sanitizeUrl,
  isTemporaryDomain as isTemporaryDomainHelper,
} from '@/utils/helpers';

export const useAddDomain = () => {
  const route = useRoute();
  const changeableDomain = computed(() => route.params.domain as string);
  const redirectUrl = computed((): string =>
    sanitizeUrl({ input: route.query.redirectUrl, isUrlString: true }),
  );

  const isLocalRedirectUrl = computed((): boolean =>
    redirectUrl.value?.includes(location.origin),
  );

  const redirectPathname = computed((): string => {
    if (!redirectUrl.value) {
      return '/';
    }

    const isFromHostingerDomain = redirectUrl.value.includes('.hostinger.');

    let url;

    if (!isFromHostingerDomain) {
      url = new URL('https://hpanel.hostinger.com/websites');
    } else {
      url = new URL(redirectUrl.value);
    }

    return url.pathname;
  });

  const isTemporaryDomain = computed(() =>
    isTemporaryDomainHelper(changeableDomain.value),
  );

  const getConnectDomainRequest = () => {
    const isBuilderWebsite = route.query.websiteType === Website.Type.BUILDER;
    const isH5GWebsite =
      route.query.websiteType === Website.Type.WORDPRESS &&
      !!route.query.websiteUid;

    if (isH5GWebsite) {
      return h5gWebsitesRepo.changeDomain.bind(h5gWebsitesRepo);
    }

    if (isBuilderWebsite) {
      return websiteBuilderRepo.changeWebsiteDomain.bind(websiteBuilderRepo);
    }

    return websiteRepo.changeWebsiteDomain.bind(websiteRepo);
  };

  return {
    getConnectDomainRequest,
    changeableDomain,
    redirectUrl,
    isLocalRedirectUrl,
    redirectPathname,
    isTemporaryDomain,
  };
};
