import { authRepo, websiteBuilderRepo } from '@/repositories';
import type { ResponseError } from '@/types';
import { errorLogger } from '@/utils/services/errorLogging';
import { hToastrService as toastr } from '@/utils/services/hToastrService';
import { i18n } from '@/utils/services/i18nService';

export const APPS = {
  BUILDER: 'website_builder',
  BUILDER_ECOMMERCE: 'website_builder_ecommerce_admin',
  LOGO_MAKER: 'logo_maker',
};

/** This service is used to pass fresh JWT to another hostinger app on every redirect, to have same auth logic in every project */
export const authRedirectService = {
  redirectToBuilder: async (
    hResourceId?: string | number | null,
    params?: {
      isChangingTemplate?: boolean;
      isAiBuilderRedirect?: boolean;
      category?: string;
      domain?: string;
      brandName?: string;
      websiteType?: string;
      brandDescription?: string;
    },
  ) => {
    try {
      const tokenRequest = authRepo.generateToken(APPS.BUILDER);

      // hResourceId does not exist for free trial users and is handled separately
      if (!hResourceId) {
        const { domain, brandName, websiteType, brandDescription } =
          params || {};

        const redirectUrl = process.env.VITE_BUILDER_AI_BUILDER_URL as string;

        const [{ data: tokenResponse }, tokenError] = await tokenRequest;

        const url = new URL(redirectUrl);
        url.searchParams.set('jwt', tokenResponse.token);

        if (domain) {
          url.searchParams.set('domain', domain);
        }

        if (brandName) {
          url.searchParams.set('brandName', brandName);
        }

        if (websiteType) {
          url.searchParams.set('websiteType', websiteType);
        }

        if (brandDescription) {
          url.searchParams.set('brandDescription', brandDescription);
        }

        return [{ data: { redirectUrl: url.toString() } }, tokenError] as [
          { data: { redirectUrl: string } },
          ResponseError,
        ];
      }

      const redirectRequest = websiteBuilderRepo.getRedirectLink(
        hResourceId,
        params,
      );

      const [
        [{ data: redirectResponse }, redirectError],
        [{ data: tokenResponse }, tokenError],
      ] = await Promise.all([redirectRequest, tokenRequest]);

      const url = new URL(redirectResponse.redirectUrl);
      url.searchParams.set('jwt', tokenResponse.token);

      return [
        { data: { redirectUrl: url.toString() } },
        redirectError || tokenError,
      ] as [{ data: { redirectUrl: string } }, ResponseError];
    } catch (error) {
      const errMessage = 'Error while redirecting to website builder';

      toastr.e(i18n.t(errMessage));

      errorLogger.logError(new Error(errMessage, { cause: error }));
    }
  },
  redirectToBuilderEcommerce: async (websiteId: string) => {
    try {
      const [{ data }, tokenError] = await authRepo.generateToken(
        APPS.BUILDER_ECOMMERCE,
      );

      const url = new URL(`${process.env.VITE_ECOMMERCE_URL}/${websiteId}`);
      url.searchParams.set('jwt', data.token);

      return {
        redirectUrl: url.toString(),
        error: tokenError,
      };
    } catch (error) {
      const errMessage = 'Error while redirecting to website builder ecommerce';

      toastr.e(i18n.t(errMessage));

      errorLogger.logError(new Error(errMessage, { cause: error }));
    }
  },
  redirectToLogoMaker: async (websiteUrl: string, ref?: string) => {
    const [{ data }, err] = await authRepo.generateToken(APPS.LOGO_MAKER);

    if (err) {
      const errMessage = 'Error while redirecting to logo maker';

      toastr.e(i18n.t(errMessage));

      errorLogger.logError(new Error(errMessage, { cause: err }));

      return;
    }

    const url = new URL('', process.env.VITE_LOGO_MAKER_URL);

    ref && url.searchParams.set('ref', ref);
    url.searchParams.set('websiteUrl', websiteUrl);
    url.searchParams.set('jwt', data.token);

    window.open(url, '_blank');
  },
  redirectToLogoMakerWithJWT: async (url: string) => {
    const [{ data }, err] = await authRepo.generateToken(APPS.LOGO_MAKER);

    if (err) {
      const errMessage = 'Error while redirecting to logo maker';

      toastr.e(i18n.t(errMessage));

      errorLogger.logError(new Error(errMessage, { cause: err }));

      return;
    }

    const urlObject = new URL(url);

    urlObject.searchParams.set('jwt', data.token);

    window.open(urlObject, '_blank');
  },
};
