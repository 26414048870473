import { defineStore } from 'pinia';
import { ref } from 'vue';

import { hostingEmailsRepo } from '@/repositories';
import type { EmailOrder } from '@/types';
import { isSameDomain } from '@/utils/helpers';

export const useHMailOrdersStore = defineStore('hMailOrdersStore', () => {
  const orders = ref<EmailOrder[]>();
  const isLoading = ref(false);
  const isLoaded = ref(false);

  const fetchOrders = async () => {
    isLoading.value = true;

    const [{ data }] = await hostingEmailsRepo.getEmailOrders({
      format: 'v1',
    });

    if (data) {
      orders.value = data;
      isLoaded.value = true;
    }

    isLoading.value = false;
  };

  const getOrderByDomain = (domain: string) =>
    orders.value?.find((order) => isSameDomain(order.domain, domain));

  return { orders, isLoading, isLoaded, fetchOrders, getOrderByDomain };
});
