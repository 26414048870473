import type { Dayjs } from 'dayjs';

import type { BillingPeriod, BillingPeriodUnit } from '../periodModels';

import type { HBilling, HResourceType, Hosting, IHResource } from '@/types';

interface BillingLineItem {
  amount: number;
  dateFrom: number;
  dateTo: number;
  description: string;
  discountAmount: number;
  entityId: string;
  entityType: string;
  id: string;
  isTaxed: boolean;
  quantity: number;
  subscriptionId: string;
  taxAmount: number;
  unitAmount: number;
  externalName: string;
}

interface BillingLineItemDiscount {
  amount: number;
  lineItemId: string;
  discountType: string;
  entityId: string;
}

interface BillingDiscount {
  amount: number;
  description: string;
  discountType: string;
  entityId: string;
}

interface BillingTax {
  name: string;
  amount: number;
  description: string;
}

interface BillingLineItemTax {
  lineItemId: string;
  taxName: string;
  taxRate: number;
  isPartialTaxApplied: false;
  isNonComplianceTax: false;
  taxableAmount: number;
  taxAmount: number;
  taxJurisType: string;
  taxJurisName: string;
  taxJurisCode: string;
  taxAmountInLocalCurrency: null;
  localCurrencyCode: null;
}

export interface BillingInvoice {
  amountAdjusted: number;
  amountDue: number;
  amountPaid: number;
  amountToCollect: number;
  baseCurrencyNode: string;
  currencyNode: string;
  customerId: string;
  date: Date;
  deleted: false;
  dueDate: number;
  exchangeRate: 0;
  firstInvoice: boolean;
  id: string;
  issuedInvoiceId?: string;
  referenceInvoiceId?: string;
  lineItems: BillingLineItem[];
  linkedPayments: {
    appliedAmount: number;
    appliedAt: number;
    txnAmount: number;
    txnDate: 1694589315;
    txnId: string;
    txnStatus: string;
  }[];
  paidAt: Date;
  currencyCode: string;
  recurring: boolean;
  status: HBilling.InvoiceStatus;
  subTotal: number;
  subscriptionId: string;
  tax: number;
  total: number;
  discounts: BillingDiscount[];
  lineItemDiscounts: BillingLineItemDiscount[];
  appliedCredits: [];
  issuedCreditNotes: [];
  taxes: BillingTax[];
  lineItemTaxes: BillingLineItemTax[];
}

export const SUBSCRIPTION_ITEM_TYPE = {
  PLAN: 'plan',
  ADDON: 'addon',
  CHARGE: 'charge',
};

export type SubscriptionItemType =
  (typeof SUBSCRIPTION_ITEM_TYPE)[keyof typeof SUBSCRIPTION_ITEM_TYPE];
export interface BillingItem {
  name?: string;
  domain?: string;
  resourceType: string;
  itemPriceId: string;
  itemType?: SubscriptionItemType;
  itemId?: string;
  itemPriceMetadata?: {
    upgradesTo: string[];
  };
}

export interface BillingMetaData {
  name?: string;
  domain?: string;
  resourceId?: string;
  planTitle?: string;
}

export interface SubscriptionParams {
  plan?: Hosting.Plan;
  domain?: string;
  emailPlan?: string;
  resourceId?: number;
}

interface RenewPrice {
  itemPriceId: string;
  enabled: boolean;
  price?: number;
}

interface BillingSubscriptionMetadata {
  domain?: string;
}

export interface BillingSubscriptionUpgradePrice {
  itemPriceId: string;
  price: number;
}

export interface BillingSubscription {
  id: string;
  billingPeriod: BillingPeriod;
  billingPeriodUnit: BillingPeriodUnit;
  canRenew: boolean;
  canReactivate: boolean;
  canEnableAutoRenew: boolean;
  chargedImmediatelyOnAutoRenew: boolean;
  status: SubscriptionStatus;
  cfSubscriptionParams?: SubscriptionParams;
  dueInvoicesCount: number;
  currentTermStart: number;
  currentTermEnd: number;
  nextBillingAt: Date;
  resourceType: HResourceType | HBillingSubscriptionResourceType;
  cancelReasonCode: SubscriptionCancelReason;
  canceledAt: number;
  items: BillingItem[];
  metadata?: BillingMetaData;
  resource?: IHResource;
  expiresAt?: string;
  invoices?: BillingInvoice[];
  renewPrices: RenewPrice[];
  renewalPrice: number;
  scheduledChanges: {
    renewalPrice: number;
    nextBillingAt: number;
    remainingBillingCycles: number | null;
    canEnableAutoRenew?: boolean;
  };
  defaultRenewalPrice: string;
  metaData: BillingSubscriptionMetadata;
  hasScheduledChanges: boolean;
  upgradePrices?: BillingSubscriptionUpgradePrice[];
  remainingBillingCycles: number | null;
  trialStart: string | null;
  trialEnd: string | null;
}

export interface SubscriptionReactivation {
  reason: SubscriptionCancelReason;
  status: SubscriptionStatus;
  service: HResourceType;
  suspendedAt: number | Dayjs;
}

export interface SubscriptionPaymentResponse {
  agentId: string;
  amountDue: number;
  completionToken: string;
  coupons: string[];
  createdAt: string;
  creditsApplied: number;
  currency: string;
  customerId: string;
  discountAmount: number;
  estimatedAt: number;
  id: number;
  items: SubscriptionPaymentResponseItem[];
  orderToken: string;
  paymentLink: string;
  paymentToken: string;
  refundedAmount: number;
  returnLink: string;
  status: string;
  subtotal: number;
  taxAmount: number;
  taxRate: number;
  total: number;
  type: string;
  updatedAt: string;
  userId: number;
  subscription: BillingSubscription;
}

export interface SubscriptionPaymentResponseItem {
  addons: string[];
  amount: string;
  chargebeeSubscriptionId: string;
  dateTo: number;
  discountAmount: number;
  firstPeriodPrice: number;
  id: number;
  itemPrice: SubscriptionPaymentResponseItemPrice;
  itemPriceId: string;
  metadata: SubscriptionPaymentResponseItemMetadata;
  orderId: number;
  parentOrderItemId: string;
  quantity: number;
  taxAmount: number;
  unitAmount: number;
  unitPrice: number;
}

export interface SubscriptionPaymentResponseItemPrice {
  accountingDetail: string;
  basePrice: number;
  billingCycles: string;
  chargebeeUserId: number;
  createdAt: string;
  currencyCode: string;
  description: string;
  externalId: string;
  externalName: string;
  firstPeriodPrice: number;
  firstYearPrice: number;
  freeQuantity: number;
  id: number;
  invoiceNotes: string;
  isTaxable: number;
  itemFamilyId: string;
  itemId: string;
  itemType: string;
  metadata: SubscriptionPaymentResponseItemMetadata;
  name: string;
  period: number;
  periodUnit: string;
  price: number;
  pricingModel: string;
  resourceVersion: number;
  shippingPeriod: number;
  shippingPeriodUnit: string;
  status: string;
  taxDetail: string;
  tiers: string;
  trialPeriod: string;
  trialPeriodUnit: string;
  updatedAt: string;
  upgradePrice: number;
}

export interface SubscriptionPaymentResponseItemMetadata {
  [index: string]: string[];
}

export enum PeriodUnit {
  MONTH = 'month',
  YEAR = 'year',
}

export enum SubscriptionStatus {
  CANCELLED = 'cancelled',
  ACTIVE = 'active',
  DOMAIN = 'domain',
  SUSPENDED = 'suspended',
  EXPIRED = 'expired',
  DOMAIN_TRANSFER = 'DOMAIN_TRANSFER',
  PENDING_SETUP = 'pending_setup',
  PENDING_DATA_ENTRY = 'pending_data_entry',
  AWAITING_PAYMENT = 'awaiting_payment',
  PAYMENT_INITIATED = 'payment_initiated',
  NON_RENEWING = 'non_renewing',
  IN_TRIAL = 'in_trial',
  PAID = 'paid',
  ABUSED = 'abused',
  PAUSED = 'paused',
  VOIDED = 'voided',
}

export enum SubscriptionName {
  GRACE_PERIOD = 'Grace period',
  REDEMPTION_PERIOD = 'Redemption period',
  SERVICE_SUSPENDED = 'Service suspended',
  PENDING_PAYMENT = 'Pending payment',
  PAYMENT_FAILED = 'Payment failed',
  SERVICE_EXPIRED = 'Service expired',
  SERVICE_CANCELED = 'Service canceled',
  PAYMENT_PROCESSING = 'Payment processing',
  PENDING_REGISTRATION = 'Pending registration',
  PENDING_SETUP = 'Pending setup',
  AUTO_RENEW_ON = 'Auto-renewal on',
  AUTO_RENEW_OFF = 'Auto-renewal off',
  AUTO_RENEW_FAILED = 'Auto-renewal failed',
  IN_PROGRESS = 'In progress',
  FAILED = 'Failed',
  DOMAIN_TRANSFER = 'Domain Transfer',
  DOMAIN_REGISTRATION = 'Domain Registration',
}

export interface SubscriptionReactivation {
  reason: SubscriptionCancelReason;
  status: SubscriptionStatus;
  service: HResourceType;
  suspendedAt: number | Dayjs;
}

export enum SubscriptionCancelReason {
  FRAUD = 'fraud',
  REFUND = 'refund',
  STAGING_DELETED = 'staging_deleted',
  ABUSE = 'abuse',
}

export enum SubscriptionResourceType {
  HOSTING = 'hosting',
  EMAIL = 'email',
  GOOGLE_WORKSPACE = 'google_workspace',
  VPS = 'vps',
  VIRTUAL_MACHINE = 'virtual_machine',
  DOMAIN = 'domain',
  FREE_DOMAIN = 'free_domain',
  TITAN_MAIL = 'titan_mail',
  DOMAIN_TRANSFER = 'domain_transfer',
  CPANEL_HOSTING = 'cpanel_hosting',
  CPANEL_RESELLER_HOSTING = 'cpanel_reseller_hosting',
  DEVELOPER_TOOLS = 'developer_tools',
}

export enum HBillingSubscriptionResourceType {
  HOSTING = 'HOSTING',
  EMAIL = 'EMAIL',
  TITAN_MAIL = 'TITAN_MAIL',
  VPS = 'VIRTUAL_MACHINE',
  DOMAIN = 'DOMAIN',
}

export const ALLOWED_CB_REACTIVATION_SERVICES = [
  SubscriptionResourceType.HOSTING,
  SubscriptionResourceType.EMAIL,
  SubscriptionResourceType.GOOGLE_WORKSPACE,
  SubscriptionResourceType.VPS,
  SubscriptionResourceType.DOMAIN,
  SubscriptionResourceType.VIRTUAL_MACHINE,
  SubscriptionResourceType.TITAN_MAIL,
  SubscriptionResourceType.CPANEL_HOSTING,
  SubscriptionResourceType.CPANEL_RESELLER_HOSTING,
  SubscriptionResourceType.DEVELOPER_TOOLS,
];

export const ALLOWED_CB_RESTORE_SERVICES = [
  SubscriptionResourceType.HOSTING,
  SubscriptionResourceType.VIRTUAL_MACHINE,
];

export const ALLOWED_CB_SUSPENDED_STATUSES = [
  SubscriptionStatus.SUSPENDED,
  SubscriptionStatus.CANCELLED,
];

export const INVALID_REASONS = [
  SubscriptionCancelReason.FRAUD,
  SubscriptionCancelReason.REFUND,
  SubscriptionCancelReason.STAGING_DELETED,
  SubscriptionCancelReason.ABUSE,
];

export const BILLING_STATE = {
  GENERATED_INVOICE_AND_AUTO_RENEW_OFF: 1,
  GENERATED_INVOICE_AND_AUTO_RENEW_ON: 999,
  NO_GENERATED_INVOICE_AND_AUTO_RENEW_OFF: 0,
  NO_GENERATED_INVOICE_AND_AUTO_RENEW_ON: null,
};

export interface ISubscriptionRequest {
  subscriptionId: string;
  itemPriceId: string;
  quantity: number;
}

export interface IAddonRolbackRequest {
  subscriptionId: string;
  itemPriceId: string;
}
