import type { ComponentPublicInstance } from 'vue';

import { errorLogger } from '@/utils/services/errorLogging';

// https://vuejs.org/error-reference/#runtime-errors
const ERROR_CODE_MAPPER = {
  0: 'setup function',
  1: 'render function',
} as const;

export const ERROR_HANDLER_VARIANT = {
  PAGE: 'ErrorBoundaryPage',
  COMPONENT: 'ErrorBoundaryComponent',
  MAIN: 'MainErrorBoundary',
} as const;

type ErrorHandlerVariant =
  (typeof ERROR_HANDLER_VARIANT)[keyof typeof ERROR_HANDLER_VARIANT];

// full information strings are used in development mode
const FAILING_RENDER_ON =
  process.env.NODE_ENV === 'production'
    ? Object.keys(ERROR_CODE_MAPPER)
    : Object.values(ERROR_CODE_MAPPER);

const getIsFailingRender = ({
  instance,
  info,
  variant,
}: {
  instance: ComponentPublicInstance | null;
  info: string | number; // string in development, number in production
  variant: ErrorHandlerVariant;
}) => {
  const isFailingRender = FAILING_RENDER_ON.includes(info.toString());

  if (variant === ERROR_HANDLER_VARIANT.PAGE) {
    const isRouteViewComponent =
      instance?.$parent?.$options?.name === 'RouterView';

    return isRouteViewComponent && isFailingRender;
  }

  return isFailingRender;
};

export const errorBoundaryHandler = ({
  error,
  instance,
  info,
  onFailToRender,
  variant,
}: {
  error: { message: string };
  instance: ComponentPublicInstance | null;
  info: string | number; // string in development, number in production
  variant: ErrorHandlerVariant;
  onFailToRender?: () => void;
}): boolean => {
  try {
    const formattedError = new Error(`${error.message}`, { cause: error });
    const isFailingRender = getIsFailingRender({
      info,
      instance,
      variant,
    });

    console.error(error);

    if (isFailingRender) {
      if (process.env.NODE_ENV === 'production') {
        errorLogger.setTag('critical');
        errorLogger.logError(formattedError);
      }

      onFailToRender?.();

      // prevent from bubbling up the tree
      return false;
    }

    if (process.env.NODE_ENV === 'production') {
      errorLogger.logError(formattedError);
    }

    // prevent from bubbling up the tree
    return false;
  } catch (error) {
    const formattedError = new Error(`${variant} execution error`, {
      cause: error,
    });

    errorLogger.logError(formattedError);

    // prevent from bubbling up the tree
    return false;
  }
};
