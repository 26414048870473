import hostingAccountDeactivationModule from './accounts/hostingAccountDeactivationModule';
import hostingAccountsDetailsModule from './accounts/hostingAccountsDetailsModule';
import hostingBoostPlanModule from './accounts/hostingBoostPlanModule';
import hostingAdvancedCacheManagerModule from './advanced/hostingAdvancedCacheManagerModule';
import hostingAdvancedGitModule from './advanced/hostingAdvancedGitModule';
import hostingAdvancedPhpConfigurationModule from './advanced/hostingAdvancedPhpConfigurationModule';
import hostingAdvancedSslModule from './advanced/hostingAdvancedSslModule';
import hostingDatabasesMySQLDatabasesModule from './databases/hostingDatabasesMySQLDatabasesModule';
import hostingDatabasesRemoteDatabasesModule from './databases/hostingDatabasesRemoteDatabasesModule';
import hostingDomainsAddWebsiteModule from './domains/hostingDomainsAddWebsiteModule';
import hostingDomainsParkedDomainsModule from './domains/hostingDomainsParkedDomainsModule';
import hostingDomainsRedirectsModule from './domains/hostingDomainsRedirectsModule';
import hostingDomainsSubdomainsModule from './domains/hostingDomainsSubdomainsModule';
import hostingFilesBackupsModule from './files/hostingFilesBackupsModule';
import hostingFilesFTPAccountsModule from './files/hostingFilesFTPAccountsModule';
import hostingOrderStatisticsModule from './order/hostingOrderStatisticsModule';
import hostingOtherActivityLogModule from './other/hostingOtherActivityLogModule';
import hostingOtherAutoInstallerModule from './other/hostingOtherAutoInstallerModule';
import hostingOtherDeactivateAccountModule from './other/hostingOtherDeactivateAccountModule';
import hostingOtherFolderIndexManagerModule from './other/hostingOtherFolderIndexManagerModule';
import hostingOtherHotlinkProtectionModule from './other/hostingOtherHotlinkProtectionModule';
import hostingOtherIpManagerModule from './other/hostingOtherIpManagerModule';
import hostingOtherPasswordProtectedDirectoriesModule from './other/hostingOtherPasswordProtectedDirectoriesModule';
import hostingSecurityMalwareScannerModule from './security/hostingSecurityMalwareScannerModule';
import hostingAccessLogsModule from './website/hostingAccessLogsModule';
import hostingWebsitePageSpeedModule from './website/hostingWebsitePageSpeedModule';
import hostingWebsiteSeoToolkitModule from './website/hostingWebsiteSeoToolkitModule';
import hostingWordPressModule from './wordpress/hostingWordPressModule';

export default {
  modules: {
    hostingOrderStatistics: hostingOrderStatisticsModule,
    hostingAccountDeactivation: hostingAccountDeactivationModule,
    hostingAccountsDetails: hostingAccountsDetailsModule,
    hostingBoostPlanModule,
    hostingWebsiteSeoToolkitModule,
    hostingFilesFTPAccounts: hostingFilesFTPAccountsModule,
    hostingFilesBackups: hostingFilesBackupsModule,
    hostingDomainsSubdomains: hostingDomainsSubdomainsModule,
    hostingDomainsRedirects: hostingDomainsRedirectsModule,
    hostingDomainsParkedDomains: hostingDomainsParkedDomainsModule,
    hostingDomainsAddWebsite: hostingDomainsAddWebsiteModule,
    hostingDatabasesMySQLDatabases: hostingDatabasesMySQLDatabasesModule,
    hostingDatabasesRemoteDatabases: hostingDatabasesRemoteDatabasesModule,
    hostingAdvancedGit: hostingAdvancedGitModule,
    hostingAdvancedCacheManager: hostingAdvancedCacheManagerModule,
    hostingAdvancedSsl: hostingAdvancedSslModule,
    hostingOtherActivityLog: hostingOtherActivityLogModule,
    hostingOtherFolderIndexManager: hostingOtherFolderIndexManagerModule,
    hostingOtherHotlinkProtection: hostingOtherHotlinkProtectionModule,
    hostingOtherIpManager: hostingOtherIpManagerModule,
    hostingOtherPasswordProtectedDirectories:
      hostingOtherPasswordProtectedDirectoriesModule,
    hostingAdvancedPhpConfiguration: hostingAdvancedPhpConfigurationModule,
    hostingOtherAutoInstallerModule,
    hostingOtherDeactivateAccountModule,
    hostingWordpress: hostingWordPressModule,
    hostingAccessLogsModule,
    hostingWebsitePageSpeedModule,
    hostingSecurityMalwareScannerModule,
  },
};
