import type {
  NavigationGuard,
  RouteLocationNormalized,
  NavigationGuardNext,
} from 'vue-router';

import { useGlobals } from '@/composables/useGlobals';
import { useServerMaintenanceStore } from '@/stores/serverMaintenanceStore';

const cdnAndResourcesMaintenanceGuard: NavigationGuard = async (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext,
) => {
  const serverMaintenanceStore = useServerMaintenanceStore();
  const { toastr, t } = useGlobals();

  if (serverMaintenanceStore.isCdnMaintenanceActive) {
    toastr.e(t('v2.resources.usage.and.cdn.are.under.maintenance'));

    return false;
  }

  return next();
};

export default cdnAndResourcesMaintenanceGuard;
