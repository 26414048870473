<script setup lang="ts">
import { ref, useSlots } from 'vue';

import AnimateHeight from '@/components/AnimateHeight.vue';

const sidemenu = ref<HTMLElement | null>(null);

const slots = useSlots();
</script>

<template>
  <div class="hp-sidemenu">
    <AnimateHeight
      :is-expanded="!!slots.header"
      name="fadeHeight"
      mode="out-in"
      class="hp-sidemenu__header-wrapper"
    >
      <div class="hp-sidemenu__header">
        <slot name="header" />
      </div>
    </AnimateHeight>

    <div v-if="!!slots['sub-header']" class="hp-sidemenu__sub-header">
      <slot name="sub-header" />
    </div>
    <div ref="sidemenu" class="hp-sidemenu__container">
      <slot />
    </div>
    <div>
      <div :class="{ 'hp-sidemenu__footer-menu': !!slots['footer-menu'] }">
        <slot name="footer-menu" />
      </div>
      <div :class="{ 'hp-sidemenu__footer': !!slots.footer }">
        <slot name="footer" />
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.hp-sidemenu {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  background-color: var(--light);
  width: var(--hmenu-width);

  &__header {
    padding: var(--hmenu-spacing);
    height: 80px;
  }

  &__header-wrapper {
    border-bottom: 1px solid var(--gray-border);
    // Actual height is 80 but because of overflow it needs to be set higher
    height: 95px;
  }

  &__container {
    padding: var(--hmenu-spacing);
    padding-top: 0;
    height: 100%;
    overflow-y: auto;
  }

  &__sub-header {
    padding: var(--hmenu-spacing);
    padding-bottom: 0;
  }

  &__footer {
    padding: var(--hmenu-spacing);
    border-top: 1px solid var(--gray-border);
  }

  &__footer-menu {
    padding: var(--hmenu-spacing);
  }
}
</style>
