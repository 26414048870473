import { planRepo } from '@/repositories';
import { getStateVariable, setForDomain } from '@/store/storeHelper';
import { formatNotInteger } from '@/utils/helpers';

const initial = {
  boostPlanDetailsLoaded: false,
  BoostPlanNames: {},
  boostPlanResources: [],
};

export default {
  state: {
    initial,
    data: [],
  },
  mutations: {
    domainChange: (state) => (state.data = [...state.data]),
    SET_BOOST_PLAN_RESOURCES: (state, data) => {
      setForDomain(state, null, 'boostPlanResources', data);
    },
    SET_BOOST_PLAN_NAMES: (state, data) => {
      setForDomain(state, null, 'BoostPlanNames', data);
    },
    SET_BOOST_PLAN_DETAILS_LOADED: (state, data) => {
      setForDomain(state, null, 'boostPlanDetailsLoaded', data);
    },
  },
  getters: {
    getBoostPlanDetailsLoaded: (state) =>
      getStateVariable(state, 'boostPlanDetailsLoaded'),
    getBoostPlanResources: (state) =>
      getStateVariable(state, 'boostPlanResources'),
    getBoostPlanNames: (state) => getStateVariable(state, 'BoostPlanNames'),
  },
  actions: {
    async planBoostDetailsIndex({ commit }, domain) {
      commit('SET_BOOST_PLAN_DETAILS_LOADED', false);
      const fault = { lveFault: 'high_io' };
      const [{ data }] = await planRepo.hostingUpgradeRecommendations({
        fault,
        hideToastr: true,
        domain,
      });
      if (data) {
        const { currentPlan, recommendedPlan } = data;
        const planNames = {
          current: currentPlan.displayName,
          recommended: recommendedPlan.displayName,
        };
        commit('SET_BOOST_PLAN_NAMES', planNames);
        const resources = [
          {
            name: 'CPU cores',
            currentValue: `${formatNotInteger(
              currentPlan.lveCpuSpeedPercent / 100,
              1,
            )}X`,
            boostedValue: `${formatNotInteger(
              recommendedPlan.lveCpuSpeedPercent / 100,
              1,
            )}X`,
            tooltip:
              'CPU usage is the number of CPU power consumed by your hosting account',
          },
          {
            name: 'RAM',
            currentValue: `${formatNotInteger(
              currentPlan.lvePmemKb / 1048576,
              1,
            )} GB`,
            boostedValue: `${formatNotInteger(
              recommendedPlan.lvePmemKb / 1048576,
              1,
            )} GB`,
            tooltip: '',
          },
          {
            name: 'I/O',
            currentValue: `${formatNotInteger(
              currentPlan.lveIoKb / 1024,
              1,
            )} Mb/s`,
            boostedValue: `${formatNotInteger(
              recommendedPlan.lveIoKb / 1024,
              1,
            )} Mb/s`,
            tooltip:
              'I/O (input/output defined as KB/s) is just the "throughput" or speed of data transfer between the hard disk and the RAM',
          },
          {
            name: 'IOPS',
            currentValue: currentPlan.lveIops,
            boostedValue: recommendedPlan.lveIops,
            tooltip:
              'IOPS (input/output operations per second) is the standard unit of measurement for the maximum number of reads and writes to non-contiguous storage locations',
          },
          {
            name: $t('v2.php.workers'),
            currentValue: currentPlan.lveMaxentryprocs,
            boostedValue: recommendedPlan.lveMaxentryprocs,
            tooltip: $t('v2.php.workers.explanation'),
          },
          {
            name: $t('v2.max.processes'),
            currentValue: currentPlan.lveNproc,
            boostedValue: recommendedPlan.lveNproc,
            tooltip: $t('v2.max.processes.explanation'),
          },
        ];

        commit('SET_BOOST_PLAN_RESOURCES', resources);
      }
      commit('SET_BOOST_PLAN_DETAILS_LOADED', true);
    },
  },
};
