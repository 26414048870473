import type { Dayjs } from 'dayjs';
import dayjs from 'dayjs';

export const convertReadableToUnixMs = (time: string) => dayjs(time).valueOf();

export const isCurrentTimeWithinDates = ({
  startsAt,
  endsAt,
}: {
  startsAt: string;
  endsAt: string;
}) => {
  const currentTimeInUnixMs = Date.now();

  return (
    currentTimeInUnixMs >= convertReadableToUnixMs(startsAt) &&
    currentTimeInUnixMs < convertReadableToUnixMs(endsAt)
  );
};

export const isDateInPast = (dateString: string, checkInUtc?: boolean) => {
  const inputDate = new Date(dateString);
  const currentDate = checkInUtc
    ? new Date(new Date().toISOString())
    : new Date();

  return inputDate < currentDate;
};

export const isDateWithinSpecifiedDaysFromNow = (
  dateString: string,
  days: number,
) => {
  const currentDate = dayjs();
  const inputDate = dayjs(dateString);
  const futureDate = currentDate.add(days, 'day');

  return inputDate.isAfter(currentDate) && inputDate.isBefore(futureDate);
};

export const isDateAfterSecondDate = (
  firstDate: string,
  secondDate: string,
) => {
  const inputDate = dayjs(firstDate);
  const secondInputDate = dayjs(secondDate);

  return inputDate.isAfter(secondInputDate);
};

export const isOlderThanHoursInUtc = (date: string, hours: number) =>
  dayjs(date).isBefore(dayjs.utc().subtract(hours, 'hour').toISOString());

export const isOlderThanUnitsInUtc = (
  date: string,
  unit: 'second' | 'minute' | 'hour' | 'day',
  amount: number,
) => {
  const inputDate = dayjs.utc(date);
  const currentDate = dayjs.utc();

  return currentDate.diff(inputDate, unit) > amount;
};

/**
 * Gets the 10th day of the following month from the given date.
 * @param date - The date string.
 * @returns The Dayjs object representing the 10th day of the following month.
 */
export const getThe10thOfTheFollowingMonth = (date: string): Dayjs =>
  dayjs(date).add(1, 'month').date(10);

/**
 * Checks if the given date is from the current year and month.
 * @param date - The date to check.
 * @returns A boolean indicating whether the date is from the current year and month.
 */
export const isCurrentYearAndMonth = (date: string) => {
  const currentYear = dayjs().year();
  const currentMonth = dayjs().month();

  const parsedDate = dayjs(date);

  return (
    parsedDate.year() === currentYear && parsedDate.month() === currentMonth
  );
};

export const DEFAULT_DATE_FORMAT = 'YYYY-MM-DD';
export const DEFAULT_TIME_FORMAT = 'HH:mm:ss';

export const DEFAULT_DATE_TIME_FORMAT = `${DEFAULT_DATE_FORMAT} ${DEFAULT_TIME_FORMAT}`;
export const DEFAULT_SHORT_DATE_TIME_FORMAT = 'YYYY-MM-DD HH:mm';

export const getDaysUntil = (targetDate: string | number) => {
  const today = dayjs();
  const endDate = dayjs(targetDate);

  return endDate.diff(today, 'day');
};
