import type { SurveyAnswers } from './onboardingModels';

import type { TBrandId } from '../enums';

export interface IProfilePatchRequest {
  currentPassword?: string;
  twoFactorAuthCode?: string;
  additionalDetails?: string;
  address?: string;
  city?: string;
  companyId?: string;
  companyName?: string;
  countryCode?: string;
  email?: string;
  emailFiltered?: string;
  firstName?: string;
  lastName?: string;
  phone?: string;
  phoneCc?: string;
  state?: string;
  vatId?: string;
  zip?: string;
}

export interface IProfileEmailPreferencesRequest {
  isSubscribedToNewsletter?: boolean;
  isSubscribedToExpirationReminder?: boolean;
}

export interface IProfileReminderPreferencesRequest {
  isSubscribedToSmsReminder?: boolean;
  isSubscribedToWhatsappReminder?: boolean;
}

export interface IProfile {
  account: IProfileAccount;
  contact: IProfileContact;
  oauth: IProfileOauth;
  settings: IProfileSettings;
  access: IProfileAccess;
}

export interface IProfileAccess {
  isStaff: boolean;
  isAccessManager: boolean;
  level: AccessLevel | null;
  manager: IProfileAccountManager;
}

export interface IProfileAccountManager {
  firstName: string;
  lastName: string | null;
  id: string;
  email: string;
}

export enum AccountStatus {
  PENDING_INVITATION = 'pending_invitation',
  PENDING_CONFIRMATION = 'pending_confirmation',
  ACTIVE = 'active',
  CANCELED = 'canceled',
}

export enum TwoFactorMethod {
  EMAIL = 'email',
  GOOGLE = 'google',
}

export interface IProfileAccount {
  id: string;
  status: AccountStatus | null;
  brand: Brand;
  matchingInBrands: any[];
  isPasswordSet: boolean;
  isEmailSet: boolean;
  isEmailHardBounced: boolean;
  isChargebee: boolean;
  isPro: boolean;
  isNewUser: boolean;
  hasSingleLoginHistoryRecord: boolean;
  hasActiveOrder: boolean;
  hasRiskIndicators: boolean;
  hasPrioritySupport: boolean;
  canSeeReferralDashboard: boolean;
  createdAt: string;
}

export interface IProfileContact {
  additionalDetails: string | null;
  address: string | null;
  city: string | null;
  companyId: string | null;
  companyName: string | null;
  countryCode: string | null;
  email: string | null;
  emailFiltered: string | null;
  firstName: string | null;
  lastName: string | null;
  phone: string | null;
  phoneCc: string | null;
  state: string | null;
  vatId: string | null;
  zip: string | null;
}

export interface Brand {
  id: TBrandId;
  name: string;
  domain: string;
  country: string;
}

export interface IProfileOauth {
  linked: any[];
  available: Available;
}

export interface Available {
  facebook?: string;
  github?: string;
  google?: string;
}

export interface IProfileSettings {
  isSubscribedToNewsletter: boolean;
  isSubscribedToExpirationReminder: boolean;
  isSubscribedToSmsReminder: boolean;
  isSubscribedToWhatsappReminder: boolean;
  intercom: Intercom;
  google: Google;
  twoFactorAuth: ITwoFactorAuth;
}

export interface IChangePasswordRequest {
  currentPassword?: string;
  twoFactorAuthCode?: string;
  newPassword: string;
  newPasswordConfirmation: string;
}

export interface Google {
  analyticsKey: null;
}

export interface Intercom {
  app: App;
  user: User;
}

export interface App {
  id: string;
  isEnabled: boolean;
}

export interface User {
  hash: string;
}

export interface ITwoFactorAuth {
  isEnabled: boolean;
  hasBackup: boolean;
  method: TwoFactorMethod | null;
}

export interface ClientInterface {
  id: string;
  brand: string;
  email: string;
  firstName: string;
  lastName: string;
  country: string;
  countryName: string;
  language?: any;
  languageSelect: boolean;
  fullAddress: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  zip: string;
  phoneCc: string;
  phone: string;
  phoneConfirmation: boolean;
  company: string;
  vatCode: string;
  cpf: string;
  other: string;
  status: string;
  resendConfirmation: boolean;
  hardBounced: boolean;
  createdAt: string;
  oauth?: any;
  oauthProviders: OauthProvider[];
  brandNames: string;
  subscribed: boolean;
  isSubscribed: boolean;
  allowEdit: boolean;
  intercomSettings: IntercomSettings;
  isAccessManager: boolean;
  accessLevel?: any;
  isStaff: boolean;
  googleAnalytics: string;
  currency: CurrencyInterface;
  addressName: string;
  gtm: Gtm;
  requirePasswordChange: boolean;
  taxes: Taxes;
  brandCountry: string;
  referralDashboard: boolean;
  bundledTitan: boolean;
  isPriority: boolean;
  bundledHostingerEmail: boolean;
  newVpsDashboard: boolean;
  selfServiceEnabled: boolean;
  twoFactorBackupUsed: boolean;
  referralRockMigrated: boolean;
  brandId: string;
}

export interface OauthProvider {
  provider: string;
  url: string;
}

export interface Data {
  appId: string;
  userHash: string;
  isPriority: boolean;
  name: string;
  email: string;
  createdAt: string;
  crmLink: string;
  reseller: string;
  userId: string;
  isGoldenCustomer: boolean;
  isAccessManager: boolean;
  chargebeeEnabled: boolean;
}

export interface IntercomSettings {
  enabled: boolean;
  appId: string;
  data: Data;
}

export interface CurrencyInterface {
  code: string;
  decimalCount: number;
  decimalPoint: string;
  thousandsSep: string;
  format: string;
}

export interface Gtm {
  isUser: boolean;
  isNewUser: boolean;
}

export interface Taxes {
  name: string;
  taxrate: number;
  taxCountry: string;
}

export interface GDPROrder {
  service: string;
  reseller: string;
  title: string;
  expiresAt: string;
}

export interface DeleteProfileReqParams {
  understandData: boolean;
  understandOrders: boolean;
  understandSubscriptions: boolean;
  understandTerms: boolean;
}

export interface ChangePasswordReqParams {
  currentPassword: string;
  newPassword: string;
  newPasswordConfirm: string;
  '2fa_code': string;
}

export interface ChangeTmpPasswordReqParams {
  password: string;
  passwordConfirm: string;
  '2fa_code_confirm': string;
}

export interface ClientBeamerData {
  brand: string;
  products: {
    domain: string;
    domainPrivacyProtection: string;
    flockMail: string;
    hosting: string;
  };
  surveyAnswers: SurveyAnswers;
  createdAt: string;
}

export interface UpsellInterface {
  title: string;
  name: string;
  shortDescription: string;
  cta: string;
  icon: string;
  action: {
    type: string;
  };
}

export interface IntercomOpenTicket {
  id: string;
  status: string;
  createdAt: string;
  isTicket?: boolean;
}

export enum Oauth {
  GOOGLE = 'google',
  GITHUB = 'github',
  FACEBOOK = 'facebook',
  ORDER = 'order',
  AUTO = 'auto',
}

export enum DeviceType {
  DESKTOP = 'desktop',
  MOBILE = 'mobile',
  TABLET = 'tablet',
}

export enum InvitationType {
  GRANT = 'grant',
  REQUEST = 'request',
}

export interface IProfileActivity {
  oauth: Oauth | null;
  deviceType: DeviceType;
  ipAddress: string;
  countryCode: string;
  createdAt: string;
}

export interface IProfileActivityMapped {
  loginTimeForDisplay: {
    time: string;
    date: string;
  };
  currentLogin: string;
  device: DeviceType;
  location: {
    country: string;
    ip: string;
  };
  firstLogin: string;
  loginType: Oauth | null;
}

export interface IAccessManager {
  email: string;
  id: number;
  status: AccountStatus;
  isPurchasesAllowed: boolean;
  isOrdersSetupAllowed: boolean;
  isNotificationsAllowed: boolean;
  allowedOrders: string[];
  allowedAccounts: string[];
  allowedDomains: string[];
  allowedResources: string[];
  allowedSubscriptions: string[] | null;
  createdAt: string;
  updatedAt: string;
  clientId: string;
  isConfirmable: boolean;
}

export interface IInviteeManager extends IAccessManager {
  type: InvitationType;
  inviteeEmail: string;
}

export interface IAccess {
  managingMe: IAccessManager[];
  managedByMe: IAccessManager[];
}

// TODO delete once v2 of grant access is fully implemented
export interface IGrantAccessRequest {
  email: string;
  allowedSubscriptions: string[];
  isPurchasesAllowed: boolean;
  isOrdersSetupAllowed: boolean;
  isNotificationsAllowed: boolean;
}

export type GrantAccessRequestV2 = {
  isPurchasesAllowed: boolean;
  isOrdersSetupAllowed: boolean;
  isNotificationsAllowed: boolean;
  title?: string;
  allowedSubscriptions: string[];
};

export interface IRequestAccessRequest {
  email: string;
  isPurchasesAllowed: boolean;
  shouldInviteUser?: boolean;
  isRequesterProPanelUser?: boolean;
}

export interface PostIntercomMessageParams {
  topic: string;
  subtopic: string;
  teamLabel: string;
  message: string;
  option: string;
}

export interface SubmitGrantAccessParams {
  email: string;
  accessLevel: string;
  selectServices: string;
  hideNotifications: boolean;
  accessOrderSetup: boolean;
  grantAccessAgreement: boolean;
}

export interface SubmitRequestAccessParams {
  requestAccessAgreement: boolean;
  email: string;
  inviteUser?: boolean;
  accessLevel: string;
  proPanel?: boolean;
}

export const PROFILE_INFORMATION_FORM_SCHEMA_KEY = {
  FIRST_NAME: 'firstName',
  LAST_NAME: 'lastName',
  EMAIL: 'email',
  ADDRESS_1: 'address_1',
  ADDRESS_2: 'address_2',
  CITY: 'city',
  STATE: 'state',
  ZIP: 'zip',
  COUNTRY: 'country',
  PHONE_CC: 'phoneCc',
  PHONE: 'phone',
  COMPANY: 'company',
  VAT_CODE: 'vatCode',
  CPF: 'cpf',
  OTHER: 'other',
} as const;

export const PROFILE_INFORMATION_FORM_V2_SCHEMA_KEY = {
  ADDRESS: 'address',
  CITY: 'city',
  COMPANY_ID: 'companyId',
  COMPANY_NAME: 'companyName',
  COUNTRY_CODE: 'countryCode',
  EMAIL: 'email',
  EMAIL_FILTERED: 'emailFiltered',
  FIRST_NAME: 'firstName',
  LAST_NAME: 'lastName',
  PHONE: 'phone',
  PHONE_CC: 'phoneCc',
  STATE: 'state',
  VAT_ID: 'vatId',
  ZIP: 'zip',
} as const;

export const PROFILE_INFORMATION_MODAL_SCHEMA_KEY = {
  NAME: 'name',
  EMAIL: 'email',
  ADDRESS: 'address',
  PHONE: 'phone',
  COMPANY: 'company',
  NAME_ADDRESS: 'nameAddress',
} as const;

export type ProfileInformationModalKeyValue =
  (typeof PROFILE_INFORMATION_MODAL_SCHEMA_KEY)[keyof typeof PROFILE_INFORMATION_MODAL_SCHEMA_KEY];

export type ProfileInformationFormKeyValue =
  (typeof PROFILE_INFORMATION_FORM_SCHEMA_KEY)[keyof typeof PROFILE_INFORMATION_FORM_SCHEMA_KEY];

export type ProfileInformationFormV2KeyValue =
  (typeof PROFILE_INFORMATION_FORM_V2_SCHEMA_KEY)[keyof typeof PROFILE_INFORMATION_FORM_V2_SCHEMA_KEY];

export type ProfileInformationValues = Record<
  ProfileInformationFormKeyValue,
  string
>;

export type ProfileInformationV2Values = Record<
  ProfileInformationFormV2KeyValue,
  string
>;

export interface ProfileInformationFormSchemaMap {
  name: 'firstName' | 'lastName';
  email: 'email';
  address: 'address_1' | 'city' | 'state' | 'zip' | 'country';
  phone: 'phoneCc' | 'phone';
  company: 'company' | 'vatCode' | 'cpf';
  additionalDetails: 'other';
  nameAddress: 'firstName' | 'lastName' | 'address' | 'city' | 'zip';
}

export interface ProfileInformationV2FormSchemaMap {
  name: 'firstName' | 'lastName';
  email: 'email';
  address: 'address' | 'city' | 'state' | 'zip' | 'countryCode';
  phone: 'phoneCc' | 'phone';
  company: 'companyName' | 'vatId' | 'companyId';
  additionalDetails: 'additionalDetails';
  nameAddress: 'firstName' | 'lastName' | 'address_1' | 'city' | 'zip';
}

export const TWO_FA_METHOD = {
  EMAIL: 'email',
  GOOGLE: 'google',
} as const;

export type TwoFaMethod = (typeof TWO_FA_METHOD)[keyof typeof TWO_FA_METHOD];

export type ExpirationReminderType = 'sms' | 'whatsapp';

export const ACCESS_LEVEL = {
  MANAGE: 'manage',
  PURCHASE: 'purchase',
} as const;

const ACCESS_STATUS = {
  ACTIVE: 'active',
};

export const ACCESS_LEVEL_TITLE_MAP = {
  [ACCESS_LEVEL.MANAGE]: 'Manage services',
  [ACCESS_LEVEL.PURCHASE]:
    'Manage services and make purchases using stored payment method',
} as const;

type AccessLevel = (typeof ACCESS_LEVEL)[keyof typeof ACCESS_LEVEL];
type AccessStatus = (typeof ACCESS_STATUS)[keyof typeof ACCESS_STATUS];

export interface IAccessListItem {
  id: string;
  resellerId: string;
  resellerClientId: string;
  managerClientId: string;
  requestedClientId: string;
  managerEmail: string;
  ctaLink: string;
  clientEmail: string;
  status: AccessStatus;
  accessLevel: AccessLevel;
  hideNotifications: string;
  config: string;
  newClient: string;
  createdAt: string;
  updatedAt: string;
}

export type IAccessManagerUnion = IAccessListItem & IAccessManager;

export interface AccountLogs {
  loginHistory: LoginHistory[];
  accountActivity: AccountActivity[];
}

interface AccountActivity {
  id: string;
  resellerId: string;
  resellerStaffId?: any;
  resellerClientId: string;
  resellerClientAccountId?: any;
  resellerClientTicketId?: any;
  resellerClientInvoiceId?: any;
  resellerClientOrderId?: any;
  resellerClientKbcommentId?: any;
  resellerClientAnnouncementCommentId?: any;
  log: string;
  username: string;
  level: string;
  ip: string;
  user: string;
  orderStatus?: any;
  config: string;
  createdAt: string;
  icon: string;
  label: string;
  link: Link;
}

interface Link {
  controller: string;
  action: string;
  id?: any;
}

interface LoginHistory {
  id: string;
  resellerId: string;
  resellerClientId: string;
  resellerClientManagerId?: any;
  accessType: string;
  oauth: string;
  ip: string;
  country: string;
  createdAt: string;
  port?: any;
}

export enum AccountSharingTab {
  REQUEST_ACCESS = 'request-access',
  GRANT_ACCESS = 'grant-access',
}
