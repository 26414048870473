import type {
  HButtonColor,
  HButtonVariant,
  IHSnackbarProps,
  HLabelTheme,
} from '@hostinger/hcomponents';

import type {
  HIconUnion,
  WORD_PRESS_PLUGINS,
  OnboardingStatusCheck,
  DataCenterName,
  IWidgetConfiguration,
  IH5GSetupDuplication,
} from '@/types';

/**
 *  Steps should be in order of the onboarding flow for the progress bar
 *  to represent the correct percentage.
 * */
export const ONBOARDING_STEPS = {
  SURVEY_FOR_WHO: 'surveyForWho',
  CREATE_OR_MIGRATE: 'createOrMigrate',
  PLATFORM_SELECT: 'platformSelect',
  GRANT_DEVELOPER_ACCESS: 'grantDeveloperAccess',
  DEVELOPER_ACCESS_GRANTED: 'developerAccessGranted',
  WORDPRESS_ADMIN_SETUP: 'wordpressAdminSetup',
  WORDPRESS_PRESETS: 'wordpressPresets',
  WORDPRESS_WEBSITE_TYPE: 'typeOfWebsite',
  WORDPRESS_BUILD_TYPE: 'wordpressBuildType',
  WORDPRESS_THEME_SELECT: 'wordpressThemeSelect',
  WORDPRESS_VISUAL_SELECT: 'wordPressVisualSelect',
  WORDPRESS_PREVIEW_ASTRA_TEMPLATE: 'wordPressPreviewAstraTemplate',
  WORDPRESS_THEME_PREVIEW: 'wordpressThemePreview',
  HOSTINGER_THEME_SELECT: 'hostingerThemeSelect',
  WORDPRESS_PLUGINS_SELECT: 'wordpressPluginsSelect',
  WORDPRESS_AI_CONTENT_GENERATION: 'wordpressAIContentGeneration',
  SOURCE: 'source',
  DOMAIN: 'domain',
  DOMAIN_REGISTRATION: 'domainRegistration',
  SERVER_SELECT: 'serverSelect',
  OVERVIEW: 'overview',
  COMPLETE: 'complete',
  COMPLETE_WITH_AI: 'completeWithAI',
} as const;

export const H5G_ONBOARDING_STEPS = {
  H5G_AGREE: 'h5gAgree',
  H5G_CHOOSE_ACTION: 'h5gChooseAction',
  H5G_CHOOSE_WEBSITE: 'h5gChooseWebsite',
  H5G_WORDPRESS_ADMIN_SETUP: 'h5gWordpressAdminSetup',
  H5G_DOMAIN: 'h5gDomain',
  H5G_DOMAIN_REGISTRATION: 'h5gDomainRegistration',
  H5G_SETUP: 'h5gSetup',
  H5G_COPY: 'h5gCopy',
} as const;

const SHORTER_WEBPRO_ONBOARDING_STEPS = {
  SURVEY_FOR_WHO: 'surveyForWho',
  CREATE_OR_MIGRATE: 'createOrMigrate',
  PLATFORM_SELECT: 'platformSelect',
  WORDPRESS_ADMIN_SETUP: 'wordpressAdminSetup',
  WORDPRESS_WEBSITE_TYPE: 'typeOfWebsite',
  HOSTINGER_THEME_SELECT: 'hostingerThemeSelect',
  SOURCE: 'source',
  DOMAIN: 'domain',
  DOMAIN_REGISTRATION: 'domainRegistration',
  SERVER_SELECT: 'serverSelect',
  OVERVIEW: 'overview',
  COMPLETE: 'complete',
} as const;

export const H5G_ONBOARDING_STEPS_ARRAY = Object.values(H5G_ONBOARDING_STEPS);
export const ONBOARDING_STEPS_ARRAY = Object.values(ONBOARDING_STEPS);
export const SHORTER_WEBPRO_ONBOARDING_STEPS_ARRAY = Object.values(
  SHORTER_WEBPRO_ONBOARDING_STEPS,
);
export const ONBOARDING_STEPS_WITHOUT_SURVEY = Object.entries(
  ONBOARDING_STEPS,
).reduce((acc, [key, value]) => {
  if (!key.includes('SURVEY')) {
    acc.push(value);
  }

  return acc;
}, [] as OnboardingStep[]);

export const SURVEY_FOR_WHO_ANSWERS = {
  MYSELF: 'myself',
  OTHERS: 'others',
} as const;

export const WORDPRESS_WEBSITE_TYPE_ANSWER = {
  ONLINE_STORE: 'online-store',
  BLOG: 'blog',
  BUSINESS: 'business',
  PORTFOLIO: 'portfolio',
  AFFILIATE_MARKETING: 'affiliate-marketing',
  OTHER: 'other',
} as const;

export const CREATE_OR_MIGRATE = {
  CREATE: 'create',
  MIGRATE: 'migrate',
  PROFESSIONAL: 'professional',
  COPY: 'copy',
} as const;

export const AMPLITUDE_BUILDER_TYPE = {
  AI: 'ai',
  PREBUILT_OR_THEMES: 'prebuilt_or_themes',
  BLANK: 'blank',
} as const;

export const WEBSITE_TYPE_TITLE_MAP = {
  [WORDPRESS_WEBSITE_TYPE_ANSWER.ONLINE_STORE]: 'v2.online.store',
  [WORDPRESS_WEBSITE_TYPE_ANSWER.BLOG]: 'v2.blog',
  [WORDPRESS_WEBSITE_TYPE_ANSWER.BUSINESS]: 'v2.business.and.services',
  [WORDPRESS_WEBSITE_TYPE_ANSWER.PORTFOLIO]: 'v2.portfolio',
  [WORDPRESS_WEBSITE_TYPE_ANSWER.AFFILIATE_MARKETING]:
    'v2.affiliate.marketing.site',
  [WORDPRESS_WEBSITE_TYPE_ANSWER.OTHER]: 'v2.other',
} as const;

export const WEBSITE_TYPE_PLUGINS_TITLE_MAP = {
  [WORDPRESS_WEBSITE_TYPE_ANSWER.ONLINE_STORE]: 'v2.online.store',
  [WORDPRESS_WEBSITE_TYPE_ANSWER.BLOG]: 'v2.blog',
  [WORDPRESS_WEBSITE_TYPE_ANSWER.BUSINESS]: 'v2.business.and.services',
  [WORDPRESS_WEBSITE_TYPE_ANSWER.PORTFOLIO]: 'v2.portfolio',
  [WORDPRESS_WEBSITE_TYPE_ANSWER.AFFILIATE_MARKETING]: 'v2.affiliate.marketing',
  [WORDPRESS_WEBSITE_TYPE_ANSWER.OTHER]: '',
} as const;

export const WEBSITE_TYPE_PLUGINS_DESCRIPTION_MAP = {
  [WORDPRESS_WEBSITE_TYPE_ANSWER.ONLINE_STORE]:
    'v2.create.an.online.store.optimize.its.conversion.rates.collect.feedback.from.visitors.and.improve.your',
  [WORDPRESS_WEBSITE_TYPE_ANSWER.BLOG]:
    'v2.create.a.blog.where.you.can.easily.optimize.content.improve.your.search.engine.rankings.connect',
  [WORDPRESS_WEBSITE_TYPE_ANSWER.BUSINESS]:
    'v2.create.a.business.site.optimize.conversion.rates.collect.feedback.from.visitors.and.improve.your.search',
  [WORDPRESS_WEBSITE_TYPE_ANSWER.PORTFOLIO]:
    'v2.create.a.portfolio.site.with.content.thats.optimized.for.search.track.visitor.data.and.make',
  [WORDPRESS_WEBSITE_TYPE_ANSWER.AFFILIATE_MARKETING]:
    'v2.create.an.automated.affiliate.website.optimize.the.content.to.improve.your.search.engine.rankings.and',
  [WORDPRESS_WEBSITE_TYPE_ANSWER.OTHER]:
    'v2.these.recommended.plugins.are.ideal.for.optimizing.content.improving.seo.performance.connecting.with.your.audience',
} as const;

export const PLATFORM = {
  WORDPRESS: 'wordpress',
  BUILDER: 'website_builder',
} as const;

export const WORDPRESS_BUILD_TYPE = {
  AI_GENERATED: 'ai_generated',
  TEMPLATES_AND_THEMES: 'templates_and_themes',
};

export const WORDPRESS_APPEARANCE_TYPE = {
  STARTER_TEMPLATE: 'starter_template',
  THEME: 'theme',
};

export const WORDPRESS_VISUAL_SELECT = {
  PRE_BUILT_WEBSITE: 'preBuiltWebsite',
  STANDARD_THEMES: 'standardThemes',
};

export const PLUGINS = {
  PLUGINS_SELECTED: 'pluginsSelected',
  NO_PLUGINS_SELECTED: 'noPluginsSelected',
} as const;

export const PRESELECTED_WORDPRESS_THEME = {
  HOSTINGER_AFFILIATE_THEME: 'hostinger-affiliate-theme',
  ASTRA: 'astra',
  GENERATEPRESS: 'generatepress',
  OCEANWP: 'oceanwp',
  KADENCE: 'kadence',
  BLOCKSY: 'blocksy',
  TWENTYTWENTYTWO: 'twentytwentytwo',
  NEVE: 'neve',
  HESTIA: 'hestia',
} as const;

export const PRESELECTED_WORDPRESS_PLUGIN = {
  WOOCOMMERCE: 'woocommerce',
  GOOGLE_ANALYTICS_FOR_WORDPRESS: 'google-analytics-for-wordpress',
  OPTINMONSTER: 'optinmonster',
  ALL_IN_ONE_SEO_PACK: 'all-in-one-seo-pack',
  GOOGLE_SITE_KIT: 'google-site-kit',
  WP_FORMS: 'wpforms-lite',
  HOSTINGER_AFFILIATE_PLUGIN: 'hostinger-affiliate-plugin',
} as const;

export type SurveyForWhoAnswers =
  (typeof SURVEY_FOR_WHO_ANSWERS)[keyof typeof SURVEY_FOR_WHO_ANSWERS];

export type WordPressWebsiteTypeAnswer =
  (typeof WORDPRESS_WEBSITE_TYPE_ANSWER)[keyof typeof WORDPRESS_WEBSITE_TYPE_ANSWER];

export type CreateOrMigrate =
  (typeof CREATE_OR_MIGRATE)[keyof typeof CREATE_OR_MIGRATE];

export type Platform = (typeof PLATFORM)[keyof typeof PLATFORM];

export type WordPressBuildType =
  (typeof WORDPRESS_BUILD_TYPE)[keyof typeof WORDPRESS_BUILD_TYPE];

export type WordPressVisualSelect =
  (typeof WORDPRESS_VISUAL_SELECT)[keyof typeof WORDPRESS_VISUAL_SELECT];

export type Plugins = (typeof PLUGINS)[keyof typeof PLUGINS];

export type PreselectedWordPressTheme =
  (typeof PRESELECTED_WORDPRESS_THEME)[keyof typeof PRESELECTED_WORDPRESS_THEME];

export type WordpressPlugins =
  (typeof WORD_PRESS_PLUGINS)[keyof typeof WORD_PRESS_PLUGINS];

export const ONBOARDING_TRANSITION = {
  SLIDE_LEFT: 'slide-left',
  SLIDE_RIGHT: 'slide-right',
} as const;

export type OnboardingStep =
  | (typeof ONBOARDING_STEPS)[keyof typeof ONBOARDING_STEPS]
  | (typeof H5G_ONBOARDING_STEPS)[keyof typeof H5G_ONBOARDING_STEPS];

export type OnboardingTransition =
  (typeof ONBOARDING_TRANSITION)[keyof typeof ONBOARDING_TRANSITION];

export interface OnboardingConfiguration {
  [ONBOARDING_STEPS.SURVEY_FOR_WHO]: {
    state?: SurveyForWhoAnswers;
  };
  [ONBOARDING_STEPS.WORDPRESS_WEBSITE_TYPE]: {
    state?: WordPressWebsiteTypeAnswer;
  };
  [ONBOARDING_STEPS.CREATE_OR_MIGRATE]: {
    state?: CreateOrMigrate;
  };
  [ONBOARDING_STEPS.PLATFORM_SELECT]: {
    state?: Platform;
  };
  [ONBOARDING_STEPS.WORDPRESS_BUILD_TYPE]: {
    state?: WordPressBuildType;
  };
  [ONBOARDING_STEPS.WORDPRESS_PREVIEW_ASTRA_TEMPLATE]: {
    state: {
      id: number;
      slug: string;
      pageBuilder: WordPressTemplatePageBuilder;
    };
  };
  [ONBOARDING_STEPS.WORDPRESS_THEME_PREVIEW]: {
    state?: string;
  };
  [ONBOARDING_STEPS.WORDPRESS_PLUGINS_SELECT]: {
    state?: WordpressPlugins[];
  };
  [ONBOARDING_STEPS.GRANT_DEVELOPER_ACCESS]: {
    state: {
      email: string;
      isAllowPurchase: boolean;
    };
  };
  [ONBOARDING_STEPS.DEVELOPER_ACCESS_GRANTED]: {
    state?: null;
  };
  [ONBOARDING_STEPS.SERVER_SELECT]: {
    state?: DataCenterName;
  };
  [ONBOARDING_STEPS.WORDPRESS_THEME_SELECT]: {
    state: string | undefined;
  };
  [ONBOARDING_STEPS.WORDPRESS_VISUAL_SELECT]: {
    state?: WordPressVisualSelect;
  };
  [ONBOARDING_STEPS.HOSTINGER_THEME_SELECT]: {
    state: {
      layoutId: string;
      paletteId: string;
    };
  };
  [ONBOARDING_STEPS.SOURCE]: {
    state?: string;
  };
  [ONBOARDING_STEPS.DOMAIN]: {
    state?: IOnboardingDomain;
  };
  [ONBOARDING_STEPS.DOMAIN_REGISTRATION]: {
    state?: null;
  };
  [ONBOARDING_STEPS.WORDPRESS_ADMIN_SETUP]: {
    state: {
      email: string;
      password: string;
      language: string;
    };
  };
  [ONBOARDING_STEPS.WORDPRESS_AI_CONTENT_GENERATION]: {
    state?: {
      brandName: string;
      businessDescription: string;
    };
  };
  [ONBOARDING_STEPS.WORDPRESS_PRESETS]: {
    state?: string;
  };
  [ONBOARDING_STEPS.OVERVIEW]: {
    state?: null;
  };
  [ONBOARDING_STEPS.COMPLETE_WITH_AI]: {
    state?: null;
  };
  [ONBOARDING_STEPS.COMPLETE]: {
    isLast?: true;
    state: OnboardingStatusCheck;
  };
  [H5G_ONBOARDING_STEPS.H5G_AGREE]: {
    state?: string;
  };
  [H5G_ONBOARDING_STEPS.H5G_CHOOSE_ACTION]: {
    state?: string;
  };
  [H5G_ONBOARDING_STEPS.H5G_WORDPRESS_ADMIN_SETUP]: {
    state: {
      email: string;
      password: string;
      language: string;
    };
  };
  [H5G_ONBOARDING_STEPS.H5G_DOMAIN]: {
    state?: IOnboardingDomain;
  };
  [H5G_ONBOARDING_STEPS.H5G_DOMAIN_REGISTRATION]: {
    state?: null;
  };
  [H5G_ONBOARDING_STEPS.H5G_SETUP]: {
    isLast?: true;
    state: OnboardingStatusCheck;
  };
  [H5G_ONBOARDING_STEPS.H5G_CHOOSE_WEBSITE]: {
    state: IH5GSetupDuplication;
  };
  [H5G_ONBOARDING_STEPS.H5G_COPY]: {
    state?: null;
  };
}

export type OnboardingState = {
  [Key in keyof OnboardingConfiguration]: OnboardingConfiguration[Key]['state'];
};

export type OnboardingHostingerThemeSelectState =
  OnboardingConfiguration[typeof ONBOARDING_STEPS.HOSTINGER_THEME_SELECT]['state'];

export type OnboardingWordPressAdminSetupState =
  OnboardingConfiguration[typeof ONBOARDING_STEPS.WORDPRESS_ADMIN_SETUP]['state'];

export const RADIO_CARD_VARIANTS = {
  TIGHT: 'tight',
  PLATFORM_SELECTION: 'platform-selection',
  WORDPRESS_WEBSITE_TYPE: 'wordpress-website-type',
} as const;

export type RadioCardVariantType =
  (typeof RADIO_CARD_VARIANTS)[keyof typeof RADIO_CARD_VARIANTS];

export type RadioCardImage = {
  src: string;
  alt: string;
};

export interface IOnboardingRadioCard {
  isActive?: boolean;
  subtitle?: string;
  title?: string;
  id: string;
  label?: {
    text: string;
    theme: HLabelTheme;
  };
  hideIndicator?: boolean;
  variant?: RadioCardVariantType;
  colors?: string[];
  dataQa?: string;
  checklists?: string[];
  previewFooterTitle?: IWidgetConfiguration['previewFooterTitle'];
  checkboxSideReversed?: boolean;
  radioCardIcon?: RadioCardImage;
  radioCardImage?: RadioCardImage;
  tooltipText?: string;
}

export interface IOnboardingRadioImageCard extends IOnboardingRadioCard {
  image: {
    src: string;
    alt: string;
  };
}

export interface IOnboardingCheckboxCard {
  id: string;
  isSelected?: boolean;
  title: string;
  description: string;
  image: string;
  isExternal?: boolean;
}

export interface IOnboardingConfirmationCard {
  isActive?: boolean;
  title?: string;
  description?: string;
}

export interface IOnboardingFooterProps {
  primaryButton?: {
    isDisabled?: boolean;
    isVisible?: boolean;
    variant?: HButtonVariant;
    color?: HButtonColor;
    text?: string;
    isLoading?: boolean;
    isHpFormSubmit?: boolean;
    disabledTooltipText?: string;
  };
  secondaryButton?: {
    isVisible?: boolean;
    text?: string;
    iconPrepend?: HIconUnion;
    tooltip?: string;
    isLoading?: boolean;
    mobileSnackbar?: string;
  };
  backButton?: {
    isVisible?: boolean;
    text?: string;
  };
  mobileSnackbar?: {
    text: string;
    isVisible?: boolean;
    props: IHSnackbarProps;
  };
  isBordered?: boolean;
}

export interface IWordPressTemplate {
  id: number;
  slug: string;
  title: string;
  pageBuilder: WordPressTemplatePageBuilder;
  description: string;
  keyFeatures: string[];
  featuredImageUrl: string;
  link: string;
  isWoo: boolean;
}

export const WORDPRESS_TEMPLATE_PAGE_BUILDER = {
  BEAVER_BUILDER: 'beaver-builder',
  BLOCK_EDITOR: 'block-editor',
} as const;

export type WordPressTemplatePageBuilder =
  (typeof WORDPRESS_TEMPLATE_PAGE_BUILDER)[keyof typeof WORDPRESS_TEMPLATE_PAGE_BUILDER];

export const TAB_ICON_NAME = {
  [PLATFORM.BUILDER]: 'icon-builder',
  [PLATFORM.WORDPRESS]: 'icon-wordpress',
  WEBSITE: 'icon-website',
  default: '',
} as const;

export type TabIconNameType =
  (typeof TAB_ICON_NAME)[keyof typeof TAB_ICON_NAME];

export const DOMAIN_RESULT_PREFIX = 'domain-result-';

//--------------------------- DOMAIN ------------------------------------
export const ONBOARDING_DOMAIN_TAG = {
  OWNED: 'owned',
  OWNED_PENDING_SETUP: 'owned-pending-setup',
  FREE: 'free',
  PURCHASE: 'purchase',
  ERROR: 'error',
  REGISTERED_ELSEWHERE: 'registered-elsewhere',
  TEMPORARY: 'temporary',
  FREE_SUBDOMAIN: 'free-subdomain',
  ALREADY_USED: 'already-used',
  REGISTERED_ANOTHER_HOSTINGER_ACCOUNT: 'registered-another-hostinger-account',
  SUSPENDED: 'suspended',
} as const;

export type OnboardingDomainTag =
  (typeof ONBOARDING_DOMAIN_TAG)[keyof typeof ONBOARDING_DOMAIN_TAG];

export interface IDomainPriced extends ISimpleDomain {
  price: {
    currentPrice: string;
    isDiscounted?: boolean | undefined;
    discount?: number | undefined;
    oldPrice?: any;
  } | null;
}

export interface IOnboardingDomain extends IDomainPriced {
  tag: OnboardingDomainTag;
  /**
   * Message to display when domain search produces an actionable error.
   */
  message?: string;
  /**
   * If domain is registered at another hostinger account, this will be a record
   * that is needed to be verified.
   */
  txtToVerify?: string;

  /**
   * Owned domains need to be validated as it might be unavailable for hosting usage
   * we show a loader when the domain is
   * being validated.
   */
  isValidating?: boolean;
}

export interface ISimpleDomain {
  domain: string;
  tld: string;
  subdomain?: string;
}
//--------------------------- DOMAIN ------------------------------------

export const ONBOARDING_SCENARIO = {
  ADDON: 'addon',
  QUICK_INSTALL_MIGRATION: 'quick-install-migration',
  QUICK_INSTALL_MIGRATION_ADDON: 'quick-install-migration-addon',
  QUICK_INSTALL_WORDPRESS_ADDON: 'quick-install-wordpress-addon',
  QUICK_INSTALL_WORDPRESS_SETUP: 'quick-install-wordpress-setup',
  QUICK_INSTALL_WEBSITE_BUILDER_ADDON: 'quick-install-website-builder-addon',
  SETUP_WORDPRESS_PRESELECTED: 'setup-wordpress-preselected',
  SETUP_WEBSITE_BUILDER_PRESELECTED: 'setup-website-builder-preselected',
  QUICK_INSTALL_EMPTY_WEBSITE_ADDON: 'quick-install-empty-website-addon',
  QUICK_INSTALL_EMPTY_WEBSITE_SETUP: 'quick-install-empty-website-setup',
  MIGRATION_CHANGE_DOMAIN: 'migration-domain-change',
} as const;

export type OnboardingScenario =
  (typeof ONBOARDING_SCENARIO)[keyof typeof ONBOARDING_SCENARIO];

export type AddonOnboardingScenario =
  | typeof ONBOARDING_SCENARIO.QUICK_INSTALL_MIGRATION_ADDON
  | typeof ONBOARDING_SCENARIO.QUICK_INSTALL_WORDPRESS_ADDON
  | typeof ONBOARDING_SCENARIO.QUICK_INSTALL_WEBSITE_BUILDER_ADDON
  | typeof ONBOARDING_SCENARIO.ADDON;

export type QuickInstallWordPressScenario =
  | typeof ONBOARDING_SCENARIO.QUICK_INSTALL_WORDPRESS_SETUP
  | typeof ONBOARDING_SCENARIO.QUICK_INSTALL_WORDPRESS_ADDON;

export type QuickInstallEmptyWebsiteScenario =
  | typeof ONBOARDING_SCENARIO.QUICK_INSTALL_EMPTY_WEBSITE_SETUP
  | typeof ONBOARDING_SCENARIO.QUICK_INSTALL_EMPTY_WEBSITE_ADDON;
