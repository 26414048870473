import type { RouteRecordRaw } from 'vue-router';

import hVpsPurchaseGuard from '@/guards/hVpsPurchaseGuard';
import { Route, VPS_PURCHASE_STEP } from '@/types';
import { RouteOwner } from '@/types/enums/routeOwnerEnum';

const meta = {
  title: 'Get New VPS',
  isPageWrapperV2: true,
  hideIntercom: true,
  routeOwner: RouteOwner.VPS,
};

export default [
  {
    path: '/purchase-new-vps/:scenario?',
    name: Route.Vps.NEW_PURCHASE,
    meta,
    component: () => import('@/views/Servers/Purchase/VpsPurchaseV2.vue'),
    children: [
      {
        path: 'start',
        name: VPS_PURCHASE_STEP.START,
        beforeEnter: hVpsPurchaseGuard,
        component: () =>
          import('@/views/Servers/Purchase/VpsPurchaseStart.vue'),
      },
      {
        path: 'features',
        name: VPS_PURCHASE_STEP.FEATURES,
        beforeEnter: hVpsPurchaseGuard,
        component: () =>
          import('@/views/Servers/Purchase/VpsPurchaseFeatures.vue'),
      },
      {
        path: 'plans',
        name: VPS_PURCHASE_STEP.GET_PLAN,
        beforeEnter: hVpsPurchaseGuard,
        component: () =>
          import('@/views/Servers/Purchase/VpsPurchasePlans.vue'),
      },
    ],
  },
] as RouteRecordRaw[];
