import type { ComputedRef } from 'vue';
import { computed } from 'vue';
import { useStore } from 'vuex';

import { useGlobals } from '@/composables';
import {
  useVpsBackupStore,
  useVpsMalwareScannerStore,
  useVpsNotificationStore,
  useVpsResourceStore,
  useVpsServerStore,
} from '@/stores';
import type { AmplitudeLocation, ServerBackup } from '@/types';
import { AmplitudeEvent, HVps } from '@/types';

export const useVps = () => {
  const { amplitudeV2 } = useGlobals();

  const store = useStore();

  const serverStore = useVpsServerStore();
  const backupStore = useVpsBackupStore();
  const resourceStore = useVpsResourceStore();
  const notificationStore = useVpsNotificationStore();
  const malwareScannerStore = useVpsMalwareScannerStore();

  const currentServer = computed(() => serverStore.currentServer);
  const currentServerTemplate = computed(
    () => currentServer.value.template || {},
  );

  const serverNotifications = computed(
    () => notificationStore.currentServerNotifications,
  );

  const serverBackups: ComputedRef<ServerBackup[]> = computed(() =>
    backupStore.getServerBackups(),
  );

  const managedId = computed(() => serverStore.managedId);

  const isServerLocked = computed(
    () => currentServer.value.actionsLock === HVps.ServerLock.LOCKED,
  );

  const isRecoveryState = computed(
    () => currentServer.value.state === HVps.ServerState.RECOVERY,
  );

  const isRecreatingState = computed(
    () => currentServer.value.state === HVps.ServerState.RECREATING,
  );

  const isMinecraftServer = computed(() =>
    serverStore.currentServer?.metadata?.planCode?.includes(
      HVps.OrderType.MINECRAFT,
    ),
  );

  const isOpenVzServer = computed(
    () => currentServer.value.providerCode === HVps.ServerProvider.OPENVZ,
  );

  const isMalwareScannerInstalled = computed(
    () => !!currentServer.value.monarx,
  );

  const disabledManagementStatuses = computed(
    () => serverStore.disabledManagementStatuses,
  );

  const malwareScanner = computed(() => currentServer.value.monarx);

  const malwareScannerMetrics = computed(
    () => malwareScannerStore.scannerMetrics,
  );

  const initHvpsPusher = () => {
    const pusher = store.state.services.pusher;
    if (pusher) {
      pusher.port.postMessage({
        event: 'connect-to-hvps-pusher',
      });
    }
  };

  const removeServerNotification = (id: string) => {
    notificationStore.removeServerNotification(id);
  };

  const clearServerNotifications = () => {
    notificationStore.clearServerNotifications();
  };

  const setMalwareAutoProtection = async (status: boolean) => {
    await malwareScannerStore.setMalwareAutoProtection(status);
  };

  const isVpsDestroyed = (orderId: string) => {
    const serverState = resourceStore.getVpsResource(orderId)
      ?.state as HVps.ServerState;

    return [HVps.ServerState.DESTROYED, HVps.ServerState.DELETED].includes(
      serverState,
    );
  };

  const isErrorState = computed(
    () =>
      serverStore.currentServer.state === HVps.ServerState.ERROR ||
      serverStore.isServerFixing(serverStore.currentServer.id),
  );

  const goToArticle = (link: string, eventLocation?: AmplitudeLocation.Vps) => {
    amplitudeV2(AmplitudeEvent.Vps.KB_ENTERED, {
      articleLink: link,
      location: eventLocation || '',
    });

    window.open(link, '_blank');
  };

  return {
    currentServer,
    currentServerTemplate,
    serverNotifications,
    serverBackups,
    managedId,
    isServerLocked,
    isRecoveryState,
    isRecreatingState,
    isMinecraftServer,
    isMalwareScannerInstalled,
    disabledManagementStatuses,
    malwareScanner,
    malwareScannerMetrics,
    initHvpsPusher,
    removeServerNotification,
    clearServerNotifications,
    setMalwareAutoProtection,
    isVpsDestroyed,
    isOpenVzServer,
    isErrorState,
    goToArticle,
  };
};
