import type { IHDomainResource } from '@hostinger/hdomains-status';
import type { NavigationGuard } from 'vue-router';

import { useHDomainState } from '@/composables/hDomains/useHDomainState';
import { useFrontendSettingsStore, useSubscriptionsStore } from '@/stores';
import { useHDomainResourceStore } from '@/stores/domain/hDomainResourceStore';
import { useResourcesStore } from '@/stores/resourcesStore';
import { Route } from '@/types';
import type { IHResource } from '@/types';
import { retryAsyncFunction } from '@/utils/helpers';
import { errorLogger } from '@/utils/services/errorLogging';
import { hToastrService as toastr } from '@/utils/services/hToastrService';
import { i18n } from '@/utils/services/i18nService';

let domain = '';
let subscriptionIdParam = '';
let routeName = '';

const domainGuard: NavigationGuard = async (to, from, next) => {
  const frontendSettingsStore = useFrontendSettingsStore();

  domain = to.params.domain as string;
  subscriptionIdParam = to.params.subscriptionId as string;
  routeName = to.name as string;

  frontendSettingsStore.setState('pageLoading', true);

  const hDomainResource = await loadHDomainResource();
  const hResource = await loadHResource(hDomainResource);
  const subscription = await loadSubscription(hResource);

  frontendSettingsStore.setState('pageLoading', false);

  const isAllRequiredResourcesExist = !!hDomainResource && !!subscription;

  const fallbackRouteName = isTransferRoute()
    ? Route.Domain.TRANSFERS
    : Route.Domain.MY_DOMAINS;

  if (!isAllRequiredResourcesExist) {
    if (!hDomainResource) {
      errorLogger.logInfo(
        `hDomain resource was not found for domain: ${domain} in domainGuard`,
      );
    }
    if (!subscription) {
      errorLogger.logInfo(
        `Subscription was not found for domain: ${domain} in domainGuard`,
      );
    }

    toastr.e(i18n.t('Order not found'));

    return next({ name: fallbackRouteName });
  }

  const isAllowedToEnterDomainManagementByStatus =
    checkIsAllowedToEnterDomainManagementByStatus();

  if (!isAllowedToEnterDomainManagementByStatus) {
    return next({ name: fallbackRouteName });
  }

  return next();
};

const loadSubscription = async (hResource?: IHResource | null) => {
  const subscriptionStore = useSubscriptionsStore();
  const subscriptionId =
    hResource?.chargebeeSubscriptionId ?? subscriptionIdParam;

  const subscription = subscriptionStore.getDomainSubscriptionByProperty({
    domain,
    subscriptionId,
  });

  if (subscription) {
    return subscription;
  }

  await subscriptionStore.fetchAllSubscriptions();

  return subscriptionStore.getDomainSubscriptionByProperty({
    domain,
    subscriptionId,
  });
};

const loadHDomainResource = async () => {
  const { fetchHDomainsResources } = useHDomainResourceStore();

  const hDomainResource = getHDomainResource();

  if (hDomainResource) {
    return hDomainResource;
  }

  await retryAsyncFunction({
    functionToRetry: fetchHDomainsResources,
    getIsRetryNeeded: () => !getHDomainResource(),
    triesCount: 3,
    timeoutInMilliseconds: 2000,
  });

  return getHDomainResource();
};

const loadHResource = async (hDomainResource?: IHDomainResource) => {
  const hResourceStore = useResourcesStore();
  const hResourceId = hDomainResource?.additionalDetails?.hresourceId;

  if (!hResourceId) {
    return null;
  }

  const hResource = hResourceStore.getResourceById(hResourceId);

  if (hResource) {
    return hResource;
  }

  await hResourceStore.fetchResources();

  return hResourceStore.getResourceById(hResourceId);
};

const getHDomainResource = () => {
  const { getHDomainResourceByDomain, getHDomainTransferResourceByDomain } =
    useHDomainResourceStore();

  return isTransferRoute()
    ? getHDomainTransferResourceByDomain(domain)
    : getHDomainResourceByDomain(domain);
};

const checkIsAllowedToEnterDomainManagementByStatus = () => {
  if (isTransferRoute()) {
    return true;
  }

  const { checkCanEnterDomainManagement } = useHDomainState();
  const { getHDomainResourceByDomain } = useHDomainResourceStore();
  const hDomainResource = getHDomainResourceByDomain(domain);

  if (!hDomainResource) {
    return false;
  }

  return checkCanEnterDomainManagement(hDomainResource);
};

const isTransferRoute = () =>
  routeName === Route.Domain.TRANSFER_DOMAIN_MANAGEMENT;

export default domainGuard;
