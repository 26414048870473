import { defineStore } from 'pinia';
import { computed, ref } from 'vue';

import {
  useGlobals,
  useOnboardingSteps,
  useVpsOnboarding,
} from '@/composables';
import { hVpsRepo } from '@/repositories';
import {
  useVpsOnboardingStore,
  useVpsServerStore,
  useVpsTemplateStore,
} from '@/stores';
import {
  AmplitudeEvent,
  HVps,
  STORE_PERSISTENT_KEYS,
  VPS_PURCHASE_STEP,
  type ArrayElementType,
  type IVpsPurchaseFeature,
  type IVpsPurchaseState,
  type ServerOS,
} from '@/types';
import { extractHAsyncErrorMessage } from '@/utils/helpers';

export const PURCHASE_STEPS_ARRAY = Object.values(VPS_PURCHASE_STEP);

export const useVpsPurchaseStore = defineStore(
  'vpsPurchaseStore',
  () => {
    const { amplitudeV2, t } = useGlobals();
    const { isMonarxSupported } = useVpsOnboarding();
    const onboardingStore = useVpsOnboardingStore();
    const serverStore = useVpsServerStore();
    const templateStore = useVpsTemplateStore();

    const purchaseSteps = computed(() => PURCHASE_STEPS_ARRAY);

    const serverQuantity = ref(1);
    const initialState = {} as IVpsPurchaseState;

    const state = ref<IVpsPurchaseState>();

    const initState = () => {
      state.value = initialState;
    };

    const setState = (newState: Partial<IVpsPurchaseState>) => {
      state.value = { ...state.value, ...newState } as IVpsPurchaseState;
    };

    const setServerQuantity = (quantity: number) => {
      serverQuantity.value = quantity;
    };

    const {
      currentStep,
      completedSteps,
      previousStep,
      hasPreviousSteps,
      goToStep,
      goToPrevious,
      completionPercentage,
      transition,
      setTransition,
    } =
      useOnboardingSteps<ArrayElementType<typeof purchaseSteps.value>>(
        purchaseSteps,
      );

    const resetStep = () => {
      currentStep.value = VPS_PURCHASE_STEP.START;
      completedSteps.value = [];
      state.value = {} as IVpsPurchaseState;
      transition.value = 'slide-left';
    };

    const initFeatures = () => {
      const features: IVpsPurchaseFeature[] = [];

      if (
        isMonarxSupported(state.value?.template) &&
        !isGamePanelPurchase.value
      ) {
        features.push({
          name: HVps.OnboardingFeature.MONARX,
          title: t('Free Monarx malware scanner'),
          description: t(
            'Protect your VPS from potential threats and malicious files',
          ),
          isSelected: true,
        });
      }

      setState({ features });
    };

    const toggleFeature = (featureName: string) => {
      const feature = state.value?.features.find((f) => f.name === featureName);

      if (feature) {
        feature.isSelected = !feature.isSelected;
      }
    };

    const defaultHostname = computed(
      () =>
        HVps.ServerHost.PREFIX + state.value?.serverId + HVps.ServerHost.SUFFIX,
    );

    const purchaseTitle = computed(() =>
      isGamePanelPurchase.value
        ? t('v2.vps_purchase.title.game_panel')
        : t('v2.vps_purchase.title'),
    );

    const gamePanelTemplate = computed((): ServerOS => {
      const ALLOWED_TEMPLATE_IDS = [1120, 1090, 1001];

      return templateStore.templates.filter(({ id }) =>
        ALLOWED_TEMPLATE_IDS.includes(id),
      )[0];
    });

    const isGamePanelPurchase = computed(
      () => state.value?.type === HVps.OrderType.GAME_PANEL,
    );

    const setupServer = async (orderId: string) => {
      if (!state.value) {
        throw new Error('vps purchase state is not initialized');
      }

      if (!state.value.serverId) {
        throw new Error('serverId is not set');
      }

      if (!state.value.dataCenter) {
        throw new Error('dataCenter is not set');
      }

      if (!state.value.template) {
        throw new Error('template is not set');
      }

      const [{ data }, error] = await hVpsRepo.setup(
        state.value.serverId,
        {
          hostname: defaultHostname.value,
          rootPassword: state.value.rootPassword,
          panelPassword: state.value.panelPassword,
          dataCenterId: state.value.dataCenter?.id,
          templateId: state.value.template?.id,
          publicKey: state.value.sshKey,
          installMonarx: state.value.features.find(
            (f) => f.name === HVps.OnboardingFeature.MONARX,
          )?.isSelected,
          enableBackups: true,
        },
        {},
      );

      if (error) {
        serverStore.setServerLock(state.value.serverId, false);

        throw new Error(extractHAsyncErrorMessage(error));
      }

      onboardingStore.fillOnboardingData(orderId, {
        vmId: data.id,
        template: data.template,
        hostname: defaultHostname.value,
        osPassword: state.value.panelPassword,
        rootPassword: state.value.rootPassword,
        sshKey: {
          name: state.value.sshKey?.name,
          sshKey: state.value.sshKey?.key,
        },
        dataCenter: state.value.dataCenter,
        installMonarx: state.value.features.find(
          (f) => f.name === HVps.OnboardingFeature.MONARX,
        )?.isSelected,
        osType: state.value.template?.group as string,
        setupInitiated: true,
        domain: orderId,
      });

      serverStore.setServerLock(state.value.serverId, true);
      serverStore.setUpdatingServer({
        serverId: state.value.serverId,
        duration: data.template.installTime,
        isCreating: true,
      });

      amplitudeV2(AmplitudeEvent.Vps.ONBOARDING_COMPLETED, {
        isFirstVps: serverStore.servers.length === 1,
      });
    };

    return {
      goToStep,
      goToPrevious,
      transition,
      currentStep,
      completedSteps,
      previousStep,
      hasPreviousSteps,
      completionPercentage,
      gamePanelTemplate,
      isGamePanelPurchase,
      purchaseTitle,
      setTransition,
      state,
      initState,
      setState,
      resetStep,
      initFeatures,
      toggleFeature,
      setupServer,
      serverQuantity,
      setServerQuantity,
    };
  },
  {
    persist: { key: STORE_PERSISTENT_KEYS.VPS_PURCHASE },
  },
);
