import { computed } from 'vue';
import { useRoute } from 'vue-router';

import { useCookies } from '@/composables/useCookies';
import { useFeatureFlag } from '@/composables/useFeatureFlag';
import { useResourceFilters } from '@/composables/useResourceFilters';
import { useResourceMapper } from '@/composables/useResourceMapper';
import { useDeveloperToolsIntroductionStore } from '@/stores/developerTools/developerToolsIntroductionStore';
import { useProfileStore } from '@/stores/profile/profileStore';
import { useWordpressStore } from '@/stores/wordpressStore';
import { Cookie, FeatureFlag, Route } from '@/types';

export const useDeveloperTools = () => {
  const { isFeatureEnabled: isFeatureFlagEnabled } = useFeatureFlag(
    FeatureFlag.ID.DEVELOPER_TOOLS,
  );
  const route = useRoute();
  const developerToolsIntroductionStore = useDeveloperToolsIntroductionStore();
  const { activeDeveloperToolsResources } = useResourceMapper();
  const { hasCloudHosting, hasDeveloperTools } = useResourceFilters();
  const profileStore = useProfileStore();
  const wordpressStore = useWordpressStore();
  const { isCookieValueTruthyNumber: isDeveloperToolsStagingCookie } =
    useCookies(Cookie.DEVELOPER_TOOLS_STAGING);

  const isDeveloperToolsEnabled = computed(() => {
    const hasFeatureAccess =
      isFeatureFlagEnabled.value ||
      hasCloudHosting.value ||
      (isDeveloperToolsStagingCookie.value &&
        process.env.VITE_MODE === 'staging');

    if (!hasFeatureAccess || profileStore.isAccessManager) {
      return false;
    }

    return (
      wordpressStore.hasManagedAndOwnedWordpressWebsites ||
      !!activeDeveloperToolsResources.value.length
    );
  });

  const hasDeveloperToolsEnabledThroughCloudPlan = computed(
    () => isDeveloperToolsEnabled.value && hasCloudHosting.value,
  );

  const canManageDeveloperToolsSubscriptions = computed(
    () => !profileStore.isAccessManager && !hasCloudHosting.value,
  );

  const hasUnlimitedPlan = computed(
    () =>
      activeDeveloperToolsResources.value.some(
        ({ config }) => config?.tools?.[0]?.maxWebsiteQuantity === -1,
      ) || hasCloudHosting.value,
  );

  const isEligibleForTrial = computed(
    () => !hasDeveloperTools.value && !hasCloudHosting.value,
  );

  const hasAccessToDeveloperToolsDashboard = computed(
    () =>
      isDeveloperToolsEnabled.value &&
      (activeDeveloperToolsResources.value.length > 0 || hasCloudHosting.value),
  );

  const developerToolsRoute = computed(() => {
    const isInDashboard = route?.matched.some(
      ({ name }) => name === Route.DeveloperTools.DASHBOARD,
    );
    const currentRouteName = route?.name ?? undefined;

    if (!isInDashboard) {
      return Route.DeveloperTools.DASHBOARD;
    }

    return currentRouteName;
  });

  const isDeveloperToolsPopoverActive = computed(
    () =>
      developerToolsIntroductionStore.isIntroductionPopoverActive &&
      !hasDeveloperToolsEnabledThroughCloudPlan.value,
  );

  return {
    hasUnlimitedPlan,
    canManageDeveloperToolsSubscriptions,
    isDeveloperToolsEnabled,
    isEligibleForTrial,
    hasAccessToDeveloperToolsDashboard,
    developerToolsRoute,
    hasDeveloperToolsEnabledThroughCloudPlan,
    isDeveloperToolsPopoverActive,
  };
};
