import { difference } from 'lodash';
import { defineStore } from 'pinia';
import { computed, ref } from 'vue';
import { useStore } from 'vuex';

import { useResourcesStore } from './resourcesStore';
import { useWHOrdersStore } from './whOrdersStore';

import { useWidgets } from '@/composables';
import widgetsRepo from '@/repositories/widgetsRepo';
import type {
  IWidget,
  IWidgetConfiguration,
  IWidgetRequest,
  IWidgetWithoutIndex,
  IWidgetResponse,
} from '@/types';
import {
  MAX_WIDGETS_PER_ROW,
  MAX_WIDGET_COUNT,
  WIDGET_CONFIGURATION,
  BUILDER_WIDGETS,
  Hosting,
  HRESOURCES_STATE,
} from '@/types';
import {
  WH_ORDER_OWNERSHIP,
  WH_ORDER_WEBSITE_STATUS,
} from '@/types/models/whOrderModels';

const generatePlaceholderWidgetId = () => Date.now() + Math.random();

const generatePlaceholderWidget = () => ({
  id: generatePlaceholderWidgetId(),
  type: 'placeholder' as const,
  component: 'placeholder' as IWidgetConfiguration['component'],
  title: 'placeholder' as IWidgetConfiguration['title'],
  previewFooterTitle:
    'placeholder' as IWidgetConfiguration['previewFooterTitle'],
  resourceId: 'placeholder',
  domainName: 'placeholder',
  indexPosition: -1,
  customerId: 'placeholder',
  createdAt: 'placeholder',
  updatedAt: 'placeholder',
});

export const useWidgetsStore = defineStore('widgetsStore', () => {
  const isEditMode = ref(false);
  const widgets = ref<IWidget[]>([]);
  const widgetsInEdit = ref<IWidget[]>([]);
  const isLoading = ref(false);
  const isLoaded = ref(false);

  const whOrdersStore = useWHOrdersStore();
  const resourcesStore = useResourcesStore();
  const store = useStore();
  const { getIsWebsiteWidget } = useWidgets();

  const filterPlaceholderWidgets = (widgets: IWidget[]) =>
    widgets.filter(({ type }) => type !== 'placeholder');

  const widgetsWithoutPlaceholders = computed(() =>
    filterPlaceholderWidgets(widgets.value),
  );

  const widgetsInEditWithoutPlaceholders = computed(() =>
    filterPlaceholderWidgets(widgetsInEdit.value),
  );

  const fillEmptySpaceWithPlaceholders = (widgets: IWidget[], fill = false) => {
    const widgetsClone = [...widgets];

    for (
      let i = widgetsClone.length;
      (i > MAX_WIDGETS_PER_ROW && i < MAX_WIDGET_COUNT) ||
      i < (fill ? MAX_WIDGET_COUNT : MAX_WIDGETS_PER_ROW);
      i++
    ) {
      const currentWidget = widgetsClone[i];

      if (currentWidget?.type !== 'placeholder' || !currentWidget) {
        widgetsClone[i] = generatePlaceholderWidget();
      }
    }

    return widgetsClone;
  };

  const isOnlyPlaceholderWidgets = computed(() =>
    widgets.value.every((widget) => widget.type === 'placeholder'),
  );

  const availableWidgetToAddCount = computed(
    () => MAX_WIDGET_COUNT - widgetsInEditWithoutPlaceholders.value.length,
  );

  const formWidgetsFromResponse = (widgets: IWidgetResponse[]) =>
    widgets.flatMap((widget) => {
      const widgetConfiguration = WIDGET_CONFIGURATION[widget.type];

      return {
        ...widget,
        ...widgetConfiguration,
      };
    });

  const formWidgetsForRequest = (): IWidgetRequest[] =>
    widgetsWithoutPlaceholders.value.flatMap((widget) => {
      if (widget.type === 'placeholder') return [];

      return {
        type: widget.type,
        indexPosition: widget.indexPosition,
        ...(widget.resourceId && {
          resourceId: widget.resourceId,
        }),
        ...(widget.domainName && {
          domainName: widget.domainName,
        }),
      };
    });

  const enterEditMode = () => {
    if (!isEditMode.value) {
      widgetsInEdit.value = fillEmptySpaceWithPlaceholders(widgets.value, true);
    }

    isEditMode.value = true;
  };

  const exitEditMode = () => {
    isEditMode.value = false;
  };

  const saveWidgets = () => {
    exitEditMode();

    widgets.value = fillEmptySpaceWithPlaceholders(
      widgetsInEditWithoutPlaceholders.value,
    );
    widgetsInEdit.value = [];

    widgetsRepo.postWidgets({
      data: { widgets: formWidgetsForRequest() },
    });
  };

  const getRemovedWidgets = () =>
    difference(
      widgetsWithoutPlaceholders.value,
      widgetsInEditWithoutPlaceholders.value,
    );

  const getAddedWidgets = () =>
    difference(
      widgetsInEditWithoutPlaceholders.value,
      widgetsWithoutPlaceholders.value,
    );

  const getWidgetsChanges = () => {
    const removed = getRemovedWidgets();
    const added = getAddedWidgets();

    const removedNames = removed?.map(({ type }) => type);
    const addedNames = added?.map(({ type }) => type);

    if (
      !difference(removedNames, addedNames).length &&
      !difference(addedNames, removedNames).length
    ) {
      return { removedNames: [], addedNames: [] };
    }

    return { removedNames, addedNames };
  };

  const addWidget = (widget: IWidgetWithoutIndex, index: number) => {
    widgetsInEdit.value[index] = {
      ...widget,
      id: generatePlaceholderWidgetId(),
      indexPosition: index,
    };
  };

  const addWidgets = (widgets: IWidgetWithoutIndex[]) => {
    widgets.forEach((widget) => {
      const firstPlaceholderWidgetIndex = filterPlaceholderWidgets(
        widgetsInEdit.value,
      ).length;

      addWidget(widget, firstPlaceholderWidgetIndex);
    });
  };

  const deleteWidgetByIndex = (index: number) => {
    widgetsInEdit.value[index] = generatePlaceholderWidget();
  };

  const setWidgets = (data: IWidget[]) => {
    widgets.value = fillEmptySpaceWithPlaceholders(data, isEditMode.value);
  };

  const changeWidgetResource = (
    data: {
      resourceId: IWidget['resourceId'];
      domainName: IWidget['domainName'];
    },
    index: number,
  ) => {
    widgets.value[index].resourceId = data.resourceId;
    widgets.value[index].domainName = data.domainName;
  };

  const isWebsiteBuilder = (domain: string) => {
    const account = store.getters.getAccountByDomainWithoutCpanel(domain);

    return account?.type === Hosting.AccountType.BUILDER;
  };

  const setAvailableResourceWidgets = async (data: IWidgetResponse[]) => {
    await whOrdersStore.getWebsitesTotal(WH_ORDER_OWNERSHIP.OWNED);

    const activeWebsites = whOrdersStore.getWebsitesByOwnershipAndStatus(
      WH_ORDER_OWNERSHIP.OWNED,
      WH_ORDER_WEBSITE_STATUS.ENABLED,
    );

    const builderWebsites = activeWebsites.filter((website) =>
      isWebsiteBuilder(website.domain),
    );
    const builderWebsitesDomainNames = builderWebsites.map(
      ({ domain }) => domain,
    );
    const builderWidgetsToBeRemoved = data.filter(
      (widget) =>
        builderWebsitesDomainNames.includes(widget.domainName ?? '') &&
        !BUILDER_WIDGETS.includes(widget.type),
    );
    const builderWidgetsToBeRemovedIds = builderWidgetsToBeRemoved.map(
      ({ id }) => id,
    );

    const existingWidgets = data.filter((widget) => {
      if (!widget.domainName && !widget.resourceId) {
        return true;
      }

      if (getIsWebsiteWidget(widget.type)) {
        return activeWebsites.some(
          (website) => website.domain === widget.domainName,
        );
      }

      const resource = resourcesStore.getResourceById(
        Number(widget.resourceId),
      );

      return resource?.items.some(
        (item) => item.state !== HRESOURCES_STATE.DELETED,
      );
    });

    const validWidgets = existingWidgets.filter(
      (widget) => !builderWidgetsToBeRemovedIds.includes(widget.id),
    );

    if (validWidgets.length !== data.length) {
      const existingWidgetsWithIndex = validWidgets.map((item, index) => ({
        ...item,
        indexPosition: index,
      }));

      setWidgets(formWidgetsFromResponse(existingWidgetsWithIndex));

      saveWidgets();

      return;
    }

    setWidgets(formWidgetsFromResponse(data));
  };

  const fetchWidgets = async () => {
    isLoaded.value = false;
    isLoading.value = true;

    const [{ data }, error] = await widgetsRepo.getWidgets();

    isLoading.value = false;

    if (error) {
      return;
    }

    await setAvailableResourceWidgets(data);

    isLoaded.value = true;
  };

  return {
    isEditMode,
    widgets,
    widgetsInEdit,
    isOnlyPlaceholderWidgets,
    availableWidgetToAddCount,
    saveWidgets,
    addWidget,
    addWidgets,
    changeWidgetResource,
    deleteWidgetByIndex,
    enterEditMode,
    exitEditMode,
    fetchWidgets,
    isLoaded,
    getWidgetsChanges,
  };
});
