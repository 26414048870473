/**
 * This file contains integration with RiskJs.
 * For more information, see https://www.checkout.com/docs/business-operations/prevent-fraud/integrate-with-risk-js.
 */
import type { RiskJsInstance } from '@/types/models/riskModels';
import { loadScript } from '@/utils/helpers';
import { errorLogger } from '@/utils/services/errorLogging';

/**
 * Creates a RiskJs instance based on the given public key.
 *
 * @param publicKey Public key for RiskJs API
 * @returns instance of RiskJs or undefined if error occurs
 */
const getRiskJsInstance = async (publicKey: string) => {
  if (!window.Risk) {
    await loadScript(process.env.VITE_RISK_JS_URL as string);
  }

  const RiskJs = window.Risk;

  if (!RiskJs) {
    throw new Error('RiskJs is not defined after loading the script');
  }

  return RiskJs.init(publicKey);
};

/**
 * Publishes device signals (e.g., ip address, browser, etc.).
 *
 * @param risk RiskJs instance
 * @returns device session id or undefined if error occurs
 */
const getDeviceSessionId = async (risk: RiskJsInstance) =>
  risk.publishRiskData();

/**
 * Generates a RiskJs token to pass to one click pay endpoints.
 * Backend handles the case when token is missing.
 *
 * @param publicKey Public key for RiskJs API
 * @returns RiskJs token or undefined if error occurs
 */
export const getRiskJsToken = async (publicKey: string) => {
  try {
    const risk = await getRiskJsInstance(publicKey);

    return await getDeviceSessionId(risk);
  } catch (error) {
    errorLogger.logError(new Error('Failed to load RiskJs', { cause: error }));
    // It is safe to ignore errors as they are beyond our control (browser extensions,
    // ad-blockers, etc.). It was discussed with checkout.com (creators of RiskJs)
    // support team. A payment will not fail if token is not sent to the backend.
    console.error(error);
  }
};
