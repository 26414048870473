export const mapFixedCurrency = ({
  currencyCode,
}: {
  currencyCode: string;
}): string | undefined => {
  const currencyMap: { [key: string]: string } = {
    HRK: `HRK `,
  };

  return currencyMap[currencyCode];
};
