import { defineStore } from 'pinia';
import { computed, ref } from 'vue';
import { useRoute } from 'vue-router';

import { useWebsiteReportActions } from '@/composables';
import { useLanguages } from '@/composables/useLanguages';
import { useReportTemplatesStore } from '@/stores/developerTools/reportTemplatesStore';
import { useScheduledReportsStore } from '@/stores/developerTools/scheduledReportsStore';
import { useLanguageStore } from '@/stores/languageStore';
import { useProfileStore } from '@/stores/profile/profileStore';
import { useWordpressStore } from '@/stores/wordpressStore';
import {
  CreateReportStep,
  Header,
  type ReportContactDetails,
  type ReportDetails,
  type ReportBaseSchedule,
  type ReportStructure,
  Route,
  ScheduleType,
  type WhiteLabel,
  LANGUAGE_CODE,
  AVAILABLE_LANGUAGE_CONFIG,
} from '@/types';
import { getMessageByKeyAndLanguage } from '@/utils/services/i18nService';

const SUPPORTED_LANGUAGES = [
  LANGUAGE_CODE.en_GB,
  LANGUAGE_CODE.es_ES,
  LANGUAGE_CODE.fr_FR,
  LANGUAGE_CODE.pt_PT,
  LANGUAGE_CODE.lt_LT,
].map((code) => ({
  code,
  locale: AVAILABLE_LANGUAGE_CONFIG[code].locale,
}));

type UploadedFile = {
  name: string;
  uuid: string;
  url: string;
  lastModified: number; // timestamp
};

export const useCreateReportStore = defineStore('createReportStore', () => {
  const profileStore = useProfileStore();
  const route = useRoute();
  const scheduledReportStore = useScheduledReportsStore();
  const wordpressStore = useWordpressStore();
  const reportTemplateStore = useReportTemplatesStore();
  const { getTranslatedLanguageNameByCode } = useLanguages();
  const languageStore = useLanguageStore();

  const {
    IS_WEBSITE_ADDRESS_ENABLED,
    getScheduleTypeOptionFromParam,
    getScheduleTypeParamFromOption,
  } = useWebsiteReportActions();

  const uploadedFiles = ref<UploadedFile[]>([]);

  const shouldIncludeContactDetails = ref(true);
  const selectedTemplateId = ref<number | null>(null);

  const activeStepId = ref(CreateReportStep.SELECT_WEBSITE);
  const setActiveStep = (step: CreateReportStep) => (activeStepId.value = step);

  const DEFAULT_TITLE_SLUG = 'v2.reports.default.title';
  const DEFAULT_INTRO_MESSAGE_SLUG = 'v2.reports.default.intro.message';

  const defaultCompanyName = computed(
    () => profileStore.contact?.companyName || '',
  );
  const defaultEmail = computed(() => profileStore.contact?.email || '');

  const defaultPhoneNumber = computed(() => {
    if (!profileStore.contact?.phone || !profileStore.contact?.phoneCc) {
      return '';
    }

    return `${profileStore.contact.phoneCc}${profileStore.contact.phone}`;
  });

  const defaultWebsiteAddress = computed(() => '');

  const emptyContactDetails = computed(() => ({
    companyName: '',
    email: '',
    phone: '',
    ...(IS_WEBSITE_ADDRESS_ENABLED ? [{ websiteAddress: '' }] : []),
  }));

  const isEditScheduleFlow = computed(
    () => route.name === Route.DeveloperTools.EDIT_REPORT_SCHEDULE,
  );

  const isEditTemplateFlow = computed(
    () => route.name === Route.DeveloperTools.EDIT_REPORT_TEMPLATE,
  );

  const getSelectedLanguage = () => {
    const currentLocale =
      AVAILABLE_LANGUAGE_CONFIG[languageStore.currentLanguage].locale;

    return (
      SUPPORTED_LANGUAGES.find(({ locale }) => locale === currentLocale)
        ?.code || LANGUAGE_CODE.en_GB
    );
  };

  const DETAILS_INITIAL_STATE = computed(() => ({
    locale: getSelectedLanguage(),
    domain: '',
    title: DEFAULT_TITLE_SLUG,
    introMessage: DEFAULT_INTRO_MESSAGE_SLUG,
    contactDetails: {
      companyName: defaultCompanyName.value,
      email: defaultEmail.value,
      phone: defaultPhoneNumber.value,
      ...(IS_WEBSITE_ADDRESS_ENABLED
        ? [{ websiteAddress: defaultWebsiteAddress.value }]
        : []),
    },
    structure: {},
    deliverToEmail: '',
    schedule: {
      scheduleType: ScheduleType.NONE,
      scheduleValue: undefined,
      scheduleHour: undefined,
    },
    whiteLabel: {},
  }));

  const details = ref<ReportDetails>(DETAILS_INITIAL_STATE.value);

  const setShouldIncludeContactDetails = (value: boolean) =>
    (shouldIncludeContactDetails.value = value);

  const setTemplateId = (id: number | null) => {
    selectedTemplateId.value = id;
  };

  const set = <K extends keyof ReportDetails>(
    field: K,
    value: ReportDetails[K],
  ) => {
    details.value = {
      ...details.value,
      [field]: value,
    };
  };

  const get = <K extends keyof ReportDetails>(field: K) => details.value[field];

  const setMultiple = (params: Partial<ReportDetails>) => {
    Object.entries(params).forEach(([key, value]) => {
      set(key as keyof ReportDetails, value);
    });
  };

  const setDomain = (targetDomain: string) => {
    set('domain', targetDomain);
  };

  const setContactDetails = <K extends keyof ReportContactDetails>(
    field: K,
    value: ReportContactDetails[K],
  ) => {
    details.value.contactDetails = {
      ...details.value.contactDetails,
      [field]: value,
    };
  };

  const setStructureDetails = <K extends keyof ReportStructure>(
    field: K,
    value: ReportStructure[K],
  ) => {
    details.value.structure = {
      ...details.value.structure,
      [field]: value,
    };
  };

  const setScheduleDetails = <K extends keyof ReportBaseSchedule>(
    field: K,
    value: ReportBaseSchedule[K],
  ) => {
    if (field === 'scheduleType') {
      details.value.schedule = {
        scheduleType: value as ScheduleType,
        scheduleValue: null,
        scheduleHour: null,
      };

      return;
    }

    details.value.schedule = {
      ...details.value.schedule,
      [field]: value,
    };
  };

  const setDeliverToEmail = (receiverEmail: string) => {
    set('deliverToEmail', receiverEmail);
  };

  const setWhiteLabel = (whiteLabel: Partial<WhiteLabel>) => {
    details.value.whiteLabel = {
      ...details.value.whiteLabel,
      ...whiteLabel,
    };
  };

  const resetContactDetails = () => {
    details.value.contactDetails = {
      companyName: '',
      email: '',
      phone: '',
      ...(IS_WEBSITE_ADDRESS_ENABLED ? [{ websiteAddress: '' }] : []),
    };
  };

  const getIsDetailsScheduleTypeSelected = (type: ScheduleType) =>
    details.value.schedule.scheduleType === type;

  const prefillScheduledReportData = async (id: number) => {
    const schedule = await scheduledReportStore.getScheduleById(id);

    if (!schedule) {
      return false;
    }
    setMultiple({
      locale: schedule.locale,
      domain: schedule?.domain,
      title: schedule?.title,
      introMessage: schedule?.introMessage,
      contactDetails: schedule.contactDetails,
      structure: schedule.structure,
      deliverToEmail: schedule?.deliverToEmail,
      schedule: {
        scheduleType: getScheduleTypeOptionFromParam(schedule.scheduleType),
        scheduleValue: `${schedule.scheduleValue}`,
        scheduleHour: `${schedule.scheduleHour}`,
      },
      whiteLabel: schedule.whiteLabel,
    });

    return true;
  };

  const prefillTemplateData = (id: number) => {
    const template = reportTemplateStore.getTemplateById(id);
    if (!template) {
      return;
    }

    setMultiple({
      locale: template.locale,
      title: template.title,
      contactDetails: template.contactDetails,
      introMessage: template.introMessage,
      structure: template.structure,
      schedule: {
        scheduleType: getScheduleTypeOptionFromParam(
          template?.scheduleType || 'default',
        ),
        scheduleValue: `${template.scheduleValue}`,
        scheduleHour: `${template.scheduleHour}`,
      },
      whiteLabel: template.whiteLabel,
    });
  };

  const populateDefaultMessages = async () => {
    const { locale } = details.value;

    const [defaultTitle, defaultIntroMessage] = await Promise.all([
      getMessageByKeyAndLanguage(DEFAULT_TITLE_SLUG, locale),
      getMessageByKeyAndLanguage(DEFAULT_INTRO_MESSAGE_SLUG, locale),
    ]);
    setMultiple({ title: defaultTitle, introMessage: defaultIntroMessage });
  };

  const resetDetails = async () => {
    setMultiple(DETAILS_INITIAL_STATE.value);

    await populateDefaultMessages();
  };

  const $reset = () => {
    activeStepId.value = CreateReportStep.SELECT_WEBSITE;
    shouldIncludeContactDetails.value = true;
    selectedTemplateId.value = null;
    resetDetails();
    uploadedFiles.value = [];
  };

  const reportRequestHeaders = computed(() => {
    const domain = details.value.domain || '';
    const website =
      wordpressStore.getManagedAndOwnedWordpressWebsiteByDomain(domain);

    if (!website) return null;

    return {
      [Header.USERNAME]: website.username,
      [Header.ORDER_ID]: website.orderId,
      [Header.DOMAIN]: domain,
    };
  });

  const getReportBaseParams = (shouldIncludeDirectory = false) => {
    const website = wordpressStore.getManagedAndOwnedWordpressWebsiteByDomain(
      details.value.domain || '',
    );
    const {
      title,
      introMessage,
      contactDetails,
      structure,
      locale,
      whiteLabel,
    } = details.value;

    return {
      locale,
      title,
      introMessage,
      contactDetails: shouldIncludeContactDetails.value
        ? contactDetails
        : emptyContactDetails.value,
      structure,
      ...(shouldIncludeDirectory
        ? { directory: website?.directory || '' }
        : {}),
      whiteLabel,
    };
  };

  const scheduleParams = computed(() => {
    const { schedule } = details.value;
    const scheduleType = getScheduleTypeParamFromOption(schedule.scheduleType);

    if (!scheduleType) {
      return {
        scheduleType: null,
        scheduleValue: null,
        scheduleHour: null,
      };
    }

    return {
      scheduleType,
      scheduleValue: schedule.scheduleValue || null,
      scheduleHour: schedule.scheduleHour || null,
    };
  });

  const updateUploadedFiles = (file: UploadedFile) => {
    const existingIndex = uploadedFiles.value.findIndex(
      (uploadedFile) => uploadedFile.name === file.name,
    );

    if (existingIndex === -1) {
      uploadedFiles.value.push(file);
    } else if (
      uploadedFiles.value[existingIndex].lastModified !== file.lastModified
    ) {
      uploadedFiles.value[existingIndex] = file;
    }
  };

  const getUploadedFileByName = (targetFileName: string) =>
    uploadedFiles.value.find(({ name }) => name === targetFileName);

  const formattedSupportedLanguages = computed(() =>
    SUPPORTED_LANGUAGES.map((language) => ({
      label: getTranslatedLanguageNameByCode(language.code),
      value: language.code,
    })),
  );

  return {
    activeStepId,
    details,
    isEditScheduleFlow,
    isEditTemplateFlow,
    shouldIncludeContactDetails,
    emptyContactDetails,
    reportRequestHeaders,
    selectedTemplateId,
    scheduleParams,
    uploadedFiles,
    formattedSupportedLanguages,
    DEFAULT_TITLE_SLUG,
    DEFAULT_INTRO_MESSAGE_SLUG,
    get,
    setActiveStep,
    setTemplateId,
    setDomain,
    set,
    setContactDetails,
    setShouldIncludeContactDetails,
    setStructureDetails,
    setScheduleDetails,
    setDeliverToEmail,
    setWhiteLabel,
    resetContactDetails,
    getIsDetailsScheduleTypeSelected,
    prefillScheduledReportData,
    prefillTemplateData,
    getReportBaseParams,
    updateUploadedFiles,
    getUploadedFileByName,
    $reset,
    resetDetails,
  };
});
