import { defineStore } from 'pinia';
import { computed, ref } from 'vue';

import { hVpsRepo } from '@/repositories';
import { useVpsServerStore } from '@/stores';
import type { ServerOS } from '@/types';
import { HVps, STORE_PERSISTENT_KEYS, GROUP_OS_NAMES } from '@/types';

export const useVpsTemplateStore = defineStore(
  'vpsTemplateStore',
  () => {
    const serverStore = useVpsServerStore();

    const templates = ref<ServerOS[]>([]);

    const providerCode = ref(serverStore.currentServer.providerCode);

    const setProviderCode = (code: string) => (providerCode.value = code);

    const setTemplates = (data: ServerOS[]) => {
      templates.value = data.sort((a, b) => a.label.localeCompare(b.label));
    };

    const getTemplate = (templateId: number) =>
      templates.value.find(({ id }) => id === templateId) || ({} as ServerOS);

    const currentServerTemplate = computed(
      () => serverStore.currentServer.template || {},
    );

    const isCurrentTemplateLicensed = computed(() => {
      const templateType =
        currentServerTemplate.value?.panelType?.toLowerCase();
      const licenseType =
        serverStore.currentServer.license?.panelType?.toLowerCase();

      return licenseType && licenseType === templateType;
    });

    const getTemplatesByType = (type: string): ServerOS[] =>
      templates.value.filter(
        ({ group, providerCode }) =>
          group === type &&
          providerCode === serverStore.currentServer.providerCode,
      );

    const getTemplatesByTypeAndKeyword = (
      type: string,
      keyword: string,
      isDeepSearch?: boolean,
    ) =>
      templates.value.filter(
        ({ group, label, description, providerCode: templateProviderCode }) =>
          group === type &&
          templateProviderCode === providerCode.value &&
          (label.toLowerCase().includes(keyword.toLowerCase()) ||
            (isDeepSearch &&
              description?.toLowerCase().includes(keyword.toLowerCase()))),
      );

    const sortByIsRecommendedAndLabel = (
      a: string,
      b: string,
      acc: Record<string, ServerOS[]>,
    ) => {
      const aHasRecommended = acc[a].some((template) => template.isRecommended);
      const bHasRecommended = acc[b].some((template) => template.isRecommended);

      if (aHasRecommended !== bHasRecommended) {
        return aHasRecommended ? -1 : 1;
      }

      return a.localeCompare(b);
    };

    const getGroupedTemplates = (
      type: HVps.OsGroup,
      keyword?: string,
      isDeepSearch?: boolean,
    ): Record<string, ServerOS[]> => {
      const templatesByType = getTemplatesByTypeAndKeyword(
        type,
        keyword || '',
        isDeepSearch,
      );

      const acc = templatesByType.reduce((acc, os) => {
        if (type !== HVps.OsGroup.PLAIN_OS) {
          return {
            ...acc,
            [os.label]: [...(acc[os.label] || []), os],
          };
        }

        const osLabelKey = Object.values(GROUP_OS_NAMES).find((osName) =>
          os.label.includes(osName),
        );

        return {
          ...acc,
          [osLabelKey || os.label]: [
            ...(acc[osLabelKey || os.label] || []),
            os,
          ],
        };
      }, {} as Record<string, ServerOS[]>);

      return Object.keys(acc)
        .sort((a, b) => sortByIsRecommendedAndLabel(a, b, acc))
        .reduce(
          (sorted, key) => ({
            ...sorted,
            [key]: acc[key].sort((a, b) => b.label.localeCompare(a.label)),
          }),
          {},
        );
    };

    const fetchTemplates = async () => {
      const [{ data }, error] = await hVpsRepo.getTemplates();

      if (!error) setTemplates(data);

      return [{ data }, error];
    };

    return {
      providerCode,
      templates,
      setTemplates,
      getTemplate,
      currentServerTemplate,
      isCurrentTemplateLicensed,
      getTemplatesByType,
      getTemplatesByTypeAndKeyword,
      getGroupedTemplates,
      fetchTemplates,
      setProviderCode,
    };
  },
  {
    persist: { key: STORE_PERSISTENT_KEYS.VPS_TEMPLATE },
  },
);
