// JSON-slugs-migrated
import {
  NEXT_BEST_ACTION,
  type IDomainStatusInput,
  type NextBestAction,
} from '@hostinger/hdomains-status';
import type { ParsedDomain, ParseError } from 'psl';
import { parse } from 'psl';
import { computed, ref } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { useStore } from 'vuex';

import DomainNsFlowModal from '@/components/Modals/HModal/DomainNsFlowModal.vue';
import TemporaryDomainDisclaimerModal from '@/components/Modals/HModal/TemporaryDomainDisclaimerModal.vue';
import { useDomainManagementRedirect } from '@/composables/hDomains/useDomainManagementRedirect';
import { useDomainPurchase } from '@/composables/hDomains/useDomainPurchase';
import { useFeatureFlag } from '@/composables/useFeatureFlag';
import { useGlobals } from '@/composables/useGlobals';
import { useModal } from '@/composables/useModal';
import { useDomainsStatusStore } from '@/stores/domainsStatusStore';
import type {
  IActionParamType,
  IDomainNextBestAction,
  IHiddenDomainStatus,
} from '@/types';
import {
  Redirect,
  AmplitudeEvent,
  AmplitudeLocation,
  HIcon,
  Route,
  Website,
  FeatureFlag,
} from '@/types';
import { getSplittedDomainParts, toASCII } from '@/utils/helpers';
import {
  openAutoConnectModal,
  openRenewNowModal,
} from '@/utils/services/domains/hDomains';
import { errorLogger } from '@/utils/services/errorLogging';

export const defaultSupportedActions = [
  NEXT_BEST_ACTION.CONNECT_DOMAIN_TEMPORARY,
  NEXT_BEST_ACTION.MANAGE,
  NEXT_BEST_ACTION.TRANSFER_DOMAIN,
  NEXT_BEST_ACTION.CONNECT_DOMAIN_EXTERNAL,
  NEXT_BEST_ACTION.BUY_DOMAIN,
  NEXT_BEST_ACTION.RENEW_DOMAIN,
  NEXT_BEST_ACTION.RESTORE_DOMAIN,
  NEXT_BEST_ACTION.FINISH_REGISTRATION,
  NEXT_BEST_ACTION.CONNECT_DOMAIN,
  NEXT_BEST_ACTION.CONTACT_SUPPORT,
  NEXT_BEST_ACTION.PENDING_VERIFICATION,
  NEXT_BEST_ACTION.REVIEWING_DOMAIN,
];
export const HIDDEN_STATUS_STORAGE_KEY = 'hidden-domain-status';
const CLOUDFLARE_NAMESERVER = '.cloudflare.com';

const uniqueLoggedNextBestActionEvents = ref<string[]>([]);

export const useDomainStatus = (
  domain: string,
  supportedActions: NextBestAction[],
  orderId?: string,
) => {
  const store = useStore();
  const domainStatusStore = useDomainsStatusStore();
  const router = useRouter();
  const route = useRoute();
  const hiddenDomainStatusList = ref<IHiddenDomainStatus>({});
  const { amplitudeV2, t } = useGlobals();
  const { openModal } = useModal();
  const { openDomainPurchaseModal, openRecreateDomainFromRedemptionModal } =
    useDomainPurchase();
  const { navigateToManagedDomain } = useDomainManagementRedirect();

  const { availableTldList } = useDomainPurchase();

  const { isFeatureEnabled: isFallbackFeatureEnabled } = useFeatureFlag(
    FeatureFlag.ID.UDS_FALLBACK_NBA_LOGGING,
  );

  const { isFeatureEnabled: isAmplitudeLoggingFeatureEnabled } = useFeatureFlag(
    FeatureFlag.ID.UDS_AMPLITUDE_NBA_LOGGING,
  );
  const SUSPENDED_DOMAIN_HELP_PAGE_LINK =
    'https://hpanel.hostinger.com/help?ref=/&topic=website_issues';
  const REVIEWING_DOMAIN_HELP_PAGE_LINK =
    'https://support.hostinger.com/articles/6305787';

  const domainRef = ref(toASCII(domain));

  const domainWithoutSubdomain = computed(() =>
    domainStatusStore.getDomainWithoutSubdomain(domainRef.value),
  );

  const logUniqueNextBestAction = (
    nextBestAction: (typeof NEXT_BEST_ACTION)[keyof typeof NEXT_BEST_ACTION],
  ) => {
    const logKey = `${domain}${route.path}${nextBestAction}`;

    if (
      isAmplitudeLoggingFeatureEnabled.value &&
      !uniqueLoggedNextBestActionEvents.value.find((value) => value === logKey)
    ) {
      uniqueLoggedNextBestActionEvents.value.push(logKey);

      amplitudeV2(AmplitudeEvent.UdsPackage.ACTION_SHOWN, {
        actionShown: nextBestAction,
      });
    }
  };

  const domainStatus = computed(() => {
    const fetchedDomainStatus = domainStatusStore.getDomainStatus(
      domainRef.value,
    );

    const { domainStatus } = fetchedDomainStatus || {};
    const { nextBestAction } = domainStatus || {};

    if (!nextBestAction) {
      return fetchedDomainStatus;
    }

    const isNextBestActionSupported =
      supportedActions?.includes(nextBestAction);

    if (isNextBestActionSupported) {
      if (nextBestAction !== NEXT_BEST_ACTION.MANAGE) {
        logUniqueNextBestAction(nextBestAction);
      }

      return fetchedDomainStatus;
    }

    if (isFallbackFeatureEnabled.value) {
      errorLogger.logInfo('Fallback next best action was returned');
    }

    return {
      ...fetchedDomainStatus,
      domainStatus: {
        ...domainStatus,
        nextBestAction: NEXT_BEST_ACTION.MANAGE,
      },
    };
  });

  const hResourceId = computed(
    () => domainStatus.value.domainStatus.hresourceId,
  );

  const domainStatusInputObject = ref<IDomainStatusInput>({
    domain: domainRef.value,
  });

  const domainSubscriptionByProperty = computed(
    () =>
      store.getters['subscriptions/getDomainSubscriptionByProperty']({
        hResourceId: hResourceId.value,
      }) || {},
  );

  const isDomainLoading = computed(() =>
    domainStatusStore.isDomainInLoadingState(domainRef.value),
  );

  const isUsingCFNameservers = computed(() => {
    const nameservers =
      domainStatus.value?.additionalDetails?.domainPointingStatus?.nameservers;

    return (
      nameservers?.some((ns) => ns.includes(CLOUDFLARE_NAMESERVER)) || false
    );
  });

  const updateDomainStatusHiddenList = () => {
    const domainStatusList = localStorage.getItem(HIDDEN_STATUS_STORAGE_KEY);
    const parsedHiddenDomainStatusList = domainStatusList
      ? (JSON.parse(domainStatusList) as IHiddenDomainStatus)
      : {};

    hiddenDomainStatusList.value = parsedHiddenDomainStatusList;
  };

  const addDomainStatusToHiddenList = (
    domain: string,
    action: NextBestAction,
  ) => {
    updateDomainStatusHiddenList();
    hiddenDomainStatusList.value = {
      ...hiddenDomainStatusList.value,
      [domain]: {
        [action as string]: true,
      },
    };

    localStorage.setItem(
      HIDDEN_STATUS_STORAGE_KEY,
      JSON.stringify(hiddenDomainStatusList.value),
    );
  };

  const hiddenDomainStatusByDomain = computed(
    () => hiddenDomainStatusList.value[domainRef.value],
  );

  const openRestoreDomainModal = () => {
    openRecreateDomainFromRedemptionModal({
      domain: domainWithoutSubdomain.value,
      subscriptionId: domainSubscriptionByProperty.value.id,
      onSuccess: refetchDomainStatus,
    });
  };

  const openRenewDomainModal = (
    props?: { amplitudeSource?: string } & IActionParamType,
  ) => {
    openRenewNowModal({
      domainName: domainWithoutSubdomain.value,
      subscription: domainSubscriptionByProperty.value,
      onSuccess: () => refetchDomainStatus(15000),
      ...props,
    });
  };

  const refetchDomainStatus = async (callAfter?: number) => {
    await domainStatusStore.fetchDomainStatus(
      { ...domainStatusInputObject.value, noCache: 1 },
      true,
      callAfter,
    );
  };

  const openBuyDomainModal = (location?: string) => {
    openDomainPurchaseModal(domainWithoutSubdomain.value, {
      onOfferChosen: () => {
        if (!location) {
          return;
        }

        const [sld, tld] = getSplittedDomainParts(domainWithoutSubdomain.value);
        amplitudeV2(AmplitudeEvent.Hosting.CROSS_SELL_OFFER_CHOSEN, {
          location,
          tld,
          sld,
        });
      },
      onSuccess: async () => {
        await refetchDomainStatus();
        router.push({
          name: Route.Domain.REGISTER_DOMAIN_RESOURCE,
          params: {
            domain: toASCII(domainWithoutSubdomain.value),
          },
          query: { redirect: redirectLocation.value },
        });
      },
      title: t('Buy domain - {domain}', {
        domain: domainWithoutSubdomain.value,
      }),
    });
  };

  const redirectToAddDomainFlow = (type: Website.Type, websiteUid?: string) => {
    router.push({
      name: Route.Domain.ADD_DOMAIN,
      params: {
        domain: domainRef.value,
      },
      query: {
        websiteType: type,
        redirectUrl: location.href,
        websiteUid,
      },
    });
  };

  const handleTemporaryDomainConnect = (
    websiteType: Website.Type,
    websiteUid?: string,
  ) => {
    openModal({
      component: { TemporaryDomainDisclaimerModal },
      data: {
        onSuccess: () => {
          redirectToAddDomainFlow(websiteType, websiteUid);
        },
      },
    });
  };

  const openDomainNsFlowModal = async () => {
    const id =
      orderId ||
      store?.getters.getCurrentAccountWithDomain(domainRef.value)?.orderId ||
      '';

    openModal({
      component: { DomainNsFlowModal },
      data: {
        domain: domainRef.value,
        orderId: id ?? '',
        onSuccess: () => {
          store.commit('SET_DOMAIN_NOT_POINTING_HIDDEN', true);
        },
      },
    });
  };
  const redirectLocation = computed(() =>
    route.path.includes(Route.Base.WEBSITES)
      ? Route.Websites.WEBSITE_DASHBOARD
      : '',
  );

  const openActionRelatedModal = async (action: Redirect.Action) => {
    switch (action) {
      case Redirect.Action.CONNECT_DOMAIN:
        openDomainNsFlowModal();
        break;
      case Redirect.Action.RENEW_DOMAIN:
        openRenewDomainModal();
        break;
      case Redirect.Action.RESTORE_DOMAIN:
        openRestoreDomainModal();
        break;
      case Redirect.Action.BUY_DOMAIN:
        openBuyDomainModal();
        break;
    }
  };

  const propagationTimeInHours = computed(() => {
    const propagationTimeInMins = parseInt(
      domainStatus.value?.domainStatus?.propagationTimeMins || '',
      10,
    );

    return isNaN(propagationTimeInMins)
      ? 24
      : Math.ceil(propagationTimeInMins / 60);
  });

  const getPropagationHoursSuffix = computed(() =>
    propagationTimeInHours.value === 1 ? 'v2.hour' : 'v2.hours',
  );

  const getIsTldSupportedByHostinger = () => {
    const parsedDomain = parse(domainRef.value || '');

    const isParsedDomainType = (
      domain: ParsedDomain | ParseError,
    ): domain is ParsedDomain => typeof domain === 'object' && 'tld' in domain;

    const domainTld = isParsedDomainType(parsedDomain)
      ? parsedDomain.tld
      : null;

    return availableTldList.value.includes(domainTld ?? '');
  };

  const domainNextBestActionMap: Record<
    NextBestAction,
    IDomainNextBestAction | null
  > = {
    [NEXT_BEST_ACTION.CONTACT_SUPPORT]: {
      props: {
        icon: HIcon.ICON_ERROR,
        iconColor: 'danger',
        text: 'v2.domain.suspended',
        actionText: 'v2.domain.suspended.action',
      },
      action: () => {
        window.open(SUSPENDED_DOMAIN_HELP_PAGE_LINK, '_blank');
      },
    },
    [NEXT_BEST_ACTION.MANAGE]: null,
    [NEXT_BEST_ACTION.FINISH_REGISTRATION]: {
      props: {
        icon: HIcon.ICON_ERROR,
        iconColor: 'warning-dark',
        text: 'v2.domain.registration.pending',
        actionText: 'v2.domain.registration.pending.action',
      },
      action: () => {
        router.push({
          name: Route.Domain.REGISTER_DOMAIN_RESOURCE,
          params: {
            domain: toASCII(domainWithoutSubdomain.value),
          },
          query: { redirect: redirectLocation.value },
        });
      },
    },
    [NEXT_BEST_ACTION.CONNECT_DOMAIN_TEMPORARY]: {
      props: {
        icon: HIcon.ICON_CLOCK_CIRCLE_24,
        iconColor: 'gray',
        text: 'v2.temporary.domain',
        actionText: 'v2.temporary.domain.action',
      },
      action: (params) => {
        amplitudeV2(AmplitudeEvent.Websites.WEBSITES_CONNECT_DOMAIN_ENTER, {
          location: AmplitudeLocation.Domain.TEMPORARY_DOMAIN,
        });

        if (params?.websiteType) {
          params.websiteType !== Website.Type.WORDPRESS &&
          params.websiteType !== Website.Type.BUILDER
            ? handleTemporaryDomainConnect(params.websiteType)
            : redirectToAddDomainFlow(params.websiteType, params.websiteUid);
        }
      },
    },
    [NEXT_BEST_ACTION.CONNECT_DOMAIN]: {
      props: {
        icon: HIcon.ICON_ERROR,
        iconColor: 'danger',
        text: 'v2.domain.not.connected.internal',
        actionText: 'v2.domain.not.connected.internal.action',
      },
      action: () => {
        amplitudeV2(AmplitudeEvent.Websites.WEBSITES_CONNECT_DOMAIN_ENTER, {
          location: AmplitudeLocation.Domain.NOT_TEMPORARY_DOMAIN,
        });

        openAutoConnectModal(domainWithoutSubdomain.value, refetchDomainStatus);
      },
    },
    [NEXT_BEST_ACTION.CONNECT_DOMAIN_EXTERNAL]: {
      props: {
        icon: HIcon.ICON_ERROR,
        iconColor: 'danger',
        text: 'v2.domain.not.connected.external',
        actionText: 'v2.domain.not.connected.external.action',
      },
      action: () => {
        amplitudeV2(
          AmplitudeEvent.Websites.WEBSITES_CONNECT_EXTERNAL_DOMAIN_ENTER,
        );
        openDomainNsFlowModal();
      },
    },
    [NEXT_BEST_ACTION.TRANSFER_DOMAIN]: {
      props: {
        icon: HIcon.ICON_MIGRATE,
        iconColor: 'gray',
        text: 'v2.domain.external.transfer',
        actionText: 'v2.domain.external.transfer.action',
        isCapsuleCloseVisible: true,
      },
      closeAction: () => {
        addDomainStatusToHiddenList(
          domainRef.value,
          NEXT_BEST_ACTION.TRANSFER_DOMAIN,
        );
      },
      action: () => {
        amplitudeV2(AmplitudeEvent.Websites.WEBSITES_CHANGE_DOMAIN_ENTER);
        router.push({
          name: Route.Domain.TRANSFER_DOMAIN,
          query: {
            domain: toASCII(domainWithoutSubdomain.value),
          },
        });
      },
    },
    [NEXT_BEST_ACTION.RENEW_DOMAIN]: {
      props: {
        iconColor: 'danger',
        icon: HIcon.ICON_ERROR,
        text: 'v2.domain.renew',
        actionText: 'v2.domain.renew.action',
      },
      action: openRenewDomainModal,
    },
    [NEXT_BEST_ACTION.RESTORE_DOMAIN]: {
      props: {
        iconColor: 'danger',
        icon: HIcon.ICON_ERROR,
        text: 'v2.domain.restore',
        actionText: 'v2.domain.restore.action',
      },
      action: openRestoreDomainModal,
    },
    [NEXT_BEST_ACTION.BUY_DOMAIN]: getIsTldSupportedByHostinger()
      ? {
          props: {
            iconColor: 'gray',
            icon: HIcon.ICON_INFO,
            text: 'v2.domain.buy',
            actionText: 'v2.domain.buy.action',
          },
          action: (params) => {
            openBuyDomainModal(params?.location);
          },
        }
      : null,
    [NEXT_BEST_ACTION.CONNECTING]: {
      props: {
        icon: HIcon.ICON_CLOCK_CIRCLE_24,
        iconColor: 'gray',
        text: 'v2.domain.is.connecting',
        informationText: {
          title: 'v2.connecting.domain.dynamic.description',
          translateParams: {
            time: `${propagationTimeInHours.value}`,
            timeSuffix: getPropagationHoursSuffix.value,
          },
        },
      },
      action: () => {},
    },
    [NEXT_BEST_ACTION.PENDING_VERIFICATION]: {
      props: {
        icon: HIcon.ICON_WARNING_CIRCLE_FILLED,
        iconColor: 'warning-dark',
        text: 'v2.verify.email.to.register.domain',
        actionText: 'v2.more.info',
      },
      action: () =>
        navigateToManagedDomain({ domain: domainWithoutSubdomain.value }),
    },
    [NEXT_BEST_ACTION.REVIEWING_DOMAIN]: {
      props: {
        icon: HIcon.ICON_CLOCK_CIRCLE_24,
        iconColor: 'gray',
        text: 'v2.domain.being.reviewed',
        informationText:
          'v2.your.domain.is.being.reviewed.as.it.contains.some.sensitive.keywords.dot.this.can.take.up.to.24.hours.dot',
      },
      action: () => {
        window.open(REVIEWING_DOMAIN_HELP_PAGE_LINK, '_blank');
      },
    },
  };

  const domainAction = computed(() => {
    const nextBestAction = domainStatus.value?.domainStatus?.nextBestAction;

    if (!nextBestAction || !domainNextBestActionMap[nextBestAction]) {
      return null;
    }

    return domainNextBestActionMap[nextBestAction];
  });

  const isDomainNotConnected = computed(() =>
    (
      [
        NEXT_BEST_ACTION.CONNECT_DOMAIN_TEMPORARY,
        NEXT_BEST_ACTION.CONNECT_DOMAIN,
        NEXT_BEST_ACTION.CONNECT_DOMAIN_EXTERNAL,
      ] as NextBestAction[]
    ).includes(
      domainStatus.value?.domainStatus?.nextBestAction as NextBestAction,
    ),
  );

  updateDomainStatusHiddenList();

  return {
    domainNextBestActionMap,
    domainAction,
    domainStatusInputObject,
    domainStatus,
    domainSubscriptionByProperty,
    openRenewDomainModal,
    openActionRelatedModal,
    hiddenDomainStatusByDomain,
    isDomainLoading,
    refetchDomainStatus,
    isDomainNotConnected,
    isUsingCFNameservers,
    getIsTldSupportedByHostinger,
  };
};
