import type { FieldSchema } from '@hostinger/hcomponents';
import { computed, nextTick, ref } from 'vue';
import { useRoute } from 'vue-router';

import { useGlobals } from '@/composables/useGlobals';
import { useVpsFirewallStore } from '@/stores/vps/vpsFirewallStore';
import { useVpsServerStore } from '@/stores/vps/vpsServerStore';
import type {
  FirewallProtocol,
  IVpsFirewallRules,
  VpsFirewallRuleKeys,
} from '@/types';
import {
  AmplitudeEvent,
  FIREWALL_ACTION,
  FIREWALL_PROTOCOL,
  FIREWALL_SOURCE,
  FieldType,
  PROTOCOL_PORT_PRESELECT_MAP,
} from '@/types';

const DEFAULT_FORM_VALUES = {
  action: FIREWALL_ACTION.ACCEPT,
  protocol: '',
  source: '',
  sourceDetail: '',
  port: '',
};

export const useFirewallAddRule = (
  defaultValues: IVpsFirewallRules = DEFAULT_FORM_VALUES,
) => {
  const { t, toastr, amplitudeV2 } = useGlobals();
  const route = useRoute();
  const firewallStore = useVpsFirewallStore();
  const serverStore = useVpsServerStore();
  const reset = ref(false);
  const isPortPreselected = ref(false);

  const isLoading = ref(false);

  const groupId = route.params.groupId as string;
  const serverId = route.params.id as string;

  const formValues = ref<IVpsFirewallRules>({
    action: defaultValues.action,
    protocol: defaultValues.protocol,
    port: defaultValues.port === 'any' ? '' : defaultValues.port,
    source: defaultValues.source,
    sourceDetail:
      defaultValues.sourceDetail === 'any' ? '' : defaultValues.sourceDetail,
  });

  const isSourceDetailDisabled = computed(
    () => formValues.value?.source !== 'custom',
  );

  const addFirewallRuleSchemas = computed(() => ({
    action: {
      id: 'firewall-action-field',
      type: FieldType.select,
      label: t('Action'),
      value: FIREWALL_ACTION.ACCEPT,
      values: [{ label: t('v2.accept'), value: FIREWALL_ACTION.ACCEPT }],
      validation: {
        required: true,
      },
    } as FieldSchema,
    protocol: {
      id: 'firewall-protocol-field',
      type: FieldType.select,
      label: t('Protocol'),
      value: formValues.value?.protocol || '',
      values: Object.values(FIREWALL_PROTOCOL).map((value) => ({
        label: value,
        value,
      })),
      custom: {
        hideOptionalLabel: true,
      },
    } as FieldSchema,
    port: {
      id: 'firewall-port-field',
      type: FieldType.text,
      label: t('Port (or range)'),
      disabled: isPortPreselected.value,
      value: formValues.value?.port || '',
      custom: {
        hideOptionalLabel: true,
        isErrorLabelAbsolute: true,
      },
      validation: {
        ...(!isPortPreselected.value &&
          formValues.value.protocol && {
            port: true,
          }),
      },
    } as FieldSchema,
    source: {
      id: 'firewall-source-field',
      type: FieldType.select,
      label: t('Source'),
      values: [
        { label: t('Anywhere'), value: 'any' },
        { label: t('Custom'), value: 'custom' },
      ],
      value: formValues.value?.source || '',
      custom: {
        hideOptionalLabel: true,
      },
    } as FieldSchema,
    sourceDetail: {
      id: 'firewall-source-detail-field',
      type: FieldType.text,
      label: t('Source detail'),
      disabled: isSourceDetailDisabled.value,
      value: formValues.value?.sourceDetail || '',
      validation: {
        ...(!isSourceDetailDisabled.value && { isIPRange: true }),
      },
      custom: {
        hideOptionalLabel: true,
        isErrorLabelAbsolute: true,
      },
    } as FieldSchema,
  }));

  const resetForm = async () => {
    reset.value = true;
    formValues.value = { ...DEFAULT_FORM_VALUES };
    isPortPreselected.value = false;
    await nextTick();
    reset.value = false;
  };

  const selectPort = (protocol: FirewallProtocol) => {
    const preselectedPort = PROTOCOL_PORT_PRESELECT_MAP[protocol];

    isPortPreselected.value = preselectedPort !== 'editable';

    if (preselectedPort === 'disabled' || preselectedPort === 'editable') {
      return '';
    }

    return preselectedPort;
  };

  const handleFieldChange = async (
    { value }: { value: any },
    name: VpsFirewallRuleKeys,
  ) => {
    if (reset.value) return;

    if (name === 'protocol') {
      formValues.value.port = selectPort(value);
    }

    if (name === 'source') {
      const sourceDetail =
        value === FIREWALL_SOURCE.ANY ? '' : formValues.value.sourceDetail;

      formValues.value.sourceDetail = sourceDetail;
    }

    formValues.value[name] = value;
  };

  const formatRuleValues = ({
    action,
    protocol,
    port,
    source,
    sourceDetail,
  }: IVpsFirewallRules) => ({
    action,
    protocol: protocol || 'any',
    port: port || 'any',
    source: source || 'any',
    sourceDetail: sourceDetail || 'any',
  });

  const handleSubmit = async ({ values }: { values: IVpsFirewallRules }) => {
    if (serverStore.isServerManagementDisabled || isLoading.value) return;

    amplitudeV2(AmplitudeEvent.Vps.FIREWALL_RULE_ADD, {
      action: values.action,
      protocol: values.protocol,
      portOrRange: values.port,
      source: values.source,
      sourceDetail: values.sourceDetail,
    });

    isLoading.value = true;
    const [, error] = await firewallStore.addRule(
      serverId,
      groupId,
      formatRuleValues(values),
    );
    isLoading.value = false;

    if (!error) {
      toastr.s(t('Firewall rule added successfully'));
      resetForm();
    }
  };

  return {
    addFirewallRuleSchemas,
    isLoading,
    reset,
    groupId,
    serverId,
    formatRuleValues,
    handleFieldChange,
    handleSubmit,
    resetForm,
  };
};
