export enum RouteOwner {
  BUILDER = 'builder',
  DOMAINS = 'domains',
  EMAILS = 'emails',
  HELP_PAGE = 'helpPage',
  HOSTING = 'hosting',
  HPANEL = 'hpanel',
  VPS = 'vps',
  WEB_PRO = 'webPro',
}
