import {
  QueryClient,
  type FetchQueryOptions,
  CancelledError,
} from '@tanstack/query-core';

export const queryClient = new QueryClient();

export const fetchQuery = async <T>(
  options: FetchQueryOptions<T> & { overrideCache?: boolean },
): Promise<T> => {
  if (options?.overrideCache) {
    try {
      await queryClient.refetchQueries({
        queryKey: options.queryKey,
        exact: true,
      });
    } catch (error) {
      if (error instanceof CancelledError) {
        // Fail silently because it was cancelled due to navigation to another route
      } else {
        throw error;
      }
    }
  }

  return await queryClient.fetchQuery(options);
};
