export namespace Hosting {
  export enum Plan {
    STARTER = 'hostinger_starter',
    PREMIUM = 'hostinger_premium',
    BUSINESS = 'hostinger_business',
    CLOUD_ECONOMY = 'cloud_economy',
    CLOUD_PROFESSIONAL = 'cloud_professional',
    CLOUD_ENTERPRISE = 'cloud_enterprise',
    WORDPRESS_SINGLE = 'wp_hostinger_single',
    WORDPRESS_SINGLE_SPECIAL = 'wp_hostinger_single_special',
    WORDPRESS_PREMIUM = 'wp_hostinger_premium',
    WORDPRESS_PREMIUM_SPECIAL = 'wp_hostinger_premium_special',
    WORDPRESS_BUSINESS = 'wp_hostinger_business',
    WORDPRESS_BUSINESS_SPECIAL = 'wp_hostinger_business_special',
    WORDPRESS_CLOUD_ECONOMY = 'wp_cloud_economy',
    WORDPRESS_CLOUD_PROFESSIONAL = 'wp_cloud_professional',
    WOO_STARTER = 'woo_hostinger_premium',
    WOO_PREMIUM = 'woo_cloud_economy',
    WOO_BUSINESS = 'woo_cloud_professional',
    WOO_HOSTINGER_BUSINESS = 'woo_hostinger_business',
    WEBLINK_BRONZE = 'weblink_bronze',
    WEBLINK_PREMIUM = 'weblink_premium',
    WEBLINK_BUSINESS = 'weblink_business',
    BUSINESS_TOP = 'gcp_business_8',
    WORDPRESS_CLOUD_ECONOMY_SPECIAL = 'wp_cloud_economy_special',
    HOSTINGER_BUSINESS_TRIAL = 'hostinger_business_trial',
  }

  export enum PartialPlanTitle {
    WORDPRESS_BUSINESS = 'hosting-wpbusiness',
    WORDPRESS_SINGLE = 'hosting-wpsingle',
    WORDPRESS_PREMIUM = 'hosting-wppremium',
    WORDPRESS_CLOUD_ECONOMY = 'hosting-wpcloudeconomy',
    WORDPRESS_CLOUD_PROFESSIONAL = 'hosting-wpcloudprofessional',
  }

  export enum LegacyPlan {
    GCP_BUSINESS_8 = 'gcp_business_8',
  }

  export enum Vhost {
    MAIN = 'main',
    ADDON = 'addon',
    SUBDOMAIN = 'subdomain',
    PARKED = 'parked',
    WEBSITE_BUILDER = 'website_builder',
  }

  export enum HostingTab {
    SHARED = 'shared',
    CLOUD = 'cloud',
    WORDPRESS = 'wordpress',
  }

  export enum backupTypes {
    FILES = 'files',
    DATABASES = 'databases',
    WEBSITES = 'websites',
  }

  export enum PlanSlug {
    STARTER = 'hosting:hostinger_starter',
    PREMIUM = 'hosting:hostinger_premium',
    BUSINESS = 'hosting:hostinger_business',
    CLOUD_ECONOMY = 'hosting:cloud_economy',
    CLOUD_PROFESSIONAL = 'hosting:cloud_professional',
    CLOUD_ENTERPRISE = 'hosting:cloud_enterprise',
    WP_SINGLE = 'hosting:wp_hostinger_single',
    WP_PREMIUM = 'hosting:wp_hostinger_premium',
    WP_BUSINESS = 'hosting:wp_hostinger_business',
    WP_CLOUD_ECONOMY = 'hosting:wp_cloud_economy',
    WP_CLOUD_PROFESSIONAL = 'hosting:wp_cloud_professional',
    WOO_CLOUD_ECONOMY = 'hosting:woo_cloud_economy',
    WOO_CLOUD_PROFESSIONAL = 'hosting:woo_cloud_professional',
    WEBLINK_BRONZE = 'hosting:weblink_bronze',
    WEBLINK_PREMIUM = 'hosting:weblink_premium',
    WEBLINK_BUSINESS = 'hosting:weblink_business',
    WEBSITE_BUILDER = 'hosting:website_builder',
  }

  export enum Subcategory {
    SHARED = 'shared',
    CLOUD = 'cloud',
  }

  export enum Perks {
    SINGLE_WEBSITE = '{count} Website',
    WEBSITES = '{count} Websites',
    STORAGE = '{count} GB SSD Storage',
    RAM_MB = '{count} MB RAM',
    RAM_GB = '{count} GB RAM',
    SINGLE_CPU = '1 CPU Core',
    CPU = '{count} CPU Cores',
    EMAIL = '{count} Email Account',
    FREE_EMAIL = 'Free Email',
    ONE_EMAIL_ACCOUNT = '1 Email Account',
    ONE_FREE_EMAIL_ACCOUNT = '1 Free Email Account',
    FREE_DOMAIN = 'Free Domain',
    SSL = 'Unlimited Free SSL',
    VISITORS = '<b>~{count}</b> Visits Monthly',
    BUILDER = 'Website builder',
    NO_CODE_BUILDER = 'No-Code Website builder',
    WP_OPTIMIZED = 'Optimized for WordPress',
    WP_STAGING = 'WordPress Staging Tool',
    WP_MULTISITE = 'WordPress Multisite',
    WP_MANAGED = 'Managed WordPress',
    SUBDOMAIN = '{count} Subdomains',
    SHARED_IP_ADDRESS = 'Shared IP address',
    FTP_SINGLE = '{count} FTP Account',
    FTP = 'Unlimited FTP Accounts',
    NOT_OPTIMIZED_FOR_WOOCOMMERCE = 'Not optimized for WooCommerce',
    BASIC_WOOCOMMERCE_OPTIMIZATION = 'Basic WooCommerce optimization',
    OPTIMIZED_FOR_WOOCOMMERCE_PERFORMANCE = 'Optimized for WooCommerce performance',
    OPTIMIZED_FOR_WOOCOMMERCE = 'Optimized for WooCommerce',
    ONE_FTP_ACCOUNT = '1 FTP Account',
    ONE_FREE_FTP_ACCOUNT = '1 Free FTP Account',
    UNMETERED_TRAFFIC = 'Unmetered traffic',
    CUSTOMER_SUPPORT = '24/7 Customer Support',
    FREE_BACKUPS = 'Free Weekly Backups',
    OBJECT_CACHE = 'Object Cache for WordPress',
    WEEKLY_BACKUPS = 'Weekly Backups',
    DAILY_BACKUPS = 'Daily Backups',
    BANDWIDTH = '{count} Bandwidth',
    DATABASE_SIZE = '{count} GB Database Size',
    CRONJOBS = '{count} Cronjobs',
    SSH_ACCESS = 'SSH Access',
    DEDICATED_RESOURCES = 'Dedicated Resources',
    DEDICATED_IP_ADDRESS = 'Dedicated IP Address',
    MALWARE_SCANNER = 'Malware Scanner',
    FREE_WEBSITE_MIGRATION = '<b>Free</b> Automatic Website Migration',
  }

  export enum NewPerks {
    SINGLE_WEBSITE = '<b>1</b> Website',
    WEBSITES = '<b>{count}</b> Websites',
    STANDARD_PERFORMANCE = '<b>Standard</b> Performance',
    INCREASED_PERFORMANCE = '<b>Increased(up to 5x)</b> Performance',
    MAX_PERFORMANCE = '<b>Maximum (up to 10x)</b> Performance',
    SSD_STORAGE = '<b>{count} GB</b> SSD Storage',
    NVME_STORAGE = '<b>{count} GB</b> NVMe Storage',
    WEEKLY_BACKUPS = '<b>Weekly</b> Backups',
    DAILY_BACKUPS = '<b>Daily</b> Backups',
    SINGLE_EMAIL = '<b>1</b> Email Account',
    FREE_EMAIL = '<b>Free</b> Email',
    EMAIL = '<b>{count}</b> Email Accounts',
    UNLIMITED_SSL = '<b>Unlimited</b> Free SSL',
    UNLIMITED_BANDWIDTH = '<b>Unlimited</b> Bandwidth',
    FREE_DOMAIN = '<b>Free</b> Domain',
    FREE_CDN = '<b>Free</b> CDN',
    SHARED_IP_ADDRESS = '<b>Shared</b> IP address',
    WEBSITE_BUILDER = '<b>Website</b> Builder',
    MANAGED_WORDPRESS = '<b>Managed</b> WordPress',
    BASIC_WOOCOMMERCE_OPTIMIZATION = '<b>Basic</b> WooCommerce optimization',
    OPTIMIZED_FOR_WOOCOMMERCE = '<b>Optimized</b> for WooCommerce',
    FREE_AUTOMATIC_WEBSITE_MIGRATION = '<b>Free Automatic</b> Website Migration',
    WORDPRESS_STAGING_TOOL = '<b>WordPress</b> Staging Tool',
    OBJECT_CACHE = '<b>Object Cache</b> for WordPress',
    ON_DEMAND_BACKUP = '<b>On-Demand</b> Backup',
    DAILY_AND_ON_DEMAND_BACKUP = '<b>Daily and On-Demand</b> Backups',
    RAM = '<b>{count} GB</b> RAM',
    CPU_CORES = '<b>{count}</b> CPU Cores',
    ADVANCED_WOOCOMMERCE = '<b>Advanced</b> WooCommerce',
    DEDICATED_IP_ADDRESS = '<b>Dedicated</b> IP Address',
    PRIORITY_SUPPORT = '<b>Priority</b> Support',
    VISITORS = '~<b>{count}</b> Visits Monthly',
    FILES_AND_DIRECTORIES = '<b>{count}</b> Files and Directories (Inodes)',
    STARTER_WOOCOMMERCE = '<b>Starter</b> WooCommerce',
    STANDARD_WOOCOMMERCE = '<b>Standard</b> WooCommerce',
    BASIC_WOOCOMMERCE = '<b>Basic</b> WooCommerce',
    EXPERT_WOOCOMMERCE = '<b>Expert</b> WooCommerce',
    WORDPRESS_AI_TOOLS = '<b>WordPress</b> AI Tools',
    AUTOMATIC_WP_UPDATES = 'WordPress Auto Updates',
    SMART_WP_UPDATES = '<b>Smart</b> WordPress Auto Updates',
    FREE_AMAZON_AFFILIATE_PLUGIN = '<b>Free</b> Amazon Affiliate WordPress Plugin',
    STANDARD_DDOS_PROTECTION = '<b>Standard</b> DDoS Protection',
    ENHANCED_DDOS_PROTECTION = '<b>Enhanced</b> DDoS Protection',
    SUBDOMAINS = '<b>{count}</b> Subdomains',
    PHP_WORKERS = '<b>{count}</b> PHP Workers',
    MYSQL_MAX_CONNECTIONS = '<b>{count}</b> MySQL Max User Connections',
    FREE_WHOIS_PRIVACY = '<b>Free</b> Domain WHOIS Privacy Protection',
    WORDPRESS_MULTISITE = 'WordPress Multisite',
    WP_CLI_AND_SSH = 'WP-CLI and SSH',
    PRE_BUILT_TEMPLATES = '<b>Free</b> pre-build templates',
    WP_VULNERABILITIES_SCANNER = 'WordPress vulnerabilities scanner',
    WP_STANDARD_ACCELERATION = '<b>Standard</b> WordPress acceleration',
    WP_ADVANCED_ACCELERATION = '<b>Advanced</b> WordPress acceleration',
    WP_ACCELERATION_LITESPEED = 'WordPress acceleration (LiteSpeed)',
    FREE_DOMAIN_VALUE = '{openTag}Free{closeTag} domain (*.** value)',
    FREE_WORDPRESS_INSTALLATION = '<b>Free</b> 1-click WordPress installation',
    WP_COMPATIBILITY_CHECKER = 'WordPress compatibility checker',
    AI_CONTENT_CREATOR = 'AI content creator',
    WP_AI_TROUBLESHOOTER = 'WordPress AI troubleshooter',
    AI_ASSISTANT = 'AI assistant',
    WEB_APPLICATION_FIREWALL = 'Web application firewall',
    CLOUDFLARE_PROTECTED_NS = 'Cloudflare protected nameservers',
    ACCESS_MANAGER = 'Secure access manager',
    UPTIME_GUARANTEE = '99.9% uptime guarantee',
    GLOBAL_DATA_CENTERS = 'Global Data Centers',
    DATABASES = '{count} Databases',
    GIT_ACCESS = 'Git Access',
    MULTIPLE_PHP_VERSIONS = 'Multiple PHP Versions',
    DNS_MANAGEMENT = 'DNS Management',
    CACHE_MANAGER = 'Cache Manager',
    CONTROL_PANEL = 'Powerful control panel',
    UNLIMITED_CRONJOBS = 'Unlimited Cronjobs',
  }

  export enum PerkTitles {
    MANAGED_WORDPRESS = 'Managed WordPress',
    AI_FEATURES = 'AI features',
    WEBSITE_BUILDER = 'Hostinger website builder',
    SECURITY = 'Security',
    SERVICE_AND_SUPPORT = 'Service and support',
    TECHNICAL_DETAILS = 'Technical details',
  }

  export enum WebsiteBuilderPerks {
    PUBLISH_WEBSITE = '<b>Publish<b> Your Website',
    FREE_DOMAIN = '<b>Free</b> Domain ($9.99 value)',
    CUSTOMER_SUPPORT = '<b>24/7</b> Customer Support',
    MONEY_BACK_GUARANTEE = '<b>30-Day</b> Money-Back Guarantee',
    DRAG_AND_DROP = '<b>Drag-and-Drop</b> Editor',
    TEMPLATES = '<b>{count}</b> Templates',
    MARKETING_INTEGRATIONS = '<b>Marketing</b> Integrations',
    MOBILE_EDITING = '<b>Mobile</b> Editing',
    AI_WEBSITE_BUILDER = '<b>AI</b> Website Builder',
    AI_IMAGE_GENERATOR = '<b>AI</b> Image Generator',
    AI_WRITER = '<b>AI</b> Writer',
    AI_BLOG_GENERATOR = '<b>AI</b> Blog Generator',
    AI_HEATMAP = '<b>AI</b> Heatmap',
    AI_SEO_TOOLS = '<b>AI</b> SEO Tools',
    AI_COPYWRITING_TOOL = '<b>AI</b> copywriting tool',
    ECOMMERCE_FEATURES = '<b>eCommerce</b> Features',
    TRANSACTIONS_FEES = '<b>0%</b> Transaction Fees',
    PAYMENT_METHODS = '<b>{count}+</b> Payment Methods',
    DESIGNER_CRAFTED_TEMPLATES = '<b>{count}</b> designer-crafted templates',
    GOOGLE_ANALYTICS = 'Google Analytics integration',
  }

  export enum PerkStatus {
    SUCCESS = 'success',
    WARNING = 'warning',
    DANGER = 'danger',
    UNAVAILABLE = 'unavailable',
  }

  export enum OrderStatus {
    PENDING_DATA_ENTRY = 'pending_data_entry',
    PENDING_SETUP = 'pending_setup',
    ACTIVE = 'active',
    SUSPENDED = 'suspended',
    IN_PROGRESS = 'in_progress',
    FAILED = 'failed',
    INSTALLING = 'installing',
    EXPIRED = 'expired',
    CANCELLED = 'cancelled',
    PERFORMANCE_DROP = 'performance_drop',
    BOOSTED = 'boosted',
    TRANSFERRING = 'transferring',
    MAINTENANCE = 'maintenance',
    SOME_RESOURCE_LIMIT_REACHED = 'some_resource_limit_reached',
    REMOVING = 'removing',
  }

  export const NotificationOrderStatus = {
    MAINTENANCE_UPCOMING: 'maintenance_upcoming',
    MAINTENANCE_ACTIVE: 'maintenance_active',
    ACTIVE_WILL_EXPIRE_SOON: 'active_will_expire_soon',
    EXPIRED_RENEWABLE: 'expired_renewable',
    EXPIRED_RESTORABLE: 'expired_restorable',
    ...OrderStatus,
  } as const;

  export enum HostingActions {
    UPGRADE = 'upgrade',
    RENEW = 'renew',
  }

  export enum SuspendReason {
    FRAUD = 'fraud',
    ABUSE = 'abuse',
    NON_PAYMENT = 'non_payment',
    FRAUD_PAYMENT = 'fraud_payment',
    INACTIVITY = 'inactivity',
    SELF_CANCELED = 'self',
    CUSTOMER_REQUESTED = 'customer_requested',
    VERIFICATION_FAILED = 'verification_failed',
    EXPIRED = 'expired',
  }

  export enum CancelledReason {
    FRAUD = 'fraud',
    REFUND = 'refund',
    INCORRECT_ORDER = 'incorrect_order',
    STAGING_DELETED = 'staging_deleted',
    WRONG_PRODUCT = 'wrong_product',
  }

  export enum CBSuspendReason {
    NOT_PAID = 'Not Paid',
  }

  export enum HostingActionType {
    RENEW = 'renew',
    UPGRADE = 'upgrade',
    RESOURCES = 'resources',
    ADD = 'add',
    MIGRATE = 'migrate',
    BOOST = 'boost',
  }

  export enum CMS {
    WORDPRESS = 'wordpress',
    WOO = 'woo',
    JOOMLA = 'joomla',
    WEBSITE_BUILDER = 'website-builder',
    OTHER = 'other',
  }

  export enum Tips {
    UNUSED_INSTALLATION = 'unused_installation',
    DOMAIN_NOT_POINTING = 'domain_not_pointing',
    SSL_FAILED = 'ssl_failed',
    TRANSFER_SERVER = 'transfer_server',
    PLAN_EXPIRES = 'plan_expires',
    PERFORMANCE_DROP = 'performance_drop',
    INSTALL_LITESPEED = 'install_litespeed',
    RESOURCE_USAGE = 'resource_usage',
    SETUP_EMAIL = 'setup_email',
    UPDATE_WORDPRESS = 'update_wordpress',
    UPDATE_PLUGIN = 'update_plugin',
    UPDATE_THEME = 'update_theme',
    REGISTER_DOMAIN = 'register_domain',
    PROTECT_DOMAIN = 'protect_domain',
    TOP_PLAN_EXCEEDED = 'top_plan_exceeded',
    RANKING_COACH = 'ranking_coach',
    ENABLE_OBJECT_CACHE = 'enable_object_cache',
    UPGRADE_OBJECT_CACHE = 'upgrade_object_cache',
    WORDPRESS_ERROR = 'wordpress_error',
    HOSTINGER_PLUGIN = 'hostinger_plugin',
    ENABLE_CDN = 'enable_cdn',
    AI_CONTENT_GENERATION = 'ai_content_generation',
    AUTO_UPDATES = 'auto_updates',
    DOMAIN_TRANSFER = 'domain_transfer',
    PHP_VERSION = 'php_version',
    WORDPRESS_VERSION = 'wordpress_version',
    WORDPRESS_COMPONENTS_COMPATIBILITY = 'wordpress_components_compatibility',
    DEPRECATED_PHP_VERSION = 'deprecated_php_version',
    DEVELOPER_TOOLS_BLACK_FRIDAY = 'developer_tools_black_friday',
  }

  export enum NonUpgradeablePlanTitle {
    CLOUD_ENTERPRISE = 'Cloud Enterprise',
    CLOUD_GLOBAL = 'Cloud Global',
  }

  export enum AccountType {
    ACCOUNT = 'account',
    BUILDER = 'builder',
    ADDON = 'addon',
  }
  export enum AccessOrderTitle {
    PENDING_CONFIRMATION = 'Pending confirmation',
    ACTIVE = 'Active',
    DISABLED = 'Disabled',
    CANCELED = 'Canceled',
  }

  export enum MaintenanceStatus {
    UPCOMING = 'upcoming',
    ACTIVE = 'active',
  }

  export enum WordpressTemplateSlugs {
    HOSTINGER_BLOG = 'hostinger-blog',
    HOSTINGER_AFFILIATE_THEME = 'hostinger-affiliate-theme',
  }

  export enum WordPressPluginName {
    HOSTINGER_AFFILIATE_PLUGIN = 'hostinger-affiliate-plugin',
    HOSTINGER_AI_ASSISTANT = 'hostinger-ai-assistant',
    HOSTINGER = 'hostinger',
    ALL_IN_ONE_SEO = 'all-in-one-seo-pack',
    GOOGLE_ANALYTICS_FOR_WP = 'google-analytics-for-wordpress',
    OPTINMONSTER = 'optinmonster',
    WP_FORMS = 'wpforms-lite',
  }

  export enum OwnershipTransferRequestState {
    PENDING = 'pending',
    ACCEPTED = 'accepted',
    REJECTED = 'rejected',
    EXPIRED = 'expired',
  }

  export enum OwnershipTransferState {
    PENDING = 'pending',
    INITIATED = 'initiated',
    STARTED = 'started',
    FINISHED = 'finished',
    FAILED = 'failed',
    COMPLETED = 'completed',
  }

  export enum OwnershipTransferWebsiteState {
    PENDING = 'pending',
    REJECTED = 'rejected',
    IN_PROGRESS = 'in_progress',
    FAILED = 'failed',
    COMPLETED = 'completed',
  }

  export const DashboardDetailsWidgets = {
    HOSTING: 'hosting',
    DOMAIN: 'domain',
    EMAIL: 'email',
    BACKUPS: 'backups',
  } as const;

  export const HostingPurchaseRouteParam = {
    OWNERSHIP_TRANSFER: 'ownership-transfer',
    WEBSITE_BUILDER: 'website-builder',
  } as const;

  export type HostingPurchaseRouteParamValue =
    (typeof HostingPurchaseRouteParam)[keyof typeof HostingPurchaseRouteParam];
}
