import type { RouteRecordRaw } from 'vue-router';

import proPanelRegistrationGuard from '@/guards/proPanelRegistrationGuard';
import { Route } from '@/types';

const baseMetaData = {
  showSubheader: false,
  header: 'simple-header',
  fullscreen: true,
  scrollToTop: false,
  isOnboarding: true,
};
const clientPurchaseOnboardingRoutes = [
  {
    path: '/hostinger-pro/client-purchase-onboarding',
    meta: {
      title: 'Hostinger Pro',
    },
    component: () =>
      import(
        '@/views/HostingerPro/ClientPurchase/ClientPurchaseOnboarding.vue'
      ),
    beforeEnter: (to, from, next) => proPanelRegistrationGuard(to, from, next),
    children: [
      {
        path: 'select-onboarding-flow',
        name: Route.HostingerPro.SELECT_ONBOARDING_FLOW,
        meta: {
          step: 1,
          ...baseMetaData,
        },
        component: () =>
          import(
            '@/views/HostingerPro/ClientPurchase/ClientPurchaseOnboardingFlow.vue'
          ),
      },

      {
        path: 'add-new-client',
        name: Route.HostingerPro.ADD_NEW_CLIENT,
        meta: {
          step: 1,
          ...baseMetaData,
        },
        component: () =>
          import(
            '@/views/HostingerPro/ClientPurchase/ClientPurchaseOnboardingNewClient.vue'
          ),
      },
      {
        path: 'select-products',
        name: Route.HostingerPro.PRO_SELECT_PRODUCTS,
        meta: {
          step: 1,
          ...baseMetaData,
        },
        component: () =>
          import(
            '@/views/HostingerPro/ClientPurchase/ClientPurchaseReferralSelect.vue'
          ),
      },
      {
        path: 'select-client',
        name: Route.HostingerPro.CLIENT_SELECT,
        meta: {
          step: 1,
          ...baseMetaData,
        },
        component: () =>
          import(
            '@/views/HostingerPro/ClientPurchase/ClientPurchaseSelect.vue'
          ),
      },
      {
        path: 'select-vps',
        name: Route.HostingerPro.PRO_VPS_PURCHASE,
        meta: {
          title: 'VPS',
          ...baseMetaData,
          sideMenuExists: false,
          step: 2,
        },
        component: () => import('@/views/HostingerPro/Vps/VpsSelection.vue'),
      },
      {
        path: 'purchase-summary',
        name: Route.HostingerPro.PURCHASE_SUMMARY,
        meta: {
          step: 3,
          ...baseMetaData,
        },
        component: () =>
          import(
            '@/views/HostingerPro/ClientPurchase/ClientPurchaseSummary.vue'
          ),
      },
    ],
  },
] as RouteRecordRaw[];
export default clientPurchaseOnboardingRoutes;
