import { H_LABEL_THEME } from '@hostinger/hcomponents';
import type { IHDomainResource } from '@hostinger/hdomains-status';
import {
  HDOMAIN_RESOURCE_TYPES,
  HDOMAIN_RESOURCE_STATUSES,
  REGISTRANT_EMAIL_VERIFICATION_STATUS,
} from '@hostinger/hdomains-status';

import type { DomainTransferInfoStatus } from '@/types';
import {
  DOMAIN_TOOLTIP_TEXT,
  DOMAIN_TRANSFER_INFO_STATUS,
  VISIBLE_DOMAIN_STATUS,
  TLDS,
} from '@/types';
import {
  capitalise,
  toLocalTime,
  urlRegexp,
  mapKeyValue,
  getSldTld,
} from '@/utils/helpers';
import { EXPIRATION_THRESHOLD_60_DAYS_IN_MS } from '@/utils/helpers/domainsHelper';
import { compareTlds } from '@/utils/helpers/hDomains/domainRegistrationHelper';
import { snakeToReadable } from '@/utils/services/namingConventionsService';

export const useHDomainState = () => {
  const isDomainResource = ({ resourceType }: IHDomainResource) =>
    resourceType === HDOMAIN_RESOURCE_TYPES.DOMAIN;

  const isDomainTransferResource = ({ resourceType }: IHDomainResource) =>
    resourceType === HDOMAIN_RESOURCE_TYPES.DOMAIN_TRANSFER;

  const inPendingVerification = ({ status }: IHDomainResource) =>
    status === HDOMAIN_RESOURCE_STATUSES.PENDING_VERIFICATION;

  const isPendingEmailVerification = (hDomainResource: IHDomainResource) =>
    isActive(hDomainResource) &&
    hDomainResource.additionalDetails?.registrantEmailVerificationStatus ===
      REGISTRANT_EMAIL_VERIFICATION_STATUS.PENDING;

  const isInGracePeriod = ({ additionalDetails }: IHDomainResource) =>
    !!additionalDetails?.isGracePeriod;

  const isInRedemptionPeriod = ({ additionalDetails }: IHDomainResource) =>
    !!additionalDetails?.isRedemptionPeriod;

  const isRequested = ({ status }: IHDomainResource) =>
    status === HDOMAIN_RESOURCE_STATUSES.REQUESTED;

  const isDomainPendingSetup = ({ status }: IHDomainResource) =>
    status === HDOMAIN_RESOURCE_STATUSES.PENDING_SETUP;

  const isActive = ({ status }: IHDomainResource) =>
    status === HDOMAIN_RESOURCE_STATUSES.ACTIVE;

  const isFailed = ({ status }: IHDomainResource) =>
    status === HDOMAIN_RESOURCE_STATUSES.FAILED;

  const isSuspended = ({ status }: IHDomainResource) =>
    status === HDOMAIN_RESOURCE_STATUSES.SUSPENDED;

  const isExpired = ({ status }: IHDomainResource) =>
    status === HDOMAIN_RESOURCE_STATUSES.EXPIRED;

  const isSuspicious = (hDomainResource: IHDomainResource) =>
    inPendingVerification(hDomainResource) && isDomainResource(hDomainResource);

  const isDocumentUploadRequired = (hDomainResource: IHDomainResource) => {
    if (!isDomainResource(hDomainResource)) {
      return false;
    }

    return (
      checkDoesUploadUrlExist(hDomainResource) && isRequested(hDomainResource)
    );
  };

  const isTransferDocumentUploadRequired = (
    hDomainResource: IHDomainResource,
  ) => {
    if (!isDomainTransferResource(hDomainResource)) return false;

    return (
      checkDoesUploadUrlExist(hDomainResource) &&
      inPendingVerification(hDomainResource)
    );
  };

  const checkDoesUploadUrlExist = (hDomainResource: IHDomainResource) => {
    const message = hDomainResource.additionalDetails?.message || '';
    const regexp = new RegExp(urlRegexp);

    return regexp.test(message);
  };

  const getStatus = (hDomainResource: IHDomainResource) => {
    if (inPendingVerification(hDomainResource)) {
      return VISIBLE_DOMAIN_STATUS.REVIEWING;
    }

    if (isDocumentUploadRequired(hDomainResource)) {
      return VISIBLE_DOMAIN_STATUS.ACTION_REQUIRED;
    }

    if (isRequested(hDomainResource)) {
      const [, tld] = getSldTld(hDomainResource.title, {
        omitDot: true,
      });

      if (isRequested(hDomainResource) && compareTlds(tld, TLDS.DK)) {
        return VISIBLE_DOMAIN_STATUS.ACTION_REQUIRED;
      }

      return VISIBLE_DOMAIN_STATUS.REGISTERING;
    }

    if (isPendingEmailVerification(hDomainResource)) {
      return VISIBLE_DOMAIN_STATUS.PENDING_VERIFICATION;
    }

    return hDomainResource.status;
  };

  const getVisibleStatus = (hDomainResource: IHDomainResource) =>
    capitalise(snakeToReadable(getStatus(hDomainResource)));

  const getStatusLabelTheme = (hDomainResource: IHDomainResource) => {
    const status = getStatus(hDomainResource);

    return mapKeyValue(
      {
        [HDOMAIN_RESOURCE_STATUSES.ACTIVE]: H_LABEL_THEME.SUCCESS,
        [HDOMAIN_RESOURCE_STATUSES.FAILED]: H_LABEL_THEME.DANGER,
        [HDOMAIN_RESOURCE_STATUSES.EXPIRED]: H_LABEL_THEME.DANGER,
        [HDOMAIN_RESOURCE_STATUSES.SUSPENDED]: H_LABEL_THEME.DANGER,
        default: H_LABEL_THEME.WARNING,
      },
      status,
    );
  };

  const getTransferStatusLabelTheme = (
    transferInfoStatus: DomainTransferInfoStatus,
  ) =>
    mapKeyValue(
      {
        [DOMAIN_TRANSFER_INFO_STATUS.COMPLETED]: H_LABEL_THEME.SUCCESS,
        [DOMAIN_TRANSFER_INFO_STATUS.ERROR]: H_LABEL_THEME.DANGER,
        default: H_LABEL_THEME.WARNING,
      },
      transferInfoStatus,
    );

  const getStatusTooltipText = (hDomainResource: IHDomainResource) => {
    if (isInGracePeriod(hDomainResource)) {
      return {
        content: DOMAIN_TOOLTIP_TEXT.IN_GRACE_PERIOD,
        variables: {
          date: toLocalTime(
            hDomainResource.additionalDetails?.gracePeriodEndDate || '',
          ),
        },
      };
    }

    if (isInRedemptionPeriod(hDomainResource)) {
      return {
        content: DOMAIN_TOOLTIP_TEXT.IN_REDEMPTION_PERIOD,
        variables: {
          date: toLocalTime(
            hDomainResource.additionalDetails?.redemptionPeriodEndDate || '',
          ),
        },
      };
    }

    if (isSuspicious(hDomainResource)) {
      return {
        content: DOMAIN_TOOLTIP_TEXT.IS_SUSPICIOUS,
      };
    }
  };

  const getIsExpirationDanger = (hDomainResource: IHDomainResource) => {
    const expireDangerDate = Date.now() + EXPIRATION_THRESHOLD_60_DAYS_IN_MS;
    const expireDate = Date.parse(toLocalTime(hDomainResource.expiresAt || ''));

    return (
      expireDate < expireDangerDate && !isInRedemptionPeriod(hDomainResource)
    );
  };

  const checkCanEnterDomainManagement = (hDomainResource: IHDomainResource) => {
    const isUnManageableSuspended =
      isSuspended(hDomainResource) &&
      !hDomainResource.expiresAt &&
      !hDomainResource.additionalDetails?.registeredAt;

    return (
      !inPendingVerification(hDomainResource) &&
      !isInRedemptionPeriod(hDomainResource) &&
      !isDomainPendingSetup(hDomainResource) &&
      !isFailed(hDomainResource) &&
      !isUnManageableSuspended
    );
  };

  return {
    isDomainResource,
    inPendingVerification,
    isInGracePeriod,
    isInRedemptionPeriod,
    isRequested,
    isActive,
    isFailed,
    isSuspended,
    isExpired,
    isSuspicious,
    getStatus,
    getVisibleStatus,
    isDomainPendingSetup,
    getStatusTooltipText,
    isDocumentUploadRequired,
    getStatusLabelTheme,
    getIsExpirationDanger,
    isDomainTransferResource,
    isTransferDocumentUploadRequired,
    getTransferStatusLabelTheme,
    isPendingEmailVerification,
    checkCanEnterDomainManagement,
  };
};
