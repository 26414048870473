import type { RouteRecordRaw } from 'vue-router';

import emailOnboardingDomainSetupGuard from '@/guards/emails/emailOnboardingDomainSetupGuard';
import emailOnboardingGuard from '@/guards/emails/emailOnboardingGuard';
import { Route } from '@/types';
import { RouteOwner } from '@/types/enums/routeOwnerEnum';

const baseMeta = {
  header: false,
  showSubheader: false,
  fullscreen: true,
  isOnboarding: true,
  routeOwner: RouteOwner.EMAILS,
};

const EmailOnboardingRoutes = [
  {
    path: '/email-onboarding/:orderId/:resourceId?',
    name: Route.Email.EMAIL_ONBOARDING_START,
    meta: {
      title: "Let's Go",
      ...baseMeta,
    },
    redirect: { name: Route.Email.EMAIL_ONBOARDING_HELLO },
    component: () => import('@/views/Emails/EmailsOnboardingWrapper.vue'),
    children: [
      {
        path: 'start',
        name: Route.Email.EMAIL_ONBOARDING_HELLO,
        meta: {
          headerBackButton: false,
          step: 10,
          ...baseMeta,
        },
        beforeEnter: emailOnboardingGuard,
        component: () =>
          import('@/views/Emails/Onboarding/EmailsOnboardingHello.vue'),
      },
      {
        path: 'choose-domain',
        name: Route.Email.EMAIL_ONBOARDING_CHOOSE_DOMAIN,
        meta: {
          headerBackButton: true,
          backRoute: { name: Route.Email.EMAIL_ONBOARDING_HELLO },
          step: 11,
          ...baseMeta,
        },
        beforeEnter: emailOnboardingGuard,
        component: () =>
          import('@/views/Emails/Onboarding/EmailsOnboardingChooseDomain.vue'),
      },
      {
        path: 'buy-domain',
        name: Route.Email.EMAIL_ONBOARDING_BUY_DOMAIN,
        meta: {
          headerBackButton: true,
          backRoute: { name: Route.Email.EMAIL_ONBOARDING_CHOOSE_DOMAIN },
          step: 12,
          ...baseMeta,
        },
        beforeEnter: emailOnboardingGuard,
        component: () =>
          import('@/views/Onboarding/Domain/OnboardingBuyNewDomainV2.vue'),
      },
      {
        path: 'existing-domain',
        name: Route.Email.EMAIL_ONBOARDING_EXISTING_DOMAIN,
        meta: {
          headerBackButton: true,
          backRoute: { name: Route.Email.EMAIL_ONBOARDING_CHOOSE_DOMAIN },
          step: 13,
          ...baseMeta,
        },
        beforeEnter: emailOnboardingGuard,
        component: () =>
          import('@/views/Onboarding/Domain/OnboardingExistingDomain.vue'),
      },
      {
        path: 'domain-setup',
        name: Route.Email.EMAIL_ONBOARDING_SETUP_DOMAIN,
        meta: {
          title: 'Finish Domain Registration',
          headerBackButton: false,
          step: 14,
          ...baseMeta,
        },
        beforeEnter: emailOnboardingDomainSetupGuard,
        component: () => import('@/views/Onboarding/OnboardingDomainSetup.vue'),
      },
      {
        path: 'create-account',
        name: Route.Email.EMAIL_ONBOARDING_CREATE_ACCOUNT,
        meta: {
          headerBackButton: true,
          backRoute: { name: Route.Email.EMAIL_ONBOARDING_CHOOSE_DOMAIN },
          step: 20,
          ...baseMeta,
        },
        beforeEnter: emailOnboardingGuard,
        component: () =>
          import('@/views/Emails/Onboarding/EmailsOnboardingCreateAccount.vue'),
      },
      {
        path: 'connect-domain',
        name: Route.Email.EMAIL_ONBOARDING_CONNECT_DOMAIN,
        meta: {
          headerBackButton: true,
          backRoute: { name: Route.Email.EMAIL_ONBOARDING_CREATE_ACCOUNT },
          step: 30,
          ...baseMeta,
        },
        beforeEnter: emailOnboardingGuard,
        component: () =>
          import('@/views/Emails/Onboarding/EmailsOnboardingConnectDomain.vue'),
      },
      {
        path: 'dns',
        name: Route.Email.EMAIL_ONBOARDING_DNS,
        meta: {
          headerBackButton: true,
          backRoute: { name: Route.Email.EMAIL_ONBOARDING_CONNECT_DOMAIN },
          step: 31,
          ...baseMeta,
        },
        beforeEnter: emailOnboardingGuard,
        component: () =>
          import('@/views/Emails/Onboarding/EmailsOnboardingDNS.vue'),
      },
      {
        path: 'confirm',
        name: Route.Email.EMAIL_ONBOARDING_CONFIRM,
        meta: {
          headerBackButton: true,
          backRoute: {
            name: Route.Email.EMAIL_ONBOARDING_DNS,
          },
          step: 32,
          ...baseMeta,
        },
        beforeEnter: emailOnboardingGuard,
        component: () =>
          import('@/views/Emails/Onboarding/EmailsOnboardingConfirm.vue'),
      },
      {
        path: 'success',
        name: Route.Email.EMAIL_ONBOARDING_SUCCESS,
        meta: {
          headerBackButton: true,
          backRoute: { name: Route.Email.EMAILS },
          step: 40,
          hideStepper: true,
          rateServicesTitle: 'How would you rate the email plan setup?',
          ...baseMeta,
        },
        beforeEnter: emailOnboardingGuard,
        component: () =>
          import('@/views/Emails/Onboarding/EmailsOnboardingSuccess.vue'),
      },
    ],
  },
] as RouteRecordRaw[];

export default EmailOnboardingRoutes;
