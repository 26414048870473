import { proTitanDomainSelectGuard } from '@/guards/emails/proTitanDomainSelectGuard';
import { Route } from '@/types';
import { RouteOwner } from '@/types/enums/routeOwnerEnum';

const baseMetaData = {
  showSubheader: false,
  breadcrumbs: false,
  fullscreen: true,
  isOnboarding: true,
  routeOwner: RouteOwner.EMAILS,
};
export default [
  {
    path: '/pro/titan-email-onboarding/:orderId/:resourceId',
    name: Route.EmailPro.TITAN_ONBOARDING_START,
    meta: {
      title: "Let's Go",
      showSubheader: false,
      header: false,
    },
    redirect: () => ({
      name: Route.EmailPro.TITAN_ONBOARDING_DOMAIN_SELECT,
    }),
    component: () =>
      import('@/views/Emails/TitanEmails/TitanOnboardingWrapper.vue'),
    children: [
      {
        path: 'domain-select',
        name: Route.EmailPro.TITAN_ONBOARDING_DOMAIN_SELECT,
        meta: {
          headerBackButton: true,
          isAccessRedirect: true,
          ...baseMetaData,
        },
        beforeEnter: proTitanDomainSelectGuard,
        component: () =>
          import('@/views/Emails/TitanEmails/TitanOnboardingDomainSelect.vue'),
      },
      {
        path: 'domain-buy',
        name: Route.EmailPro.TITAN_ONBOARDING_DOMAIN_BUY,
        meta: {
          title: 'Buy a Domain',
          headerBackButton: true,
          backRoute: Route.EmailPro.TITAN_ONBOARDING_DOMAIN_SELECT,
          step: 2,
          ...baseMetaData,
        },
        beforeEnter: proTitanDomainSelectGuard,
        component: () =>
          import('@/views/Emails/TitanEmails/TitanOnboardingDomainBuy.vue'),
      },
      {
        path: 'existing-domain',
        name: Route.EmailPro.TITAN_ONBOARDING_DOMAIN_EXISTING,
        meta: {
          title: 'Use an Existing Domain',
          headerBackButton: true,
          isSubtitleVisible: false,
          isExistingDomain: true,
          backRoute: Route.EmailPro.TITAN_ONBOARDING_DOMAIN_SELECT,
          step: 2,
          ...baseMetaData,
        },
        beforeEnter: proTitanDomainSelectGuard,
        component: () =>
          import('@/views/Emails/TitanEmails/TitanOnboardingDomainBuy.vue'),
      },
      {
        path: 'finish/:domain',
        name: Route.EmailPro.TITAN_ONBOARDING_FINISH,
        meta: {
          headerBackButton: false,
          ...baseMetaData,
        },
        beforeEnter: proTitanDomainSelectGuard,
        component: () =>
          import('@/views/Emails/TitanEmails/TitanOnboardingFinish.vue'),
      },
    ],
  },
];
