/**
 * Please do not import anything into this file or else generateTranslations.js will break
 */
export const LANGUAGE_CODE = {
  ar_AR: 'ar_AR',
  zh_CN: 'zh_CN',
  en_GB: 'en_GB',
  fr_FR: 'fr_FR',
  id_ID: 'id_ID',
  lt_LT: 'lt_LT',
  pt_PT: 'pt_PT',
  pt_BR: 'pt_BR',
  ru_RU: 'ru_RU',
  es_ES: 'es_ES',
  es_AR: 'es_AR',
  es_CO: 'es_CO',
  es_MX: 'es_MX',
  tr_TR: 'tr_TR',
  uk_UA: 'uk_UA',
  hi_IN: 'hi_IN',
  de_DE: 'de_DE',
  it_IT: 'it_IT',
  nl_NL: 'nl_NL',
  he_IL: 'he_IL',
  pl_PL: 'pl_PL',
  vi_VN: 'vi_VN',
  th_TH: 'th_TH',
  ro_RO: 'ro_RO',
  ja_JP: 'ja_JP',
  ko_KR: 'ko_KR',
} as const;
