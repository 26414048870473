import type { RouteRecordRaw } from 'vue-router';

import { accountInformationGuard } from '@/guards/accountInformationGuard';
import { profileGuard } from '@/guards/profileGuard';
import { HOTSPOT, Route, HIcon } from '@/types';
import { RouteOwner } from '@/types/enums/routeOwnerEnum';

const sideMenu = {
  sideMenuExists: true,
  sideMenuFixed: false,
  sideMenuComponent: {
    component: 'ProfileSidebar',
    menu: 'ProfileSidebar',
  },
  feedback: true,
  wrapperName: 'account',
  routeOwner: RouteOwner.HPANEL,
};

export default [
  {
    path: '/profile',
    name: 'profile',
    redirect: { name: Route.Profile.PERSONAL_INFORMATION },
    meta: {
      title: 'Profile',
      breadcrumbs: ['profile'],
      ...sideMenu,
    },
    beforeEnter: profileGuard,
    component: () => import('@/views/Profile/Profile.vue'),
    children: [
      {
        path: 'personal-information',
        beforeEnter: accountInformationGuard,
        name: Route.Profile.PERSONAL_INFORMATION,
        meta: {
          title: 'Account Information',
          menu: ['ProfileSidebar'],
          icon: HIcon.ICON_PERSON,
          iconHeader: HIcon.ICON_USER,
          breadcrumbs: ['profile'],
          ...sideMenu,
        },
        component: () => import('@/views/Profile/AccountInformation.vue'),
      },
      {
        path: 'security',
        name: Route.Profile.SECURITY,
        redirect: { name: Route.Profile.TWO_FACTOR_AUTH },
        meta: {
          title: 'Security',
          menu: ['ProfileSidebar'],
          icon: HIcon.ICON_LOCK_LOCKED,
          hotspotId: HOTSPOT.TWO_FACTOR_HOTSPOT_LEADING,
          iconHeader: HIcon.ICON_SHIELD_CHECKMARK,
          breadcrumbs: ['profile'],
          ...sideMenu,
        },
        component: () => import('@/views/Profile/Security.vue'),
        children: [
          {
            path: 'two-factor-auth',
            name: Route.Profile.TWO_FACTOR_AUTH,
            meta: {
              title: 'Two-Factor Authentication Setup',
              breadcrumbs: ['profile'],
              ...sideMenu,
            },
            component: () => import('@/views/Profile/Auth/AuthSelect.vue'),
          },
          {
            path: 'email-auth',
            name: Route.Profile.EMAIL_AUTH,
            meta: {
              ...sideMenu,
            },
            component: () => import('@/views/Profile/Auth/EmailAuth.vue'),
          },
          {
            path: 'auth-success',
            name: Route.Profile.AUTH_SUCCESS,
            meta: {
              ...sideMenu,
            },
            component: () => import('@/views/Profile/Auth/AuthSuccess.vue'),
          },
          {
            path: 'application-auth',
            name: Route.Profile.APPLICATION_AUTH,
            meta: {
              ...sideMenu,
            },
            component: () => import('@/views/Profile/Auth/ApplicationAuth.vue'),
          },
        ],
      },
      {
        path: 'account-access',
        name: Route.Profile.ACCOUNT_ACCESS,
        redirect: { name: Route.Profile.ACCOUNT_SHARING },
        meta: {
          title: 'Account Sharing',
          menu: ['ProfileSidebar'],
          icon: HIcon.ICON_PEOPLE,
          iconHeader: HIcon.ICON_USER_DOUBLE,
          breadcrumbs: ['profile'],
          ...sideMenu,
        },
        component: () => import('@/views/Profile/AccountSharing.vue'),
        children: [
          {
            path: 'account-sharing',
            name: Route.Profile.ACCOUNT_SHARING,
            meta: {
              title: 'Account Sharing',
              breadcrumbs: ['profile'],
              ...sideMenu,
            },
            component: () => import('@/views/Profile/Access/AccessSelect.vue'),
          },
          {
            path: 'grant-access',
            name: Route.Profile.GRANT_ACCESS,
            meta: {
              title: 'Grant Access',
              breadcrumbs: false,
              ...sideMenu,
            },
            component: () =>
              import('@/views/Profile/Access/GrantAccessForm.vue'),
          },
          {
            path: 'request-access',
            name: Route.Profile.REQUEST_ACCESS,
            meta: {
              title: 'Request access',
              breadcrumbs: false,
              ...sideMenu,
            },
            component: () =>
              import('@/views/Profile/Access/RequestAccessForm.vue'),
          },
        ],
      },
      {
        path: 'account-logs',
        name: Route.Profile.ACCOUNT_LOGS,
        meta: {
          title: 'Account Activity',
          menu: ['ProfileSidebar'],
          icon: HIcon.ICON_LIST,
          iconHeader: HIcon.ICON_USER_INFO_RECTANGLE,
          breadcrumbs: ['profile'],
          ...sideMenu,
        },
        component: () => import('@/views/Profile/AccountActivity.vue'),
      },
    ],
  },
] as RouteRecordRaw[];
