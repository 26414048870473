import type { RouteLocationRaw } from 'vue-router';
import { useRouter, useRoute } from 'vue-router';
import { useStore } from 'vuex';

import ChangeHostingPlanModal from '@/components/Modals/HModal/Hosting/ChangeHostingPlanModal.vue';
import {
  useSubscriptions,
  useHostingOrders,
  useGlobals,
  useCpanel,
  useModal,
} from '@/composables';
import { useProfileStore } from '@/stores/profile/profileStore';
import { useResourcesStore } from '@/stores/resourcesStore';
import { useServerMaintenanceStore } from '@/stores/serverMaintenanceStore';
import type { Order, WebsitesActiveOrder, HostingCardOrder } from '@/types';
import {
  Route,
  AmplitudeLocation,
  AmplitudeEvent,
  Hosting,
  Service,
  Email,
  AccountActions,
} from '@/types';
import { isHostingPlanUpgradable } from '@/utils/helpers';

export const useHostingCardActions = () => {
  const DAYS_TILL_EXPIRATION = 21 * 24 * 60 * 60 * 1000;

  const store = useStore();
  const router = useRouter();
  const route = useRoute();
  const profileStore = useProfileStore();
  const {
    canOrderBeReactivated,
    canServiceBeRenewed,
    hasSubscriptionUnpaidInvoices,
    openReactivateSubscriptionModalByOrderId,
    openHBillingPayInvoiceModal,
  } = useSubscriptions();
  const {
    filteredAccountsByOrderId,
    getActiveOrderByOrderId,
    areAllWebsitesBuilder,
  } = useHostingOrders();
  const { permissions, amplitudeV2 } = useGlobals();
  const { isCpanelEmailHostingPackage } = useCpanel();
  const { openModal } = useModal();

  const serverMaintenanceStore = useServerMaintenanceStore();
  const resourcesStore = useResourcesStore();

  /* 'Add website' action button */

  const getIsAddWebsiteVisible = (order: HostingCardOrder) =>
    showAddWebsite(order) &&
    !hasPendingSetupAccount(order) &&
    !profileStore.isAccessManager &&
    !getIsPlanMaintenance({
      maintenanceStatus: Hosting.MaintenanceStatus.ACTIVE,
      order,
    });

  const hasPendingSetupAccount = (order: Order) =>
    filteredAccountsByOrderId(order.id).find(
      (account: any) => account.status === Hosting.OrderStatus.PENDING_SETUP,
    );

  const showAddWebsite = (order: Order) =>
    order.status === Hosting.OrderStatus.ACTIVE &&
    order.service === Service.Type.HOSTING &&
    !!order.expiresAt;

  /* END OF 'Add website' action button */

  /* 'Renew' action button */

  const getIsRenewButtonVisible = (order: Order) =>
    permissions.userHasPurchaseAccess() &&
    order.status !== Hosting.OrderStatus.PENDING_DATA_ENTRY &&
    (canOrderBeReactivated(order.id) || canServiceBeRenewed(order.id));

  const openRenewModal = (
    order: HostingCardOrder,
    amplitude?: {
      name: AmplitudeEvent.Hosting;
      location:
        | AmplitudeLocation.Status
        | AmplitudeLocation.Base
        | AmplitudeLocation.Hosting;
    },
  ) => {
    if (amplitude?.name) {
      amplitudeV2(amplitude.name, {
        location: amplitude.location,
      });
    }

    if (hasSubscriptionUnpaidInvoices(order)) {
      return openHBillingPayInvoiceModal(order.id, () =>
        store.dispatch('billingItems/getBillingItems'),
      );
    }

    openReactivateSubscriptionModalByOrderId(order.id, amplitude?.location);
  };

  /* END OF 'Renew' action button */

  /* 'Upgrade' action button */
  const getCurrentOrder = () =>
    route.params?.id
      ? store.getters.getOrderById(route.params?.id)
      : store.getters.getCurrentOrder;

  const getIsUpgradeVisible = (selectedOrder?: HostingCardOrder) => {
    const order: HostingCardOrder = selectedOrder || getCurrentOrder();

    if (!order) return false;

    const hasUpgradeLinks = !!order.links?.filter(
      (link) => link.name === Hosting.HostingActions.UPGRADE,
    ).length;

    const upgradeConditions =
      hasUpgradeLinks &&
      !hasPendingSetupAccount(order) &&
      getOrderDomainName(order.id) &&
      !getIsPlanMaintenance({
        maintenanceStatus: Hosting.MaintenanceStatus.ACTIVE,
        order,
      });

    const upgradeablePlans =
      isHostingPlanUpgradable(order.productName as Hosting.Plan) && // old billing
      order.title !== Hosting.NonUpgradeablePlanTitle.CLOUD_ENTERPRISE; // cb user (their plan names are very custom)

    return (
      permissions.userHasPurchaseAccess() &&
      upgradeConditions &&
      upgradeablePlans
    );
  };

  const handleOrderLinkAction = (
    button: { name?: string; link?: string },
    order: Order,
    amplitudeLocation?:
      | AmplitudeLocation.Status
      | AmplitudeLocation.Base
      | AmplitudeLocation.Hosting,
  ) => {
    const route = router.resolve(
      getOrderBtnLink(button, order, amplitudeLocation) as RouteLocationRaw,
    );

    route.href = route.href.replace('/https', 'https');
    window.open(route.href, '_self');
  };
  const getOrderBtnLink = (
    button: { name?: string; link?: string },
    order: Order,
    amplitudeLocation?: string,
  ) => {
    if (order.service === Service.Type.HOSTING && button.name === 'setup') {
      const link = {
        name: Route.Onboarding.START,
        params: { order_id: order.id },
      };
      if (link) return link;
    }

    if (
      order.service === Service.Type.HOSTING &&
      button.name === Hosting.HostingActions.UPGRADE &&
      order.productName !== Email.Plan.HOSTINGER_BUSINESS_FREE
    ) {
      const link = {
        name: Route.Order.ORDER_UPGRADE,
        params: { domain: getOrderDomainName(order.id) },
        query: {
          location: amplitudeLocation,
        },
      };

      if (link) return link;
    }

    return button.link;
  };

  const getOrderDomainName = (orderId: string) => {
    const account = store.getters.getFirstAccountByOrder(orderId);

    return account?.domain;
  };

  /* END OF 'Upgrade' action button */

  /* 'View resource usage' action button */

  const hasPerformanceFaults = (order: WebsitesActiveOrder) => {
    if (!order) return;

    const serviceId = order.serviceId;

    return (
      order.serviceName === Service.Type.HOSTING &&
      store.getters.getOrderLveFaultsExist(serviceId)
    );
  };
  const getIsViewResourcesVisible = (order: Order) => {
    const activeOrder = getActiveOrderByOrderId(order.id);

    return hasPerformanceFaults(activeOrder as unknown as WebsitesActiveOrder);
  };

  const redirectViewOrderUsage = (order: Order) => {
    router.push({
      name: Route.Hosting.RESOURCES_USAGE_V2,
      params: { id: order.id },
    });
  };

  /* END OF 'View resource usage' action button */

  /* 'Migrate website' action button */

  const redirectMigrateWebsite = () => {
    router.push({ name: Route.Websites.MIGRATIONS_REQUESTS });
  };

  /* END OF 'Migrate website' action button */
  /* 'Manage' button */

  const isCpanelHosting = (service: string) =>
    service === Service.Type.CPANEL_HOSTING ||
    service === Service.Type.CPANEL_RESELLER_HOSTING;

  const redirectToManage = async (order: HostingCardOrder) => {
    amplitudeV2(AmplitudeEvent.Hosting.MANAGE_ENTER, {
      location: AmplitudeLocation.Base.LIST,
    });
    const activeOrder = getActiveOrderByOrderId(order.id);

    if (isCpanelHosting(activeOrder?.serviceName || order.service)) {
      let routeName: string = Route.CPanel.INDEX;

      if (
        isCpanelEmailHostingPackage(
          (activeOrder?.cpanelPlan || order.cpanelPlan) as string,
        )
      ) {
        routeName = Route.EmailCpanel.DASHBOARD;
      }

      return router.push({
        name: routeName,
        params: {
          domain:
            activeOrder?.hostname ||
            activeOrder?.serviceId ||
            order?.cpanelHostname,
        },
      });
    }

    if (isCpanelHosting(order.service) && order.manage) {
      getOrderSetupLink(order);

      return;
    }

    router.push({
      name: Route.Hosting.RESOURCES_USAGE_V2,
      params: { id: order.id },
    });
  };

  const getIsManageButtonDisabled = (order: HostingCardOrder) => {
    const eligibleStatuses = (
      [Hosting.OrderStatus.IN_PROGRESS, Hosting.OrderStatus.FAILED] as string[]
    ).includes(order.status);

    const additionalConditions = isOrderBeingInstalled(order);

    return eligibleStatuses || additionalConditions;
  };

  const getIsManageButtonVisible = (order: HostingCardOrder) => {
    const eligibleStatuses = (
      [
        Hosting.OrderStatus.ACTIVE,
        Hosting.OrderStatus.EXPIRED,
        Hosting.OrderStatus.IN_PROGRESS,
        Hosting.OrderStatus.INSTALLING,
        Hosting.OrderStatus.SUSPENDED,
      ] as string[]
    ).includes(order.status);

    const additionalConditions =
      order.status === Hosting.OrderStatus.SUSPENDED &&
      new Date(order.expiresAt).getDate() + DAYS_TILL_EXPIRATION > Date.now() &&
      (order.reason === Hosting.CBSuspendReason.NOT_PAID ||
        order.reason === Hosting.SuspendReason.NON_PAYMENT);

    const hasNonBuilderWebsites =
      !!order.websites.length && !areAllWebsitesBuilder(order);

    return (eligibleStatuses || additionalConditions) && hasNonBuilderWebsites;
  };

  const isOrderBeingInstalled = (order: HostingCardOrder) => {
    const eligibleStatus = (
      [
        Hosting.OrderStatus.PENDING_SETUP,
        Hosting.OrderStatus.EXPIRED,
        Hosting.OrderStatus.ACTIVE,
      ] as string[]
    ).includes(order.status);

    return (
      order.websites?.length &&
      eligibleStatus &&
      order.websites.filter(
        ({ status }) => status === Hosting.OrderStatus.PENDING_SETUP,
      ).length === order.websites.length
    );
  };

  /* END OF 'Manage' button */

  /* 'Setup' button */
  const setupHostingPlan = (order: Order) => {
    const isCpanel = order.service === Service.Type.CPANEL_HOSTING;

    amplitudeV2(AmplitudeEvent.Hosting.PENDING_SETUP_ENTER, {
      location: AmplitudeLocation.Base.LIST,
    });

    if (isCpanel || order.service === Service.Type.HOSTING) {
      redirectHostingSetup(order);

      return;
    }

    getOrderSetupLink(order);
  };

  const redirectHostingSetup = (order: Order) => {
    if (isCpanelHosting(order.service)) {
      return router.push({
        name: Route.Onboarding.START,
        params: {
          order_id: order.id,
        },
        query: {
          cpanel: 'true',
          planId: order.serviceId,
        },
      });
    }

    router.push({
      name: Route.Onboarding.START,
      params: {
        order_id: order.id,
      },
    });
  };

  const getOrderSetupLink = (order: Order) => {
    if (order.ctaLink) return window.open(order.ctaLink, '_self');
  };

  /* END OF 'Setup' button */
  /* Plan maintenance status */
  const getIsPlanMaintenance = ({
    maintenanceStatus,
    order,
  }: {
    maintenanceStatus:
      | Hosting.MaintenanceStatus.ACTIVE
      | Hosting.MaintenanceStatus.UPCOMING;
    order: HostingCardOrder;
  }) => {
    const maintenanceStatusArray =
      maintenanceStatus === Hosting.MaintenanceStatus.ACTIVE
        ? serverMaintenanceStore.getOrderActiveMaintenances(order.serviceId)
        : serverMaintenanceStore.getOrderScheduledMaintenances(order.serviceId);

    return !!maintenanceStatusArray?.length;
  };

  const getPlanMaintenance = ({
    maintenanceStatus,
    order,
  }: {
    maintenanceStatus:
      | Hosting.MaintenanceStatus.ACTIVE
      | Hosting.MaintenanceStatus.UPCOMING;
    order: HostingCardOrder;
  }) =>
    maintenanceStatus === Hosting.MaintenanceStatus.ACTIVE
      ? serverMaintenanceStore.getOrderActiveMaintenances(order.serviceId)
      : serverMaintenanceStore.getOrderScheduledMaintenances(order.serviceId);

  /* End of plan maintenance status*/

  /* 'Customize plan name' button */

  const getIsPlanNameCustomizable = (order: HostingCardOrder) =>
    !!order.websites.length &&
    !areAllWebsitesBuilder(order) &&
    !isCpanelHosting(order.service);

  const changePlanName = (
    order: HostingCardOrder,
    amplitude?: {
      name: AmplitudeEvent.Hosting;
      location: AmplitudeLocation.Base | AmplitudeLocation.Hosting.PLAN_DETAILS;
    },
  ) => {
    const hostingPlanTitle = order.websites[0]?.customPlanName || order.title;

    if (getIsCustomizePlanNameDisabled(order)) return;

    if (amplitude?.name) {
      amplitudeV2(amplitude.name, {
        location: amplitude.location,
        subscriptionId: order.id,
      });
    }

    openModal({
      component: { ChangeHostingPlanModal },
      data: {
        planName: hostingPlanTitle,
        orderReferenceId: order.referenceId,
        domain: order.websites[0]?.domain,
        onSuccess: () => {
          store.dispatch(AccountActions.HOSTING_ORDER_ACCOUNTS_INDEX, order);
          resourcesStore.fetchResources();

          if (amplitude?.name) {
            amplitudeV2(AmplitudeEvent.Hosting.RENAME_RENAMED, {
              location: amplitude?.location,
              subscriptionId: order.id,
            });
          }
        },
      },
    });
  };
  const getIsCustomizePlanNameDisabled = (order: HostingCardOrder) =>
    order.websites[0]?.inTransfer ||
    order.status === Hosting.OrderStatus.SUSPENDED;

  /* END OF 'Customize plan name' button */
  return {
    getIsViewResourcesVisible,
    getIsRenewButtonVisible,
    getIsPlanNameCustomizable,
    openRenewModal,
    getIsCustomizePlanNameDisabled,
    getIsManageButtonVisible,
    getIsManageButtonDisabled,
    getIsAddWebsiteVisible,
    getIsUpgradeVisible,
    redirectHostingSetup,
    handleOrderLinkAction,
    redirectToManage,
    redirectViewOrderUsage,
    isOrderBeingInstalled,
    changePlanName,
    redirectMigrateWebsite,
    getOrderSetupLink,
    setupHostingPlan,
    getIsPlanMaintenance,
    getPlanMaintenance,
    isCpanelHosting,
  };
};
