//JSON-slugs-migrated
import type { RouteRecordRaw } from 'vue-router';

import { MIGRATIONS_ONBOARDING_STEPS, Route } from '@/types';
import { RouteOwner } from '@/types/enums/routeOwnerEnum';

const meta = {
  isPageWrapperV2: true,
  routeOwner: RouteOwner.HOSTING,
};

export default [
  {
    path: '/migrations-onboarding/:domain?/:order_id?',
    name: Route.MigrationsOnboarding.BASE_PATH,
    meta,
    component: () =>
      import('@/views/Hosting/MigrationsOnboarding/MigrationsOnboarding.vue'),
    children: [
      {
        path: 'website-to-migrate',
        name: MIGRATIONS_ONBOARDING_STEPS.WEBSITE_TO_MIGRATE_NAME,
        meta: {
          ...meta,
          title: 'v2.what.is.the.name.of.the.website.you.want.to.migrate',
        },
        component: () =>
          import(
            '@/views/Hosting/MigrationsOnboarding/MigrationsEnterWebsite.vue'
          ),
      },
      {
        path: 'builder',
        name: MIGRATIONS_ONBOARDING_STEPS.BUILDER,
        meta: {
          ...meta,
          title: 'v2.your.website.cant.be.migrated.to.hostinger',
        },
        component: () =>
          import('@/views/Hosting/MigrationsOnboarding/MigrationsBuilder.vue'),
      },
      {
        path: 'how-to-migrate-wordpress-website',
        name: MIGRATIONS_ONBOARDING_STEPS.HOW_TO_MIGRATE_WORDPRESS_WEBSITE,
        meta: {
          ...meta,
          title: 'v2.how.do.you.want.to.migrate.your.website',
        },
        component: () =>
          import(
            '@/views/Hosting/MigrationsOnboarding/MigrationsMigrateWordPressWebsite.vue'
          ),
      },
      {
        path: 'select-website-platform',
        name: MIGRATIONS_ONBOARDING_STEPS.SELECT_WEBSITE_PLATFORM,
        meta: {
          ...meta,
          title: 'v2.what.platform.is.your.website.using.right.now',
        },
        component: () =>
          import(
            '@/views/Hosting/MigrationsOnboarding/MigrationsSelectPlatform.vue'
          ),
      },
      {
        path: 'migrations-requirements',
        name: MIGRATIONS_ONBOARDING_STEPS.MIGRATIONS_REQUIREMENTS,
        meta: {
          ...meta,
          title: 'v2.migration.requirements',
        },
        component: () =>
          import(
            '@/views/Hosting/MigrationsOnboarding/MigrationsRequirements.vue'
          ),
      },
      {
        path: 'upload-backups',
        name: MIGRATIONS_ONBOARDING_STEPS.UPLOAD_BACKUPS,
        meta: {
          ...meta,
          title: 'v2.upload.your.backups.using.drag.and.drop.or.select.files',
        },
        component: () =>
          import(
            '@/views/Hosting/MigrationsOnboarding/MigrationsUploadBackups.vue'
          ),
      },
      {
        path: 'enter-cpanel-details',
        name: MIGRATIONS_ONBOARDING_STEPS.ENTER_CPANEL_DETAILS,
        meta: {
          ...meta,
          title: 'v2.enter.your.cpanel.admin.login.details',
        },
        component: () =>
          import(
            '@/views/Hosting/MigrationsOnboarding/MigrationsEnterCpanelDetails.vue'
          ),
      },
      {
        path: 'enter-other-panel-details',
        name: MIGRATIONS_ONBOARDING_STEPS.ENTER_OTHER_CONTROL_PANEL_MIGRATION_DETAILS,
        meta: {
          ...meta,
          title: 'v2.enter.your.panel.login.information',
        },
        component: () =>
          import(
            '@/views/Hosting/MigrationsOnboarding/MigrationsEnterOtherPanelDetails.vue'
          ),
      },
      {
        path: 'enter-wordpress-details',
        name: MIGRATIONS_ONBOARDING_STEPS.ENTER_WORDPRESS_DETAILS,
        meta: {
          ...meta,
          title: 'v2.enter.your.wordpress.admin.login.details',
        },
        component: () =>
          import(
            '@/views/Hosting/MigrationsOnboarding/MigrationsEnterWordPressDetails.vue'
          ),
      },
      {
        path: 'where-to-migrate-website',
        name: MIGRATIONS_ONBOARDING_STEPS.WHERE_TO_MIGRATE_WEBSITE,
        meta: {
          ...meta,
          title: 'v2.where.do.you.want.to.migrate.your.website',
        },
        component: () =>
          import(
            '@/views/Hosting/MigrationsOnboarding/MigrationsWhereToMigrate.vue'
          ),
      },
      {
        path: 'summary-v2',
        name: MIGRATIONS_ONBOARDING_STEPS.SUMMARY_V2,
        meta: {
          ...meta,
          title: 'v2.here.is.your.website.migration.summary',
        },
        component: () =>
          import(
            '@/views/Hosting/MigrationsOnboarding/MigrationsSummaryV2.vue'
          ),
      },
      {
        path: 'setup',
        name: MIGRATIONS_ONBOARDING_STEPS.SETUP,
        meta: {
          ...meta,
          title: 'v2.setup',
        },
        component: () =>
          import('@/views/Hosting/MigrationsOnboarding/MigrationsSetup.vue'),
      },
    ],
  },
] as RouteRecordRaw[];
