import type { NavigationGuard } from 'vue-router';

import { useFeatureFlag } from '@/composables/useFeatureFlag';
import { useProfileStore } from '@/stores';
import { Route, FeatureFlag, ACCESS_LEVEL } from '@/types';

const paidInvoicesGuard: NavigationGuard = async (to, from, next) => {
  const { isFeatureEnabled } = useFeatureFlag(
    FeatureFlag.ID.REFUNDS_PAID_INVOICES,
  );
  const profileStore = useProfileStore();

  if (!profileStore.access) {
    await profileStore.fetchProfile();
  }

  if (
    profileStore.isAccessManager &&
    profileStore.access?.level !== ACCESS_LEVEL.PURCHASE
  ) {
    return next({ name: Route.Base.NOT_FOUND });
  }

  const name = isFeatureEnabled.value
    ? Route.Base.PAID_INVOICES_V2
    : Route.Base.PAID_INVOICES;

  if (to.name === name) {
    next();
  }

  next({ name });
};

export default paidInvoicesGuard;
