<script lang="ts" setup>
import { onClickOutside } from '@vueuse/core';
import { defineProps, ref, computed, nextTick } from 'vue';

import HButtonV1 from '@/components/HButtons/HButtonV1.vue';
import { useScreen } from '@/composables';
import { HIcon } from '@/types';

type Variant = 'simple' | 'expandingButton';

interface Emits {
  (eventName: 'expand-menu'): void;
}
type Props = {
  horizontal?: boolean;
  alwaysShowIcon?: boolean;
  disabled?: boolean;
  tooltipText?: string;
  iconWidth?: number;
  customButton?: string;
  variant?: Variant;
  iconBreakpoint?: 'sm' | 'md' | 'lg';
  dataQa?: string;
};

const emits = defineEmits<Emits>();
const props = withDefaults(defineProps<Props>(), {
  horizontal: false,
  alwaysShowIcon: false,
  disabled: false,
  tooltipText: '',
  iconWidth: 20,
  customButton: '',
  variant: 'simple',
  iconBreakpoint: 'md',
});

const { isSmall } = useScreen();

const expand = ref(false);
const left = ref(0);
const top = ref(0);
const list = ref();

const menuComponent = ref<HTMLElement | null>(null);

onClickOutside(menuComponent, () => {
  expand.value = false;
});

const cssVars = computed(() => ({
  '--action-dots-hover-width': `${props.iconWidth + 8}px`,
}));
const isSimpleVariant = computed(() => props.variant === 'simple');
const iconAppend = computed(() => {
  if (isSmall.value) return undefined;

  return expand.value ? 'ic-keyboard-arrow-up-24' : 'ic-keyboard-arrow-down-24';
});

const openList = () => {
  if (props.disabled) return;
  expand.value = !expand.value;
  if (expand.value) emits('expand-menu');
  getMenuPosition();
};
const getMenuPosition = async () => {
  top.value = 30;
  left.value = 0;

  await nextTick();
  const listItemInfo = list.value.getBoundingClientRect();
  const windowWidth = window.innerWidth;
  const windowHeight = window.innerHeight;

  if (windowWidth < listItemInfo.left + listItemInfo.width) {
    left.value = -listItemInfo.width + 24;
  }

  if (windowHeight < listItemInfo.top + listItemInfo.height) {
    top.value = -listItemInfo.height - 12;
  }
};

const setInitialTopValue = () => {
  top.value = isSimpleVariant.value ? 30 : 40;
};

setInitialTopValue();
</script>

<template>
  <div
    ref="menuComponent"
    class="h-action-menu"
    :class="{
      'h-action-menu__simple': isSimpleVariant,
      'h-action-menu--active': expand,
    }"
    :style="cssVars"
  >
    <template v-if="isSimpleVariant">
      <button
        :data-qa="props.dataQa"
        class="h-action-menu__button"
        :disabled="disabled"
        @click="openList"
      >
        <HpIcon
          v-tooltip.bottom="tooltipText"
          :icon="HIcon.ICON_MORE_VERT"
          class="cursor-pointer"
          primary
          block
          :class="{
            horizontal,
            [`d-none d-${iconBreakpoint}-flex`]: !alwaysShowIcon,
          }"
          :disabled="disabled"
          :width="iconWidth"
          :height="iconWidth"
        />
      </button>

      <HButtonV1
        v-if="!alwaysShowIcon"
        block
        outline
        primary
        :class="`borderless d-${iconBreakpoint}-none`"
        :disabled="disabled"
        @click="expand = !expand"
      >
        Actions
      </HButtonV1>
    </template>
    <HButton
      v-else
      v-qa-generate
      :is-disabled="disabled"
      variant="outline"
      :icon-append="iconAppend"
      @click="expand = !expand"
    >
      {{ customButton }}
    </HButton>
    <transition name="fade">
      <div
        v-show="expand"
        ref="list"
        class="h-action-menu__list"
        :class="{ 'h-action-menu__list--expanded': expand }"
        :style="{ left: `${left}px`, top: `${top}px` }"
        @click="expand = !expand"
      >
        <slot />
      </div>
    </transition>
  </div>
</template>

<style lang="scss" scoped>
.h-action-menu {
  position: relative;

  &__button {
    display: block;
    padding: 0;
    appearance: none;
    border: none;
    background-color: transparent;
  }

  &__simple {
    text-align: center;
    border-radius: 50%;
    @mixin state-styling {
      content: '';
      width: var(--action-dots-hover-width);
      height: var(--action-dots-hover-width);
      position: absolute;
      top: -4px;
      left: -4px;
      border-radius: 50%;
      pointer-events: none;
    }

    &:hover:not(#{&}--active)::before {
      @include state-styling;
      background: var(--gray-border);
      opacity: 0.2;
    }

    &--active::before {
      @include state-styling;
      background: var(--primary);
      opacity: 0.1;
    }
  }

  img {
    height: 20px;
    width: auto;
  }

  &__list {
    position: absolute;
    z-index: var(--z-index-2);

    @media only screen and (max-width: 767px) {
      position: fixed;
      top: unset !important;
      left: 0 !important;
      bottom: 0;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      z-index: var(--z-index-10);
      background-color: rgba(0, 0, 0, 0.6);
    }

    &--collapsed {
      pointer-events: none;
    }
  }
}
.horizontal {
  transform: rotate(90deg);
}
</style>
