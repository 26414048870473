import { computed } from 'vue';

import { useDeveloperTools } from '@/composables/developerTools/useDeveloperTools';
import { useResourceMapper } from '@/composables/useResourceMapper';
import { useAccessStore } from '@/stores/accessStore';
import { useDeveloperToolsWebsitesStore } from '@/stores/developerTools/developerToolsWebsitesStore';
import { useWordpressStore } from '@/stores/wordpressStore';
import type { IWordpressManagedWebsiteWithEmail } from '@/types';

export const useDeveloperToolsWebsites = () => {
  const wordpressStore = useWordpressStore();
  const accessStore = useAccessStore();
  const developerToolsWebsitesStore = useDeveloperToolsWebsitesStore();
  const { activeDeveloperToolsResources } = useResourceMapper();
  const { hasUnlimitedPlan } = useDeveloperTools();
  const availableWebsites = computed<IWordpressManagedWebsiteWithEmail[]>(
    () =>
      wordpressStore.managedAndOwnedWordpressWebsites?.map((website) => ({
        ...website,
        ownerEmail: accessStore.getManagedClientEmailById(website.clientId),
      })) ?? [],
  );

  const findWebsiteInAvailableWebsites = (domain: string) =>
    availableWebsites.value?.find(({ website }) => website === domain);

  const assignedWebsites = computed<IWordpressManagedWebsiteWithEmail[]>(() =>
    Object.values(developerToolsWebsitesStore.assignedWebsites)
      .flat()
      .map(({ domain }) => findWebsiteInAvailableWebsites(domain))
      .filter(
        (website): website is IWordpressManagedWebsiteWithEmail =>
          website !== undefined,
      )
      .filter(
        ({ website }, index, websites) =>
          websites.findIndex(
            ({ website: websiteToCompare }) => websiteToCompare === website,
          ) === index,
      ),
  );

  const enabledDeveloperToolsWebsites = computed<
    IWordpressManagedWebsiteWithEmail[]
  >(() =>
    hasUnlimitedPlan.value ? availableWebsites.value : assignedWebsites.value,
  );

  const fetchAllAssignedWebsites = async () => {
    if (developerToolsWebsitesStore.isAllAssignedWebsitesLoaded) {
      return;
    }

    const promises = activeDeveloperToolsResources.value.map(({ id }) =>
      developerToolsWebsitesStore.fetchAssignedWebsites(id),
    );
    await Promise.all(promises);
    developerToolsWebsitesStore.isAllAssignedWebsitesLoaded = true;
  };

  const getActiveWebsitesByResourceId = (resourceId: number) =>
    (developerToolsWebsitesStore.assignedWebsites[resourceId] ?? [])
      .map(({ domain }) => findWebsiteInAvailableWebsites(domain))
      .filter(
        (website): website is IWordpressManagedWebsiteWithEmail =>
          website !== undefined,
      );

  return {
    enabledDeveloperToolsWebsites,
    availableWebsites,
    fetchAllAssignedWebsites,
    getActiveWebsitesByResourceId,
  };
};
