import type { HRESOURCE_ITEM_STATUS } from '@/types';

export interface HRefundItemCalculations {
  calculations: {
    withTaxes: number;
    withoutTaxes: number;
    taxes: number;
  };
}

export const HREFUND_INVOICE_STATUS = {
  REFUNDED: 'refunded',
} as const;

export const HREFUND_INVOICE_ITEM_TYPE = {
  PLAN_ITEM: 'plan_item',
  ADDON: 'addon',
  CHARGE: 'charge',
} as const;

export type HRefundInvoiceItemType =
  (typeof HREFUND_INVOICE_ITEM_TYPE)[keyof typeof HREFUND_INVOICE_ITEM_TYPE];

export interface HRefundInvoiceItem extends HRefundItemCalculations {
  total: number;
  amount: number;
  dateFrom: number;
  dateTo: number;
  description: string;
  discountAmount: number;
  entityId: string;
  id: string;
  taxPercent: number;
  taxAmount: number;
  unitAmount: number;
  refundable: boolean;
  subscriptionId: string;
  renewal: boolean;
  resourceType: string;
  invoiceId: string;
  freeDomain?: string | null;
  unsupportedDetails: HRefundInvoiceItemUnsupportedDetails;
  itemType: HRefundInvoiceItemType;
  domain: string | null;
}

export interface HRefundInvoicePaymentInfo {
  paymentGateway: string;
  paymentMethod: string;
  paymentToken: string;
  supported: boolean;
  methodSupportsTos: boolean | null;
}

export interface HRefundInvoiceItemUnsupportedDetails {
  unsupportedReason: UnsupportedMessages | null;
  contactSupport: boolean | null;
}

export interface HRefundInvoice {
  amountAdjusted: number;
  amountDue: number;
  amountPaid: number;
  currencyCode: string;
  date: number;
  id: string;
  invoiceItems: HRefundInvoiceItem[];
  isRefundableToBalance: boolean;
  paidAt: Date;
  status: string;
  processingMessageId: 0 | 1 | 2 | 3;
  refundStatus: string;
  subTotal: number;
  taxPercent: number;
  tax: number;
  total: number;
  refundable: boolean;
  supported: boolean;
  hpaymentsInfo: HRefundInvoicePaymentInfo;
  showEmailNotification: boolean;
  unsupportedDetails: HRefundInvoiceItemUnsupportedDetails;
}

export type HResourceItemStatus =
  (typeof HRESOURCE_ITEM_STATUS)[keyof typeof HRESOURCE_ITEM_STATUS];
export interface HRefundInvoiceItemWithAdditions extends HRefundInvoiceItem {
  title: string;
  isSelected?: boolean;
  price: string;
  totalPriceDiscount: string;
  isDisabled?: boolean;
  status?: HResourceItemStatus;
}

export interface HRefundInvoiceEstimateItemWithAdditions
  extends HRefundInvoiceEstimateItem {
  title: string;
  isSelected?: boolean;
  price: string;
  totalPriceDiscount: string;
}

export interface HRefundInvoiceEstimateItem extends HRefundItemCalculations {
  id: string;
  entityId: string;
  description: string;
  domain: string | null;
  unitAmount: number;
  amount: number;
  taxAmount: number;
  taxPercent: number;
  discountAmount: number;
  totalAmount: number;
  subscriptionId: string;
  renewal: boolean;
  refundable: boolean;
  unsupportedDetails: HRefundInvoiceItemUnsupportedDetails;
}

export interface HRefundInvoiceEstimate extends HRefundItemCalculations {
  currencyCode: string;
  id: string;
  refundable: boolean;
  supported: boolean;
  refundedTotal: number;
  lineItems: HRefundInvoiceEstimateItem[];
  hpaymentsInfo: HRefundInvoicePaymentInfo;
  showEmailNotification: boolean;
}

export type HRefundInvoiceEstimatePayload = {
  refundReason: string;
  clientNote: string;
  subscriptions: string[];
};

export const UNSUPPORTED_MESSAGES_MAP = {
  BALANCE_FEATURE_NOT_ENABLED:
    'v2.unsupported.refund.balance.feature.not.enabled',
  BALANCE_COIN_PAYMENTS_GATEWAY:
    'v2.unsupported.refund.balance.coin.payments.gateway',
  ALREADY_REQUESTED: 'v2.unsupported.refund.already.requested',
  GATEWAY_NOT_SUPPORTED: 'v2.unsupported.refund.gateway.not.supported',
  OLD_INVOICE: 'v2.unsupported.refund.old.invoice',
  CREDITS_APPLIED: 'v2.unsupported.refund.credits.applied',
  NOT_ALL_ITEMS_HAS_SUBSCRIPTION:
    'v2.unsupported.refund.not.all.items.has.subscription',
  SUBSCRIPTION_NOT_ACTIVE: 'v2.unsupported.refund.subscription.not.active',
  MULTIPLE_PAYMENTS: 'v2.unsupported.refund.multiple.payments',
  HAS_ADVANCE_CHARGES: 'v2.unsupported.refund.has.advance.charges',
  NOT_LAST_INVOICE: 'v2.unsupported.refund.not.last.invoice',
  UNSUPPORTED_ORDER_TYPE: 'v2.unsupported.refund.unsupported.order.type',
  ALL_ORDER_ITEMS_NOT_REFUNDABLE:
    'v2.unsupported.refund.all.items.not.refundable',
  SOME_ORDER_ITEMS_NOT_REFUNDABLE:
    'v2.unsupported.refund.some.items.not.refundable',
  DOMAIN_TRANSFER_NOT_FAILED_OR_PENDING:
    'v2.unsupported.refund.domain.transfer.not.failed.or.pending',
  DOMAIN_RENEWAL_NOT_SUPPORTED:
    'v2.unsupported.refund.domain.renewal.not.supported',
  DOMAIN_RESOURCE_NOT_VALID: 'v2.unsupported.refund.domain.resource.not.valid',
  CANNOT_CALCULATE_REFUNDABLE:
    'v2.unsupported.refund.cannot.calculate.refundable',
  ZERO_AMOUNT_CALCULATION_INVOICE:
    'v2.unsupported.refund.zero.amount.calculation.invoice',
  GATEWAY_UNRESOLVED: 'v2.unsupported.refund.gateway.unresolved',
  GATEWAY_NOT_SUPPORTED_BY_TOS:
    'v2.unsupported.refund.gateway.not.supported.by.tos',
  UNRELIABLE_DATA_FOR_TOS_CHECKS:
    'v2.unsupported.refund.unreliable.data.for.tos.checks',
  MULTIPLE_PAYMENTS_TOO_OLD_PAYMENT:
    'v2.unsupported.refund.multiple.payments.too.old.payment',
  MULTIPLE_PAYMENTS_APPLIED_AMOUNT_TOO_LOW:
    'v2.unsupported.refund.multiple.payments.applied.amount.too.low',
  TOS_UNSUPPORTED_PSP_REFUND:
    'v2.unsupported.refund.tos.unsupported.psp.refund',
  MANUAL_BALANCE_FREE_DOMAIN_UNSUPPORTED:
    'v2.unsupported.refund.manual.balance.free.domain.unsupported',
  DOMAIN_TRANSFER_NOT_FAILED:
    'v2.unsupported.refund.domain.transfer.not.failed',
  DOMAIN_ACTIVATED_LONGER_FOR_THAN_ALLOWED_BY_TOS:
    'v2.unsupported.refund.domain.activated.longer.for.than.allowed.by.tos',
  DOMAIN_SLUG_NOT_ALLOWED_BY_TOS:
    'v2.unsupported.refund.domain.slug.not.allowed.by.tos',
  DOMAIN_REFUND_ALREADY_INITIATED_WITHIN_TIMEFRAME:
    'v2.usupported.refund.domain.refund.already.initiated.within.timeframe',
} as const;

export type UnsupportedMessages =
  (typeof UNSUPPORTED_MESSAGES_MAP)[keyof typeof UNSUPPORTED_MESSAGES_MAP];
