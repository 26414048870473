import { defineStore } from 'pinia';
import { ref, computed } from 'vue';
import { useRouter } from 'vue-router';

import { useCookies } from '@/composables/useCookies';
import { referralRepo } from '@/repositories';
import {
  type ClientReferrals,
  type ReferralsListItem,
  type HAsyncError,
  type IUserReferralData,
  type ReferralClientInfo,
  type RequestConfig,
  type IReferralReward,
  AmplitudeLocation,
  type IReferralPromotion,
} from '@/types';
import { Route, HostingerProPanel, Referral, Cookie } from '@/types';

export const useReferralStore = defineStore('referralStore', () => {
  const DEFAULT_ENTRY_LOCATION = AmplitudeLocation.Base.URL;

  const {
    cookie: customCommissionIdCookie,
    removeCookie: removeCustomCommissionIdCookie,
  } = useCookies(Cookie.REFERRALS_CUSTOM_COMMISSION_ID);

  const userReferralData = ref<IUserReferralData | null>(null);
  const userReferrals = ref<ClientReferrals | null>(null);
  const isLoading = ref(false);
  const isLoadingMemberData = ref(false);
  const isFormSubmitting = ref(false);
  const payPalList = ref<string[]>([]);
  const error = ref<HAsyncError | null>(null);
  const payouts = ref<IReferralReward[] | null>(null);
  const referralsEntryLocation = ref<AmplitudeLocation.Base | null>(
    DEFAULT_ENTRY_LOCATION,
  );

  const proUserReferralData = ref<ReferralClientInfo | null>(null);
  const proUserReferrals = ref<ClientReferrals | null>(null);
  const proPayPalList = ref<string[]>([]);
  const activePromotion = ref<IReferralPromotion | undefined | null>();

  const router = useRouter();

  const getPaypalList = computed(() =>
    payPalList.value.map((item) => ({ label: item, value: item })),
  );

  const hasAtLeastOneQualifiedReferral = computed(() =>
    (userReferrals.value?.list || []).some(({ status }: ReferralsListItem) =>
      [
        HostingerProPanel.ProRewardStatus.APPROVED,
        HostingerProPanel.ProRewardStatus.QUALIFIED,
      ].includes(status),
    ),
  );

  const referralsCount = computed(
    () => userReferrals.value?.totals.totalReferrals || 0,
  );

  const totalCommissionsEarned = computed(
    () => userReferralData.value?.rewardsIssuedAmount || 0,
  );

  const approvedCommissionsAmount = computed(
    () => userReferralData.value?.rewardsApprovedAmount || 0,
  );

  const isReferralRockProgramMember = computed(
    () =>
      !!(userReferralData.value && Object.keys(userReferralData.value).length),
  );

  const currentPayoutMethod = computed(
    () => userReferralData.value?.payoutInfo?.type,
  );

  const splittedReferralUrl = computed(() => {
    if (!userReferralData.value?.referralUrl) {
      return [];
    }
    const parts = userReferralData.value?.referralUrl?.split('=');

    if (parts?.length !== 2) {
      return [];
    }

    return [`${parts[0]}=`, parts[1]];
  });

  const fetchMemberData = async (hideToastr = false) => {
    isLoadingMemberData.value = true;
    const [{ data }, requestError] = await referralRepo.getReferralClientInfo(
      hideToastr,
    );

    if (requestError) {
      isLoadingMemberData.value = false;
      error.value = requestError;

      return;
    }

    if (Array.isArray(data) && !data.length) {
      userReferralData.value = null;
      isLoadingMemberData.value = false;

      return;
    }

    userReferralData.value = data;
    isLoadingMemberData.value = false;
  };

  const fetchReferralClientInfo = async (hideToastr?: boolean) => {
    isLoading.value = true;
    await fetchMemberData(hideToastr);

    if (userReferralData.value) {
      await fetchClientReferrals();
    }

    isLoading.value = false;
  };

  const fetchClientReferrals = async (requestConfig?: RequestConfig) => {
    const [{ data }, requestError] = await referralRepo.getClientReferrals(
      requestConfig,
    );

    if (requestError) {
      isLoading.value = false;
      error.value = requestError;

      return;
    }

    userReferrals.value = data;
  };

  const fetchPaypalEmailList = async () => {
    const [{ data }, requestError] = await referralRepo.getPaypalEmails();

    if (requestError) {
      isLoading.value = false;
      error.value = requestError;

      return;
    }

    payPalList.value = data;
  };

  const addEmail = async (email: string) => {
    const currentEmail =
      userReferralData.value?.payoutInfo?.type === Referral.PayoutType.PAYPAL
        ? userReferralData.value.payoutInfo.config.email
        : null;

    if (currentEmail === email) {
      return;
    }

    const [{ data }, requestError] = await (currentEmail
      ? referralRepo.patchPaypalEmail(email)
      : referralRepo.addPaypalEmail(email));

    if (requestError) {
      error.value = requestError;

      return;
    }

    userReferralData.value = data;
  };

  const setEmail = async (email: string) => {
    const [, requestError] = await referralRepo.setPaypalEmails({ email });

    if (!requestError) {
      await fetchReferralClientInfo();
    }
  };

  const fetchProUserReferralData = async (params: { hideToastr: boolean }) => {
    const [
      [{ data: proClientInfoData }, proClientInfoError],
      [{ data: proClientReferralsData }, proClientReferralsError],
      [proPaypalListResponse, proPayPalListError],
    ] = await Promise.all([
      referralRepo.getReferralProClientInfo(params?.hideToastr),
      referralRepo.getProClientReferrals(params?.hideToastr),
      referralRepo.getProPaypalEmails(),
    ]);

    const hasErrors = [
      proClientInfoError,
      proClientReferralsError,
      proPayPalListError,
    ].some(Boolean);

    if (!hasErrors) {
      proUserReferralData.value = proClientInfoData;
      proUserReferrals.value = proClientReferralsData;
      proPayPalList.value = proPaypalListResponse.data;
    }
  };

  const fetchPayoutsData = async () => {
    const [{ data }, error] = await referralRepo.getRewards();

    if (error) {
      return;
    }

    payouts.value = data;
  };

  const updateRewardRateAndFetchActivePromotion = async () => {
    if (customCommissionIdCookie.value) {
      const [_, error] = await referralRepo.postCustomRewardRate(
        customCommissionIdCookie.value,
      );

      if (!error) {
        removeCustomCommissionIdCookie({
          domain: process.env.VITE_COOKIE_DOMAIN,
        });

        router.replace({
          name: Route.Referral.REFERRALS,
        });
      }
    }

    fetchActivePromotion();
  };

  const fetchActivePromotion = async () => {
    const [{ data }, error] = await referralRepo.getReferralPromotion();

    if (error || !data) {
      activePromotion.value = null;

      return;
    }

    const key = [
      Referral.PromotionalTopBannerKey.WORDCAMP_30,
      Referral.PromotionalTopBannerKey.WORDCAMP_40,
    ].includes(data.key)
      ? Referral.PromotionalTopBannerKey.WORDCAMP
      : data.key;

    activePromotion.value = {
      ...data,
      key,
    };
  };

  const $reset = () => {
    userReferralData.value = null;
    proUserReferralData.value = null;
    userReferrals.value = null;
    proUserReferrals.value = null;
    isLoading.value = false;
    isFormSubmitting.value = false;
    payPalList.value = [];
    proPayPalList.value = [];
    error.value = null;
    referralsEntryLocation.value = DEFAULT_ENTRY_LOCATION;
  };

  return {
    userReferralData,
    proUserReferralData,
    userReferrals,
    proUserReferrals,
    isLoading,
    isFormSubmitting,
    payPalList,
    proPayPalList,
    error,
    getPaypalList,
    hasAtLeastOneQualifiedReferral,
    isReferralRockProgramMember,
    payouts,
    referralsCount,
    totalCommissionsEarned,
    approvedCommissionsAmount,
    referralsEntryLocation,
    currentPayoutMethod,
    activePromotion,
    splittedReferralUrl,
    addEmail,
    setEmail,
    fetchProUserReferralData,
    fetchReferralClientInfo,
    fetchClientReferrals,
    fetchPayoutsData,
    updateRewardRateAndFetchActivePromotion,
    fetchMemberData,
    fetchPaypalEmailList,
    $reset,
  };
});
