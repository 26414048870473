export const HIcon = {
  ICON3D_ROTATION: 'icon3d-rotation',
  ICON_AC_UNIT: 'icon-ac-unit',
  ICON_ACCESS_ALARM: 'icon-access-alarm',
  ICON_ACCESS_ALARMS: 'icon-access-alarms',
  ICON_ACCESS_TIME: 'icon-access-time',
  ICON_ACCESSIBILITY: 'icon-accessibility',
  ICON_ACCESSIBLE: 'icon-accessible',
  ICON_ACCOUNT_BALANCE: 'icon-account-balance',
  ICON_ACCOUNT_BALANCE_Walette: 'icon-account-balance-walette',
  ICON_ACCOUNT_BOX: 'icon-account-box',
  ICON_ACCOUNT_CIRCLE: 'icon-account-circle',
  ICON_ADB: 'icon-adb',
  ICON_ADD: 'icon-add',
  ICON_GRAY: 'icon-gray',
  ICON_COMMERCIAL: 'icon-commercial',
  ICON_SEARCH_SMALL: 'icon-search-small',
  ICON_ADD_A_PHOTO: 'icon-add-a-photo',
  ICON_ADD_ALARM: 'icon-add-alarm',
  ICON_DRAG_INDICATOR: 'icon-drag-indicator',
  ICON_ADD_ALERT: 'icon-add-alert',
  ICON_ADD_BOX: 'icon-add-box',
  ICON_ADD_CIRCLE: 'icon-add-circle',
  ICON_ADD_CIRCLE_OUTLINE: 'icon-add-circle-outline',
  ICON_ADD_LOCATION: 'icon-add-location',
  ICON_ADD_SHOPPING_CART: 'icon-add-shopping-cart',
  ICON_ADD_TO_PHOTOS: 'icon-add-to-photos',
  ICON_ADD_TO_QUEUE: 'icon-add-to-queue',
  ICON_ADJUST: 'icon-adjust',
  ICON_AI: 'icon-ai',
  ICON_AIRLINE_SEAT_FLAT: 'icon-airline-seat-flat',
  ICON_AIRLINE_SEAT_FLAT_ANGLED: 'icon-airline-seat-flat-angled',
  ICON_AIRLINE_SEAT_INDIVIDUAL_SUITE: 'icon-airline-seat-individual-suite',
  ICON_AIRLINE_SEAT_LEGROOM_EXTRA: 'icon-airline-seat-legroom-extra',
  ICON_AIRLINE_SEAT_LEGROOM_NORMAL: 'icon-airline-seat-legroom-normal',
  ICON_AIRLINE_SEAT_LEGROOM_REDUCED: 'icon-airline-seat-legroom-reduced',
  ICON_AIRLINE_SEAT_RECLINE_EXTRA: 'icon-airline-seat-recline-extra',
  ICON_AIRLINE_SEAT_RECLINE_NORMAL: 'icon-airline-seat-recline-normal',
  ICON_AIRPLANEMODE_ACTIVE: 'icon-airplanemode-active',
  ICON_AIRPLANEMODE_INACTIVE: 'icon-airplanemode-inactive',
  ICON_AIRPLAY: 'icon-airplay',
  ICON_AIRPORT_SHUTTLE: 'icon-airport-shuttle',
  ICON_ALARM: 'icon-alarm',
  ICON_ALARM_ADD: 'icon-alarm-add',
  ICON_ALARM_OFF: 'icon-alarm-off',
  ICON_ALARM_ON: 'icon-alarm-on',
  ICON_ALBUM: 'icon-album',
  ICON_ALL_INCLUSIVE: 'icon-all-inclusive',
  ICON_ALL_OUT: 'icon-all-out',
  ICON_ANDROID: 'icon-android',
  ICON_ANNOUNCEMENT: 'icon-announcement',
  ICON_APPLE_PAY: 'icon-apple-pay',
  ICON_APPLICATION_BRACKETS: 'icon-application-brackets',
  ICON_APPS: 'icon-apps',
  ICON_ARCHIVE: 'icon-archive',
  ICON_ARROW_BACK: 'icon-arrow-back',
  ICON_ARROW_CIRCLE_DOWN: 'icon-arrow-circle-down',
  ICON_ARROW_DOWN: 'icon-arrow-down',
  ICON_ARROW_DOWN_LINE: 'icon-arrow-down-line',
  ICON_ARROW_DOWN_LINE_16: 'icon-arrow-down-line-16',
  ICON_ARROW_DOWNWARD: 'icon-arrow-downward',
  ICON_ARROW_DROP_DOWN: 'icon-arrow-drop-down',
  ICON_ARROW_DROP_DOWN_CIRCLE: 'icon-arrow-drop-down-circle',
  ICON_ARROW_DROP_LEFT: 'icon-arrow-drop-left',
  ICON_ARROW_DROP_RIGHT: 'icon-arrow-drop-right',
  ICON_ARROW_DROP_UP: 'icon-arrow-drop-up',
  ICON_ARROW_FORWARD: 'icon-arrow-forward',
  ICON_ARROW_UPWARD: 'icon-arrow-upward',
  ICON_ART_TRACK: 'icon-art-track',
  ICON_ASPECT_RATIO: 'icon-aspect-ratio',
  ICON_ASSESSMENT: 'icon-assessment',
  ICON_ASSIGNMENT: 'icon-assignment',
  ICON_ASSIGNMENT_IND: 'icon-assignment-ind',
  ICON_ASSIGNMENT_LATE: 'icon-assignment-late',
  ICON_ASSIGNMENT_RETURN: 'icon-assignment-return',
  ICON_ASSIGNMENT_RETURNED: 'icon-assignment-returned',
  ICON_ASSIGNMENT_TURNED_IN: 'icon-assignment-turned-in',
  ICON_ASSISTANT: 'icon-assistant',
  ICON_ASSISTANT_PHOTO: 'icon-assistant-photo',
  ICON_ATTACH_FILE: 'icon-attach-file',
  ICON_ATTACH_MONEY: 'icon-attach-money',
  ICON_ATTACHMENT: 'icon-attachment',
  ICON_AUDIOTRACK: 'icon-audiotrack',
  ICON_AUTORENEW: 'icon-autorenew',
  ICON_AV_TIMER: 'icon-av-timer',
  ICON_BACKSPACE: 'icon-backspace',
  ICON_BACKUP: 'icon-backup',
  ICON_BACKUPS: 'icon-backups',
  ICON_BADGE_DISCOUNT: 'icon-badge-discount',
  ICON_BANK: 'icon-bank',
  ICON_BATTERY_ALERT: 'icon-battery-alert',
  ICON_BATTERY_CHARGING_FULL: 'icon-battery-charging-full',
  ICON_BATTERY_FULL: 'icon-battery-full',
  ICON_BATTERY_STD: 'icon-battery-std',
  ICON_BATTERY_UNKNOWN: 'icon-battery-unknown',
  ICON_BEACH_ACCESS: 'icon-beach-access',
  ICON_BELL: 'icon-bell',
  ICON_BELL_LIGHT: 'icon-bell-light',
  ICON_BEENHERE: 'icon-beenhere',
  ICON_BLOCK: 'icon-block',
  ICON_BLUETOOTH: 'icon-bluetooth',
  ICON_BLUETOOTH_AUDIO: 'icon-bluetooth-audio',
  ICON_BLUETOOTH_CONNECTED: 'icon-bluetooth-connected',
  ICON_BLUETOOTH_DISABLED: 'icon-bluetooth-disabled',
  ICON_BLUETOOTH_SEARCHING: 'icon-bluetooth-searching',
  ICON_BLUR_CIRCULAR: 'icon-blur-circular',
  ICON_BLUR_LINEAR: 'icon-blur-linear',
  ICON_BLUR_OFF: 'icon-blur-off',
  ICON_BLUR_ON: 'icon-blur-on',
  ICON_BOOK: 'icon-book',
  ICON_BOOKMARK_BORDER: 'icon-bookmark-border',
  ICON_BORDER_ALL: 'icon-border-all',
  ICON_BORDER_BOTTOM: 'icon-border-bottom',
  ICON_BORDER_CLEAR: 'icon-border-clear',
  ICON_BORDER_COLOR: 'icon-border-color',
  ICON_BORDER_HORIZONTAL: 'icon-border-horizontal',
  ICON_BORDER_INNER: 'icon-border-inner',
  ICON_BORDER_LEFT: 'icon-border-left',
  ICON_BORDER_OUTER: 'icon-border-outer',
  ICON_BORDER_RIGHT: 'icon-border-right',
  ICON_BORDER_STYLE: 'icon-border-style',
  ICON_BORDER_TOP: 'icon-border-top',
  ICON_BORDER_VERTICAL: 'icon-border-vertical',
  ICON_BRANDING_WATERMARK: 'icon-branding-watermark',
  ICON_BRIGHTNESS1: 'icon-brightness1',
  ICON_BRIGHTNESS2: 'icon-brightness2',
  ICON_BRIGHTNESS3: 'icon-brightness3',
  ICON_BRIGHTNESS4: 'icon-brightness4',
  ICON_BRIGHTNESS5: 'icon-brightness5',
  ICON_BRIGHTNESS6: 'icon-brightness6',
  ICON_BRIGHTNESS7: 'icon-brightness7',
  ICON_BRIGHTNESS_AUTO: 'icon-brightness-auto',
  ICON_BRIGHTNESS_HIGH: 'icon-brightness-high',
  ICON_BRIGHTNESS_LOW: 'icon-brightness-low',
  ICON_BRIGHTNESS_MEDIUM: 'icon-brightness-medium',
  ICON_BROKEN_IMAGE: 'icon-broken-image',
  ICON_BRUSH: 'icon-brush',
  ICON_BUBBLE_CHART: 'icon-bubble-chart',
  ICON_BUG_REPORT: 'icon-bug-report',
  ICON_BUILD: 'icon-build',
  ICON_BUILDING: 'icon-building',
  ICON_BURST_MODE: 'icon-burst-mode',
  ICON_BUSINESS: 'icon-business',
  ICON_BUSINESS_CENTER: 'icon-business-center',
  ICON_C_PANEL: 'icon-c-panel',
  ICON_CACHED: 'icon-cached',
  ICON_CAKE: 'icon-cake',
  ICON_CALL: 'icon-call',
  ICON_CALL_END: 'icon-call-end',
  ICON_CALL_MADE: 'icon-call-made',
  ICON_CALL_MERGE: 'icon-call-merge',
  ICON_CALL_MISSED: 'icon-call-missed',
  ICON_CALL_MISSED_OUTGOING: 'icon-call-missed-outgoing',
  ICON_CALL_RECEIVED: 'icon-call-received',
  ICON_CALL_SPLIT: 'icon-call-split',
  ICON_CALL_TO_ACTION: 'icon-call-to-action',
  ICON_CAMERA: 'icon-camera',
  ICON_CAMERA_ALT: 'icon-camera-alt',
  ICON_CAMERA_ENHANCE: 'icon-camera-enhance',
  ICON_CAMERA_FRONT: 'icon-camera-front',
  ICON_CAMERA_REAR: 'icon-camera-rear',
  ICON_CAMERA_ROLL: 'icon-camera-roll',
  ICON_CANCEL: 'icon-cancel',
  ICON_CANCEL_LIGHT: 'icon-cancel-light',
  ICON_CARD_FILLED: 'icon-card-filled',
  ICON_CARD_GIFTCARD: 'icon-card-giftcard',
  ICON_CARD_MEMBERSHIP: 'icon-card-membership',
  ICON_CARD_TRAVEL: 'icon-card-travel',
  ICON_CASINO: 'icon-casino',
  ICON_CAST: 'icon-cast',
  ICON_CAST_CONNECTED: 'icon-cast-connected',
  ICON_CENTER_FOCUS_STRONG: 'icon-center-focus-strong',
  ICON_CENTER_FOCUS_WEAK: 'icon-center-focus-weak',
  ICON_CIRCLE: 'icon-circle',
  ICON_CIRCLE_ARROW_UP: 'icon-circle-arrow-up',
  ICON_CHANGE_HISTORY: 'icon-change-history',
  ICON_CHAT: 'icon-chat',
  ICON_CHAT_BUBBLE: 'icon-chat-bubble',
  ICON_CHAT_BUBBLE_OUTLINE: 'icon-chat-bubble-outline',
  ICON_CHECK: 'icon-check',
  ICON_CHECK_LIGHT: 'icon-check-light',
  ICON_CHECK_BOX: 'icon-check-box',
  ICON_CHECK_BOX_OUTLINE_BLANK: 'icon-check-box-outline-blank',
  ICON_CHECK_CIRCLE: 'icon-check-circle',
  ICON_CHECK_CIRCLE_LIGHT: 'icon-check-circle-light',
  ICON_SUCCESS_CIRCULAR_LOADER: 'icon-success-circular-loader',
  ICON_CHEVRON_LEFT: 'icon-chevron-left',
  ICON_CHEVRON_RIGHT: 'icon-chevron-right',
  ICON_CHEVRON_RIGHT_LIGHT: 'icon-chevron-right-light',
  ICON_CHEVRON_UP: 'icon-chevron-up',
  ICON_CHILD_CARE: 'icon-child-care',
  ICON_CHILD_FRIENDLY: 'icon-child-friendly',
  ICON_CHROME_READER_MODE: 'icon-chrome-reader-mode',
  ICON_CLASS: 'icon-class',
  ICON_CLEAR: 'icon-clear',
  ICON_CLEAR_ALL: 'icon-clear-all',
  ICON_CLOCK_CIRCLE_16: 'icon-clock-circle-16',
  ICON_CLOCK_CIRCLE_24: 'icon-clock-circle-24',
  ICON_CLOCK_CIRCLE: 'icon-clock-circle',
  ICON_CHECKMARK_CIRCLE: 'icon-checkmark-circle',
  ICON_CHECKMARK_CIRCLE_FILLED: 'icon-checkmark-circle-filled',
  ICON_CLOSE: 'icon-close',
  ICON_CLOSE_LIGHT: 'icon-close-light',
  ICON_CLOSED_CAPTION: 'icon-closed-caption',
  ICON_CLOUD: 'icon-cloud',
  ICON_CLOUD_CIRCLE: 'icon-cloud-circle',
  ICON_CLOUD_DONE: 'icon-cloud-done',
  ICON_CLOUD_DOWNLOAD: 'icon-cloud-download',
  ICON_CLOUD_OFF: 'icon-cloud-off',
  ICON_CLOUD_QUEUE: 'icon-cloud-queue',
  ICON_CLOUD_UPLOAD: 'icon-cloud-upload',
  ICON_CODE: 'icon-code',
  ICON_COLLECTIONS: 'icon-collections',
  ICON_COLLECTIONS_BOOKMARK: 'icon-collections-bookmark',
  ICON_COLOR_LENS: 'icon-color-lens',
  ICON_COLORIZE: 'icon-colorize',
  ICON_COMMENT: 'icon-comment',
  ICON_COMPARE: 'icon-compare',
  ICON_COMPARE_ARROWS: 'icon-compare-arrows',
  ICON_COMPUTER: 'icon-computer',
  ICON_COMPUTER_LIST: 'icon-computer-list',
  ICON_CONFIRMATION_NUMBER: 'icon-confirmation-number',
  ICON_CONTACT_INFORMATION: 'icon-contact-information',
  ICON_CONTACT_MAIL: 'icon-contact-mail',
  ICON_CONTACT_PHONE: 'icon-contact-phone',
  ICON_CONTACTS: 'icon-contacts',
  ICON_CONTENT_COPY: 'icon-content-copy',
  ICON_CONTENT_CUT: 'icon-content-cut',
  ICON_CONTENT_PASTE: 'icon-content-paste',
  ICON_CONTROL_POINT: 'icon-control-point',
  ICON_CONTROL_POINT_DUPLICATE: 'icon-control-point-duplicate',
  ICON_COPYRIGHT: 'icon-copyright',
  ICON_CREATE: 'icon-create',
  ICON_CREATE_NEW_FOLDER: 'icon-create-new-folder',
  ICON_CREDIT_CARD: 'icon-credit-card',
  ICON_CROP: 'icon-crop',
  ICON_CROP169: 'icon-crop169',
  ICON_CROP32: 'icon-crop32',
  ICON_CROP54: 'icon-crop54',
  ICON_CROP75: 'icon-crop75',
  ICON_CROP_DIN: 'icon-crop-din',
  ICON_CROP_FREE: 'icon-crop-free',
  ICON_CROP_LANDSCAPE: 'icon-crop-landscape',
  ICON_CROP_ORIGINAL: 'icon-crop-original',
  ICON_CROP_PORTRAIT: 'icon-crop-portrait',
  ICON_CROP_ROTATE: 'icon-crop-rotate',
  ICON_CROP_SQUARE: 'icon-crop-square',
  ICON_DASHBOARD: 'icon-dashboard',
  ICON_DASHBOARD_LIGHT: 'icon-dashboard-light',
  ICON_DATA_USAGE: 'icon-data-usage',
  ICON_DATE_RANGE: 'icon-date-range',
  ICON_DEHAZE: 'icon-dehaze',
  ICON_DELETE: 'icon-delete',
  ICON_DELETE_LIGHT: 'icon-delete-light',
  ICON_DELETE_FOREVER: 'icon-delete-forever',
  ICON_DELETE_SWEEP: 'icon-delete-sweep',
  ICON_DELETE_LOGS: 'icon-delete-logs',
  ICON_DESCRIPTION: 'icon-description',
  ICON_DESKTOP_MAC: 'icon-desktop-mac',
  ICON_DESKTOP_WINDOWS: 'icon-desktop-windows',
  ICON_DETAILS: 'icon-details',
  ICON_DEVELOPER_BOARD: 'icon-developer-board',
  ICON_DEVELOPER_MODE: 'icon-developer-mode',
  ICON_DEVICE_HUB: 'icon-device-hub',
  ICON_DEVICES: 'icon-devices',
  ICON_DEVICES_OTHER: 'icon-devices-other',
  ICON_DIALER_SIP: 'icon-dialer-sip',
  ICON_DIALPAD: 'icon-dialpad',
  ICON_DIRECT_ADMIN: 'icon-direct-admin',
  ICON_DIRECTIONS: 'icon-directions',
  ICON_DIRECTIONS_BIKE: 'icon-directions-bike',
  ICON_DIRECTIONS_BOAT: 'icon-directions-boat',
  ICON_DIRECTIONS_BUS: 'icon-directions-bus',
  ICON_DIRECTIONS_CAR: 'icon-directions-car',
  ICON_DIRECTIONS_RAILWAY: 'icon-directions-railway',
  ICON_DIRECTIONS_RUN: 'icon-directions-run',
  ICON_DIRECTIONS_SUBWAY: 'icon-directions-subway',
  ICON_DIRECTIONS_TRANSIT: 'icon-directions-transit',
  ICON_DIRECTIONS_WALK: 'icon-directions-walk',
  ICON_DISC_FULL: 'icon-disc-full',
  ICON_DNS: 'icon-dns',
  ICON_DNS1: 'icon-dns1',
  ICON_DO_NOT_DISTURB: 'icon-do-not-disturb',
  ICON_DO_NOT_DISTURB_ALT: 'icon-do-not-disturb-alt',
  ICON_DO_NOT_DISTURB_OFF: 'icon-do-not-disturb-off',
  ICON_DOCK: 'icon-dock',
  ICON_DOMAIN: 'icon-domain',
  ICON_DOMAIN_FORWARD: 'icon-domain-forward',
  ICON_MANAGE_WEBSITE: 'icon-manage-website',
  ICON_MANAGE_MAIL: 'icon-manage-mail',
  ICON_MALWARE_SCANNER: 'icon-malware-scanner',
  ICON_DONE: 'icon-done',
  ICON_DONE_ALL: 'icon-done-all',
  ICON_DONUT_LARGE: 'icon-donut-large',
  ICON_DONUT_SMALL: 'icon-donut-small',
  ICON_DRAFTS: 'icon-drafts',
  ICON_DRAG_HANDLE: 'icon-drag-handle',
  ICON_DRIVE_ETA: 'icon-drive-eta',
  ICON_DVR: 'icon-dvr',
  ICON_EDIT: 'icon-edit',
  ICON_EDIT_LIGHT: 'icon-edit-light',
  ICON_EDIT_LOCATION: 'icon-edit-location',
  ICON_EJECT: 'icon-eject',
  ICON_EMAIL: 'icon-email',
  ICON_EMERGENCY: 'icon-emergency',
  ICON_ENERGY_SAVINGS: 'icon-energy-savings',
  ICON_ENHANCED_ENCRYPTION: 'icon-enhanced-encryption',
  ICON_ENVELOPE: 'icon-envelope',
  ICON_EQUALIZER: 'icon-equalizer',
  ICON_ERROR: 'icon-error',
  ICON_ERROR_OUTLINE_LIGHT: 'icon-error-outline-light',
  ICON_EURO_SYMBOL: 'icon-euro-symbol',
  ICON_EV_STATION: 'icon-ev-station',
  ICON_EVENT: 'icon-event',
  ICON_EVENT_AVAILABLE: 'icon-event-available',
  ICON_EVENT_BUSY: 'icon-event-busy',
  ICON_EVENT_NOTE: 'icon-event-note',
  ICON_EVENT_SEAT: 'icon-event-seat',
  ICON_EXIT_TO_APP: 'icon-exit-to-app',
  ICON_EXPAND_LESS: 'icon-expand-less',
  ICON_EXPAND_MORE: 'icon-expand-more',
  ICON_EXPLICIT: 'icon-explicit',
  ICON_EXPLORE: 'icon-explore',
  ICON_EXPOSURE: 'icon-exposure',
  ICON_EXPOSURE_NEG1: 'icon-exposure-neg1',
  ICON_EXPOSURE_NEG2: 'icon-exposure-neg2',
  ICON_EXPOSURE_PLUS1: 'icon-exposure-plus1',
  ICON_EXPOSURE_PLUS2: 'icon-exposure-plus2',
  ICON_EXPOSURE_ZERO: 'icon-exposure-zero',
  ICON_EXTENSION: 'icon-extension',
  ICON_FACE: 'icon-face',
  ICON_FAST_FORWARD: 'icon-fast-forward',
  ICON_FAST_REWIND: 'icon-fast-rewind',
  ICON_FAVORITE: 'icon-favorite',
  ICON_FAVORITE_BORDER: 'icon-favorite-border',
  ICON_FEATURED_PLAY_LIST: 'icon-featured-play-list',
  ICON_FEATURED_VIDEO: 'icon-featured-video',
  ICON_FEEDBACK: 'icon-feedback',
  ICON_FIBER_DVR: 'icon-fiber-dvr',
  ICON_FIBER_MANUAL_RECORD: 'icon-fiber-manual-record',
  ICON_FIBER_NEW: 'icon-fiber-new',
  ICON_FIBER_PIN: 'icon-fiber-pin',
  ICON_FIBER_SMART_RECORD: 'icon-fiber-smart-record',
  ICON_FILE_DOWNLOAD: 'icon-file-download',
  ICON_FILE_LIGHT: 'icon-file-light',
  ICON_FILE_UPLOAD: 'icon-file-upload',
  ICON_FILTER: 'icon-filter',
  ICON_FILTER1: 'icon-filter1',
  ICON_FILTER2: 'icon-filter2',
  ICON_FILTER3: 'icon-filter3',
  ICON_FILTER4: 'icon-filter4',
  ICON_FILTER5: 'icon-filter5',
  ICON_FILTER6: 'icon-filter6',
  ICON_FILTER7: 'icon-filter7',
  ICON_FILTER8: 'icon-filter8',
  ICON_FILTER9: 'icon-filter9',
  ICON_FILTER9_PLUS: 'icon-filter9-plus',
  ICON_FILTER_B_AND_W: 'icon-filter-b-and-w',
  ICON_FILTER_CENTER_FOCUS: 'icon-filter-center-focus',
  ICON_FILTER_DRAMA: 'icon-filter-drama',
  ICON_FILTER_FRAMES: 'icon-filter-frames',
  ICON_FILTER_HDR: 'icon-filter-hdr',
  ICON_FILTER_LIST: 'icon-filter-list',
  ICON_FILTER_NONE: 'icon-filter-none',
  ICON_FILTER_TILT_SHIFT: 'icon-filter-tilt-shift',
  ICON_FILTER_VINTAGE: 'icon-filter-vintage',
  ICON_FIND_IN_PAGE: 'icon-find-in-page',
  ICON_FIND_REPLACE: 'icon-find-replace',
  ICON_FINGERPRINT: 'icon-fingerprint',
  ICON_FIRST_PAGE: 'icon-first-page',
  ICON_FITNESS_CENTER: 'icon-fitness-center',
  ICON_FIVERR: 'icon-fiverr',
  ICON_FLAG: 'icon-flag',
  ICON_FLARE: 'icon-flare',
  ICON_FLASH_AUTO: 'icon-flash-auto',
  ICON_FLASH_OFF: 'icon-flash-off',
  ICON_FLASH_ON: 'icon-flash-on',
  ICON_FLIGHT: 'icon-flight',
  ICON_FLIGHT_LAND: 'icon-flight-land',
  ICON_FLIGHT_TAKEOFF: 'icon-flight-takeoff',
  ICON_FLIP: 'icon-flip',
  ICON_FLIP_TO_BACK: 'icon-flip-to-back',
  ICON_FLIP_TO_FRONT: 'icon-flip-to-front',
  ICON_FOLDER: 'icon-folder',
  ICON_FOLDER_OPEN: 'icon-folder-open',
  ICON_FOLDER_SHARED: 'icon-folder-shared',
  ICON_FOLDER_SPECIAL: 'icon-folder-special',
  ICON_FONT_DOWNLOAD: 'icon-font-download',
  ICON_FORMAT_ALIGN_CENTER: 'icon-format-align-center',
  ICON_FORMAT_ALIGN_JUSTIFY: 'icon-format-align-justify',
  ICON_FORMAT_ALIGN_LEFT: 'icon-format-align-left',
  ICON_FORMAT_ALIGN_RIGHT: 'icon-format-align-right',
  ICON_FORMAT_BOLD: 'icon-format-bold',
  ICON_FORMAT_CLEAR: 'icon-format-clear',
  ICON_FORMAT_COLOR_FILL: 'icon-format-color-fill',
  ICON_FORMAT_COLOR_RESET: 'icon-format-color-reset',
  ICON_FORMAT_COLOR_TEXT: 'icon-format-color-text',
  ICON_FORMAT_INDENT_DECREASE: 'icon-format-indent-decrease',
  ICON_FORMAT_INDENT_INCREASE: 'icon-format-indent-increase',
  ICON_FORMAT_ITALIC: 'icon-format-italic',
  ICON_FORMAT_LINE_SPACING: 'icon-format-line-spacing',
  ICON_FORMAT_LIST_BULLETED: 'icon-format-list-bulleted',
  ICON_FORMAT_LIST_NUMBERED: 'icon-format-list-numbered',
  ICON_FORMAT_PAINT: 'icon-format-paint',
  ICON_FORMAT_QUOTE: 'icon-format-quote',
  ICON_FORMAT_SHAPES: 'icon-format-shapes',
  ICON_FORMAT_SIZE: 'icon-format-size',
  ICON_FORMAT_STRIKETHROUGH: 'icon-format-strikethrough',
  ICON_FORMAT_TEXTDIRECTION_L_TO_R: 'icon-format-textdirection-l-to-r',
  ICON_FORMAT_TEXTDIRECTION_R_TO_L: 'icon-format-textdirection-r-to-l',
  ICON_FORMAT_UNDERLINED: 'icon-format-underlined',
  ICON_FORUM: 'icon-forum',
  ICON_FORWARD: 'icon-forward',
  ICON_FORWARD10: 'icon-forward10',
  ICON_FORWARD30: 'icon-forward30',
  ICON_FORWARD5: 'icon-forward5',
  ICON_FREE_BREAKFAST: 'icon-free-breakfast',
  ICON_FULLSCREEN: 'icon-fullscreen',
  ICON_FULLSCREEN_EXIT: 'icon-fullscreen-exit',
  ICON_FUNCTIONS: 'icon-functions',
  ICON_G_TRANSLATE: 'icon-g-translate',
  ICON_GAMEPAD: 'icon-gamepad',
  ICON_GAMES: 'icon-games',
  ICON_GAVEL: 'icon-gavel',
  ICON_GENERATE_PASSWORD: 'icon-generate-password',
  ICON_GESTURE: 'icon-gesture',
  ICON_GET_APP: 'icon-get-app',
  ICON_GIF: 'icon-gif',
  ICON_GIFT: 'icon-gift',
  ICON_GLOBE: 'icon-globe',
  ICON_GLOBE_PLUS: 'icon-globe-plus',
  ICON_GOLF_COURSE: 'icon-golf-course',
  ICON_GPS_FIXED: 'icon-gps-fixed',
  ICON_GPS_NOT_FIXED: 'icon-gps-not-fixed',
  ICON_GPS_OFF: 'icon-gps-off',
  ICON_GRADE: 'icon-grade',
  ICON_GRADIENT: 'icon-gradient',
  ICON_GRAIN: 'icon-grain',
  ICON_GRAPH: 'icon-graph',
  ICON_GRAPHEQ: 'icon-grapheq',
  ICON_GRID_OFF: 'icon-grid-off',
  ICON_GRID_ON: 'icon-grid-on',
  ICON_GROUP: 'icon-group',
  ICON_GROUP_LIGHT: 'icon-group-light',
  ICON_GROUP_ADD: 'icon-group-add',
  ICON_GROUP_WORK: 'icon-group-work',
  ICON_GSUITE: 'icon-gsuite',
  ICON_HD: 'icon-hd',
  ICON_HDR_OFF: 'icon-hdr-off',
  ICON_HDR_ON: 'icon-hdr-on',
  ICON_HDR_STRONG: 'icon-hdr-strong',
  ICON_HDR_WEAK: 'icon-hdr-weak',
  ICON_HEADSET: 'icon-headset',
  ICON_HEADSET_MIC: 'icon-headset-mic',
  ICON_HEALING: 'icon-healing',
  ICON_HEARING: 'icon-hearing',
  ICON_HELP: 'icon-help',
  ICON_HELP_OUTLINE: 'icon-help-outline',
  ICON_HIGH_QUALITY: 'icon-high-quality',
  ICON_HIGHLIGHT: 'icon-highlight',
  ICON_HIGHLIGHT_OFF: 'icon-highlight-off',
  ICON_HIGHLIGHT_OFF_OUTLINE: 'icon-highlight-off-outline',
  ICON_HISTORY: 'icon-history',
  ICON_HOME: 'icon-home',
  ICON_HOT_TUB: 'icon-hot-tub',
  ICON_HOTEL: 'icon-hotel',
  ICON_HOURGLASS_EMPTY: 'icon-hourglass-empty',
  ICON_HOURGLASS_FULL: 'icon-hourglass-full',
  ICON_HTTP: 'icon-http',
  ICON_IMAGE: 'icon-image',
  ICON_IMAGE_ASPECT_RATIO: 'icon-image-aspect-ratio',
  ICON_IMPORT_CONTACTS: 'icon-import-contacts',
  ICON_IMPORT_EXPORT: 'icon-import-export',
  ICON_IMPORTANT_DEVICES: 'icon-important-devices',
  ICON_INBOX: 'icon-inbox',
  ICON_INDETERMINATE_CHECK_BOX: 'icon-indeterminate-check-box',
  ICON_INFO: 'icon-info',
  ICON_INFO_LIGHT: 'icon-info-light',
  ICON_INFO_FILLED: 'icon-info-filled',
  ICON_INFO_OUTLINE: 'icon-info-outline',
  ICON_INFO_OUTLINED: 'icon-info-outlined',
  ICON_INPUT: 'icon-input',
  ICON_INSERT_CHART: 'icon-insert-chart',
  ICON_INSERT_COMMENT: 'icon-insert-comment',
  ICON_INSERT_DRIVE_FILE: 'icon-insert-drive-file',
  ICON_INSERT_EMOTICON: 'icon-insert-emoticon',
  ICON_INSERT_INVITATION: 'icon-insert-invitation',
  ICON_INSERT_LINK: 'icon-insert-link',
  ICON_INSERT_PHOTO: 'icon-insert-photo',
  ICON_INTERCOM: 'icon-intercom',
  ICON_INVERT_COLORS: 'icon-invert-colors',
  ICON_INVERT_COLORS_OFF: 'icon-invert-colors-off',
  ICON_ISO: 'icon-iso',
  ICON_KEY: 'icon-key',
  ICON_KEYBOARD: 'icon-keyboard',
  ICON_KEYBOARD_ARROW_DOWN: 'icon-keyboard-arrow-down',
  ICON_KEYBOARD_ARROW_LEFT: 'icon-keyboard-arrow-left',
  ICON_KEYBOARD_ARROW_RIGHT: 'icon-keyboard-arrow-right',
  ICON_KEYBOARD_ARROW_UP: 'icon-keyboard-arrow-up',
  ICON_KEYBOARD_BACKSPACE: 'icon-keyboard-backspace',
  ICON_KEYBOARD_CAPSLOCK: 'icon-keyboard-capslock',
  ICON_KEYBOARD_HIDE: 'icon-keyboard-hide',
  ICON_KEYBOARD_RETURN: 'icon-keyboard-return',
  ICON_KEYBOARD_TAB: 'icon-keyboard-tab',
  ICON_KEYBOARD_VOICE: 'icon-keyboard-voice',
  ICON_KITCHEN: 'icon-kitchen',
  ICON_KODEE: 'icon-kodee',
  ICON_LABEL: 'icon-label',
  ICON_LABEL_OUTLINE: 'icon-label-outline',
  ICON_LANDSCAPE: 'icon-landscape',
  ICON_LANGUAGE: 'icon-language',
  ICON_LANGUAGE_LIGHT: 'icon-language-light',
  ICON_LAPTOP_LIGHT: 'icon-laptop-light',
  ICON_LAPTOP_CHROMEBOOK: 'icon-laptop-chromebook',
  ICON_LAPTOP_MAC: 'icon-laptop-mac',
  ICON_LAPTOP_WINDOWS: 'icon-laptop-windows',
  ICON_LAST_PAGE: 'icon-last-page',
  ICON_LAUNCH: 'icon-launch',
  ICON_LAUNCH_V2: 'icon-launch-v2',
  ICON_LAUNCH_LIGHT: 'icon-launch-light',
  ICON_LAYERS: 'icon-layers',
  ICON_LAYERS_CLEAR: 'icon-layers-clear',
  ICON_LEAK_ADD: 'icon-leak-add',
  ICON_LEAK_REMOVE: 'icon-leak-remove',
  ICON_LENS: 'icon-lens',
  ICON_LIBRARY_ADD: 'icon-library-add',
  ICON_LIBRARY_BOOKS: 'icon-library-books',
  ICON_LIBRARY_MUSIC: 'icon-library-music',
  ICON_LICENSE: 'icon-license',
  ICON_LIGHTBULB_FILLED: 'icon-lightbulb-filled',
  ICON_LIGHTBULB_OUTLINE: 'icon-lightbulb-outline',
  ICON_LINE_STYLE: 'icon-line-style',
  ICON_LINE_WEIGHT: 'icon-line-weight',
  ICON_LINEAR_SCALE: 'icon-linear-scale',
  ICON_LINK: 'icon-link',
  ICON_LINKED_CAMERA: 'icon-linked-camera',
  ICON_LIST: 'icon-list',
  ICON_LITE_SPEED: 'icon-lite-speed',
  ICON_LIVE_HELP: 'icon-live-help',
  ICON_LIVE_TV: 'icon-live-tv',
  ICON_LOCAL_ACTIVITY: 'icon-local-activity',
  ICON_LOCAL_AIRPORT: 'icon-local-airport',
  ICON_LOCAL_ATM: 'icon-local-atm',
  ICON_LOCAL_BAR: 'icon-local-bar',
  ICON_LOCAL_CAFE: 'icon-local-cafe',
  ICON_LOCAL_CAR_WASH: 'icon-local-car-wash',
  ICON_LOCAL_CONVENIENCE_STORE: 'icon-local-convenience-store',
  ICON_LOCAL_DINING: 'icon-local-dining',
  ICON_LOCAL_DRINK: 'icon-local-drink',
  ICON_LOCAL_FLORIST: 'icon-local-florist',
  ICON_LOCAL_GAS_STATION: 'icon-local-gas-station',
  ICON_LOCAL_GROCERY_STORE: 'icon-local-grocery-store',
  ICON_LOCAL_HOSPITAL: 'icon-local-hospital',
  ICON_LOCAL_HOTEL: 'icon-local-hotel',
  ICON_LOCAL_LAUNDRY_SERVICE: 'icon-local-laundry-service',
  ICON_LOCAL_LIBRARY: 'icon-local-library',
  ICON_LOCAL_MALL: 'icon-local-mall',
  ICON_LOCAL_MOVIES: 'icon-local-movies',
  ICON_LOCAL_OFFER: 'icon-local-offer',
  ICON_LOCAL_PARKING: 'icon-local-parking',
  ICON_LOCAL_PHARMACY: 'icon-local-pharmacy',
  ICON_LOCAL_PHONE: 'icon-local-phone',
  ICON_LOCAL_PIZZA: 'icon-local-pizza',
  ICON_LOCAL_PLAY: 'icon-local-play',
  ICON_LOCAL_POST_OFFICE: 'icon-local-post-office',
  ICON_LOCAL_PRINTSHOP: 'icon-local-printshop',
  ICON_LOCAL_SEE: 'icon-local-see',
  ICON_LOCAL_SHIPPING: 'icon-local-shipping',
  ICON_LOCAL_TAXI: 'icon-local-taxi',
  ICON_LOCATION_CITY: 'icon-location-city',
  ICON_LOCATION_DISABLED: 'icon-location-disabled',
  ICON_LOCATION_OFF: 'icon-location-off',
  ICON_LOCATION_ON: 'icon-location-on',
  ICON_LOCATION_SEARCHING: 'icon-location-searching',
  ICON_LOCK_LOCKED: 'icon-lock-locked',
  ICON_LOCK_OPEN: 'icon-lock-open',
  ICON_LOCK_OUTLINE: 'icon-lock-outline',
  ICON_LOOKS: 'icon-looks',
  ICON_LOOKS3: 'icon-looks3',
  ICON_LOOKS4: 'icon-looks4',
  ICON_LOOKS5: 'icon-looks5',
  ICON_LOOKS6: 'icon-looks6',
  ICON_LOOKS_ONE: 'icon-looks-one',
  ICON_LOOKS_TWO: 'icon-looks-two',
  ICON_LOOP: 'icon-loop',
  ICON_LOUPE: 'icon-loupe',
  ICON_LOW_PRIORITY: 'icon-low-priority',
  ICON_LOYALTY: 'icon-loyalty',
  ICON_MAIL: 'icon-mail',
  ICON_MAIL_OUTLINE: 'icon-mail-outline',
  ICON_MAILS_STACKED: 'icon-mails-stacked',
  ICON_MAP: 'icon-map',
  ICON_MARKUNREAD: 'icon-markunread',
  ICON_MARKUNREAD_MAILBOX: 'icon-markunread-mailbox',
  ICON_MEMORY: 'icon-memory',
  ICON_MENU: 'icon-menu',
  ICON_MERGE_TYPE: 'icon-merge-type',
  ICON_MESSAGE: 'icon-message',
  ICON_MIC: 'icon-mic',
  ICON_MIGRATE: 'icon-migrate',
  ICON_MIGRATE_LIGHT: 'icon-migrate-light',
  ICON_MMS: 'icon-mms',
  ICON_MNONE: 'icon-mnone',
  ICON_MODE_COMMENT: 'icon-mode-comment',
  ICON_MODE_EDIT: 'icon-mode-edit',
  ICON_MOFF: 'icon-moff',
  ICON_MONEY_OFF: 'icon-money-off',
  ICON_MONOCHROME_PHOTOS: 'icon-monochrome-photos',
  ICON_MOOD: 'icon-mood',
  ICON_MOOD_BAD: 'icon-mood-bad',
  ICON_MORE: 'icon-more',
  ICON_MORE_HORIZ: 'icon-more-horiz',
  ICON_MORE_HORIZ_OVAL: 'icon-more-horiz-oval',
  ICON_MORE_VERT: 'icon-more-vert',
  ICON_MOTORCYCLE: 'icon-motorcycle',
  ICON_MOUSE: 'icon-mouse',
  ICON_MOVE_TO_INBOX: 'icon-move-to-inbox',
  ICON_MOVIE: 'icon-movie',
  ICON_MOVIE_CREATION: 'icon-movie-creation',
  ICON_MOVIE_FILTER: 'icon-movie-filter',
  ICON_MULTILINE_CHART: 'icon-multiline-chart',
  ICON_MUSNOTE: 'icon-musnote',
  ICON_MUSVIDEO: 'icon-musvideo',
  ICON_MY_LOCATION: 'icon-my-location',
  ICON_NATURE: 'icon-nature',
  ICON_NATURE_PEOPLE: 'icon-nature-people',
  ICON_NAVIGATE_BEFORE: 'icon-navigate-before',
  ICON_NAVIGATE_NEXT: 'icon-navigate-next',
  ICON_NAVIGATION: 'icon-navigation',
  ICON_NEAR_ME: 'icon-near-me',
  ICON_NETWORK_CELL: 'icon-network-cell',
  ICON_NETWORK_CHECK: 'icon-network-check',
  ICON_NETWORK_LOCKED: 'icon-network-locked',
  ICON_NETWORK_WIFI: 'icon-network-wifi',
  ICON_NEUTRAL: 'icon-neutral',
  ICON_NEW_RELEASES: 'icon-new-releases',
  ICON_NEXT_WEEK: 'icon-next-week',
  ICON_NFC: 'icon-nfc',
  ICON_NFS_SUPPORT: 'icon-nfs-support',
  ICON_NO_ENCRYPTION: 'icon-no-encryption',
  ICON_NO_SIM: 'icon-no-sim',
  ICON_FILE: 'icon-file',
  ICON_ZIP_FOLDER: 'icon-zip-folder',
  ICON_NOT_INTERESTED: 'icon-not-interested',
  ICON_NOTE: 'icon-note',
  ICON_NOTE_ADD: 'icon-note-add',
  ICON_NOTIFICATIONS: 'icon-notifications',
  ICON_NOTIFICATIONS_ACTIVE: 'icon-notifications-active',
  ICON_NOTIFICATIONS_NONE: 'icon-notifications-none',
  ICON_NOTIFICATIONS_OFF: 'icon-notifications-off',
  ICON_NOTIFICATIONS_PAUSED: 'icon-notifications-paused',
  ICON_OFFLINE_PIN: 'icon-offline-pin',
  ICON_ONDEMAND_VIDEO: 'icon-ondemand-video',
  ICON_OPACITY: 'icon-opacity',
  ICON_OPEN_IN_BROWSER: 'icon-open-in-browser',
  ICON_OPEN_IN_NEW: 'icon-open-in-new',
  ICON_OPEN_WITH: 'icon-open-with',
  ICON_OPEN_BOOK: 'icon-open-book',
  ICON_PAGES: 'icon-pages',
  ICON_PAGEVIEW: 'icon-pageview',
  ICON_PALETTE: 'icon-palette',
  ICON_PAN_TOOL: 'icon-pan-tool',
  ICON_PANORAMA: 'icon-panorama',
  ICON_PANORAMA_FISH_EYE: 'icon-panorama-fish-eye',
  ICON_PANORAMA_HORIZONTAL: 'icon-panorama-horizontal',
  ICON_PANORAMA_VERTICAL: 'icon-panorama-vertical',
  ICON_PANORAMA_WIDE_ANGLE: 'icon-panorama-wide-angle',
  ICON_PARTY_MODE: 'icon-party-mode',
  ICON_PAUSE: 'icon-pause',
  ICON_PAUSE_CIRCLE_FILLED: 'icon-pause-circle-filled',
  ICON_PAUSE_CIRCLE_FILLED_LIGHT: 'icon-pause-circle-filled-light',
  ICON_PAUSE_CIRCLE_OUTLINE: 'icon-pause-circle-outline',
  ICON_PAYMENT: 'icon-payment',
  ICON_PAYMENT2: 'icon-payment2',
  ICON_PAYMENT_SOLID: 'icon-payment-solid',
  ICON_PEOPLE: 'icon-people',
  ICON_PEOPLE_OUTLINE: 'icon-people-outline',
  ICON_PERM_CAMERA_MIC: 'icon-perm-camera-mic',
  ICON_PERM_CONTACT_CALENDAR: 'icon-perm-contact-calendar',
  ICON_PERM_DATA_SETTING: 'icon-perm-data-setting',
  ICON_PERM_DEVICE_INFORMATION: 'icon-perm-device-information',
  ICON_PERM_IDENTITY: 'icon-perm-identity',
  ICON_PERM_MEDIA: 'icon-perm-media',
  ICON_PERM_PHONE_MSG: 'icon-perm-phone-msg',
  ICON_PERM_SCAN_WIFI: 'icon-perm-scan-wifi',
  ICON_PERSON: 'icon-person',
  ICON_PERSON_LIGHT: 'icon-person-light',
  ICON_PERSON_ADD: 'icon-person-add',
  ICON_PERSON_OUTLINE: 'icon-person-outline',
  ICON_PERSON_PIN_FIXED_: 'icon-person-pin-fixed_',
  ICON_PERSON_PIN: 'icon-person-pin',
  ICON_PERSON_PIN_CIRCLE: 'icon-person-pin-circle',
  ICON_PERSONAL_VIDEO: 'icon-personal-video',
  ICON_PETS: 'icon-pets',
  ICON_PHONE: 'icon-phone',
  ICON_PHONE_ANDROID: 'icon-phone-android',
  ICON_PHONE_BLUETOOTH_SPEAKER: 'icon-phone-bluetooth-speaker',
  ICON_PHONE_FORWARDED: 'icon-phone-forwarded',
  ICON_PHONE_IN_TALK: 'icon-phone-in-talk',
  ICON_PHONE_IPHONE: 'icon-phone-iphone',
  ICON_PHONE_LOCKED: 'icon-phone-locked',
  ICON_PHONE_MISSED: 'icon-phone-missed',
  ICON_PHONE_PAUSED: 'icon-phone-paused',
  ICON_PHONELINK: 'icon-phonelink',
  ICON_PHONELINK_ERASE: 'icon-phonelink-erase',
  ICON_PHONELINK_LOCK: 'icon-phonelink-lock',
  ICON_PHONELINK_OFF: 'icon-phonelink-off',
  ICON_PHONELINK_RING: 'icon-phonelink-ring',
  ICON_PHONELINK_SETUP: 'icon-phonelink-setup',
  ICON_PHOTO: 'icon-photo',
  ICON_PHOTO_ALBUM: 'icon-photo-album',
  ICON_PHOTO_CAMERA: 'icon-photo-camera',
  ICON_PHOTO_FILTER: 'icon-photo-filter',
  ICON_PHOTO_LIBRARY: 'icon-photo-library',
  ICON_PHOTO_SIZE_SELECT_ACTUAL: 'icon-photo-size-select-actual',
  ICON_PHOTO_SIZE_SELECT_LARGE: 'icon-photo-size-select-large',
  ICON_PHOTO_SIZE_SELECT_SMALL: 'icon-photo-size-select-small',
  ICON_PICTURE_AS_PDF: 'icon-picture-as-pdf',
  ICON_PICTURE_IN_PICTURE: 'icon-picture-in-picture',
  ICON_PICTURE_IN_PICTURE_ALT: 'icon-picture-in-picture-alt',
  ICON_PIE_CHART: 'icon-pie-chart',
  ICON_PIE_CHART_OUTLINED: 'icon-pie-chart-outlined',
  ICON_PIN_DROP: 'icon-pin-drop',
  ICON_PLACE: 'icon-place',
  ICON_PLAY_ARROW: 'icon-play-arrow',
  ICON_PLAY_CIRCLE_FILLED: 'icon-play-circle-filled',
  ICON_PLAY_CIRCLE_OUTLINE: 'icon-play-circle-outline',
  ICON_PLAY_FOR_WORK: 'icon-play-for-work',
  ICON_PLAYLIST_ADD: 'icon-playlist-add',
  ICON_PLAYLIST_ADD_CHECK: 'icon-playlist-add-check',
  ICON_PLAYLIST_PLAY: 'icon-playlist-play',
  ICON_PLESK: 'icon-plesk',
  ICON_PLUGINS_LIGHT: 'icon-plugins-light',
  ICON_PLUS_ONE: 'icon-plus-one',
  ICON_PLUS: 'icon-plus',
  ICON_PLUS_CIRCLE_GREEN: 'icon-plus-circle-green',
  ICON_POLL: 'icon-poll',
  ICON_POLYMER: 'icon-polymer',
  ICON_POOL: 'icon-pool',
  ICON_PORTABLE_WIFI_OFF: 'icon-portable-wifi-off',
  ICON_PORTRAIT: 'icon-portrait',
  ICON_POWER: 'icon-power',
  ICON_POWER_INPUT: 'icon-power-input',
  ICON_POWER_SETTINGS_NEW: 'icon-power-settings-new',
  ICON_PREGNANT_WOMAN: 'icon-pregnant-woman',
  ICON_PRESENT_TO_ALL: 'icon-present-to-all',
  ICON_PRINT: 'icon-print',
  ICON_PRIORITY_HIGH: 'icon-priority-high',
  ICON_PUBLIC: 'icon-public',
  ICON_PUBLISH: 'icon-publish',
  ICON_QUERY_BUILDER: 'icon-query-builder',
  ICON_QUESTION_ANSWER: 'icon-question-answer',
  ICON_QUEUE: 'icon-queue',
  ICON_QUEUE_MUSIC: 'icon-queue-music',
  ICON_QUEUE_PLAY_NEXT: 'icon-queue-play-next',
  ICON_RADIO: 'icon-radio',
  ICON_RADIO_BUTTON_CHECKED: 'icon-radio-button-checked',
  ICON_RADIO_BUTTON_UNCHECKED: 'icon-radio-button-unchecked',
  ICON_RATE_REVIEW: 'icon-rate-review',
  ICON_RECENT_ACTORS: 'icon-recent-actors',
  ICON_RECORD_VOICE_OVER: 'icon-record-voice-over',
  ICON_RECOVERY: 'icon-recovery',
  ICON_REDEEM: 'icon-redeem',
  ICON_REDO: 'icon-redo',
  ICON_REFRESH: 'icon-refresh',
  ICON_RESTART: 'icon-restart',
  ICON_REMOVE: 'icon-remove',
  ICON_REMOVE_CIRCLE: 'icon-remove-circle',
  ICON_REMOVE_CIRCLE_OUTLINE: 'icon-remove-circle-outline',
  ICON_REMOVE_FROM_QUEUE: 'icon-remove-from-queue',
  ICON_REMOVE_RED_EYE: 'icon-remove-red-eye',
  ICON_REMOVE_SHOPPING_CART: 'icon-remove-shopping-cart',
  ICON_REORDER: 'icon-reorder',
  ICON_REPEAT: 'icon-repeat',
  ICON_REPEAT_ONE: 'icon-repeat-one',
  ICON_REPLAY: 'icon-replay',
  ICON_REPLAY10: 'icon-replay10',
  ICON_REPLAY30: 'icon-replay30',
  ICON_REPLAY5: 'icon-replay5',
  ICON_REPLY: 'icon-reply',
  ICON_REPLY_ALL: 'icon-reply-all',
  ICON_REPORT: 'icon-report',
  ICON_REPORT_PROBLEM: 'icon-report-problem',
  ICON_RESTAURANT: 'icon-restaurant',
  ICON_RESTAURANT_MENU: 'icon-restaurant-menu',
  ICON_RESTORE: 'icon-restore',
  ICON_RESTORE_PAGE: 'icon-restore-page',
  ICON_RING_VOLUME: 'icon-ring-volume',
  ICON_ROOM: 'icon-room',
  ICON_ROOM_SERVICE: 'icon-room-service',
  ICON_ROTATE90_DEGREES_CCW: 'icon-rotate90-degrees-ccw',
  ICON_ROTATE_LEFT: 'icon-rotate-left',
  ICON_ROTATE_RIGHT: 'icon-rotate-right',
  ICON_ROUNDED_CORNER: 'icon-rounded-corner',
  ICON_ROUTER: 'icon-router',
  ICON_ROWING: 'icon-rowing',
  ICON_RSS_FEED: 'icon-rss-feed',
  ICON_RV_HOOKUP: 'icon-rv-hookup',
  ICON_SATELLITE: 'icon-satellite',
  ICON_SAVE: 'icon-save',
  ICON_SCANNER: 'icon-scanner',
  ICON_SCHEDULE: 'icon-schedule',
  ICON_SCHOOL: 'icon-school',
  ICON_SCREEN_LOCK_LANDSCAPE: 'icon-screen-lock-landscape',
  ICON_SCREEN_LOCK_PORTRAIT: 'icon-screen-lock-portrait',
  ICON_SCREEN_LOCK_ROTATION: 'icon-screen-lock-rotation',
  ICON_SCREEN_ROTATION: 'icon-screen-rotation',
  ICON_SCREEN_SHARE: 'icon-screen-share',
  ICON_SD_CARD: 'icon-sd-card',
  ICON_SD_STORAGE: 'icon-sd-storage',
  ICON_SEARCH: 'icon-search',
  ICON_SECURITY: 'icon-security',
  ICON_SELECT_ALL: 'icon-select-all',
  ICON_SEND: 'icon-send',
  ICON_SENTIMENT_DISSATISFIED_FIXED_: 'icon-sentiment-dissatisfied-fixed_',
  ICON_SENTIMENT_NEUTRAL: 'icon-sentiment-neutral',
  ICON_SENTIMENT_SATISFIED_FIXED_: 'icon-sentiment-satisfied-fixed_',
  ICON_SENTIMENT_VERY_DISSATISFIED_FIXED_:
    'icon-sentiment-very-dissatisfied-fixed_',
  ICON_SENTIMENT_VERY_SATISFIED_FIXED_: 'icon-sentiment-very-satisfied-fixed_',
  ICON_SERVER: 'icon-server',
  ICON_SERVERS: 'icon-servers',
  ICON_SERVER_LIGHT: 'icon-server-light',
  ICON_SETTINGS: 'icon-settings',
  ICON_SETTINGS_APPLICATIONS: 'icon-settings-applications',
  ICON_SETTINGS_BACKUP_RESTORE: 'icon-settings-backup-restore',
  ICON_SETTINGS_BLUETOOTH: 'icon-settings-bluetooth',
  ICON_SETTINGS_BRIGHTNESS: 'icon-settings-brightness',
  ICON_SETTINGS_CELL: 'icon-settings-cell',
  ICON_SETTINGS_ETHERNET: 'icon-settings-ethernet',
  ICON_SETTINGS_INPUT_ANTENNA: 'icon-settings-input-antenna',
  ICON_SETTINGS_INPUT_COMPONENT: 'icon-settings-input-component',
  ICON_SETTINGS_INPUT_COMPOSITE: 'icon-settings-input-composite',
  ICON_SETTINGS_INPUT_HDMI: 'icon-settings-input-hdmi',
  ICON_SETTINGS_INPUT_SVIDEO: 'icon-settings-input-svideo',
  ICON_SETTINGS_OVERSCAN: 'icon-settings-overscan',
  ICON_SETTINGS_PHONE: 'icon-settings-phone',
  ICON_SETTINGS_POWER: 'icon-settings-power',
  ICON_SETTINGS_REMOTE: 'icon-settings-remote',
  ICON_SETTINGS_SYSTEM_DAYDREAM: 'icon-settings-system-daydream',
  ICON_SETTINGS_VOICE: 'icon-settings-voice',
  ICON_SHARE: 'icon-share',
  ICON_SHIELD_OUTLINE: 'icon-shield-outline',
  ICON_SHOP: 'icon-shop',
  ICON_SHOP_TWO: 'icon-shop-two',
  ICON_SHOPPING_BASKET: 'icon-shopping-basket',
  ICON_SHOPPING_CART: 'icon-shopping-cart',
  ICON_SHORT_TEXT: 'icon-short-text',
  ICON_SHUFFLE: 'icon-shuffle',
  ICON_SIGNAL_CELLULAR4_BAR: 'icon-signal-cellular4-bar',
  ICON_SIGNAL_CELLULAR_CONNECTED_NO_INTERNET4_BAR:
    'icon-signal-cellular-connected-no-internet4-bar',
  ICON_SIGNAL_CELLULAR_NO_SIM: 'icon-signal-cellular-no-sim',
  ICON_SIGNAL_CELLULAR_NULL: 'icon-signal-cellular-null',
  ICON_SIGNAL_CELLULAR_OFF: 'icon-signal-cellular-off',
  ICON_SIGNAL_WIFI4_BAR: 'icon-signal-wifi4-bar',
  ICON_SIGNAL_WIFI4_BAR_LOCK: 'icon-signal-wifi4-bar-lock',
  ICON_SIGNAL_WIFI_OFF: 'icon-signal-wifi-off',
  ICON_SIM_CARD: 'icon-sim-card',
  ICON_SIM_CARD_ALERT: 'icon-sim-card-alert',
  ICON_SKIP_NEXT: 'icon-skip-next',
  ICON_SKIP_PREVIOUS: 'icon-skip-previous',
  ICON_SLIDESHOW: 'icon-slideshow',
  ICON_SLOW_MOTION_VIDEO: 'icon-slow-motion-video',
  ICON_SMARTPHONE: 'icon-smartphone',
  ICON_SMOKE_FREE: 'icon-smoke-free',
  ICON_SMOKING_ROOMS: 'icon-smoking-rooms',
  ICON_SMS: 'icon-sms',
  ICON_SMS_FAILED: 'icon-sms-failed',
  ICON_SNOOZE: 'icon-snooze',
  ICON_SOCIAL_FACEBOOK: 'icon-social-facebook',
  ICON_SOCIAL_YOUTUBE_FILLED: 'icon-social-youtube-filled',
  ICON_SOCIAL_INSTAGRAM: 'icon-social-instagram',
  ICON_SOCIAL_LINKEDIN: 'icon-social-linkedin',
  ICON_SOCIAL_PINTEREST: 'icon-social-pinterest',
  ICON_SOCIAL_TIKTOK: 'icon-social-tiktok',
  ICON_SOCIAL_TWITTER: 'icon-social-twitter',
  ICON_SOCIAL_WHATSAPP: 'icon-social-whatsapp',
  ICON_SORT: 'icon-sort',
  ICON_SORT_BY_ALPHA: 'icon-sort-by-alpha',
  ICON_SPA: 'icon-spa',
  ICON_SPACE_BAR: 'icon-space-bar',
  ICON_SPEAKER: 'icon-speaker',
  ICON_SPEAKER_GROUP: 'icon-speaker-group',
  ICON_SPEAKER_NOTES: 'icon-speaker-notes',
  ICON_SPEAKER_NOTES_OFF: 'icon-speaker-notes-off',
  ICON_SPEAKER_PHONE: 'icon-speaker-phone',
  ICON_SPEED: 'icon-speed',
  ICON_SPELLCHECK: 'icon-spellcheck',
  ICON_STAR: 'icon-star',
  ICON_STAR_LIGHT: 'icon-star-light',
  ICON_STAR_BORDER: 'icon-star-border',
  ICON_STAR_HALF: 'icon-star-half',
  ICON_STARS: 'icon-stars',
  ICON_STARS_CIRCLE: 'icon-stars-circle',
  ICON_STAY_CURRENT_LANDSCAPE: 'icon-stay-current-landscape',
  ICON_STAY_CURRENT_PORTRAIT: 'icon-stay-current-portrait',
  ICON_STAY_PRIMARY_LANDSCAPE: 'icon-stay-primary-landscape',
  ICON_STAY_PRIMARY_PORTRAIT: 'icon-stay-primary-portrait',
  ICON_STOP: 'icon-stop',
  ICON_STOP_SCREEN_SHARE: 'icon-stop-screen-share',
  ICON_STORAGE: 'icon-storage',
  ICON_STORE_MALL_DIRECTORY: 'icon-store-mall-directory',
  ICON_STRAIGHTEN: 'icon-straighten',
  ICON_STREETVIEW: 'icon-streetview',
  ICON_STRIKETHROUGH_S: 'icon-strikethrough-s',
  ICON_STYLE: 'icon-style',
  ICON_SUBDIRECTORY_ARROW_LEFT: 'icon-subdirectory-arrow-left',
  ICON_SUBDIRECTORY_ARROW_RIGHT: 'icon-subdirectory-arrow-right',
  ICON_SUBJECT: 'icon-subject',
  ICON_SUBSCRIPTIONS: 'icon-subscriptions',
  ICON_SUBTITLES: 'icon-subtitles',
  ICON_SUBWAY: 'icon-subway',
  ICON_SUPERVISOR_ACCOUNT: 'icon-supervisor-account',
  ICON_SUPERVISOR_ACCOUNT_CIRCLE: 'icon-supervisor-account-circle',
  ICON_SURROUND_SOUND: 'icon-surround-sound',
  ICON_SWAP_CALLS: 'icon-swap-calls',
  ICON_SWAP_HORIZ: 'icon-swap-horiz',
  ICON_SWAP_VERT: 'icon-swap-vert',
  ICON_SWAP_VERTICAL_CIRCLE: 'icon-swap-vertical-circle',
  ICON_SWITCH_CAMERA: 'icon-switch-camera',
  ICON_SWITCH_VIDEO: 'icon-switch-video',
  ICON_SYNC: 'icon-sync',
  ICON_SYNC_DISABLED: 'icon-sync-disabled',
  ICON_SYNC_PROBLEM: 'icon-sync-problem',
  ICON_SYSTEM_UPDATE: 'icon-system-update',
  ICON_SYSTEM_UPDATE_ALT: 'icon-system-update-alt',
  ICON_TAB: 'icon-tab',
  ICON_TAB_UNSELECTED: 'icon-tab-unselected',
  ICON_TABLET: 'icon-tablet',
  ICON_TABLET_MAC: 'icon-tablet-mac',
  ICON_TAG_FACES: 'icon-tag-faces',
  ICON_TAP_AND_PLAY: 'icon-tap-and-play',
  ICON_TERRAIN: 'icon-terrain',
  ICON_TERMINAL: 'icon-terminal',
  ICON_TEXT_FIELDS: 'icon-text-fields',
  ICON_TEXT_FORMAT: 'icon-text-format',
  ICON_TEXTSMS: 'icon-textsms',
  ICON_TEXTURE: 'icon-texture',
  ICON_THEATERS: 'icon-theaters',
  ICON_THUMB_DOWN: 'icon-thumb-down',
  ICON_THUMB_UP: 'icon-thumb-up',
  ICON_THUMBS_UP_DOWN: 'icon-thumbs-up-down',
  ICON_TIME_TO_LEAVE: 'icon-time-to-leave',
  ICON_TIMELAPSE: 'icon-timelapse',
  ICON_TIMELINE: 'icon-timeline',
  ICON_TIMER: 'icon-timer',
  ICON_TIMER10: 'icon-timer10',
  ICON_TIMER3: 'icon-timer3',
  ICON_TIMER_OFF: 'icon-timer-off',
  ICON_TITLE: 'icon-title',
  ICON_TOC: 'icon-toc',
  ICON_TODAY: 'icon-today',
  ICON_TOGGLE_OFF24PX1: 'icon-toggle-off24px1',
  ICON_TOGGLE_ON24PX1: 'icon-toggle-on24px1',
  ICON_TOLL: 'icon-toll',
  ICON_TONALITY: 'icon-tonality',
  ICON_HERO: 'icon-hero',
  ICON_TOUCH_APP: 'icon-touch-app',
  ICON_TOYS: 'icon-toys',
  ICON_TRACK_CHANGES: 'icon-track-changes',
  ICON_TRAFFIC: 'icon-traffic',
  ICON_TRAIN: 'icon-train',
  ICON_TRAM: 'icon-tram',
  ICON_TRANSFER: 'icon-transfer',
  ICON_TRANSFER_WITHIN_A_STATION: 'icon-transfer-within-a-station',
  ICON_TRANSFORM: 'icon-transform',
  ICON_TRANSLATE: 'icon-translate',
  ICON_TRASH_BIN: 'icon-trash-bin',
  ICON_BIN: 'icon-bin',
  ICON_TRENDING_DOWN: 'icon-trending-down',
  ICON_TRENDING_FLAT: 'icon-trending-flat',
  ICON_TRENDING_UP: 'icon-trending-up',
  ICON_TUNE: 'icon-tune',
  ICON_TUN_TAP_ADAPTER: 'icon-tun-tap-adapter',
  ICON_TURNED_IN: 'icon-turned-in',
  ICON_TURNED_IN_NOT: 'icon-turned-in-not',
  ICON_TV: 'icon-tv',
  ICON_UNARCHIVE: 'icon-unarchive',
  ICON_UNDO: 'icon-undo',
  ICON_UPDATE: 'icon-update',
  ICON_UPDATE_LIGHT: 'icon-update-light',
  ICON_UPGRADE: 'icon-upgrade',
  ICON_UPI_PAYMENT: 'icon-upi-payment',
  ICON_USB: 'icon-usb',
  ICON_SERVER_USAGE: 'icon-server-usage',
  ICON_VERIFIED_USER: 'icon-verified-user',
  ICON_VERTICAL_ALIGN_BOTTOM: 'icon-vertical-align-bottom',
  ICON_VERTICAL_ALIGN_CENTER: 'icon-vertical-align-center',
  ICON_VERTICAL_ALIGN_TOP: 'icon-vertical-align-top',
  ICON_VIBRATION: 'icon-vibration',
  ICON_VIDEO_CALL: 'icon-video-call',
  ICON_VIDEO_LABEL: 'icon-video-label',
  ICON_VIDEO_LIBRARY: 'icon-video-library',
  ICON_VIDEOCAM: 'icon-videocam',
  ICON_VIDEOCAM_OFF: 'icon-videocam-off',
  ICON_VIDEOGAME_ASSET: 'icon-videogame-asset',
  ICON_VIEW_AGENDA: 'icon-view-agenda',
  ICON_VIEW_ARRAY: 'icon-view-array',
  ICON_VIEW_CAROUSEL: 'icon-view-carousel',
  ICON_VIEW_COLUMN: 'icon-view-column',
  ICON_VIEW_COMFY: 'icon-view-comfy',
  ICON_VIEW_COMPACT: 'icon-view-compact',
  ICON_VIEW_DAY: 'icon-view-day',
  ICON_VIEW_HEADLINE: 'icon-view-headline',
  ICON_VIEW_LIST: 'icon-view-list',
  ICON_VIEW_MODULE: 'icon-view-module',
  ICON_VIEW_QUILT: 'icon-view-quilt',
  ICON_VIEW_STREAM: 'icon-view-stream',
  ICON_VIEW_WEEK: 'icon-view-week',
  ICON_VIGNETTE: 'icon-vignette',
  ICON_VISIBILITY: 'icon-visibility',
  ICON_VISIBILITY_OFF: 'icon-visibility-off',
  ICON_VOICE_CHAT: 'icon-voice-chat',
  ICON_VOICEMAIL: 'icon-voicemail',
  ICON_VOLUME_DOWN: 'icon-volume-down',
  ICON_VOLUME_MUTE: 'icon-volume-mute',
  ICON_VOLUME_OFF: 'icon-volume-off',
  ICON_VOLUME_UP: 'icon-volume-up',
  ICON_VPN_KEY: 'icon-vpn-key',
  ICON_VPN_LOCK: 'icon-vpn-lock',
  ICON_WALLPAPER: 'icon-wallpaper',
  ICON_WARNING: 'icon-warning',
  ICON_WARNING_CIRCLE_FILLED: 'icon-warning-circle-filled',
  ICON_CHECKMARK_CIRCLE_FILLED_16: 'ic-checkmark-circle-filled-16',
  ICON_CHECKMARK_CIRCLE_FILLED_24: 'ic-checkmark-circle-filled-24',
  ICON_WARNING_TRIANGLE: 'icon-warning-triangle',
  ICON_WARNING_LIGHT: 'icon-warning-light',
  ICON_WATCH: 'icon-watch',
  ICON_WATCH_LATER: 'icon-watch-later',
  ICON_WB_AUTO: 'icon-wb-auto',
  ICON_WB_CLOUDY: 'icon-wb-cloudy',
  ICON_WB_INCANDESCENT: 'icon-wb-incandescent',
  ICON_WB_IRIDESCENT: 'icon-wb-iridescent',
  ICON_WB_SUNNY: 'icon-wb-sunny',
  ICON_WC: 'icon-wc',
  ICON_WEB: 'icon-web',
  ICON_WEB_ASSET: 'icon-web-asset',
  ICON_WEBSITE: 'icon-website',
  ICON_BUILDER: 'icon-builder',
  ICON_WEBUZO: 'icon-webuzo',
  ICON_WEEKEND: 'icon-weekend',
  ICON_WHATSHOT: 'icon-whatshot',
  ICON_WIDGETS: 'icon-widgets',
  ICON_WIFI: 'icon-wifi',
  ICON_WIFI_LOCK: 'icon-wifi-lock',
  ICON_WIFI_TETHERING: 'icon-wifi-tethering',
  ICON_WORDPRESS: 'icon-wordpress',
  ICON_WORK: 'icon-work',
  ICON_WRAP_TEXT: 'icon-wrap-text',
  ICON_YOUTUBE_SEARCHED_FOR: 'icon-youtube-searched-for',
  ICON_ZOOM_IN: 'icon-zoom-in',
  ICON_ZOOM_OUT: 'icon-zoom-out',
  ICON_ZOOM_OUT_MAP: 'icon-zoom-out-map',
  ICON_SAVINGS: 'icon-savings',
  ICON_CHAT_OUTLINE: 'icon-chat-outline',
  ICON_TRANSFER_REQUIREMENTS: 'icon-transfer-requirements',
  ICON_DOMAIN_UNLOCKED: 'icon-domain-unlocked',
  ICON_AUTH_CODE: 'icon-auth-code',
  ICON_ERROR_LIGHT: 'icon-error-light',
  ICON_ADD_LIGHT: 'icon-add-light',
  ICON_VPS: 'icon-vps',
  ICON_VPS_RESOURCE: 'icon-vps-resource',
  ICON_DOMAIN_RESOURCE: 'icon-domain-resource',
  ICON_HANDYMAN: 'icon-handyman',
  ICON_HOSTING_RESOURCE: 'icon-hosting-resource',
  ICON_EMAIL_RESOURCE: 'icon-email-resource',
  ICON_GOOGLE_RESOURCE: 'icon-google-resource',
  ICON_TITAN_RESOURCE: 'icon-titan-resource',
  ICON_ROCKET_STATIC: 'icon-rocket-static',
  ICON_CARDS_LIGHT: 'icon-cards-light',
  ICON_LIST_LIGHT_V2: 'icon-list-light-v2',
  ICON_USER_SUPERVISOR_ACCOUNT_LIGHT: 'icon-user-supervisor-account-light',
  ICON_MONETIZATION_ON_LIGHT: 'icon-monetization-on-light',
  ICON_BILL_PAY_LIGHT: 'icon-bill-pay-light',
  ICON_30_DAYS_GUARANTEE: 'icon-30-days-guarantee',
  ICON_CLOSE_SMALL: 'icon-close-small',
  ICON_CONTEXT_MENU_VERTICAL: 'icon-context-menu-vertical',
  ICON_ARROW_FORWARD_SKELETON: 'icon-arrow-forward-skeleton',
  ICON_ARROW_BACK_SKELETON: 'icon-arrow-back-skeleton',
  ICON_REFRESH_SKELETON: 'icon-refresh-skeleton',
  ICON_DATA_USAGE_LIGHT: 'icon-data-usage-light',
  IC_INFO_CONTAINED: 'ic-info-contained',
  ICON_HELP_FILLED: 'icon-help-filled',
  IC_NAVIGATE_LEFT: 'ic-navigate-left',
  IC_RIGHT_ANGLE_BRACKET: 'ic-right-angle-bracket',
  ICON_ACCOUNT_SHARING_LIGHT: 'icon-account-sharing-light',
  ICON_BUILDER_V1: 'icon-builder-v1',
  ICON_WORDPRESS_V1: 'icon-wordpress-v1',
  ICON_WEBSITE_V1: 'icon-website-v1',
  ICON_PHP_UPDATE: 'icon-php-update',
  ICON_TICKET: 'icon-ticket',
  ICON_CHEVRON_SMALL_UP: 'icon-chevron-small-up',
  ICON_TWO_EMAILS_LIGHT: 'icon-two-emails-light',
  ICON_CALENDAR_FILLED: 'icon-calendar-filled',
  ICON_CALENDAR_EXPIRED: 'icon-calendar-expired',
  ICON_CROSS: 'icon-cross',
  ICON_MAGNIFYING_GLASS: 'icon-magnifying-glass',
  ICON_ELLIPSE: 'icon-ellipse',
  ICON_HELP_LIGHT: 'icon-help-light',
  ICON_SHIELD_CHECKMARK: 'icon-shield-checkmark',
  ICON_USER_DOUBLE: 'icon-user-double',
  ICON_USER_INFO_RECTANGLE: 'icon-user-info-rectangle',
  ICON_ARROWS_RIGHT_LEFT: 'icon-arrows-right-left',
  ICON_DOLLAR_SIGN_SACK: 'icon-dollar-sign-sack',
  ICON_QUESTION_MARK_CIRCLE: 'icon-question-mark-circle',
  ICON_USER: 'icon-user',
  ICON_SYMBOL_LETTER: 'icon-symbol-letter',
  IC_BLOCKS_16: 'icon-ic-blocks-16',
  IC_CHEVRON_SMALL_DOWN_16: 'icon-ic-chevron-small-down-16',
  IC_CHEVRON_SMALL_UP_16: 'icon-ic-chevron-small-up-16',
  IC_CHEVRON_SMALL_LEFT_16: 'icon-ic-chevron-small-left-16',
  IC_CHEVRON_SMALL_RIGHT_16: 'icon-ic-chevron-small-right-16',
  IC_CHEVRON_DOWN_FILLED_16: 'icon-ic-chevron-down-filled-16',
  IC_CHEVRON_LEFT_FILLED_16: 'icon-ic-chevron-left-filled-16',
  IC_CHEVRON_RIGHT_FILLED_16: 'icon-ic-chevron-right-filled-16',
  IC_CHEVRON_UP_FILLED_16: 'icon-ic-chevron-up-filled-16',
  IC_SPARKLES_16: 'icon-ic-sparkles-16',
  IC_GEAR_16: 'icon-ic-gear-16',
  IC_MONITOR: 'icon-ic-monitor',
  IC_MONITOR_16: 'icon-ic-monitor-16',
  IC_CIRCLE_HALF_ARROW_LEFT_16: 'icon-ic-circle-half-arrow-left-16',
  IC_SHIELD_CHECKMARK_16: 'icon-ic-shield-checkmark-16',
  IC_DNS_16: 'icon-ic-dns-16',
  IC_BOOK_OPEN_16: 'icon-ic-book-open-16',
  IC_BOOK_OPEN: 'icon-ic-book-open',
  IC_SPEECH_BUBBLE_16: 'icon-ic-speech-bubble-16',
  IC_USER_16: 'icon-ic-user16',
  IC_FOLDER_16: 'icon-ic-folder-16',
  IC_USER_DOUBLE_16: 'icon-ic-user-double16',
  IC_CDN_16: 'icon-ic-cdn-16',
  IC_CIRCLE_CUT_OUT: 'icon-ic-circle-cut-out-16',
  IC_USER_INFO_RECTANGLE_16: 'icon-ic-user-info-rectangle16',
  IC_DOLLAR_SIGN_SACK_16: 'icon-ic-dollar-sign-sack16',
  ICON_BILLING_16: 'icon-billing-16',
  ICON_BLOCKS_16: 'icon-blocks-16',
  ICON_BLOCKS_PLUS_16: 'icon-blocks-plus-16',
  ICON_BROWSER_WINDOW_16: 'icon-browser-window-16',
  ICON_DATABASE_16: 'icon-database-16',
  ICON_ENVELOPE_16: 'icon-envelope-16',
  ICON_HOME_16: 'icon-home-16',
  ICON_LOCK_KEYHOLE_16: 'icon-lock-keyhole-16',
  ICON_UNLOCK_KEYHOLE_16: 'icon-unlock-keyhole-16',
  ICON_SERVERS_16: 'icon-servers-16',
  ICON_ARROWS_CIRCLE_16: 'icon-arrows-circle-16',
  ICON_ARROWS_CIRCLE_24: 'icon-arrows-circle-24',
  ICON_GLOBE_16: 'icon-globe-16',
  IC_BARS_24: 'icon-ic-bars-24',
  IC_BARS_BULLETS: 'icon-ic-bars-bullets',
  ICON_SHOP_16: 'icon-shop-16',
  ICON_SHOPPING_CART_16: 'icon-shopping-cart-16',
  IC_BRAND_WORDPRESS_16: 'icon-ic-brand-wordpress-16',
  IC_SERVERS_16: 'icon-ic-servers-16',
  IC_GRAPH_ARROW_UP: 'icon-ic-graph-arrow-up',
  IC_GRAPH_ARROW_UP_16: 'icon-ic-graph-arrow-up-16',
  IC_ENVELOPE_16: 'icon-ic-envelope-16',
  IC_GLOBE: 'icon-ic-globe',
  IC_GLOBE_16: 'icon-ic-globe-16',
  IC_BROWSER_WINDOW_16: 'icon-ic-browser-window-16',
  IC_DATABASE_16: 'icon-ic-database-16',
  IC_MONITOR_LIST_16: 'icon-ic-monitor-list-16',
  IC_INBOX_16: 'icon-ic-inbox-16',
  IC_AT_16: 'icon-ic-at-16',
  IC_ENVELOPE_ARROW_BACK_16: 'icon-ic-envelope-arrow-back-16',
  IC_ENVELOPE_ARROW_16: 'icon-ic-envelope-arrow-16',
  IC_GLOBE_PLUS_16: 'icon-ic-globe-plus-16',
  IC_DEVICE_PLUS: 'icon-ic-device-plus',
  IC_DEVICE_PLUS_16: 'icon-ic-device-plus-16',
  IC_ENVELOPE_MAGNIFYING_GLASS_16: 'icon-ic-envelope-magnifying-glass-16',
  IC_ARROW_DOWN_16: 'icon-ic-arrow-down-16',
  ICON_PROCESSING_CIRCULAR_LOADER: 'icon-processing-circular-loader',
  ICON_FAILURE: 'icon-failure',
  IC_BELL_24: 'icon-ic-bell-24',
  IC_QUESTION_MARK_24: 'icon-ic-question-mark-circle-24',
  IC_USER_CIRCLE_24: 'icon-ic-user-circle-24',
  IC_AVATAR_16: 'icon-ic-avatar-16',
  IC_CLOSE_16: 'icon-ic-close-16',
  IC_ADD_LIGHT_24: 'icon-ic-add-light-24',
  IC_GRID_GLOBE_SEARCH: 'icon-grid-globe-search',
  IC_BADGE_DISCOUNT_16: 'icon-ic-badge-discount-16',
  IC_BADGE_DISCOUNT_24: 'icon-ic-badge-discount-24',
  IC_YOUTUBE: 'ic-youtube',
  IC_PASSWORD_EYE_OPEN: 'password-eye-open',
  IC_GAMEPAD_16: 'icon-ic-gamepad-16',
  IC_GAMEPAD_24: 'icon-ic-gamepad-24',
  IC_GLOBE_ARROW_RIGHT_16: 'icon-ic-globe-arrow-right-16',
  IC_GLOBE_ARROW_RIGHT_24: 'icon-ic-globe-arrow-right-24',
  IC_INFO_CIRCLE_16: 'icon-ic-info-circle-16',
  IC_INFO_CIRCLE_24: 'icon-ic-info-circle-24',
  IC_THUMBS_UP_16: 'icon-ic-thumbs-up-16',
  IC_INFO_CIRCLE_FILLED: 'icon-ic-info-circle-filled',
} as const;

export type HIconUnion = (typeof HIcon)[keyof typeof HIcon];
