import type { RouteRecordRaw } from 'vue-router';

import onboardingRoutes from './websites/onboarding';

import authGuard from '../../guards/authGuard';
import hostingPageGuard from '../../guards/hostingPageGuard';

import { Route, RoutePath } from '@/types';
import { RouteOwner } from '@/types/enums/routeOwnerEnum';

const hostingRoutes = [
  {
    path: RoutePath.Hosting.HOSTING,
    beforeEnter: (to, from, next) => authGuard(to, from, next),
    component: () => import('@/views/Hosting/HostingWrapper.vue'),
    children: [
      {
        path: '',
        name: Route.Base.HOSTING,
        meta: {
          title: 'Hosting',
          menu: ['horizontal'],
          routeOwner: RouteOwner.HOSTING,
        },
        redirect: { name: Route.Base.HOSTING_V2 },
      },
      {
        path: 'migrations',
        redirect: { name: Route.Websites.MIGRATIONS_REQUESTS },
      },
    ],
  },
  {
    path: RoutePath.Hosting.HOSTING_V2,
    meta: {
      title: 'Hosting',
      wrapperName: 'hosting',
      routeOwner: RouteOwner.HOSTING,
    },
    beforeEnter: (to, from, next) => authGuard(to, from, next),
    component: () => import('@/views/Hosting/HostingWrapperV2.vue'),
    children: [
      {
        path: '',
        name: Route.Base.HOSTING_V2,
        meta: {
          title: 'Hosting',
          menu: ['horizontal'],
          routeOwner: RouteOwner.HOSTING,
        },
        beforeEnter: (to, from, next) => hostingPageGuard(to, from, next),
        component: () => import('@/views/Hosting/HostingV2.vue'),
      },
      {
        path: ':id',
        component: () => import('@/views/Hosting/HostingWrapperV2.vue'),
        children: [
          {
            path: 'details',
            name: Route.Hosting.DETAILS_V2,
            meta: {
              title: 'Plan Details',
              breadcrumbs: [Route.Base.HOSTING],
              sideMenuExists: true,
              sideMenuComponent: 'sideMenuHosting',
            },
            beforeEnter: (to, from, next) => hostingPageGuard(to, from, next),
            component: () =>
              import('@/views/Hosting/Accounts/AccountDetailsV2.vue'),
          },
          {
            path: 'resources-usage',
            name: Route.Hosting.RESOURCES_USAGE_V2,
            meta: {
              title: 'Resources Usage',
              breadcrumbs: [Route.Base.HOSTING],
              sideMenuExists: true,
              sideMenuComponent: 'sideMenuHosting',
            },
            beforeEnter: (to, from, next) => hostingPageGuard(to, from, next),
            component: () =>
              import('@/views/Hosting/Accounts/ResourcesUsageV2.vue'),
          },
          {
            path: 'upgrade',
            name: Route.Hosting.HOSTING_UPGRADE_V2,
            meta: {
              title: 'Upgrade',
              breadcrumbs: [Route.Base.HOSTING],
              sideMenuExists: true,
              sideMenuComponent: 'sideMenuHosting',
            },
            beforeEnter: (to, from, next) => hostingPageGuard(to, from, next),
            component: () =>
              import('@/views/Hosting/Order/Upgrade/OrderUpgrade.vue'),
          },
        ],
      },
    ],
  },
  {
    path: '/buy-hosting/:flow?',
    name: Route.Base.BUY_HOSTING,
    meta: {
      title: 'Purchase Hosting',
      hideTitle: true,
      routeOwner: RouteOwner.HOSTING,
    },
    component: () => import('@/views/Hosting/HostingPurchase.vue'),
  },

  ...onboardingRoutes,
] as RouteRecordRaw[];
export default hostingRoutes;
