<script lang="ts" setup>
import type { FieldSchema } from '@hostinger/hcomponents';
import { computed, ref } from 'vue';

import { useGlobals, useModal } from '@/composables';
import { profileInformationV2ModalDataById } from '@/data/profile/profileInformationV2';
import { emailRepo, hBillingRepo } from '@/repositories';
import { useProfileStore, useTwoFactorStore } from '@/stores';
import type {
  ProfileInformationFormV2KeyValue,
  ProfileInformationModalKeyValue,
  ProfileInformationV2Values,
} from '@/types';
import {
  PROFILE_INFORMATION_FORM_V2_SCHEMA_KEY,
  Country,
  Client,
} from '@/types';
import {
  capitalizeAllWords,
  extractHAsyncErrorMessage,
  extractHAsyncResponseCode,
} from '@/utils/helpers';
import { removeLTWithDashFromZip } from '@/utils/services/profileService';

type ExtendedFieldSchema = FieldSchema & {
  id: ProfileInformationFormV2KeyValue;
};

const props = defineProps<{
  data: {
    modalData: {
      id: ProfileInformationModalKeyValue;
      title: string;
      isInline?: boolean;
      formSchema: Record<ProfileInformationFormV2KeyValue, ExtendedFieldSchema>;
    };
  };
}>();

const { t, toastr } = useGlobals();
const twoFactorStore = useTwoFactorStore();
const profileStore = useProfileStore();

const { closeModal } = useModal();

const isLoadingSubmit = ref(false);
const formError = ref<string | null>(null);

const localFormSchema = ref<
  Record<ProfileInformationFormV2KeyValue, ExtendedFieldSchema>
>(props.data.modalData.formSchema);
const localFormValues = ref<ProfileInformationV2Values>();

const indiaUserValidationRequired = computed(
  () =>
    profileStore.contact?.countryCode === Country.Code.IN &&
    profileStore.brandId === Client.BrandId.Hostinger.IN,
);

const handleChange = ({ values }: { values: ProfileInformationV2Values }) => {
  formError.value = null;

  if (localFormValues.value?.countryCode !== values.countryCode) {
    values.state = '';
  }

  localFormSchema.value = profileInformationV2ModalDataById(
    props.data.modalData.id,
    values,
    indiaUserValidationRequired.value,
  ).formSchema as Record<ProfileInformationFormV2KeyValue, ExtendedFieldSchema>;

  localFormValues.value = values;
};

const handleBlur = async (fieldId: ProfileInformationFormV2KeyValue) => {
  if (!localFormValues.value) {
    return;
  }

  if (
    localFormValues.value.countryCode === Country.Code.US &&
    fieldId === PROFILE_INFORMATION_FORM_V2_SCHEMA_KEY.ZIP
  ) {
    const { address, city, state, zip } = localFormValues.value;

    if (address && city && state && zip) {
      await validateUSData({ address, city, state, zip });
    }
  }
};

const validateUSData = async ({
  address,
  city,
  state,
  zip,
}: {
  address?: string;
  city?: string;
  state?: string;
  zip?: string;
}) => {
  isLoadingSubmit.value = true;

  const [{ data }, error] = await hBillingRepo.resolveUSAddress({
    streetAddress: address || '',
    city: city || '',
    state: state || '',
    zipCode: zip || '',
  });

  isLoadingSubmit.value = false;

  if (error) {
    return false;
  }

  if (!data.isValid) {
    localFormSchema.value.address.customError = { text: '' };
    localFormSchema.value.zip.customError = { text: '' };

    formError.value = t(
      "It looks like you've entered an invalid street address or ZIP code",
    );

    return false;
  }

  const resolveAddress = data.resolvedAddress;

  handleChange({
    values: {
      address: capitalizeAllWords(resolveAddress.streetAddress),
      city: capitalizeAllWords(resolveAddress.city),
      state: resolveAddress.state,
      zip: resolveAddress.zipCode,
      countryCode: Country.Code.US,
    } as ProfileInformationV2Values,
  });

  return true;
};

const userCurrentEmail = computed(() => profileStore.contact?.email);

const updateProfileData = async (
  values: Partial<ProfileInformationV2Values>,
) => {
  if (values.email) {
    const [, emailError] = await emailRepo.patchChangeInitiate({
      email: values.email,
    });

    if (emailError) {
      const errorCode = extractHAsyncResponseCode(emailError);
      if (errorCode === 429) {
        formError.value = t('v2.errors.email.change.tooManyRequests');
      } else {
        formError.value = t(extractHAsyncErrorMessage(emailError));
      }

      throw emailError;
    }

    return;
  }

  isLoadingSubmit.value = true;

  const [, error] = await profileStore.updateProfileData(values, {
    hideToastr: true,
  });

  isLoadingSubmit.value = false;

  const VAT_ERROR_MESSAGES = [
    'You need to enter a valid VAT number.',
    'Invalid VAT Code',
  ];

  const HUMAN_READALBE_VAT_ERROR =
    'The VAT code is invalid. If a code was recently registered, it may take some time to be recognized internationally.';

  if (error) {
    const errorMessage = extractHAsyncErrorMessage(error);
    const formattedErrorMessage = VAT_ERROR_MESSAGES.includes(errorMessage)
      ? HUMAN_READALBE_VAT_ERROR
      : errorMessage;

    toastr.e(t(formattedErrorMessage));

    return;
  }

  if (values.email) {
    toastr.s(t('Verification email sent successfully'), {
      text: t('A verification email has been sent to {email}', {
        email: userCurrentEmail.value || values.email,
      }),
    });
  } else {
    toastr.s(t('Data has been updated successfully'));
  }
  closeModal();
};

const handleSubmit = async ({
  values,
  isFormValid,
}: {
  values: Partial<ProfileInformationV2Values>;
  isFormValid: boolean;
}) => {
  if (!isFormValid) {
    return;
  }

  // India users need to have a name and surname with a length between
  // 5 and 50 characters, otherwise - it'll be rejected by Razorpay
  if (
    indiaUserValidationRequired.value &&
    values.firstName &&
    values.lastName
  ) {
    const totalCharactersLength = (values.firstName + values.lastName).trim()
      .length;

    if (totalCharactersLength < 5 || totalCharactersLength > 50) {
      localFormSchema.value.lastName.customError = {
        text: t('v2.validation.indian.name.surname.length'),
      };

      return;
    }
  }

  // Remove LT- from zip code as users tend to enter it an CB considers it invalid
  if (values.zip && values.countryCode === Country.Code.LT) {
    values.zip = removeLTWithDashFromZip(values.zip);
  }

  if (values.countryCode === Country.Code.US) {
    const isUSDataValid = await validateUSData(values);

    if (!isUSDataValid) {
      return;
    }
  }

  if (!profileStore.hasTwoFactorAuth && !profileStore.account?.isPasswordSet) {
    updateProfileData(values);

    return;
  }

  twoFactorStore.openVerifyPersonalInformationModal({
    submitData: values,
  });
};
</script>

<template>
  <HForm
    :disabled="isLoadingSubmit"
    @on-change="handleChange($event)"
    @on-submit="handleSubmit($event)"
  >
    <div
      class="personal-information__inputs"
      :class="{
        'personal-information__inputs--inline': data.modalData.isInline,
      }"
    >
      <HFormField
        v-for="schema in localFormSchema"
        :key="schema.id"
        :name="schema.id"
        :schema="schema"
        :custom-error="schema.customError"
        @on-blur="handleBlur(schema.id)"
      />
    </div>
    <HSnackbar v-if="formError" variant="error" class="h-mb-16">
      {{ formError }}
    </HSnackbar>
    <div class="personal-information__buttons">
      <HButton v-qa-generate variant="text" @click="closeModal">
        {{ t('Cancel') }}
      </HButton>
      <HButton v-qa-generate :is-loading="isLoadingSubmit" h-form-submit>
        {{ t('Continue') }}
      </HButton>
    </div>
  </HForm>
</template>

<style lang="scss" scoped>
.personal-information {
  &__buttons {
    display: flex;
    flex-wrap: wrap;
    justify-content: end;
    gap: 16px;
    margin-top: 16px;
  }

  &__inputs {
    &--inline {
      // Hack because of error message pushes input top and they don't align
      $phone-cc-width: 100px;

      display: flex;
      align-items: center;
      position: relative;

      & > *:first-child {
        max-width: $phone-cc-width;
      }

      & > *:last-child {
        position: absolute;
        top: 0px;
        margin-left: $phone-cc-width;
        width: calc(100% - $phone-cc-width);
      }
    }
  }
}
</style>
