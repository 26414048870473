import dayjs from 'dayjs';
import type { Module } from 'vuex';

import { hBillingRepo } from '@/repositories';
import { usePaymentMethodStore } from '@/stores';
import type { AddPaymentMethodRequest, PaymentMethod, Customer } from '@/types';

interface State {
  loading: boolean;
  loaded: boolean;
  paymentMethods: PaymentMethod[];
  hpaymentURL: string | null;
  customer: Customer | null;
}

export default {
  namespaced: true,
  state: {
    loading: false,
    loaded: false,
    paymentMethods: [],
    hpaymentURL: null,
    customer: null,
  },
  getters: {
    hasPaymentMethod: (state) => state.paymentMethods.length,
    getActivePaymentMethodsCount: (state) => {
      if (!state.paymentMethods) return 0;

      const now = dayjs();
      const isExpired = ({ expirationDate }: PaymentMethod) =>
        expirationDate && now.isAfter(expirationDate);

      return state.paymentMethods.filter(
        (card) => !card.isSuspended && !isExpired(card),
      ).length;
    },
    hasActivePaymentMethods: (_, getters) =>
      !!getters.getActivePaymentMethodsCount,
    getPaymentMethodById: (state) => (id: string) => {
      const paymentMethodId = parseInt(id);
      if (!state.paymentMethods.length) return null;

      return (
        state.paymentMethods.find((card) => card.id === paymentMethodId) ?? {}
      );
    },
    getAllPaymentMethods: (state) => state.paymentMethods,
    getDefaultPaymentMethod: (state) =>
      (state.paymentMethods || []).find((card) => card.isDefault === 1),
    getCreditsBalance: (state) => {
      if (!state.customer) return 0;

      return (
        state.customer.excessPayments +
        state.customer.promotionalCredits +
        state.customer.refundableCredits
      );
    },
  },
  mutations: {
    SET_PAYMENT_METHODS(state, payload?: PaymentMethod[]) {
      state.paymentMethods = payload || [];
    },
    SET_LOADING(state, payload: boolean) {
      state.loading = payload;
    },
    SET_LOADED(state, payload: boolean) {
      state.loaded = payload;
    },
    SET_HPAYMENT_URL(state, payload: string) {
      state.hpaymentURL = payload;
    },
    SET_CUSTOMER(state, payload: Customer) {
      state.customer = payload;
    },
    UPDATE_DEFAULT_PAYMENT_METHOD(state, id: number) {
      const updatedPaymentMethods = state.paymentMethods.map((method) => ({
        ...method,
        isDefault: method.id === id ? 1 : 0,
      }));

      state.paymentMethods = [...updatedPaymentMethods];
    },
  },
  actions: {
    async billingGetPaymentMethods({ commit }) {
      commit('SET_LOADING', true);
      const [{ data }, error] = await hBillingRepo.getPaymentMethods();

      if (!error && Array.isArray(data)) {
        commit('SET_PAYMENT_METHODS', data);
      }
      commit('SET_LOADING', false);
      commit('SET_LOADED', true);
    },
    async billingAddPaymentMethod(
      { commit },
      payload: AddPaymentMethodRequest,
    ) {
      const [{ data }, error] = await hBillingRepo.addPaymentMethod(payload);

      if (!error) commit('SET_HPAYMENT_URL', data.redirectLink);

      return data.redirectLink;
    },
    async billingRemovePaymentMethod(
      { dispatch },
      { methodId }: { methodId: string },
    ) {
      const [, error] = await hBillingRepo.removePaymentMethod(methodId);
      if (!error) dispatch('billingGetPaymentMethods');
    },
    async billingSetDefaultPaymentMethod(
      { commit },
      {
        methodId,
        methodIdentifier,
      }: { methodId: string; methodIdentifier: string },
    ) {
      const [, error] = await hBillingRepo.setDefaultPaymentMethod(methodId);
      const paymentMethodStore = usePaymentMethodStore();

      paymentMethodStore.setDefaultCard(methodIdentifier, Number(methodId));
      if (!error) {
        commit('UPDATE_DEFAULT_PAYMENT_METHOD', methodId);
      }
    },
    async getCustomer({ commit }) {
      const [{ data }, error] = await hBillingRepo.getCustomer();
      if (!error) commit('SET_CUSTOMER', data);
    },
  },
} as Module<State, any>;
