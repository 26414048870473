<script lang="ts" setup>
import HDrawer from '@/components/HDrawer.vue';
import Navigation from '@/components/Navigation.vue';
import { useScreen } from '@/composables';
import { useFrontendSettingsStore, useHPanelControlsStore } from '@/stores';

type Props = {
  computedHeaderAndBannerHeight: string;
};

defineProps<Props>();

const hpanelControlsStore = useHPanelControlsStore();
const frontendSettingsStore = useFrontendSettingsStore();

const { isXLarge } = useScreen();
</script>

<template>
  <Navigation
    v-if="frontendSettingsStore.isNavigationVisible && !isXLarge"
    :computed-header-and-banner-height="computedHeaderAndBannerHeight"
  />

  <Teleport to="#app">
    <HDrawer
      v-if="isXLarge && frontendSettingsStore.isNavigationVisible"
      fixed-width
      :is-active="hpanelControlsStore.isMobileNavigationOpen"
      position="left"
      show-logo
      @on-close="hpanelControlsStore.toggleMobileNavigation"
    >
      <Navigation
        :computed-header-and-banner-height="computedHeaderAndBannerHeight"
      />
    </HDrawer>
  </Teleport>
</template>
