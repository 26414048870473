<script lang="ts" setup>
import { computed } from 'vue';
import { useStore } from 'vuex';

import { useBrands } from '@/composables/useBrands';
import { Payment } from '@/types';

const store = useStore();
const { isBrazilReseller } = useBrands();

const paymentMethod = computed(() => {
  const { paymentMethod } =
    store.getters['paymentMethods/getDefaultPaymentMethod'] || {};

  return paymentMethod;
});

const showWarning = computed(
  () => paymentMethod.value === Payment.Method.PAYPAL && isBrazilReseller.value,
);
</script>

<template>
  <HSnackbar v-if="showWarning" background-color="gray-light" class="h-mt-4">
    <span v-trans>
      Please, be aware that when paying by Paypal, you’d be doing an
      international purchase, therefore you’ll be paying in USD. Taxes are not
      included. To pay in Brazilian Reals, please choose another payment method.
    </span>
  </HSnackbar>
</template>
