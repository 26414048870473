<template>
  <div
    class="purchase-quantity"
    :class="{ 'purchase-quantity--justify-center': !title }"
  >
    <div v-if="title" class="d-flex align-items-center">
      <h4 v-trans class="h-m-0 h-mr-8">
        {{ title }}
      </h4>
      <slot name="titleIcon" />
    </div>

    <div class="d-flex align-items-center">
      <div
        class="purchase-quantity__button"
        data-qa="purchase-quantity-decrease"
        @click.stop="decreaseAmount"
      >
        <HpIcon
          id="hpanel_tracking-purchase-quantity_decrease"
          icon="ic-remove-circle"
          v-bind="iconProps"
          :primary="amount !== minValue"
          :disabled="amount === minValue"
        />
      </div>
      <input
        v-model="amount"
        type="number"
        class="purchase-quantity__amount-value"
        :min="minValue"
        :max="maxValue"
        data-qa="purchase-quantity-input"
      />
      <div
        class="purchase-quantity__button"
        data-qa="purchase-quantity-increase"
        @click.stop="increaseAmount"
      >
        <HpIcon
          id="hpanel_tracking-purchase-quantity_increase"
          v-tooltip="amount === maxValue ? maxDisabledTooltip : ''"
          icon="ic-add-circle"
          v-bind="iconProps"
          :primary="amount !== maxValue"
          :disabled="amount === maxValue"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { debounce } from 'lodash';

export default {
  name: 'PurchaseQuantity',
  props: {
    title: {
      type: String,
    },
    minValue: {
      type: Number,
      default: 1,
    },
    maxValue: {
      type: Number,
      default: 20,
    },
    value: Number,
    tooltip: String,
    isEstimationLoading: Boolean,
  },
  created() {
    this.setDebounceAmount();
  },
  data() {
    return {
      amount: this.value || this.minValue,
      debounceAmount: () => {},
    };
  },
  emits: ['change'],
  computed: {
    tooltipText() {
      return this.$t(this.tooltip);
    },
    iconProps() {
      return {
        'static-view-box': true,
        'no-select': true,
      };
    },
    maxDisabledTooltip() {
      return this.$t('v2.purchase_quantity.max_disabled_tooltip', {
        maxValue: this.maxValue,
      });
    },
  },
  methods: {
    decreaseAmount() {
      if (this.amount > this.minValue) {
        this.setAmount(this.amount - 1);
      }
    },
    increaseAmount() {
      if (this.amount < this.maxValue) {
        this.setAmount(this.amount + 1);
      }
    },
    setAmount(value) {
      if (this.isEstimationLoading) return;
      this.amount = value;

      this.$emit('change', value);
    },
    setDebounceAmount() {
      this.debounceAmount = debounce((value) => {
        this.setAmount(value);
      }, 1000);
    },
  },
  watch: {
    amount(value) {
      if (!value || value < this.minValue) {
        this.amount = this.minValue;
        this.$emit('change', this.minValue);

        return;
      }

      if (value > this.maxValue) {
        this.amount = this.maxValue;
        this.$emit('change', this.maxValue);

        return;
      }

      this.debounceAmount(value);
    },
  },
};
</script>

<style lang="scss" scoped>
.purchase-quantity {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 16px;
  border: 1px solid var(--gray-border);
  border-radius: 4px;

  &__button {
    cursor: pointer;
  }

  &--justify-center {
    justify-content: center;
  }

  &__amount-value {
    width: 55px;
    height: 48px;
    margin: 0 8px;
    text-align: center;
    border: 1px solid var(--gray-border);
    font-weight: bold;
    border-radius: 4px;
  }

  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .icon-wrapper .h-icon {
    cursor: pointer;
  }
}
</style>
