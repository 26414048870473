import { defineStore } from 'pinia';
import { ref, computed, readonly } from 'vue';
import { useRouter } from 'vue-router';

import { useSubscriptionsStore } from './subscriptionsStore';

import { useCookies } from '@/composables';
import { useGlobals } from '@/composables/useGlobals';
import { hRefundsRepo } from '@/repositories';
import { useFeedbackStore } from '@/stores/feedbackStore';
import { useResourcesStore } from '@/stores/resourcesStore';
import {
  Route,
  type HRefundInvoice,
  type HRefundInvoiceItem,
  type HRefundInvoiceEstimate,
  type HRefundInvoiceItemWithAdditions,
  HRESOURCES_TYPE,
  HRefund,
  AmplitudeEvent,
  type HRefundInvoiceEstimateItem,
  type HRefundInvoiceEstimateItemWithAdditions,
  CSAT_CUSTOM_IDENTIFIER,
  Cookie,
  HRESOURCES_STATE,
  type HResourceState,
  HRESOURCE_ITEM_STATUS,
} from '@/types';
import { toUnicode } from '@/utils/helpers';
import { mapFixedCurrency } from '@/utils/mappers/currencyMapper';
import { currencyService } from '@/utils/services';

export const useRefundStore = defineStore('refundStore', () => {
  const currentStep = ref<0 | 1 | 2 | 3>(0);
  const { t, toastr, amplitudeV2 } = useGlobals();
  const subscriptionsStore = useSubscriptionsStore();
  const resourceStore = useResourcesStore();
  const feedbackStore = useFeedbackStore();
  const { cookies } = useCookies();
  const STEPS_CONFIG = computed(() => [
    {
      title: t('v2.services.you.want.to.refund'),
      stepper: {
        title: t('v2.services'),
        index: 0,
        id: '0',
      },
    },
    {
      title: t('v2.reason.why.you.want.a.refund'),
      label: t('v2.optional'),
      stepper: {
        title: t('v2.refund.reason'),

        index: 1,
        id: '1',
      },
    },
    {
      title: t('v2.select.refund.method'),
      stepper: {
        title: t('v2.refund.method'),
        index: 2,
        id: '2',
      },
    },
    {
      title: t('v2.refund.summary'),
      stepper: {
        title: t('v2.summary'),
        index: 3,
        id: '3',
      },
    },
  ]);

  const surveyData = ref({
    reason: '',
    comment: '',
  });

  const invoice = ref<HRefundInvoice | null>(null);
  const selectedPaymentMethodId = ref<string | null>(null);
  const selectedInvoiceItems = ref<HRefundInvoiceItem[]>([]);
  const isLoading = ref(false);
  const isEstimationLoading = ref(false);
  const isSubmitLoading = ref(false);
  const refundEstimation = ref<HRefundInvoiceEstimate | null>(null);

  const getRefundSubmitObject = () => ({
    refundReason: surveyData.value.reason
      ? HRefund.RefundReasonValues[surveyData.value.reason]
      : 'N/A',
    clientNote: surveyData.value.comment || 'N/A',
    subscriptions: [
      ...new Set(selectedInvoiceItems.value.map((item) => item.subscriptionId)),
    ],
  });

  const getRefundEstimation = async () => {
    if (!invoice.value) {
      return;
    }

    isEstimationLoading.value = true;
    const [{ data }, error] = await hRefundsRepo.getRefundEstimate(
      invoice.value.id,
      getRefundSubmitObject(),
    );
    if (error) {
      isEstimationLoading.value = false;

      return;
    }
    refundEstimation.value = data;
    isEstimationLoading.value = false;
  };

  const router = useRouter();

  const getInvoice = async (invoiceId: HRefundInvoice['id']) => {
    if (invoice.value?.id === invoiceId) {
      return invoice;
    }

    return await fetchInvoice(invoiceId);
  };

  const fetchInvoice = async (invoiceId: HRefundInvoice['id']) => {
    isLoading.value = true;

    const [{ data }, _] = await hRefundsRepo.getInvoice(invoiceId);

    invoice.value = data;

    isLoading.value = false;
  };

  const stepEstimationHandler = () => {
    if (currentStep.value === 0) {
      getRefundEstimation();
    }
  };

  const stepAmplitudeHandler = (previousStep?: 0 | 1 | 2 | 3) => {
    const additionalAmplitudeData: { skipped_survey?: boolean } = {};

    if (previousStep === 1) {
      additionalAmplitudeData.skipped_survey =
        !surveyData.value.reason && !surveyData.value.comment;
    }

    amplitudeV2(AmplitudeEvent.Billing.BILLING_REFUND_ENTER, {
      step: HRefund.StepKeys[currentStep.value],
      ...additionalAmplitudeData,
    });
  };

  const nextStep = () => {
    if (currentStep.value < STEPS_CONFIG.value.length) {
      stepEstimationHandler();
      currentStep.value++;
    }
  };

  const previousStep = () => {
    if (currentStep.value > 0) {
      currentStep.value--;
    }
  };

  const setStep = (step: 0 | 1 | 2 | 3) => {
    if (step >= 1 && step <= STEPS_CONFIG.value.length) {
      stepEstimationHandler();
      currentStep.value = step;
    }
  };

  const getStepperConfig = () => STEPS_CONFIG.value.map((step) => step.stepper);

  const currentStepConfig = computed(
    () =>
      STEPS_CONFIG.value.find(
        (step) => step.stepper.index === currentStep.value,
      ) || STEPS_CONFIG.value[0],
  );

  const submitRefundRequest = async (invoice: HRefundInvoice) => {
    isSubmitLoading.value = true;
    amplitudeV2(AmplitudeEvent.Billing.BILLING_REFUND_SUBMITTED);

    const [, error] = isRefundToHostingerBalance.value
      ? await hRefundsRepo.requestRefundToBalance(
          invoice.id,
          getRefundSubmitObject(),
        )
      : await hRefundsRepo.requestRefund(invoice.id, getRefundSubmitObject());

    isSubmitLoading.value = false;
    if (error) {
      return;
    }

    toastr.s(
      t('v2.refund.for.invoiceid.requested.successfully', {
        invoiceId: invoice.id,
      }),
    );

    if (!cookies[Cookie.FEEDBACK_CLOSED]) {
      feedbackStore.openCSATFeedback({
        location: CSAT_CUSTOM_IDENTIFIER.REFUND_SUBMITTED_V2,
        firstStepTitle:
          'v2.how.would.you.rate.your.experience.requesting.a.refund',
      });
    }
    router.push({ name: Route.HBilling.REFUND_HISTORY });
  };

  const isRefundToHostingerBalance = computed(
    () =>
      selectedPaymentMethodId.value ===
      HRefund.PaymentMethodId.HOSTINGER_BALANCE,
  );

  const setInvoice = (newInvoice: HRefundInvoice) => {
    invoice.value = newInvoice;
  };

  const setSelectedPaymentMethodId = (paymentMethod: string | null) => {
    selectedPaymentMethodId.value = paymentMethod;
  };

  const setSelectedInvoiceItems = (invoiceItems: HRefundInvoiceItem[]) => {
    selectedInvoiceItems.value = invoiceItems;
  };

  const setLoading = (loading: boolean) => {
    isLoading.value = loading;
  };

  const formatInvoiceServicePrice = (price: number, invoice: HRefundInvoice) =>
    currencyService.format(price, {
      fixedCurrency: mapFixedCurrency({
        currencyCode: invoice.currencyCode,
      }),
      monthly: false,
      isChargebeePrice: true,
    });

  const getServiceTitle = (invoiceItem: {
    subscriptionId: string;
    entityId: string;
    domain?: string | null;
  }) => {
    const subscription = subscriptionsStore.getSubscriptionById(
      invoiceItem.subscriptionId,
    );
    const priceItem = subscription?.items.find(
      (item) => item.itemPriceId === invoiceItem.entityId,
    );
    const domain = invoiceItem?.domain;
    const availableResourceTypesWithDomain: string[] = [
      HRESOURCES_TYPE.HOSTING,
      HRESOURCES_TYPE.DOMAIN,
    ];

    if (
      domain &&
      availableResourceTypesWithDomain.includes(priceItem?.resourceType ?? '')
    ) {
      return `${priceItem?.name} - ${toUnicode(domain)}`;
    }

    return `${priceItem?.name}`;
  };

  const getInvoiceItemStatus = (subscriptionId: string) => {
    const ACTIVE_HRESOURCES_STATE: HResourceState[] = [
      HRESOURCES_STATE.ACTIVE,
      HRESOURCES_STATE.UPDATING,
      HRESOURCES_STATE.ACTIVATING,
      HRESOURCES_STATE.CREATED,
    ];

    const state =
      resourceStore.getResourceByIdempotencyKey(subscriptionId)?.state ||
      resourceStore.getResourceBySubscriptionId(subscriptionId)?.state;

    if (!state) {
      return;
    }

    if (ACTIVE_HRESOURCES_STATE.includes(state)) {
      return HRESOURCE_ITEM_STATUS.ACTIVE;
    }

    return HRESOURCE_ITEM_STATUS.INACTIVE;
  };
  const mapInvoiceItem = (
    invoiceItem: HRefundInvoiceItem,
    invoice: HRefundInvoice,
  ): HRefundInvoiceItemWithAdditions => ({
    ...invoiceItem,
    title: getServiceTitle({
      ...invoiceItem,
      domain: invoiceItem?.domain || invoiceItem?.freeDomain,
    }),
    isDisabled: !invoiceItem.refundable || invoiceItem.invoiceId === invoice.id,
    price: formatInvoiceServicePrice(invoiceItem.amount, invoice),
    status: getInvoiceItemStatus(invoiceItem.subscriptionId),
    totalPriceDiscount: formatInvoiceServicePrice(
      invoiceItem.calculations.withTaxes,
      invoice,
    ),
  });

  const mapEstimateItem = (
    invoiceItem: HRefundInvoiceEstimateItem,
    invoice: HRefundInvoice,
  ): HRefundInvoiceEstimateItemWithAdditions => ({
    ...invoiceItem,
    title: getServiceTitle(invoiceItem),
    price: formatInvoiceServicePrice(invoiceItem.amount, invoice),
    totalPriceDiscount: formatInvoiceServicePrice(
      invoiceItem.calculations.withTaxes,
      invoice,
    ),
  });

  const resetStore = () => {
    currentStep.value = 0;
    invoice.value = null;
    isLoading.value = false;
    surveyData.value.comment = '';
    surveyData.value.reason = '';
    selectedInvoiceItems.value = [];
    selectedPaymentMethodId.value = null;
  };

  return {
    STEPS_CONFIG,
    currentStep,
    currentStepConfig,
    formatInvoiceServicePrice,
    getInvoice,
    getRefundEstimation,
    getStepperConfig,
    invoice,
    isEstimationLoading,
    isLoading,
    isRefundToHostingerBalance,
    isSubmitLoading,
    mapEstimateItem,
    mapInvoiceItem,
    nextStep,
    previousStep,
    refundEstimation,
    resetStore,
    selectedInvoiceItems: readonly(selectedInvoiceItems),
    selectedPaymentMethodId: readonly(selectedPaymentMethodId),
    setInvoice,
    setLoading,
    setSelectedInvoiceItems,
    setSelectedPaymentMethodId,
    setStep,
    stepAmplitudeHandler,
    submitRefundRequest,
    getInvoiceItemStatus,
    surveyData,
  };
});
