/* eslint-disable */
import axios from 'axios';
import dayjs from 'dayjs';
import cookies from 'js-cookie';
import { ref } from 'vue';
import type { LocaleMessages, Path, VueMessageType } from 'vue-i18n';
import { createI18n } from 'vue-i18n';

import type { LanguageType, TLanguageCode } from '@/types';
import { Cookie, FeatureFlag, LANGUAGE_CODE } from '@/types';
import { errorLogger } from '@/utils/services/errorLogging';
import { useFeatureFlag } from '@/composables/useFeatureFlag.js';
import { useRoute } from 'vue-router';

const missingTranslationsStorageKey = 'logged-translations'
const loggedOldTranslationsStorageKey = 'logged-old-translations'
const messages: LocaleMessages<VueMessageType> = {};
const loadedLanguages: Array<string> = [];
const languageSelected: LanguageType =
  (cookies.get('language') as LanguageType) || 'en_GB';
let initialLanguageLoaded = false;
export const slugLanguageSelected = ref(
  cookies.get(Cookie.SLUG_LANGUAGE_SELECTED) === 'true',
);

setDayjsLanguage(languageSelected);

let slugsMessagesMap: Record<string, string> = {};
let usedTranslationsList: string[] = [];

let missingLogged = false;
let alreadyLoggedTranslations: string[] = [];
export const i18n = createI18n({
  silentTranslationWarn: true,
  silentFallbackWarn: true,
  warnHtmlInMessage: 'off',
  locale: languageSelected,
  fallbackLocale: LANGUAGE_CODE.en_GB,
  missing: (locale: string, slug: string) => {
    if (cookies.get('log_missing_translations')) {
      console.log('missing translation: ', locale, slug);
    }

    if (slug.includes('v2.') && !missingLogged && initialLanguageLoaded) {
      errorLogger.logError(
        new Error(`Missing translation for slug: ${slug}, language: ${locale}`),
      );
      missingLogged = true;
    }

    const oldMessage = slugsMessagesMap[slug];
    const selectedLanguageMessages = (messages[languageSelected] ||
      {}) as unknown as Record<string, string>;

    if (selectedLanguageMessages[oldMessage]) {
      return selectedLanguageMessages[oldMessage];
    }

    const enGbMessages = (messages[LANGUAGE_CODE.en_GB] ||
      {}) as unknown as Record<string, string>;

    return enGbMessages[slug] || oldMessage || slug;
  },
  //@ts-ignore
  messages,
}).global;

/**
 * Overrides the default i18n.te function to support the dot notation keys (v2 translation).
 * https://github.com/kazupon/vue-i18n/issues/1521
 */
i18n.te = (...[key, locale]: Parameters<typeof i18n.te>) => {
  const effectiveLocale = locale?.length ? locale : i18n.locale
  const allLocales: Record<string, Record<string, string> | undefined> = i18n.messages
  const messages = allLocales[effectiveLocale]

  return !!messages && Object.hasOwn(messages, key)
}

const getMessagesByLanguage = async (language: TLanguageCode) => {
  const messages = await import(`../../../translations/${language}.js`);

  if (process.env.NODE_ENV === 'development') {
    // Ignore TS as type declarations are not necessary for this file
    //@ts-ignore
    const localMessages = await import(`../../../translations-dev.js`);

    return { ...messages.default[language], ...localMessages.default };
  }

  return messages.default[language];
};

export const getMessageByKeyAndLanguage = async (key: string, language: TLanguageCode) => {
  const messages = await getMessagesByLanguage(language);
  return messages[key];
};

const setMessagesByLanguage = async (language: TLanguageCode) => {
  const messages = await getMessagesByLanguage(language);

  i18n.setLocaleMessage(language, messages);

  loadedLanguages.push(language);
};

const initSlugsMessageMap = async () => {
  slugsMessagesMap = (
    // @ts-ignore
    await import(`../../../translations/slugs-message-map.json`)
  ).default;
};

const initUsedTranslations = async () => {
  usedTranslationsList = (
    // @ts-ignore
    await import(`../../../translationsV2/used-translationsV2.json`)
    ).default;

  try {
    const loggedMessages = JSON.parse(localStorage.getItem(missingTranslationsStorageKey) ?? '[]')

    alreadyLoggedTranslations = loggedMessages;
  } catch {
    errorLogger.logInfo('Failed to fetch logged translations from local storage');
  }
}

export const initialMessagesLoad = async (language: TLanguageCode) => {
  try {
    await initUsedTranslations();
    await initSlugsMessageMap();
    if (language !== LANGUAGE_CODE.en_GB) {
      //load english for fallback messages
      await setMessagesByLanguage(LANGUAGE_CODE.en_GB);
    }

    await setMessagesByLanguage(language);

    setI18nLanguage(language);
    initialLanguageLoaded = true;
  } catch (e) {
    //error
  }
};

export const handleOldTranslationSearch = (message: string) => {
  if (
    !message ||
    message.startsWith('v2.') ||
    !initialLanguageLoaded ||
    i18n.locale !== LANGUAGE_CODE.en_GB ||
    alreadyLoggedTranslations.includes(message)
  ) {
    return;
  }

    if (!usedTranslationsList.includes(message)) {
      alreadyLoggedTranslations.push(message);
      localStorage.setItem(missingTranslationsStorageKey, JSON.stringify(alreadyLoggedTranslations))
      errorLogger.logInfo(`Missing old translation in used list: ${message}`, {
        tags: { translationMissing: message },
      });
    }
};


const checkIfOldTranslationWasLoggedDuringSession = (translation: string) => {
  const loggedTranslations = JSON.parse(sessionStorage.getItem(loggedOldTranslationsStorageKey) || '[]');
  return loggedTranslations.includes(translation);
}

const setLoggedOldTranslationToSessionStorage = (translation: string) => {
  const loggedTranslations = JSON.parse(sessionStorage.getItem(loggedOldTranslationsStorageKey) || '[]');
  sessionStorage.setItem(loggedOldTranslationsStorageKey, JSON.stringify([...loggedTranslations, translation]));
}

const handleOldTranslationLogging = (translation: string) => {
  if (
    !translation 
    || translation.startsWith('v2.') 
    || !initialLanguageLoaded 
    || i18n.locale !== LANGUAGE_CODE.en_GB
  ) {
    return;
  }

  const { isFeatureEnabled } = useFeatureFlag(FeatureFlag.ID.HPANEL_OLD_TRANSLATIONS_LOGGING);
  if (!isFeatureEnabled.value) {
    return;
  }

  const route = useRoute();
  if (!route?.meta?.logOldTranslations) {
    return;
  }

  if (checkIfOldTranslationWasLoggedDuringSession(translation)) {
    return;
  }

  errorLogger.logInfo(`Old translation being used: ${translation}`, {
    tags: { translationUsed: translation, routePath: route?.path },
  });

  setLoggedOldTranslationToSessionStorage(translation);
}

function setI18nLanguage(language: string): string {
  i18n.locale = language;
  axios.defaults.headers['Accept-Language'] = language;
  setLanguageCookie(language);

  return language;
}

function setLanguageCookie(language: string): void {
  const [subdomain] = location.host.split('.');
  const domain = location.host.replace(subdomain, '');

  cookies.remove('language');
  errorLogger.setTag(Cookie.LANGUAGE, language);
  cookies.set(Cookie.LANGUAGE, language, { expires: 365, domain });
}

function setDayjsLanguage(language: string): void {
  let currentLang = language;
  if (language === 'ar_AR') currentLang = 'en_GB';
  dayjs.locale(currentLang);
}

export function $t(...args: any[]) {
  if (args[0] === undefined || args[0] === null) return '';
  if (slugLanguageSelected.value) {
    return args[0] as string;
  }

  handleOldTranslationLogging(args[0]);

  let result = '';
  // handleOldTranslationSearch(args[0]);
  try {
    //@ts-ignore
    result = i18n.t(...args);
  } catch (e) {
    errorLogger.setTag('translationFailure', args[0]?.substring?.(0, 128));
    errorLogger.logError('Failed to translate message');
    errorLogger.setTag('translationFailure', undefined);

    return '';
  }

  if (result.includes(`{'@'}`)) result = result.replace(`{'@'}`, '@');
  if (result.includes(`{'$'}`)) result = result.replace(`{'$'}`, '$');
  if (result.includes(`{'#'}`)) result = result.replace(`{'#'}`, '#');
  if (result.includes(`{'|'}`)) result = result.replace(`{'|'}`, '|');
  if (result.includes(`{'{'}`)) result = result.replace(`{'{'}`, '{');
  if (result.includes(`{'}'}`)) result = result.replace(`{'}'}`, '}');

  const matches = result.match(/\{(.*?)\}/gim);

  if (matches) {
    matches.forEach((param: any) => {
      if (/{'@'}|{'\$'}|{'#'}|{'\|'}/gm.test(param) || !args[1]) return;

      result = result.replace(param, args[1][param.replace(/({|})/gm, '')]);
    });
  }

  return result;
}

export async function loadLanguageAsync(language: TLanguageCode) {
  if (i18n.locale === language || loadedLanguages.includes(language)) {
    return Promise.resolve(setI18nLanguage(language));
  }

  await setMessagesByLanguage(language);

  return setI18nLanguage(language);
}

export function filterKey(message: string) {
  const currMessage = message?.replace(/\sdata-v-[^(="")]+=""/gim, '');

  return currMessage;
}
