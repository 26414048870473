import { defineStore } from 'pinia';
import { computed, ref } from 'vue';

import { useDeveloperTools } from '@/composables/developerTools/useDeveloperTools';
import { useCookies } from '@/composables/useCookies';
import { useScreen } from '@/composables/useScreen';
import { YEAR_IN_DAYS } from '@/data/globalConstants';
import { useFrontendSettingsStore } from '@/stores/frontendSettingsStore';
import { useProfileStore } from '@/stores/profile/profileStore';
import { useSubscriptionsStore } from '@/stores/subscriptionsStore';
import { Cookie } from '@/types';

export const useAccessSharingIntroductionStore = defineStore(
  'accessSharingIntroductionStore',
  () => {
    const { isCookieValueTruthyNumber: isAccessSharingIntroClosed, setCookie } =
      useCookies(Cookie.ACCESS_SHARING_INTRODUCTION_CLOSED);
    const frontendSettingsStore = useFrontendSettingsStore();
    const { isDeveloperToolsPopoverActive, isDeveloperToolsEnabled } =
      useDeveloperTools();
    const screen = useScreen();
    const profileStore = useProfileStore();
    const subscriptionStore = useSubscriptionsStore();

    const isIntroductionClosedByCookie = ref(isAccessSharingIntroClosed.value);

    const isAddonsMenuItemVisible = computed(
      () => isDeveloperToolsEnabled.value && !profileStore.isAccessManager,
    );

    const isIntroductionPopoverActive = computed(() => {
      if (!subscriptionStore.isLoaded) {
        return false;
      }

      const isDeveloperToolsPopoverVisible = isAddonsMenuItemVisible.value
        ? isDeveloperToolsPopoverActive.value
        : false;

      if (!screen.isLarge.value && isDeveloperToolsPopoverVisible) {
        return false;
      }

      return !isIntroductionClosedByCookie.value;
    });

    const setIntroductionSeen = () => {
      isIntroductionClosedByCookie.value = true;
      frontendSettingsStore.resetHeaderMenuResponsiveTogglerPopover();
      setCookie('1', { expires: YEAR_IN_DAYS });
    };

    return {
      isIntroductionPopoverActive,
      setIntroductionSeen,
    };
  },
);
