<template>
  <li>
    <HSkeletonLoader
      v-for="i in 10"
      :key="i"
      :width="255"
      :height="22"
      rounded
      class="h-my-36"
    />
  </li>
</template>

<style lang="scss" scoped></style>
