<script setup lang="ts">
import { computed } from 'vue';
import { useRouter } from 'vue-router';

import { useDeveloperTools } from '@/composables/developerTools/useDeveloperTools';
import { useGlobals } from '@/composables/useGlobals';
import { useDeveloperToolsIntroductionStore } from '@/stores/developerTools/developerToolsIntroductionStore';
import { AmplitudeEvent } from '@/types';

const { t, amplitudeV2 } = useGlobals();
const router = useRouter();
const { developerToolsRoute, hasDeveloperToolsEnabledThroughCloudPlan } =
  useDeveloperTools();
const developerToolsIntroductionStore = useDeveloperToolsIntroductionStore();

const handleClose = () => {
  developerToolsIntroductionStore.setIntroductionSeen();
  amplitudeV2(AmplitudeEvent.DeveloperTools.NAVIGATION_INTRODUCTION_CLOSED);
};

const title = computed(() => {
  if (hasDeveloperToolsEnabledThroughCloudPlan.value) {
    return t('v2.developer.tools.introduction.title');
  }

  return t('v2.developer.tools.introduction.title.paid');
});

const handleTryNow = () => {
  router.push({
    name: developerToolsRoute.value,
  });
};

(() => {
  amplitudeV2(AmplitudeEvent.DeveloperTools.NAVIGATION_INTRODUCTION_SHOWN);
})();
</script>
<template>
  <div class="introduction-popover">
    <div class="introduction-popover__content">
      <h3 class="h-m-0">{{ title }}</h3>
      <span>{{ t('v2.developer.tools.introduction.subtitle') }}</span>
    </div>
    <div class="introduction-popover__actions">
      <HButton
        variant="text"
        class="introduction-popover__close-button"
        size="small"
        @click="handleClose"
      >
        {{ t('v2.close') }}
      </HButton>

      <HButton size="small" @click="handleTryNow">
        {{ t('v2.try.now') }}
      </HButton>
    </div>
  </div>
</template>

<style scoped lang="scss">
.introduction-popover {
  display: flex;
  flex-direction: column;
  gap: 16px;
  &__content {
    display: flex;
    flex-direction: column;
    gap: 8px;
    color: var(--white);
  }

  &__actions {
    display: flex;
    gap: 8px;
    justify-content: flex-end;
  }

  &__close-button {
    color: var(--white);
    &:hover:not(&--disabled):not([disabled]) {
      background-color: var(--meteorite-dark);
    }
  }
}
</style>
