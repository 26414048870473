import { cloneDeep } from 'lodash';
import { defineStore } from 'pinia';
import { ref, computed } from 'vue';

import { hDomainsRepo } from '@/repositories';
import { useDomainManagementStore } from '@/stores/domain/domainManagementStore';
import type {
  WhoIsProfile,
  IHDomainTLDSettings,
  WhoIsSelectOption,
  IHDomainSuggestedAddress,
  hDomainWhoisProfileTypes,
  WhoisDataType,
  WhoIsProfileTypeKey,
  RegistroBrErrorType,
  ResponseError,
  RequestConfig,
} from '@/types';
import { DOMAIN_REGISTRAR, WHOIS_PROFILE_TYPES } from '@/types';

type StoreWhoIsProfileResponseType = [
  {
    data: WhoIsProfile;
  },
  { error: RegistroBrErrorType | ResponseError | string } | null,
];

const WHOIS_PROFILE_DISABLED_REASONS_CONTACT_CHANGE = {
  hasPendingAction: 'v2.domains.contact.change.has.pending.action',
  hasPendingTrade: 'v2.domains.contact.change.has.pending.trade',
  hasPendingActionOldRegistrant:
    'v2.domains.contact.change.has.pending.action.old.registrant',
  hasPendingActionNewRegistrant:
    'v2.domains.contact.change.has.pending.action.new.registrant',
  hasPendingActionOldAndNewRegistrant:
    'v2.domains.contact.change.has.pending.action.old.and.new.registrant',
} as const;

const WHOIS_PROFILE_DISABLED_REASONS_DOMAIN_MOVE = {
  hasPendingAction: 'v2.domains.domain.move.has.pending.action',
  hasPendingActionOldRegistrant:
    'v2.domains.domain.move.has.pending.action.old.registrant',
  hasPendingActionNewRegistrant:
    'v2.domains.domain.move.has.pending.action.new.registrant',
  hasPendingActionOldAndNewRegistrant:
    'v2.domains.domain.move.has.pending.action.old.and.new.registrant',
} as const;

export const useWhoisStore = defineStore('whoisStore', () => {
  const hDomainWhoisProfileTypes = ref<hDomainWhoisProfileTypes>({
    ownerWhoisProfile: {
      slug: 'owner',
      label: 'v2.domains.whois.profile.type.registrant',
      disabled: false,
    },
    adminWhoisProfile: {
      slug: 'admin',
      label: 'v2.domains.whois.profile.type.administrative',
      disabled: false,
    },
    billingWhoisProfile: {
      slug: 'billing',
      label: 'v2.domains.whois.profile.type.billing',
      disabled: false,
    },
    techWhoisProfile: {
      slug: 'tech',
      label: 'v2.domains.whois.profile.type.technical',
      disabled: false,
    },
  });
  const hDomainWhoisProfiles = ref<WhoIsProfile[]>([]);
  const lastRegisteredWhoisProfileId = ref<number | null>(null);
  const hDomainWhoisProfilesUsage = ref<Record<string, any>>();
  const isHDomainWhoisProfilesLoading = ref<boolean>(false);
  const isPerformingWhoIsAction = ref<boolean>(false);
  const performingWhoIsAction = ref<boolean>(false);
  const whoIsManagementStep = ref<number>(1);
  const hDomainWhoisProfilePreUpdate = ref<any>();
  const tldSettings = ref<Record<string, IHDomainTLDSettings>>({});
  const whoIsLtAddressOptions = ref<WhoIsSelectOption[]>([]);
  const whoIsLtAddressRaw = ref<IHDomainSuggestedAddress[]>([]);
  const availableDomainActions = ref<Record<string, any>>({
    admin: { isModifyAllowed: true },
    billing: { isModifyAllowed: true },
    owner: { isModifyAllowed: true },
    tech: { isModifyAllowed: true },
  });

  const getHDomainWhoisProfileTypes = (domain: string) => {
    if (hDomainWhoisProfileTypes.value === undefined) return;

    const profileTypes: hDomainWhoisProfileTypes = cloneDeep(
      hDomainWhoisProfileTypes.value,
    );

    const { getDomainData } = useDomainManagementStore();
    const domainDetails: { [key: string]: any } = getDomainData(domain) || {};

    Object.keys(profileTypes).forEach((key) => {
      if (!domainDetails[key]) delete profileTypes[key];
      if (profileTypes[key]) {
        profileTypes[key]!.name = key;
      }
    });

    if (
      !domainDetails.tldSettings?.isModifyOwnerSupported &&
      profileTypes.ownerWhoisProfile
    ) {
      profileTypes.ownerWhoisProfile.disabled = true;
    }

    if (
      domainDetails.registrar === DOMAIN_REGISTRAR.REGISTRO_BR &&
      profileTypes.adminWhoisProfile
    ) {
      profileTypes.adminWhoisProfile.disabled = true;
    }

    return profileTypes;
  };

  const getHDomainWhoisProfilesIds = computed(() =>
    hDomainWhoisProfiles.value?.map(({ id }) => id),
  );
  const getTldAllowedCountries = (tld: string) => {
    if (Object.values(tldSettings.value).length === 0) return;

    return tldSettings.value[tld]?.allowedCountries;
  };

  const getHDomainWhoisProfileById = (id: number) =>
    hDomainWhoisProfiles.value?.find((profile) => profile.id === id);

  const getHDomainWhoisProfilesUsage = (id: number) =>
    hDomainWhoisProfilesUsage.value && hDomainWhoisProfilesUsage.value[id];

  const fetchAllowedCountries = async (tld: string) => {
    const [{ data }, err] = await hDomainsRepo.getTldSettings(tld);

    if (!err) {
      tldSettings.value[data.tld] = data;
    }
  };

  const fetchWhoIsProfiles = async (tld?: string) => {
    const response = await hDomainsRepo.getWhoises(tld);

    if (response[0] && 'data' in response[0]) {
      const { data } = response[0];

      hDomainWhoisProfiles.value = data;
    }
    await fetchWhoisUsage();
    isHDomainWhoisProfilesLoading.value = false;
  };

  const fetchWhoisUsage = async () => {
    if (!getHDomainWhoisProfilesIds.value?.length) return;
    const [{ data }, err] = await hDomainsRepo.getWhoisUsage(
      getHDomainWhoisProfilesIds.value,
    );

    if (!err) {
      hDomainWhoisProfilesUsage.value = data;
    }
  };

  const storeWhoisProfile = async (
    whoisData: WhoisDataType,
    config: RequestConfig = {},
  ): Promise<StoreWhoIsProfileResponseType> => {
    const [{ data }, err] = await hDomainsRepo.storeWhoisProfile(
      whoisData,
      config,
    );
    if (!err) {
      addWhoIsProfile(data);
    }

    return [{ data }, err];
  };

  const addWhoIsProfile = (profile: WhoIsProfile) => {
    if (hDomainWhoisProfiles.value) {
      hDomainWhoisProfiles.value = [...hDomainWhoisProfiles.value, profile];
    } else {
      hDomainWhoisProfiles.value = [profile];
    }
  };

  const whoisProfileDestroy = async (whoisId: string) => {
    const [, err] = await hDomainsRepo.deleteWhois(whoisId);

    if (!err) {
      hDomainWhoisProfiles.value = hDomainWhoisProfiles.value.filter(
        ({ id }) => id !== parseFloat(whoisId),
      );
    }
  };

  const whoisProfileUpdate = async (whoisData: any) => {
    isPerformingWhoIsAction.value = true;

    const [{ data }, err] = await hDomainsRepo.changeWhoisProfile(whoisData);
    await fetchDomainChangeStatus(whoisData.domain);

    whoIsManagementStep.value = 1;
    hDomainWhoisProfilePreUpdate.value = whoisData;
    isPerformingWhoIsAction.value = false;

    return [{ data }, err];
  };

  const fetchDomainChangeStatus = async (domain: string) => {
    const [{ data }, err] = await hDomainsRepo.getDomainChangeStatus(domain);

    if (!err) {
      availableDomainActions.value = data;
    }
  };

  const setWhoIsManagementStep = (step: number) =>
    (whoIsManagementStep.value = step);
  const setIsPerformingWhoIsAction = (isPerformingAction: boolean) =>
    (isPerformingWhoIsAction.value = isPerformingAction);
  const setWhoIsLtAddressOptions = (options: WhoIsSelectOption[]) =>
    (whoIsLtAddressOptions.value = options);
  const setWhoIsLtAddressRaw = (options: IHDomainSuggestedAddress[]) =>
    (whoIsLtAddressRaw.value = options);

  const setLastRegisteredWhoisProfileId = (id: number) =>
    (lastRegisteredWhoisProfileId.value = id);

  const resetLastRegisteredWhoisProfileId = () =>
    (lastRegisteredWhoisProfileId.value = null);

  const isWhoIsProfileTypeKey = (key: string): key is WhoIsProfileTypeKey =>
    key in WHOIS_PROFILE_TYPES;

  const getIsContactChangeAvailable = (whoisType: string) => {
    if (isWhoIsProfileTypeKey(whoisType)) {
      const shortWhoIsType = WHOIS_PROFILE_TYPES[whoisType];

      return availableDomainActions.value[shortWhoIsType]?.isModifyAllowed;
    }

    return false;
  };

  const lastRegisteredWhoisProfile = computed(() => {
    if (!lastRegisteredWhoisProfileId.value) return null;

    return getHDomainWhoisProfileById(lastRegisteredWhoisProfileId.value);
  });

  const isDomainMoveAvailable = computed(
    () => availableDomainActions.value.owner?.isTradeAllowed,
  );

  const getUnavailableContactChangeReason = () => {
    const reasons = availableDomainActions.value.owner;

    if (
      reasons.hasPendingActionOldRegistrant &&
      reasons.hasPendingActionNewRegistrant
    ) {
      return WHOIS_PROFILE_DISABLED_REASONS_CONTACT_CHANGE.hasPendingActionOldAndNewRegistrant;
    }

    return (
      (reasons.hasPendingActionOldRegistrant &&
        WHOIS_PROFILE_DISABLED_REASONS_CONTACT_CHANGE.hasPendingActionOldRegistrant) ||
      (reasons.hasPendingActionNewRegistrant &&
        WHOIS_PROFILE_DISABLED_REASONS_CONTACT_CHANGE.hasPendingActionNewRegistrant) ||
      (reasons.hasPendingTrade &&
        WHOIS_PROFILE_DISABLED_REASONS_CONTACT_CHANGE.hasPendingTrade) ||
      (reasons.hasPendingAction &&
        WHOIS_PROFILE_DISABLED_REASONS_CONTACT_CHANGE.hasPendingAction) ||
      ''
    );
  };

  const getUnavailableDomainMoveReason = () => {
    const reasons = availableDomainActions.value.owner;

    if (
      reasons.hasPendingActionOldRegistrant &&
      reasons.hasPendingActionNewRegistrant
    ) {
      return WHOIS_PROFILE_DISABLED_REASONS_DOMAIN_MOVE.hasPendingActionOldAndNewRegistrant;
    }

    return (
      (reasons.hasPendingActionOldRegistrant &&
        WHOIS_PROFILE_DISABLED_REASONS_DOMAIN_MOVE.hasPendingActionOldRegistrant) ||
      (reasons.hasPendingActionNewRegistrant &&
        WHOIS_PROFILE_DISABLED_REASONS_DOMAIN_MOVE.hasPendingActionNewRegistrant) ||
      (reasons.hasPendingAction &&
        WHOIS_PROFILE_DISABLED_REASONS_DOMAIN_MOVE.hasPendingAction) ||
      ''
    );
  };

  return {
    hDomainWhoisProfiles,
    whoIsManagementStep,
    tldSettings,
    isHDomainWhoisProfilesLoading,
    performingWhoIsAction,
    hDomainWhoisProfileTypes,
    hDomainWhoisProfilePreUpdate,
    isPerformingWhoIsAction,
    hDomainWhoisProfilesUsage,
    whoIsLtAddressOptions,
    whoIsLtAddressRaw,
    isDomainMoveAvailable,
    getIsContactChangeAvailable,
    getHDomainWhoisProfilesUsage,
    fetchAllowedCountries,
    getTldAllowedCountries,
    addWhoIsProfile,
    getHDomainWhoisProfileTypes,
    fetchWhoIsProfiles,
    setWhoIsManagementStep,
    storeWhoisProfile,
    whoisProfileUpdate,
    fetchWhoisUsage,
    setIsPerformingWhoIsAction,
    setWhoIsLtAddressRaw,
    setWhoIsLtAddressOptions,
    whoisProfileDestroy,
    fetchDomainChangeStatus,
    getUnavailableContactChangeReason,
    getUnavailableDomainMoveReason,
    getHDomainWhoisProfileById,
    lastRegisteredWhoisProfileId,
    lastRegisteredWhoisProfile,
    resetLastRegisteredWhoisProfileId,
    setLastRegisteredWhoisProfileId,
  };
});
