import { mapActions, mapMutations, mapGetters } from 'vuex';

import AddWebsiteRankingCoachModal from '@/components/Modals/HModal/AddWebsiteRankingCoachModal.vue';
import RenewModal from '@/components/Modals/HModal/Purchase/RenewModal.vue';
import { useOnboardingV2, useResourceMapper } from '@/composables';
import { useOnboardingStore } from '@/stores';
import {
  Route,
  SubscriptionName,
  SubscriptionResourceType,
  SubscriptionStatus,
  ALLOWED_CB_REACTIVATION_SERVICES,
  SubscriptionCancelReason,
  Service,
  HRESOURCES_TYPE,
} from '@/types';
import statusText from '@/utils/billingItemStatusTextHelper';
import {
  toLocalTime,
  toUnicode,
  getSplittedDomainParts,
} from '@/utils/helpers';
import { getServiceType, isFreshInvoice } from '@/utils/helpers/hBillingHelper';

export default {
  props: {
    order: {
      type: Object,
      default: null,
    },
    domainResource: {
      type: Object,
      default: null,
    },
  },
  created() {
    if (this.hasFreshUnpaidInvoice) {
      this.pingSubcriptionInvoices();
    }
  },
  data() {
    return {
      triesToFetchInvoices: 1,
    };
  },
  computed: {
    hasSubscription() {
      return !!this.subscription;
    },
    hasOrder() {
      return !!this.order;
    },
    hasUnpaidOrder() {
      if (!this.order) return false;

      return (
        [
          SubscriptionStatus.AWAITING_PAYMENT,
          SubscriptionStatus.PAYMENT_INITIATED,
        ].includes(this.order.status) &&
        !this.isCanceled &&
        this.order.paymentLink
      );
    },
    hasUnpaidInvoice() {
      if (!this.subscription) return false;

      return (
        this.subscription.dueInvoicesCount && !this.isCanceled && !this.isPaused
      );
    },
    isSubscriptionReactivatable() {
      return this.subscription?.canReactivate;
    },
    isSubscriptionRenewable() {
      return this.subscription?.canRenew;
    },
    hasResource() {
      if (!this.hasSubscription) return false;

      return 'resource' in this.subscription;
    },
    isCanceled() {
      if (this.hasSubscription) {
        return this.subscription.status === SubscriptionStatus.CANCELLED;
      }
      if (this.hasOrder) {
        return this.order.status === SubscriptionStatus.CANCELLED;
      }

      return false;
    },
    isCancelReasonRefund() {
      return (
        this.subscription?.cancelReasonCode === SubscriptionCancelReason.REFUND
      );
    },
    isDomain() {
      return this.subscription?.resource?.type === SubscriptionStatus.DOMAIN;
    },
    isDomainTransfer() {
      return (
        this.subscription?.resourceType ===
          SubscriptionResourceType.DOMAIN_TRANSFER.toUpperCase() ||
        this.subscription?.resource?.type ===
          SubscriptionResourceType.DOMAIN_TRANSFER
      );
    },
    hasPendingOrderSetup() {
      if (this.hasUnpaidInvoice || this.hasUnpaidOrder) return false;

      return (
        this.subscription?.resource?.state === SubscriptionStatus.PENDING_SETUP
      );
    },
    showSetup() {
      return this.hasPendingOrderSetup && !this.isCanceled;
    },
    isCpanelReseller() {
      return (
        this.subscription?.resourceType ===
        Service.Type.CPANEL_RESELLER_HOSTING.toUpperCase()
      );
    },
    subscriptionTitle() {
      if (this.isCpanelReseller) {
        return `${this.subscription?.resource?.metadata?.planTitle} - ${this.subscription?.resource?.title}`;
      }

      const subscriptionName = this.isDomainTransfer
        ? 'Domain Transfer'
        : this.subscription.items[0].name;
      let domain =
        this.subscription?.resource?.metadata?.domain ||
        this.subscription?.resource?.config?.domain ||
        this.subscription?.resource?.title ||
        this.subscription?.cfSubscriptionParams?.domain ||
        this.subscription?.metaData?.domain;

      if (
        !domain &&
        [HRESOURCES_TYPE.HOSTING, HRESOURCES_TYPE.CPANEL_HOSTING].includes(
          this.subscription?.resource?.type,
        )
      ) {
        domain = this.subscription?.resource?.title;
      }

      return domain
        ? this.$t(`${subscriptionName} - {domainName}`, {
            domainName: toUnicode(domain),
          })
        : this.$t(subscriptionName);
    },
    orderTitle() {
      const item = this.order?.items ? this.order.items[0] : null;
      const externalName = item ? item.externalName : '';
      const domain = item?.metadata?.domain;
      const subtype = item?.itemId?.includes('hostingermail') ? ' Email' : '';

      return domain
        ? this.$t(`${externalName}${subtype} - {domainName}`, {
            domainName: toUnicode(domain),
          })
        : this.$t(externalName + subtype);
    },
    title() {
      if (this.hasSubscription) return this.subscriptionTitle;
      if (this.hasOrder) return this.orderTitle;

      return 'Untitled';
    },
    iconColor() {
      return {
        success: this.isSuccess || null,
        warning: this.isWaring || null,
        danger: this.isDanger || null,
      };
    },
    isSuccess() {
      return [SubscriptionName.AUTO_RENEW_ON].includes(this.statusText);
    },
    isWaring() {
      return [
        SubscriptionName.AUTO_RENEW_OFF,
        SubscriptionName.PAYMENT_PROCESSING,
        SubscriptionName.PENDING_REGISTRATION,
        SubscriptionName.SERVICE_EXPIRED,
        SubscriptionName.PENDING_SETUP,
        SubscriptionName.PENDING_PAYMENT,
        SubscriptionName.IN_PROGRESS,
        SubscriptionName.GRACE_PERIOD,
      ].includes(this.statusText);
    },
    isDanger() {
      return [
        SubscriptionName.PAYMENT_FAILED,
        SubscriptionName.SERVICE_CANCELED,
        SubscriptionName.AUTO_RENEW_FAILED,
        SubscriptionName.FAILED,
        SubscriptionName.REDEMPTION_PERIOD,
        SubscriptionName.SERVICE_SUSPENDED,
      ].includes(this.statusText);
    },
    statusText() {
      return statusText({
        order: this.order,
        subscription: this.subscription,
        domainResource: this.domainResource,
      });
    },
    statusIcon() {
      switch (this.statusText) {
        case SubscriptionName.GRACE_PERIOD:
        case SubscriptionName.REDEMPTION_PERIOD:
        case SubscriptionName.SERVICE_EXPIRED:
          return 'icon-error';
        case SubscriptionName.PENDING_PAYMENT:
          return 'ic-pause-circle';
        case SubscriptionName.PAYMENT_FAILED:
        case SubscriptionName.FAILED:
          return 'ic-times-outline';
        case SubscriptionName.SERVICE_CANCELED:
          return 'ic-minus-circle';
        case SubscriptionName.PAYMENT_PROCESSING:
        case SubscriptionName.IN_PROGRESS:
          return 'ic-timelapse';
        case SubscriptionName.SERVICE_SUSPENDED:
          return 'ic-pause-circle';
        case SubscriptionName.PENDING_REGISTRATION:
        case SubscriptionName.PENDING_SETUP:
          return 'ic-pause-circle';
        case SubscriptionName.AUTO_RENEW_ON:
        case SubscriptionName.AUTO_RENEW_OFF:
        case SubscriptionName.AUTO_RENEW_FAILED:
          return 'billing-payment-history';
        default:
          return '';
      }
    },
    statusTooltipText() {
      switch (this.statusText) {
        case SubscriptionName.GRACE_PERIOD:
          return this.$t(
            'Domain expired. You can still renew the domain until {gracePeriodDate} for a regular fee.',
            {
              gracePeriodDate: toLocalTime(
                this.domainResource.additionalDetails.gracePeriodEndDate,
              ),
            },
          );
        case SubscriptionName.REDEMPTION_PERIOD:
          return this.$t(
            'Domain expired. You can still renew the domain until {redemptionPeriodDate}.',
            {
              redemptionPeriodDate: toLocalTime(
                this.domainResource.additionalDetails.redemptionPeriodEndDate,
              ),
            },
          );
        case SubscriptionName.SERVICE_SUSPENDED:
          return this.$t('The account is suspended. Please contact us.');
        case SubscriptionName.PENDING_PAYMENT:
          return this.$t('Complete the payment to activate the service');
        case SubscriptionName.PAYMENT_FAILED:
          return this.$t('The payment failed, try again. {reason}', {
            reason: '',
          });
        case SubscriptionName.SERVICE_EXPIRED:
          return this.$t(
            'We were not able to renew your subscription. Renew now to continue using the service.',
          );
        case SubscriptionName.SERVICE_CANCELED:
          return this.$t('Services canceled and will not renew');
        case SubscriptionName.PAYMENT_PROCESSING:
          return this.$t(
            'Payment confirmation may take up to 24 hours. You can pay now if you did not pay yet.',
          );
        case SubscriptionName.PENDING_REGISTRATION:
          return this.$t(
            'The domain is not registered yet. Proceed with setup to register the domain.',
          );
        case SubscriptionName.PENDING_SETUP:
          return this.$t('Setup to activate services');
        case SubscriptionName.AUTO_RENEW_ON:
          return this.$t('Subscription will renew automatically');
        case SubscriptionName.AUTO_RENEW_OFF:
          return this.$t('Your service will not be renewed');
        case SubscriptionName.AUTO_RENEW_FAILED:
          return this.$t(
            'Auto-renewal failed, we will try again on {nextPaymentDate}',
            { nextPaymentDate: '' },
          );
        case SubscriptionName.IN_PROGRESS:
          return this.$t(
            'Changes are in progress, it may take several minutes',
          );
        case SubscriptionName.FAILED:
          return this.$t(
            'Currently not available, please contact us for further information',
          );
        default:
          return '';
      }
    },
    inProgressPinging() {
      return this.hasFreshUnpaidInvoice && this.triesToFetchInvoices !== 0;
    },
    hasFreshUnpaidInvoice() {
      if (!this.subscription?.invoices) return false;
      const invoice = this.subscription?.invoices.find(
        (item) => item.status !== SubscriptionStatus.PAID,
      );
      if (!invoice) return false;

      return isFreshInvoice(invoice.date);
    },
    isSuspended() {
      if (!this.hasResource) return false;

      return this.subscription.resource.state === SubscriptionStatus.SUSPENDED;
    },
    isPaused() {
      return this.subscription.status === SubscriptionStatus.PAUSED;
    },
    isAbuse() {
      if (!this.isSuspended) return false;

      return this.subscription.resource.reason === 'abuse';
    },
    subscriptionDomain() {
      return (
        this.subscription.metaData?.domain ||
        this.subscription.cfSubscriptionParams?.domain
      );
    },
    ...mapGetters('subscriptions', ['getSubscriptionById']),
  },
  methods: {
    openReactivate() {
      const item = this.subscription?.items?.find(({ resourceType }) =>
        ALLOWED_CB_REACTIVATION_SERVICES.includes(resourceType?.toLowerCase()),
      );

      if (!item) return;

      this.openReactivateModal({
        itemPriceId: item?.itemPriceId,
        subscriptionId: this.subscription.id,
        service: this.subscriptionTitle,
        quantity: item?.quantity || 1,
        serviceType: getServiceType(item.resourceType),
        isEmail: getServiceType(item.resourceType) === Service.Type.EMAIL,
        onSuccess: () => {
          this.$store.dispatch('fetchHostingOrders');
          this.$store.dispatch('billingItems/getBillingItems');
        },
      });
    },
    openReactivateModal(data) {
      this.$openModal({
        component: { RenewModal },
        data: {
          title: this.$t('Reactivate Your {service}', {
            service: data.service,
          }),
          subtitle: this.$t('Review the details and proceed to checkout'),
          reactivate: true,
          ...data,
        },
        steps: [
          {
            hideX: true,
          },
        ],
      });
    },
    setup(location) {
      if (!this.hasResource) return;
      switch (this.subscription.resource.type) {
        case 'hosting': {
          const { openContinueOnboardingModal } = useOnboardingV2();
          const { subscriptionsWithOnboardingProgress } = useOnboardingStore();

          if (
            subscriptionsWithOnboardingProgress.find(
              (subscriptionId) => this.subscription.id === subscriptionId,
            )
          ) {
            openContinueOnboardingModal(this.subscription.id, () =>
              this.$router.push({
                name: Route.OnboardingV2.BASE_PATH,
                params: {
                  order_id: this.subscription.id,
                },
              }),
            );

            return;
          }

          this.$router.push(`/${this.subscription.id}/lets-start`);

          return;
        }

        case 'domain': {
          this.$router.push({
            name: 'register-domain-resource',
            params: { domain: this.subscriptionDomain },
          });

          return;
        }

        case 'domain_transfer': {
          const [domainSld, domainTld] = getSplittedDomainParts(
            this.subscription.metaData.domain,
          );

          this.$store.commit('SET_REDIRECT', {
            domain_sld: domainSld,
            domain_tld: domainTld,
          });

          this.$router.push({
            name: 'transfer',
            params: {
              domain: this.subscription.metaData.domain,
              subscriptionId: this.subscription.id,
            },
          });

          return;
        }

        case 'virtual_machine': {
          this.$router.push(`/${this.subscription.id}/start-setup`);

          return;
        }

        case 'google_workspace': {
          this.$router.push({
            name: Route.Email.GOOGLE_WORKSPACE_SETUP,
            params: {
              orderId: this.subscription.resource.referenceId,
            },
            query: {
              location,
            },
          });

          return;
        }

        case 'titan_mail': {
          this.$router.push({
            name: Route.Email.TITAN_ONBOARDING_START,
            params: {
              orderId: this.subscription.id,
            },
            query: {
              location,
            },
          });

          return;
        }

        case 'email': {
          this.$router.push({
            name: Route.Email.EMAIL_ONBOARDING_START,
            params: {
              orderId: this.subscription.id,
            },
            query: {
              location,
            },
          });

          return;
        }

        case 'cpanel_hosting': {
          this.$router.push({
            name: 'onboarding_start',
            params: {
              order_id: this.subscription.id,
              planId: this.subscription.resource.referenceId,
              cpanel: true,
            },
          });

          return;
        }

        case 'ranking_coach': {
          const { getMappedMonolithOrderById } = useResourceMapper();

          this.$openModal({
            component: { AddWebsiteRankingCoachModal },
            data: {
              order: getMappedMonolithOrderById(this.subscription.id),
            },
          });

          return;
        }
      }
    },
    async getSubscriptionInvoicesAndMutateBillingItem() {
      await this.getSubscriptionInvoices({
        subscriptionId: this.subscription.id,
      });
      this.UPDATE_MAPPED_BILLING_ITEM_SUBSCRIPTION(
        this.getSubscriptionById(this.subscription.id),
      );
    },
    pingSubcriptionInvoices() {
      const pingInvoices = setInterval(() => {
        if (this.triesToFetchInvoices === 0 || !this.hasFreshUnpaidInvoice) {
          clearInterval(pingInvoices);

          return;
        }
        this.getSubscriptionInvoicesAndMutateBillingItem();
        this.triesToFetchInvoices = this.triesToFetchInvoices - 1;
      }, 8000);
    },
    ...mapActions('subscriptions', ['getSubscriptionInvoices']),
    ...mapMutations('billingItems', [
      'UPDATE_MAPPED_BILLING_ITEM_SUBSCRIPTION',
    ]),
  },
};
