import type { RouteRecordRaw } from 'vue-router';

import { referralsGuard } from '@/guards/referralsGuard';
import { Route } from '@/types';
import { RouteOwner } from '@/types/enums/routeOwnerEnum';

export default [
  {
    path: '/referrals',
    name: Route.Referral.REFERRALS,
    redirect: { name: Route.Referral.REFFER_AND_EARN },
    meta: {
      title: 'Referrals',
      hideTitle: true,
      rateServicesTitle:
        'How would you rate your experience using the Referral program?',
      routeOwner: RouteOwner.WEB_PRO,
    },
    component: () => import('@/views/User/Referrals/ReferralsWrapper.vue'),
    beforeEnter: referralsGuard,
    children: [
      {
        path: '',
        name: Route.Referral.DASHBOARD,
        component: () =>
          import('@/views/User/Referrals/v2/ReferralsDashboard.vue'),
        children: [
          {
            path: '',
            name: Route.Referral.REFFER_AND_EARN,
            meta: {
              breadcrumbs: null,
              hideBackButton: true,
            },
            component: () =>
              import('@/views/User/Referrals/v2/ReferAndEarn.vue'),
          },
          {
            path: 'my-referrals',
            name: Route.Referral.MY_REFERRALS,
            meta: {
              breadcrumbs: null,
              hideBackButton: true,
            },
            component: () =>
              import('@/views/User/Referrals/v2/MyReferrals.vue'),
          },
        ],
      },
      {
        path: 'wire-transfer',
        name: Route.Referral.WIRE_TRANSFER,
        meta: {
          title: 'Add Wire Transfer Account',
          header: 'simple-header',
          showSubheader: true,
          breadcrumbs: false,
          headerBackButton: true,
          hideBackButton: false,
          hideTitle: false,
        },
        component: () => import('@/views/User/Referrals/WireTransfer.vue'),
      },
    ],
  },
  {
    path: '/select-hosting-plan',
    name: Route.Referral.SELECT_HOSTING_PLAN,
    meta: {
      title: 'Select Hosting Plan',
      hideTitle: true,
      sideMenuExists: false,
      breadcrumbs: false,
      routeOwner: RouteOwner.WEB_PRO,
    },
    component: () =>
      import(
        '@/views/HostingerPro/ClientPurchase/HostingPurchaseProWrapper.vue'
      ),
  },
  {
    path: '/purchase-summary',
    name: Route.Referral.PLAN_SELECTION_SUMMARY,
    meta: {
      sideMenuExists: false,
      breadcrumbs: false,
      routeOwner: RouteOwner.WEB_PRO,
    },
    component: () =>
      import('@/views/HostingerPro/ClientPurchase/ClientPurchaseSummary.vue'),
  },
] as RouteRecordRaw[];
