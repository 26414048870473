export namespace HRefund {
  export const PaymentMethodId = {
    DEFAULT: 'default-payment-method',
    HOSTINGER_BALANCE: 'hostinger-balance',
  } as const;

  export const RefundReasonKeys = {
    I_DONT_NEED_THESE_SERVICES_ANYMORE: 'v2.i.dont.need.these.services.anymore',
    I_DONT_HAVE_ENOUGH_TIME_AT_THE_MOMENT:
      'v2.i.dont.have.enough.time.at.the.moment',
    BUSINESS_NAME_HAS_CHANGED: 'v2.business.name.has.changed',
    IT_LACKS_THE_FEATURES_I_NEED: 'v2.it.lacks.the.features.i.need',
    ITS_TOO_EXPENSIVE: 'v2.its.too.expensive',
    IVE_EXPERIENCED_TECHNICAL_ISSUES: 'v2.ive.experienced.technical.issues',
    ITS_TOO_DIFFICULT_TO_USE: 'v2.its.too.difficult.to.use',
    THE_CUSTOMER_SUPPORT_IS_UNHELPFUL: 'v2.the.customer.support.is.unhelpful',
    I_WANT_TO_BUY_ANOTHER_HOSTINGER_SERVICE:
      'v2.i.want.to.buy.another.hostinger.service',
    OTHER: 'v2.other',
  };

  export const RefundReasonValues = {
    [RefundReasonKeys.I_DONT_NEED_THESE_SERVICES_ANYMORE]:
      "I don't need these services anymore",
    [RefundReasonKeys.I_DONT_HAVE_ENOUGH_TIME_AT_THE_MOMENT]:
      "I don't have enough time at the moment",
    [RefundReasonKeys.BUSINESS_NAME_HAS_CHANGED]: 'Business name has changed',
    [RefundReasonKeys.IT_LACKS_THE_FEATURES_I_NEED]:
      'It lacks the features I need',
    [RefundReasonKeys.ITS_TOO_EXPENSIVE]: "It's too expensive",
    [RefundReasonKeys.IVE_EXPERIENCED_TECHNICAL_ISSUES]:
      "I've experienced technical issues",
    [RefundReasonKeys.ITS_TOO_DIFFICULT_TO_USE]: "It's too difficult to use",
    [RefundReasonKeys.THE_CUSTOMER_SUPPORT_IS_UNHELPFUL]:
      'The customer support is unhelpful',
    [RefundReasonKeys.I_WANT_TO_BUY_ANOTHER_HOSTINGER_SERVICE]:
      'I want to buy another Hostinger service',
    [RefundReasonKeys.OTHER]: 'Other',
  };

  export const RefundTimeEstimates = {
    0: 'v2.4.dash.7.business.days',
    1: 'v2.7.dash.14.business.days',
    2: 'v2.instant',
    3: 'v2.up.to.14.business.days',
  };

  export const StepKeys = {
    0: 'services',
    1: 'refund-reason',
    2: 'refund-method',
    3: 'summary',
  };
}
