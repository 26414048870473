//JSON-slugs-migrated
import { merge } from 'lodash';
import { defineStore } from 'pinia';
import { computed, ref } from 'vue';

import { useGlobals } from '@/composables';
import { type HotspotId, type HotspotConfig, HOTSPOT } from '@/types';

export const useHotspotStore = defineStore(
  'hotspotStoreV2',
  () => {
    const { t } = useGlobals();

    const getHotspotsTexts = (): Partial<
      Record<HotspotId, Partial<HotspotConfig>>
    > => ({
      accessListProPanel: {
        title: t('v2.manage.your.clients.with.pro.panel'),
      },
      domainFromDescriptionHotspot: {
        title: t('v2.try.out.our.ai.domain.generator.exclamation'),
        description: t(
          'v2.enter.a.description.about.your.project.and.get.suggested.multiple.ai.generated.domain.names',
        ),
      },
      proWebsitesListFilter: {
        title: t('v2.edit.your.websites.list'),
        description: t(
          'v2.now.you.can.manage.both.your.personal.and.client.projects.in.one.place.dot.just.add.the.websites.you.need.to.the.list.dot',
        ),
      },
      twoFactorHotspot: {
        title: t('v2.secure.account.colon.enable.two.factor.authentication'),
        description: t(
          'v2.ensure.seamless.integration.of.the.mobile.authentication.program.with.your.hostinger.account.dot',
        ),
      },
      marketplaceHotspot: {
        title: t('v2.explore.new.marketplace'),
        description: t(
          'v2.our.updated.marketplace.interface.simplifies.product.exploration.comma.creating.a.more.efficient.shopping.experience',
        ),
      },
      vcTerminalHotspot: {
        title: t('v2.access.your.vps.through.browser'),
        description: t(
          'v2.introducing.browser.terminal.access.for.your.vps.exclamation.say.goodbye.to.clunky.external.terminals.and.hello.to.a.smooth.comma.streamlined.experience.dot',
        ),
      },
      noCacheHotspot: {
        title: t('v2.disable.cache.for.your.website'),
        description: t(
          'v2.this.may.be.useful.if.website.has.frequently.updated.information.dot.however.comma.this.can.also.slow.down.websites.loading.time.and.increase.server.load.comma.so.its.important.to.weigh.the.pros.and.cons.before.making.a.decision.dot',
        ),
      },
      vpsKodeeHotspot: {
        title: t('v2.kodee.vps.intro_title'),
        description: t('v2.kodee.vps.intro_description'),
        actionTitle: t('v2.got_it'),
      },
    });

    const hotspots = ref<Record<HotspotId, HotspotConfig>>(
      merge(
        {
          websitesFavouritesHotspot: {
            id: 'websitesFavouritesHotspot',
            isClickable: false,
            priority: 110,
            indicatorPosition: {
              left: -7,
              top: -8,
            },
          },
          accessListProPanel: {
            id: 'accessListProPanel',
            priority: 100,
          },
          domainFromDescriptionHotspot: {
            id: 'domainFromDescriptionHotspot',
            priority: 110,
            indicatorPosition: {
              left: 0,
              top: -8,
            },
          },
          proWebsitesListFilter: {
            indicatorPosition: { top: 8, left: 8 },
            id: 'proWebsitesListFilter',
            priority: 100,
          },
          twoFactorHotspot: {
            id: 'twoFactorHotspot',
            priority: 100,
          },
          twoFactorLeadingHotspot: {
            id: 'twoFactorHotspot',
            isClickable: false,
            priority: 100,
            indicatorPosition: {
              left: 0,
              top: -14,
            },
          },
          marketplaceHotspot: {
            id: 'marketplaceHotspot',
            priority: 90,
          },
          vcTerminalHotspot: {
            id: 'vcTerminalHotspot',
            priority: 80,
          },
          noCacheHotspot: {
            id: 'noCacheHotspot',
            placement: 'right',
            priority: 70,
          },
          vpsKodeeHotspot: {
            id: HOTSPOT.VPS_KODEE_HOTSPOT,
            imgPath: '@/images/kodee/kodee-intro.png',
            isImmediate: true,
            isIndicator: false,
            placement: 'right',
            priority: 200,
          },
        },
        getHotspotsTexts(),
      ),
    );

    const closedHotspots = computed(() =>
      Object.values(hotspots.value).filter(({ isClosed }) => isClosed),
    );

    const unclosedHotspots = computed(() =>
      Object.values(hotspots.value)
        .filter(({ isClosed, isRegistered }) => !isClosed && isRegistered)
        .sort((a, b) => (b?.priority || 0) - (a?.priority || 0)),
    );

    const currentlyVisibleHotspots = computed(() => {
      const [topHotspot] = unclosedHotspots.value;

      if (!topHotspot) {
        return [];
      }

      return unclosedHotspots.value.filter(({ id }) => id === topHotspot.id);
    });

    const setHotspotsTexts = () => {
      hotspots.value = merge(hotspots.value, getHotspotsTexts());
    };

    const isHotspotClosed = (id: HotspotId) =>
      closedHotspots.value.some((hotspot) => hotspot.id === id);

    const closeHotspot = (id: HotspotId) => {
      hotspots.value[id].isClosed = true;
    };

    const registerHotspot = (id: HotspotId) => {
      if (!hotspots.value[id]) {
        return;
      }

      hotspots.value[id].isRegistered = true;
    };

    const unregisterHotspot = (id: HotspotId) => {
      hotspots.value[id].isRegistered = false;
    };

    return {
      hotspots,
      closedHotspots,
      unclosedHotspots,
      currentlyVisibleHotspots,
      setHotspotsTexts,
      isHotspotClosed,
      closeHotspot,
      registerHotspot,
      unregisterHotspot,
    };
  },
  {
    persist: true,
  },
);
