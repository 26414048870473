import type { CaptureContext } from '@sentry/types';
import type {
  Event as SentryEvent,
  User as SentryUser,
  Breadcrumb as SentryBreadcrumb,
} from '@sentry/vue';
import * as Sentry from '@sentry/vue';
import type { AxiosError } from 'axios';
import dayjs from 'dayjs';
import cookies from 'js-cookie';
import { isString } from 'lodash';
import type { App } from 'vue';
import type { Router } from 'vue-router';

import type { ResponseError, HAsyncError } from '@/types';
import {
  BreadcrumbCategory,
  BreadcrumbLogLevel,
  Header,
  Cookie,
} from '@/types';
import { getParsedJwtToken } from '@/utils/helpers/authHelpers';
import { shouldIgnoreBasedOnSampleRate } from '@/utils/services/sampleRateService';
import { removeKeysFromObject } from '@/utils/services/sanitizeService';

window.$hpanel = {
  dumpLogs: () => {},
  reportBug: () => {},
  version: process.env.VITE_VERSION,
};

export const ERROR_FETCH_DYNAMIC_MODULE =
  'Failed to fetch dynamically imported module';
export const ERROR_LOAD_DYNAMIC_MODULE = 'loading dynamically imported module';
export const ERROR_PRELOAD_CSS = 'Unable to preload CSS';

const ERROR_IGNORE_GET_BREADCRUMBS = 'custom-get-breadcrumbs';
const ERROR_LOADING_PR_PREVIEW =
  'LOADING_SOURCE_CODE: Preview app failed to load';
const ERROR_FORTER_INTERNAL_ERROR =
  'keys: currentTarget, detail, isTrusted, target';
const ERROR_RESIZE_OBSERVER_LOOP = [
  'ResizeObserver loop completed with undelivered notifications.',
  'ResizeObserver loop limit exceeded',
];
const ERROR_EXTENSION = [
  'Error: Extension context invalidated.',
  `CustomEvent: Event ${'`'}CustomEvent${'`'} (type=unhandledrejection) captured as promise rejection`,
];

const ERROR_LOADING_TRUST_PILOT = 'Failed to load trustpilot script';

const ERROR_TRUST_PILOT_TYPEERROR = [
  `undefined is not an object (evaluating 'window.Trustpilot.Modules')`,
  "Cannot read properties of undefined (reading 'Modules')",
  `can't access property "Modules", window.Trustpilot is undefined`,
  'window.Trustpilot is undefined',
];

const ERROR_UET_IS_NOT_DEFINED = [
  'UET is not defined',
  'ReferenceError: UET is not defined',
];

const ERROR_IGNORE_PATTERNS = [
  ERROR_IGNORE_GET_BREADCRUMBS,
  ERROR_LOADING_PR_PREVIEW,
  ERROR_FORTER_INTERNAL_ERROR,
  ERROR_LOADING_TRUST_PILOT,
  ...ERROR_EXTENSION,
  ...ERROR_RESIZE_OBSERVER_LOOP,
];

const USER_KEYS_TO_SANITIZE = [
  'phoneCc',
  'phone',
  'address1',
  'address2',
  'firstName',
  'lastName',
  'name',
  'email',
  'fullAddress',
  'addressName',
  'taxCountry',
  'country',
  'countryName',
  'city',
  'state',
  'zip',
] as const;

const IGNORED_PERFORMANCE_SPAN_PATTERNS = [
  'getbeamer.com',
  '/api/direct/hevents/amplitude',
  'frontend-event-api.hostinger.com',
  'app.clearbit.com',
  'pusher.com',
  '.linkedin.',
  'analytics.tiktok.com',
  'facebook.com',
  'quora.com',
  'google-analytics.com',
];

let breadcrumbs: SentryBreadcrumb[] | undefined;
let lastErrorData: {
  id?: string;
  message?: string;
  backendServiceName?: string;
  serviceName?: any;
  backendPathname?: any;
  errorResponseMessage?: any;
} = {};

const sentrySpecificUserState = {
  isSentryErrorsIgnored: false,
};

const CUSTOM_ERROR_EXCEPTION_DETECTORS = [
  (sentryEvent: SentryEvent) =>
    sentryEvent.exception?.values?.some(
      ({ value, type }) => value === 'Failed to fetch' && type === 'TypeError',
    ),
  (sentryEvent: SentryEvent) =>
    sentryEvent.exception?.values?.some(
      ({ value }) =>
        value?.includes(ERROR_FETCH_DYNAMIC_MODULE) ||
        value?.includes(ERROR_LOAD_DYNAMIC_MODULE) ||
        value?.includes(ERROR_PRELOAD_CSS),
    ),
  (sentryEvent: SentryEvent) =>
    sentryEvent.exception?.values?.some(
      ({ value, type }) =>
        value === "Cannot read properties of null (reading 'remove')" &&
        type === 'TypeError',
    ),
  (sentryEvent: SentryEvent) =>
    sentryEvent.exception?.values?.some(
      ({ value, type }) =>
        value &&
        ERROR_UET_IS_NOT_DEFINED.includes(value) &&
        type === 'ReferenceError',
    ),
  (sentryEvent: SentryEvent) =>
    sentryEvent.exception?.values?.some(
      ({ value, type }) =>
        value &&
        ERROR_TRUST_PILOT_TYPEERROR.includes(value) &&
        type === 'TypeError',
    ),
  (sentryEvent: SentryEvent) =>
    sentryEvent.exception?.values?.some(({ value }) =>
      value?.includes('ResizeObserver'),
    ),
  (sentryEvent: SentryEvent) =>
    sentryEvent.exception?.values?.some(
      ({ value, type }) =>
        value === "Cannot read properties of undefined (reading 'cookie')" &&
        type === 'TypeError',
    ),
  (sentryEvent: SentryEvent) =>
    sentryEvent.exception?.values?.some(
      ({ value, type }) =>
        value?.endsWith(
          '(type=unhandledrejection) captured as promise rejection',
        ) && type === 'CustomEvent',
    ),
  (sentryEvent: SentryEvent) =>
    sentryEvent.exception?.values?.some(({ value }) =>
      value?.includes('Cannot redefine property: googletag'),
    ),
  (sentryEvent: SentryEvent) =>
    sentryEvent.tags?.errorResponseMessage ===
    'Domain does not belong to this client',
];

const isMatchingCustomDetector = (sentryEvent: SentryEvent) =>
  CUSTOM_ERROR_EXCEPTION_DETECTORS.some((detector) => detector(sentryEvent));

const mapExceptionTypesFromSentryEvent = (event: SentryEvent) =>
  event?.exception?.values?.map(({ type }) => type || '') || [];

const shouldIgnoreBasedOnErrorPattern = (errorPatterns: string[]) =>
  errorPatterns.some((errorPattern) =>
    ERROR_IGNORE_PATTERNS.includes(errorPattern),
  );

const captureException = (error: Error, name?: string) => {
  if (name) {
    error.name = name;
  }

  Sentry.captureException(error);
};

const getIsFirst24HourUser = () => {
  try {
    const createdAtDate = getParsedJwtToken()?.profileDetails?.createdAt;

    if (!createdAtDate) {
      return;
    }

    const createdAt = dayjs(createdAtDate);
    const now = dayjs();
    const isFirst24HourUser = now.diff(createdAt, 'hour') < 24;

    return isFirst24HourUser;
  } catch (error) {
    console.error(error);
  }
};

export const setSentryTagIsFirst24HourUser = async () => {
  try {
    const isFirst24HourUser = getIsFirst24HourUser();
    Sentry.setTag('isFirst24HourUser', isFirst24HourUser);
  } catch (error) {
    console.error(error);
  }
};

const logInfo = (message: string, captureContext?: CaptureContext) => {
  Sentry.captureMessage(message, {
    level: 'info',
    ...captureContext,
  });
};

const logError = (
  error: Error | string | HAsyncError | null,
  name?: string,
) => {
  const errorObj = error as Error;
  const errorString = error as string;
  const errorResponseError = error as ResponseError;
  const errorResponseErrorObject = error as { error: ResponseError };

  if (!error) {
    captureException(new Error('empty error'), name);

    return;
  }

  if (!!errorObj.stack) {
    captureException(errorObj, name);

    return;
  }

  if (isString(errorString)) {
    captureException(new Error(errorString), name);

    return;
  }

  if (!!errorResponseError.code) {
    captureException(
      new Error(
        errorResponseError?.message || errorResponseError.code.toString(),
      ),
      name,
    );

    return;
  }

  if (errorResponseErrorObject.error) {
    captureException(new Error(errorResponseErrorObject.error.message), name);

    return;
  }
};
export type LogErrorParams = ArgumentTypes<typeof logError>;
export type LogErrorReturn = ReturnType<typeof logError>;

const loggerStub = {
  setClientForErrorLogger: () => {},
  logError: (error: Error | string | HAsyncError | null) =>
    console.error(error),
  logInfo: (message: string) => console.warn(message),
  addAxiosErrorBreadcrumb: () => {},
  addBreadcrumb: () => {},
  getBreadCrumbs: () => ({}),
  getLastErrorData: () => {},
  setTag: () => {},
  ignoreSentryErrors: () => {},
  isIgnoredBasedOnState: () => {},
};

const sanitizeUserData = (user: SentryUser) =>
  removeKeysFromObject(user, USER_KEYS_TO_SANITIZE);

const setClientForErrorLogger = (client: Object) => {
  Sentry.setUser(sanitizeUserData(client));
};

const addBreadcrumb = (breadcrumbData: { name: string; data: object }) => {
  try {
    const message = isString(breadcrumbData)
      ? breadcrumbData
      : JSON.stringify(breadcrumbData);
    Sentry.addBreadcrumb({
      message,
      category: BreadcrumbCategory.CUSTOM_BREADCRUMB,
      level: BreadcrumbLogLevel.INFO,
    });
  } catch (error) {
    Sentry.captureException(new Error('addBreadcrumb error'));
  }
};

const setTag = (
  tagName: string,
  tagValue?: string | number | symbol | null,
) => {
  Sentry.setTag(tagName, tagValue);
};

const addAxiosErrorBreadcrumb = (error: AxiosError) => {
  try {
    const { response, config } = error;
    const { code, message, debug } = (response?.data as any)?.error || {};

    const file = debug?.file;
    const line = debug?.line;
    const correlationId = config?.headers?.[Header.CORRELATION_ID];

    Sentry.addBreadcrumb({
      message: JSON.stringify({
        code,
        message,
        file,
        line,
        correlationId,
        errorMessage: error.message,
      }),
      category: BreadcrumbCategory.REQUEST_ERROR,
      level: BreadcrumbLogLevel.INFO,
    });
  } catch (error) {
    Sentry.captureException(new Error('addAxiosErrorBreadcrumb error'));
  }
};

const syncBreadcrumbs = () => {
  Sentry.captureMessage(ERROR_IGNORE_GET_BREADCRUMBS);
};

const addDumpLogFunction = (newFunction: Function) => {
  const nextFunction = window.$hpanel.dumpLogs;
  window.$hpanel.dumpLogs = () => {
    newFunction();
    nextFunction();
  };
};

const setSentryTagsFromCookies = () => {
  try {
    const gaCookie = cookies.get(Cookie.GA_ID);
    const e2eTestId = cookies.get(Cookie.E2E_TEST_ID);
    const language = cookies.get(Cookie.LANGUAGE);

    if (gaCookie) {
      setTag(Cookie.GA_ID, gaCookie);
    }
    if (e2eTestId) {
      setTag(Cookie.E2E_TEST_ID, e2eTestId);
    }
    if (language) {
      setTag(Cookie.LANGUAGE, language);
    }
  } catch {}
};

const addReportBugFunction = () => {
  window.$hpanel.reportBug = () => {
    const replay = Sentry.getReplay();
    replay?.start();
    logInfo('Manual bug report started');

    window.setTimeout(() => {
      replay?.flush();
      logInfo('Manual bug report finished');
    }, 60000);
  };
};

export const initializeErrorLogger = (app: App, router: Router) => {
  if (!process.env.VITE_SENTRY_DSN) {
    return;
  }

  Sentry.init({
    app,
    dsn: process.env.VITE_SENTRY_DSN,
    environment: process.env.VITE_SENTRY_ENVIRONMENT_NAME,
    debug: !process.env.VITE_SENTRY_DSN,
    release: process.env.VITE_VERSION,
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 0.01,
    integrations: [
      Sentry.replayIntegration({
        maskAllText: true,
        blockAllMedia: true,
      }),
      Sentry.browserTracingIntegration({
        router,
        shouldCreateSpanForRequest: (url) =>
          !IGNORED_PERFORMANCE_SPAN_PATTERNS.some((pattern) =>
            url.includes(pattern),
          ),
      }),
    ],
    tracingOptions: {
      timeout: 1000,
    },
    tracesSampler: () => 0.005,
    beforeBreadcrumb(breadcrumb) {
      const isJWTIncluded = JSON.stringify(breadcrumb.data || {}).includes(
        'jwt=',
      );
      if (isJWTIncluded) {
        breadcrumb.data = { message: '[filtered, reason:jwt]' };
      }

      return breadcrumb;
    },
    beforeSend: (event, hint) => {
      const eventName = event.message || '';
      const exceptionTypes = mapExceptionTypesFromSentryEvent(event);
      const errorPatterns = [eventName, ...exceptionTypes];

      breadcrumbs = event.breadcrumbs;

      if (shouldIgnoreBasedOnErrorPattern(errorPatterns)) {
        return null;
      }

      if (shouldIgnoreBasedOnSampleRate()) {
        return null;
      }

      if (sentrySpecificUserState.isSentryErrorsIgnored) {
        return null;
      }

      if (isMatchingCustomDetector(event)) {
        return null;
      }

      lastErrorData = {
        id: hint.event_id,
        message: (hint.originalException as any)?.message,
        serviceName: event.tags?.serviceName,
        backendPathname: event.tags?.backendPathname,
        errorResponseMessage: event.tags?.errorResponseMessage,
      };

      return event;
    },
  });
  setSentryTagsFromCookies();
  setSentryTagIsFirst24HourUser();
  addReportBugFunction();
};

const getErrorLogger = () => {
  if (!process.env.VITE_SENTRY_DSN) {
    return loggerStub;
  }

  const getBreadCrumbs = () => {
    syncBreadcrumbs();

    return breadcrumbs;
  };

  const getLastErrorData = () => lastErrorData;

  addDumpLogFunction(() => {
    console.error(JSON.stringify(getBreadCrumbs()));
  });

  return {
    addAxiosErrorBreadcrumb,
    addBreadcrumb,
    setClientForErrorLogger,
    logError,
    logInfo,
    setTag,
    getBreadCrumbs,
    getLastErrorData,
    ignoreSentryErrors: (ignoreTime?: number) => {
      sentrySpecificUserState.isSentryErrorsIgnored = true;
      if (ignoreTime) {
        window.setTimeout(() => {
          sentrySpecificUserState.isSentryErrorsIgnored = false;
        }, ignoreTime);
      }
    },
  };
};

export const errorLogger = getErrorLogger();
