export const BUILDER_DOMAINS = [
  'sites.google.com',
  'blogspot.com',
  'blogger.com',
  'wix.com',
  'wixsite.com',
  'shopify.com',
  'myshopify.com',
  'weebly.com',
  'squarespace.com',
  'godaddysites.com',
  'webflow.io',
  'webflow.com',
  'nuvemshop.com.br',
  'jimdo.com',
  'jimdofree.com',
  'duda.co',
  'dudaone.com',
  'univer.se',
  'onuniverse.com',
  'builder-preview.com',
  'canva.site',
  'canva.com',
  'odoo.com',
  'dns-parking.com',
  'site123.com',
  'bravenet.com',
  'website.com',
  'looka.com',
  'wixstudio.io',
  'ecwid.com',
  'equiplaunch.com',
  'sitesgpt.com',
  'fitune.io',
  'webwavecms.com',
  'meloja.com.br',
  'strikingly.com',
  'unicornplatform.com',
  'ueni.com',
  'websitebuilder.com',
  'webstarts.com',
  'freelogoservices.com',
  'greatpages.com.br',
  'wizishop.com',
  'monetizze.com.br',
  'site123.me',
  'shop.com',
  'mixo.io',
  'weeblycloud.com',
  'orbitpages.com',
  'one.com',
  'ladipage.com',
  'renderforestsites.com',
  'wocode.com',
  'tiendanube.com',
  'ccsweb.es',
  'framer.website',
  'framer.app',
  'wfolio.com',
  'netlify.app',
  'builderall.com',
  'turbify.com',
  'square.com',
  'tuconstructorweb.com',
  'myideasoft.com',
  'hotmart.com',
  'youcan.shop',
  'rediredi.com',
  'tailorbrands.com',
  '8b.com',
  'e-monsite.com',
  'heyleia.com',
  'websites.godaddy.com',
] as const;

export const INVALID_BLOCKED_WORDS = [
  'shopify',
  'gdrive',
  'niagahoster',
  'hpanel.hostinger',
  '000webhost',
  'hostinger.',
  'google.',
  'webador.',
  'webwave.',
];

export const INVALID_HOSTINGER_DOMAINS = [
  'hostinger.com',
  'builder.hostinger.com',
  'auth.hostinger.com',
  'preview-domain.com',
  'hostingersite.com',
  'hstgr.cloud',
  'hstgr.io',
  'main-hosting.eu',
] as const;

export const UNSUPPORTED_DOMAINS = [
  'wordpress.com',
  'main-hosting.eu',
  'google.com',
  'mysql.com',
  'registro.br',
  'cloudflare.com',
  'pwpush.com',
] as const;

export const INVALID_MIGRATION_DOMAINS = [
  ...UNSUPPORTED_DOMAINS,
  ...BUILDER_DOMAINS,
];

export const BUILDER_CMS = [
  'blogger',
  'wix',
  'shopify',
  'weebly',
  'webflow',
  'jimdo',
  'duda',
  'godaddywebsitebuilder',
  'squarespace',
] as const;
