import type { RouteRecordRaw } from 'vue-router';

import hVpsOnboardingGuard from '@/guards/hVpsOnboardingGuard';
import { Route } from '@/types';
import { RouteOwner } from '@/types/enums/routeOwnerEnum';

export default [
  {
    path: '/:order_id/start-setup',
    name: Route.VpsOnboarding.SERVER_ONBOARDING,
    meta: {
      showSubheader: false,
      fullscreen: true,
      isOnboarding: true,
      header: false,
      rateServicesTitle: 'How would you rate the setup process?',
      routeOwner: RouteOwner.VPS,
    },
    component: () => import('@/views/ServersOnboarding/Wrapper.vue'),
    children: [
      {
        path: '',
        name: Route.VpsOnboarding.SERVER_ONBOARDING_START,
        meta: {
          title: "Let's Go",
          step: 10,
          isOnboarding: true,
          showSubheader: false,
          isAccessRedirect: true,
        },
        beforeEnter: (to, from, next) => hVpsOnboardingGuard(to, from, next),
        component: () => import('@/views/ServersOnboarding/Start.vue'),
      },
      {
        path: 'choose-vps-location',
        name: Route.VpsOnboarding.SERVER_ONBOARDING_LOCATION,
        meta: {
          title: 'Choose VPS location',
          step: 20,
          isOnboarding: true,
          showSubheader: false,
        },
        component: () => import('@/views/ServersOnboarding/Location.vue'),
      },
      {
        path: 'choose-os',
        name: Route.VpsOnboarding.SERVER_ONBOARDING_OS,
        meta: {
          title: 'Choose Operating system',
          step: 30,
          isOnboarding: true,
          showSubheader: false,
        },
        component: () =>
          import('@/views/ServersOnboarding/OperatingSystem.vue'),
      },
      {
        path: 'recommended-os',
        name: Route.VpsOnboarding.SERVER_ONBOARDING_OS_RECOMMENDED,
        meta: {
          title: 'Choose Operating system',
          step: 31,
          isOnboarding: true,
          showSubheader: false,
        },
        component: () =>
          import('@/views/ServersOnboarding/RecommendedOperatingSystem.vue'),
      },
      {
        path: 'additional-features',
        name: Route.VpsOnboarding.SERVER_ONBOARDING_ADDITIONAL_FEATURES,
        meta: {
          title: 'Additional Features',
          step: 32,
          isOnboarding: true,
          showSubheader: false,
        },
        component: () =>
          import('@/views/ServersOnboarding/AdditionalFeatures.vue'),
      },
      {
        path: 'create-os-password',
        name: Route.VpsOnboarding.SERVER_ONBOARDING_CREATE_OS_PASSWORD,
        meta: {
          title: 'Create Password',
          step: 33,
          isOnboarding: true,
          showSubheader: false,
        },
        component: () =>
          import('@/views/ServersOnboarding/OnboardingOsPassword.vue'),
      },
      {
        path: 'credentials',
        name: Route.VpsOnboarding.SERVER_ONBOARDING_CREDENTIALS,
        meta: {
          title: 'Choose Operating system',
          step: 40,
          isOnboarding: true,
          showSubheader: false,
        },
        component: () => import('@/views/ServersOnboarding/Credentials.vue'),
      },
      {
        path: 'summary',
        name: Route.VpsOnboarding.SERVER_ONBOARDING_SUMMARY,
        meta: {
          title: 'Summary',
          step: 50,
          isOnboarding: true,
          showSubheader: false,
        },
        component: () => import('@/views/ServersOnboarding/Summary.vue'),
      },
      {
        path: 'server-setting-up',
        name: Route.VpsOnboarding.SERVER_SETTING_UP,
        meta: {
          title: 'Setting up',
          step: 51,
          headerBackButton: false,
          isOnboarding: true,
          showSubheader: false,
          surveyLocation: 'vps-onboarding',
        },
        component: () => import('@/views/ServersOnboarding/SettingUp.vue'),
      },
      {
        path: 'server-is-ready',
        name: Route.VpsOnboarding.SERVER_ONBOARDING_COMPLETED,
        meta: {
          title: 'Completed',
          step: 52,
          headerBackButton: false,
          isOnboarding: true,
          showSubheader: false,
          surveyLocation: 'vps-onboarding',
        },
        component: () => import('@/views/ServersOnboarding/Completed.vue'),
      },
    ],
  },
] as RouteRecordRaw[];
