import type { RouteRecordRaw } from 'vue-router';

import store from '@/store';
import { useRefundHistoryStore } from '@/stores';
import type { CreditNoteId } from '@/types';
import { Route } from '@/types';
import { RouteOwner } from '@/types/enums/routeOwnerEnum';

const sideMenu = {
  sideMenuExists: true,
  feedback: true,
  wrapperName: 'billing',
};

const hbillingRoutes = [
  {
    path: '/billing',
    meta: {
      title: 'Billing',
      chargeBee: true,
      routeOwner: RouteOwner.HPANEL,
    },
    component: () => import('../../views/HBilling/HBillingWrapper.vue'),
    children: [
      {
        path: '/',
        name: 'billing',
        meta: {
          title: 'Billing',
          sideMenuExists: false,
          chargeBee: true,
        },
        redirect: { name: Route.HBilling.SUBSCRIPTIONS },
      },
      {
        path: 'subscriptions',
        name: Route.HBilling.SUBSCRIPTIONS,
        meta: {
          title: 'Subscriptions',

          sideMenuComponent: {
            menu: 'billing',
          },
          breadcrumbsTitle: {
            title: 'Subscriptions',
            translate: true,
          },
          breadcrumbs: ['billing'],
          menu: [{ name: 'billing', order: 1 }],
          icon: 'ic-updated',
          chargeBee: true,
          ...sideMenu,
        },
        component: () =>
          import('../../views/HBilling/HBillingSubscriptions.vue'),
      },
      {
        path: 'subscriptions/:id',
        name: Route.HBilling.SUBSCRIPTION_DETAILS,
        meta: {
          title: 'Subscription details',
          sideMenuExists: false,
          breadcrumbs: false,
          chargeBee: true,
        },
        component: () =>
          import('../../views/HBilling/HBillingSubscriptionDetails.vue'),
        beforeEnter: (to, _from, next) => {
          const getSubscriptionById =
            store.getters['subscriptions/getSubscriptionById'];
          if (!getSubscriptionById(to.params.id)) {
            next({ name: Route.HBilling.SUBSCRIPTIONS });
          } else next();
        },
      },
      {
        path: 'payment-history',
        meta: {
          title: 'Payment History',
          chargeBee: true,
        },
        component: () => import('../../views/Billing/BillingWrapper.vue'),
        children: [
          {
            path: '',
            name: Route.HBilling.PAYMENT_HISTORY,
            meta: {
              title: 'Payment History',

              sideMenuComponent: {
                menu: 'billing',
              },
              breadcrumbsTitle: {
                title: 'Payment History',
                translate: true,
              },
              breadcrumbs: ['billing'],
              menu: [{ name: 'billing', order: 2 }],
              icon: 'ic-receipt',
              chargeBee: true,
              ...sideMenu,
            },
            redirect: { name: Route.HBilling.PAYMENT_HISTORY_PAID },
          },
          {
            path: 'paid',
            name: Route.HBilling.PAYMENT_HISTORY_PAID,
            meta: {
              title: 'Payment History',

              chargeBee: true,
              sideMenuComponent: {
                menu: 'billing',
              },
              breadcrumbsTitle: {
                title: 'Paid',
                translate: true,
              },
              breadcrumbs: ['billing', 'billing-payment-history-cb'],
              ...sideMenu,
            },
            component: () =>
              import('../../views/HBilling/HBillingPaymentHistory.vue'),
          },
          {
            path: 'paid/:id',
            name: Route.HBilling.PAYMENT_HISTORY_DETAILS,
            meta: {
              title: 'Payment details',
              breadcrumbs: false,
              chargeBee: true,
              ...sideMenu,
              sideMenuExists: false,
            },
            component: () =>
              import('../../views/HBilling/HBillingPaymentHistoryDetails.vue'),
          },
          {
            path: 'refunded',
            name: Route.HBilling.REFUND_HISTORY,
            meta: {
              title: 'Payment History',

              chargeBee: true,
              sideMenuComponent: {
                menu: 'billing',
              },
              breadcrumbsTitle: {
                title: 'Refunded',
                translate: true,
              },
              breadcrumbs: ['billing', 'billing-payment-history-cb'],
              ...sideMenu,
            },
            component: () =>
              import('../../views/HBilling/HBillingPaymentHistory.vue'),
          },
          {
            path: 'refunded/:id',
            name: Route.HBilling.REFUND_HISTORY_DETAILS,
            meta: {
              title: 'Refund details',
              sideMenuExists: false,
              breadcrumbs: false,
              chargeBee: true,
            },
            component: () =>
              import(
                '../../views/HBilling/HBillingPaymentHistoryRefundDetails.vue'
              ),
            beforeEnter: (to, _from, next) => {
              const { getRefundItemByCreditNoteId } = useRefundHistoryStore();
              const item = getRefundItemByCreditNoteId(
                to.params.id as CreditNoteId,
              );

              if (!item) {
                next({ name: Route.HBilling.REFUND_HISTORY });
              } else next();
            },
          },
        ],
      },
      {
        path: 'payment-methods',
        name: Route.HBilling.PAYMENT_METHODS,
        meta: {
          title: 'Payment Methods',

          sideMenuComponent: {
            menu: 'billing',
          },
          breadcrumbsTitle: {
            title: 'Payment Methods',
            translate: true,
          },
          breadcrumbs: ['billing'],
          menu: [{ name: 'billing', order: 3 }],
          icon: 'billing-payment-method',
          chargeBee: true,
          ...sideMenu,
        },
        component: () =>
          import('../../views/HBilling/HBillingPaymentMethodView.vue'),
      },
      {
        path: 'payment-methods/:id',
        name: Route.HBilling.PAYMENT_METHOD_DETAILS,
        meta: {
          title: 'Payment Method Details',
          sideMenuExists: false,
          breadcrumbs: false,
        },
        component: () =>
          import('../../views/HBilling/HBillingPaymentMethodSingle.vue'),
        beforeEnter: (to, _from, next) => {
          const getPaymentMethodById =
            store.getters['paymentMethods/getPaymentMethodById'];
          if (!getPaymentMethodById(to.params.id)) {
            next({ name: Route.HBilling.PAYMENT_METHODS });
          } else next();
        },
      },
    ],
  },
] as RouteRecordRaw[];

export default hbillingRoutes;
