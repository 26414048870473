import cPanelPhpModule from './cpanel/cPanelPhpModule';
import cPanelWordPressModule from './cpanel/cPanelWordPressModule';
import accountModule from './hosting/accountModule';
import domainPointingModule from './hosting/domainPointingModule';
import onboardingModule from './hosting/onboardingModule';
import orderModule from './hosting/orderModule';

import cPanelDomainModule from '@/store/modules/cpanel/cPanelDomainModule';
import cPanelModule from '@/store/modules/cpanel/cPanelModule';
import { StoreEnum } from '@/types';

export default {
  modules: {
    order: orderModule,
    account: accountModule,
    domainPointing: domainPointingModule,
    onboarding: onboardingModule,
    [StoreEnum.ModuleNames.CPANEL]: cPanelModule,
    [StoreEnum.ModuleNames.CPANEL_WORDPRESS]: cPanelWordPressModule,
    [StoreEnum.ModuleNames.CPANEL_DOMAIN]: cPanelDomainModule,
    [StoreEnum.ModuleNames.CPANEL_PHP]: cPanelPhpModule,
  },
};
