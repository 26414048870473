<script setup lang="ts">
import cookies from 'js-cookie';
import { computed, ref } from 'vue';
import { useRouter } from 'vue-router';

import HChip from '@/components/HChips/HChip.vue';
import { useH5GWebsite } from '@/composables/h5g/useH5GWebsite';
import { useProPanelRoutes } from '@/composables/hostingerPro/useProPanelRoutes';
import { useReferralsPromotion } from '@/composables/referrals/useReferralsPromotion';
import { useCookies } from '@/composables/useCookies';
import { useGlobals } from '@/composables/useGlobals';
import { useReferrals } from '@/composables/useReferrals';
import { npsRepo, trustpilotRepo } from '@/repositories';
import { useFeedbackStore } from '@/stores/feedbackStore';
import { useFrontendSettingsStore } from '@/stores/frontendSettingsStore';
import { useLanguageStore } from '@/stores/languageStore';
import { useProfileStore } from '@/stores/profile/profileStore';
import { useReferralStore } from '@/stores/referralStore';
import {
  AmplitudeLocation,
  type CsatCustomIdentifierType,
  Route,
} from '@/types';
import {
  CSAT_CUSTOM_IDENTIFIER,
  Cookie,
  SNOOZE_DAYS,
  SURVEY_TYPE,
  AmplitudeEvent,
  HIcon,
} from '@/types';
import { mapNpsAnswers, timeout } from '@/utils/helpers';
import { errorLogger } from '@/utils/services/errorLogging';

type Props = {
  isOpen?: boolean;
  isOpenedByUser?: boolean;
};

const ONE_HOUR_IN_MS = 60 * 60 * 1000;
const THREE_DAYS_IN_MS = 3 * 24 * 60 * 60 * 1000;

const { t, amplitudeV2 } = useGlobals();
const emit = defineEmits<Emits>();
const props = defineProps<Props>();
const feedbackStore = useFeedbackStore();
const frontendSettingsStore = useFrontendSettingsStore();
const router = useRouter();
const profileStore = useProfileStore();
const languageStore = useLanguageStore();
const { setCookies } = useCookies();
const { isProPanelRoute } = useProPanelRoutes();
const { h5gWebsiteUid } = useH5GWebsite();
const { isCSATPromoActive, activePromotionCommissionInDollars } =
  useReferralsPromotion();
const referralStore = useReferralStore();
const { referralDiscountRate } = useReferrals();

const currentRating = ref<number | null>(null);

const feedback: any = {
  type: 'textarea',
  label: 'Write your feedback here',
  rows: 3,
};

const isLoading = ref(false);
const isSuccess = ref(false);

const trustpilotInvitationLink = ref<string>();

interface Emits {
  (eventName: 'h-popup-close'): void;
}

const feedbackMessage = computed(() => {
  if (feedbackStore.feedbackProps?.firstStepTitle) {
    return t(feedbackStore.feedbackProps.firstStepTitle);
  }

  if (!specificServiceFeedback.value) {
    return t(
      'How likely are you to recommend {brand} to a friend or colleague?',
      {
        brand: profileStore.clientBrandTitle,
      },
    );
  }

  if (preferCSATRateServicesTitle.value && rateServicesTitle.value) {
    return t(rateServicesTitle.value);
  }

  if (!wrapperName.value && !rateServicesTitle.value) {
    return t('How would you rate our services?');
  }

  return wrapperName.value
    ? t(`How would you rate ${wrapperName.value} services?`)
    : t(rateServicesTitle.value);
});

const ratingMessage = computed(() => {
  if (!currentRating.value) return;

  if (feedbackStore.feedbackProps?.secondStepTitle) {
    return t(feedbackStore.feedbackProps.secondStepTitle);
  }

  if (currentRating.value <= 3) {
    return t(
      'We are sad to disappoint you.<br>How can we improve your experience?',
    );
  }

  if (currentRating.value > 3 && currentRating.value <= 8) {
    return t('How can we improve your experience?');
  }

  return t(
    'We are happy you enjoy our services!<br>How can we improve your experience?',
  );
});

const wrapperName = computed(() => router.currentRoute.value.meta?.wrapperName);

const preferCSATRateServicesTitle = computed(
  () => router.currentRoute.value.query?.preferCSATRateServicesTitle,
);

const rateServicesTitle = computed(
  () => router.currentRoute.value.meta?.rateServicesTitle,
);

const goodRatingMessage = computed(() =>
  specificServiceFeedback.value ? 'Excellent' : 'Extremely likely',
);
const badRatingMessage = computed(() =>
  specificServiceFeedback.value ? 'Poor' : 'Not at all likely',
);

const specificServiceFeedback = computed(
  () => props.isOpenedByUser && (wrapperName.value || rateServicesTitle.value),
);

const isRatingAbove8 = computed(
  () => currentRating.value && currentRating.value > 8,
);

const isTrustpilotAvailable = computed(() => {
  const isNps = !props.isOpenedByUser;
  const isSubmittedSuccessfully = isSuccess.value;

  return (
    isNps &&
    isRatingAbove8.value &&
    trustpilotInvitationLink.value &&
    isSubmittedSuccessfully
  );
});

const isReferralsPromoVisible = computed(
  () => isRatingAbove8.value && isCSATPromoActive.value,
);

const referralsPromoText = computed(() =>
  t('v2.referrals.csat.promo.title', {
    openTag: '<strong>',
    closeTag: '</strong>',
    discountPercentage: `${referralDiscountRate.value * 100}%`,
    commissionAmountInDollars: activePromotionCommissionInDollars.value,
  }),
);

const setTrustpilotInvitationLink = async () => {
  const redirectUri = `${window.location.href}${
    window.location.search ? '&' : '?'
  }trustpilot=1`;

  const [{ data }] = await trustpilotRepo.getTrustpilotInvitationLink({
    email: profileStore.contact?.email || '',
    locale: languageStore.currentLanguageCode.replace('_', '-'),
    name: profileStore.fullName,
    location: profileStore.country || '',
    tags: ['nps'],
    redirectUri,
  });

  if (!data) {
    return;
  }

  amplitudeV2(AmplitudeEvent.Trustpilot.SHOWN);
  trustpilotInvitationLink.value = data.url;
};

const getSurveyLocation = () => {
  const currentRoute = router.currentRoute.value;

  if (currentRoute.meta?.surveyLocation) {
    return currentRoute.meta.surveyLocation;
  }

  return currentRoute.matched.at(-1)?.path;
};

const submitSurvey = async (values: any) => {
  errorLogger.addBreadcrumb({
    name: 'Survey: submitted',
    data: {
      isOpenedByUser: props.isOpenedByUser,
    },
  });

  const surveyType = props.isOpenedByUser ? SURVEY_TYPE.CSAT : SURVEY_TYPE.NPS;
  const request = getFeedbackRequest(values, surveyType);

  return surveyType === SURVEY_TYPE.CSAT
    ? await feedbackStore.submitCSAT(request)
    : await feedbackStore.submitNPS(request);
};

const getFeedbackRequest = (values: any, type: SURVEY_TYPE) => {
  const request: any = {};

  request.rating = currentRating.value;

  if (values.feedback) request.comment = values.feedback;

  const cSatIdentifier =
    router.currentRoute.value.query?.cSat ||
    feedbackStore.feedbackProps?.location;

  if (
    Object.values(CSAT_CUSTOM_IDENTIFIER).includes(
      cSatIdentifier as CsatCustomIdentifierType,
    )
  ) {
    request.location = cSatIdentifier;
  }

  if (type === SURVEY_TYPE.CSAT) {
    return mapNpsAnswers(
      {
        location: getSurveyLocation(),
        ...request,
      },
      { rating: 'score' },
    );
  }

  return mapNpsAnswers(request, { rating: 'recommend' });
};

const setCookieDisabledProPanelFeedbackCookie = () => {
  if (!(props.isOpenedByUser && isProPanelRoute.value)) return;

  const expirationDate = new Date(
    new Date().getTime() + (feedbackStore.expirationTime || ONE_HOUR_IN_MS),
  );

  cookies.set(Cookie.DISABLED_PRO_PANEL_FEEDBACK, 'true', {
    expires: expirationDate,
  });
};

const setCookieDisabledH5GFeedbackCookie = () => {
  if (!(props.isOpenedByUser && h5gWebsiteUid.value)) return;

  const expirationDate = new Date(
    new Date().getTime() + (feedbackStore.expirationTime || THREE_DAYS_IN_MS),
  );

  cookies.set(Cookie.DISABLED_H5H_FEEDBACK, 'true', {
    expires: expirationDate,
  });
};

const onSubmit = async ({ values }: any) => {
  isLoading.value = true;

  setCookieDisabledProPanelFeedbackCookie();
  setCookieDisabledH5GFeedbackCookie();

  if (isRatingAbove8.value) {
    await setTrustpilotInvitationLink();
  }

  const [, error] = await submitSurvey(values);

  if (error) {
    isLoading.value = false;

    return closePopup();
  }

  await snoozeSurvey();

  isSuccess.value = true;
  isLoading.value = false;

  if (isReferralsPromoVisible.value) {
    amplitudeV2(AmplitudeEvent.Csat.REFERRAL_PROMO_SHOWN);
  }
  if (isRatingAbove8.value) {
    return;
  }

  await timeout(4000);
  closePopup();
};

const closeAndProvideScore = async () => {
  await submitSurvey({ values: { feedback: '' } });
  closePopup();
  await snoozeSurvey();
};

const closePopup = async () => {
  if (props.isOpenedByUser) {
    setCookies(Cookie.FEEDBACK_CLOSED, 'true');
    frontendSettingsStore.toggleFeedbackOpen(false);
  } else {
    emit('h-popup-close');
    cookies.set('nps-closed', 'true', { expires: 30 });
  }
  feedbackStore.resetFeedbackProps();
};

const snoozeSurvey = async () => {
  await npsRepo.closeNps(
    props.isOpenedByUser ? SURVEY_TYPE.CSAT : SURVEY_TYPE.NPS,
    props.isOpenedByUser ? SNOOZE_DAYS.CSAT : SNOOZE_DAYS.NPS,
  );
};

const handleTrustpilotRedirect = async () => {
  window.open(trustpilotInvitationLink.value, '_blank');
  trustpilotInvitationLink.value = '';
  amplitudeV2(AmplitudeEvent.Trustpilot.ENTER);
  await timeout(30000);
  closePopup();
};

const onClosePopupClick = async () => {
  closePopup();
  await snoozeSurvey();
};

const redirectToReferrals = () => {
  closePopup();

  referralStore.referralsEntryLocation =
    AmplitudeLocation.Base.CSAT_REFERRAL_PROMO;

  router.push({ name: Route.Referral.REFERRALS });
};

(() => {
  // Show success state & fire event to amplitude after coming back from trustpilot
  const isComingFromTrustpilot =
    window.location.search?.includes('trustpilot=1');

  if (isComingFromTrustpilot) {
    amplitudeV2(AmplitudeEvent.Trustpilot.SCORED);
    isSuccess.value = true;
  }
})();
</script>

<template>
  <div class="nps">
    <template v-if="isTrustpilotAvailable">
      <div class="nps__title h-mb-8">
        <p class="text-heading-2 nps__title--text">
          {{ t('Rate us on Trustpilot') }}
        </p>
        <HpIcon
          :icon="HIcon.ICON_CLEAR"
          :width="32"
          :height="32"
          static-view-box
          gray
          class="nps__close"
          @click="closePopup"
        />
      </div>
      <div class="nps__rating-meaning h-mb-24">
        <p>
          {{
            t(
              'Your review on Trustpilot will assist others in picking their hosting provider and help us improve our services',
            )
          }}
        </p>
      </div>
      <div class="d-flex justify-content-end">
        <HButton
          v-qa-generate
          class="nps__submit nps__submit--trustpilot"
          :is-loading="isLoading"
          h-form-submit
          icon-append="ic-launch-24"
          @click="handleTrustpilotRedirect"
        >
          {{ $t('Rate us') }}
        </HButton>
      </div>
    </template>
    <template v-else-if="isSuccess">
      <div class="nps__title nps__title-success">
        <div class="nps__title-success-top">
          <div class="nps__title-success-text">
            <HImage
              src="@/images/statuses/status-active.svg"
              :alt="t('status active')"
              class="nps__image"
            />
            <p class="text-heading-2">{{ t('Thank you!') }}</p>
          </div>
          <!-- @vue-skip -->
          <HpIcon
            icon="ic-clear"
            :width="32"
            :height="32"
            static-view-box
            gray
            class="nps__close"
            @click="closePopup()"
          />
        </div>
        <div class="nps__message">
          <p class="text-body-2">
            {{ t('Your feedback will help us improve our services') }}
          </p>
        </div>
        <div v-if="isReferralsPromoVisible" class="nps__referrals-promo">
          <p v-safe-html="referralsPromoText" class="text-dark" />
          <HButton
            class="h-mt-16"
            icon-prepend="ic-gift-16"
            size="small"
            @click="redirectToReferrals"
          >
            {{ t('v2.invite.a.friend') }}
          </HButton>
        </div>
      </div>
    </template>
    <template v-else-if="!currentRating">
      <div class="nps__title">
        <p class="text-heading-2 nps__title--text">
          {{ feedbackMessage }}
        </p>
        <HpIcon
          :icon="HIcon.ICON_CLEAR"
          :width="32"
          :height="32"
          static-view-box
          gray
          class="nps__close"
          @click="onClosePopupClick"
        />
      </div>
      <div class="nps__rating-meaning nps__rating-meaning--mobile">
        <p>
          {{ t(badRatingMessage) }}
        </p>
      </div>
      <div class="nps__list">
        <HChip
          v-for="rating in 10"
          :key="rating"
          class="nps__chip"
          @select="currentRating = rating"
        >
          {{ rating }}
        </HChip>
      </div>

      <div class="nps__rating-meaning">
        <p class="nps__rating-meaning--not-likley">
          {{ t(badRatingMessage) }}
        </p>
        <p>
          {{ t(goodRatingMessage) }}
        </p>
      </div>

      <p class="text-body-2 nps__steps h-mb-8">
        {{ t('Question 1 of 2') }}
      </p>
    </template>
    <template v-else>
      <div class="nps__title">
        <Trans class="text-heading-2" tag="p" v-html="ratingMessage" />
        <!-- @vue-skip -->
        <HpIcon
          icon="ic-clear"
          :width="32"
          :height="32"
          static-view-box
          gray
          class="nps__close"
          @click="closeAndProvideScore"
        />
      </div>
      <HForm
        :disabled="isLoading"
        class="nps__form"
        @on-submit="onSubmit($event)"
      >
        <HFormField class="h-mb-0" name="feedback" :schema="feedback" />
        <div class="nps__steps">
          <div
            class="text-body-2 nps__steps-rated"
            @click="currentRating = null"
          >
            {{ t('Question 2 of 2') }}
          </div>
          <HButton
            v-qa-generate
            class="nps__submit"
            :is-loading="isLoading"
            h-form-submit
          >
            {{ $t('Submit') }}
          </HButton>
        </div>
      </HForm>
    </template>
  </div>
</template>

<style lang="scss">
@import '../../sass/components/net-promoter-score';
</style>
