import Ravelin from 'ravelinjs/core+track+encrypt+promise';

import { errorLogger } from '@/utils/services/errorLogging';

const initRavelin = async (publicKey: string, apiBase?: string) => {
  if (window.ravelinDeviceId) return;

  try {
    const ravelin = new Ravelin({
      key: publicKey,
      ...(apiBase && { api: apiBase }),
    });

    window.ravelinDeviceId = await ravelin.core.id();
  } catch (error) {
    errorLogger.logError(new Error('Failed to load Ravelin', { cause: error }));
  }
};

export default initRavelin;
