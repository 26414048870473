import type { RouteRecordRaw } from 'vue-router';

import googleWorkspaceRoutes from '@/router/routes/emails/googleWorkspaceRoutes';
import hostingEmails from '@/router/routes/emails/hostingEmails';
import titanEmailRoutes from '@/router/routes/emails/titanEmailRoutes';
import { Route } from '@/types';
import { RouteOwner } from '@/types/enums/routeOwnerEnum';

export default [
  {
    path: '/emails',
    meta: {
      title: 'Emails',
      routeOwner: RouteOwner.EMAILS,
    },
    children: [
      {
        path: '',
        name: Route.Email.EMAILS,
        meta: {
          title: 'Emails',
          sideMenuExists: false,
        },
        component: () => import('@/views/Emails/EmailsList.vue'),
      },
      ...googleWorkspaceRoutes,
      ...titanEmailRoutes,
      ...hostingEmails,
    ],
  },
] as RouteRecordRaw[];
