import type { NavigationGuard } from 'vue-router';

import { useDeveloperTools } from '@/composables/developerTools/useDeveloperTools';
import { useResourceMapper } from '@/composables/useResourceMapper';
import { Route } from '@/types';

export const developerToolsGuard: NavigationGuard = async (to) => {
  const { isDeveloperToolsEnabled, hasAccessToDeveloperToolsDashboard } =
    useDeveloperTools();
  const { isSubscriptionsAndResourcesLoaded, fetchResourcesAndSubscriptions } =
    useResourceMapper();

  if (!isSubscriptionsAndResourcesLoaded.value) {
    await fetchResourcesAndSubscriptions();
  }

  if (isDeveloperToolsEnabled.value) {
    if (!hasAccessToDeveloperToolsDashboard.value) {
      return to.name === Route.DeveloperTools.PURCHASE
        ? undefined
        : { name: Route.DeveloperTools.PURCHASE };
    }

    return;
  }

  return { name: Route.Base.HOME };
};

export const developerToolsDashboardGuard: NavigationGuard = async () => {
  const { hasAccessToDeveloperToolsDashboard } = useDeveloperTools();
  if (!hasAccessToDeveloperToolsDashboard.value) {
    return { name: Route.DeveloperTools.PURCHASE };
  }
};
