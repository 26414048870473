<script lang="ts" setup>
import { HIcon } from '@hostinger/hcomponents';
import { computed } from 'vue';

import MonospaceWrapper from '@/components/Modals/Parts/MonospaceWrapper.vue';
import { usePaymentMethod } from '@/composables/hBilling/usePaymentMethod';
import { useGlobals } from '@/composables/useGlobals';
import { usePaymentMethodStore } from '@/stores/paymentMethodStore';
import { Payment } from '@/types/enums/PaymentEnum';
import type { HIconUnion } from '@/types/enums/hIconEnum';
import type { PaymentMethod } from '@/types/models/billing/paymentMethodModels';

const { t } = useGlobals();

const { paymentMethodIcon, isCard, identifier, isPaymentMethodRazorpayCard } =
  usePaymentMethod();

const paymentMethodStore = usePaymentMethodStore();

const props = defineProps<{
  paymentMethod: PaymentMethod | null;
  highlighted: boolean;
  qaTag: string;
}>();

const razorPayCard = computed(() => {
  if (
    !props.paymentMethod ||
    !isPaymentMethodRazorpayCard(props.paymentMethod)
  ) {
    return {
      loading: false,
      revealed: false,
      digits: null,
    };
  }

  return {
    ...paymentMethodStore.razorPayDetails(props.paymentMethod),
  };
});

const getLabelText = computed(() => {
  if (!props.paymentMethod) {
    return '';
  }

  const isRazorpay = isPaymentMethodRazorpayCard(props.paymentMethod);

  if (props.paymentMethod.isExpired) {
    if (isRazorpay) {
      return t('v2.expired');
    }

    return t('v2.expired.date', {
      date: props.paymentMethod.displayExpiryDt,
    });
  }

  if (isRazorpay) {
    return '';
  }

  return t('v2.expires.date', {
    date: props.paymentMethod.displayExpiryDt,
  });
});

const cardDetails = computed<{
  identifier: string | null;
  icon: HIconUnion | null;
  label: string;
  type: string;
}>(() => {
  if (!props.paymentMethod) {
    return {
      identifier: t('v2.choose.a.different.payment.method'),
      icon: null,
      label: '',
      type: '',
    };
  }

  const details = {
    identifier: identifier(props.paymentMethod),
    icon: paymentMethodIcon(props.paymentMethod) as HIconUnion,
    label: '',
    type: props.paymentMethod.title,
  };

  if (isCard(props.paymentMethod)) {
    details.identifier = `${Payment.DOTS} ${details.identifier}`;
    details.label = getLabelText.value;
  }

  return details;
});

const componentToUse = computed(() => {
  if (props.paymentMethod && isCard(props.paymentMethod)) {
    return MonospaceWrapper;
  }

  return 'span';
});

const labelState = computed(() => {
  if (
    props.paymentMethod?.isExpired &&
    !props.paymentMethod.identifier?.includes('@')
  ) {
    return 'danger';
  }

  if (props.highlighted) {
    return 'primary';
  }

  return 'grayLight';
});
</script>

<template>
  <div
    class="payment-method"
    :class="{
      'payment-method--expired': paymentMethod?.isExpired,
      'payment-method--highlighted': highlighted,
    }"
  >
    <HpIcon
      v-if="cardDetails.icon"
      data-qa="payment-method-icon"
      :icon="cardDetails.icon"
      static-view-box
      class="payment-method__type"
    />
    <span v-if="cardDetails.type">
      {{ cardDetails.type }}
    </span>
    <template v-if="paymentMethod">
      <template v-if="isCard">
        <span
          v-if="
            isPaymentMethodRazorpayCard(paymentMethod) && razorPayCard?.loading
          "
        >
          <span>
            <Component
              :is="componentToUse"
              v-for="index in 4"
              :key="`${index}-1`"
            >
              {{ Payment.DOT }}
            </Component>
            <Component :is="componentToUse">&nbsp;</Component>
          </span>
          <span class="payment-method-id--loading">
            <Component
              :is="componentToUse"
              v-for="index in 4"
              :key="`${index}-2`"
            >
              {{ Payment.DOT }}
            </Component>
          </span>
        </span>
        <Component :is="componentToUse" v-else :data-qa="`${qaTag}-identifier`">
          {{ cardDetails.identifier }}
        </Component>
        <HIcon
          v-if="isPaymentMethodRazorpayCard(paymentMethod)"
          :color="highlighted ? 'primary' : 'gray'"
          :name="
            paymentMethodStore.razorPayDetails(paymentMethod).revealed
              ? 'ic-visibility-24'
              : 'ic-visibility-off-24'
          "
          class="payment-method__visibility"
          @mousedown.prevent
          @click.stop="paymentMethodStore.revealRazorPayDigits(paymentMethod)"
        />
        <HLabel v-if="cardDetails.label" :theme="labelState">
          {{ cardDetails.label }}
        </HLabel>
      </template>
      <template v-else>
        <span :data-qa="`${qaTag}-identifier`">
          {{ cardDetails.identifier }}
        </span>
      </template>
    </template>
    <template v-else>
      <HIcon
        dimensions="24px"
        :color="highlighted ? 'primary' : 'gray'"
        name="ic-credit-card-24"
      />
      <span>{{ t('v2.choose.a.different.payment.method') }}</span>
    </template>
  </div>
</template>

<style lang="scss" scoped>
.payment-method {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;

  color: var(--gray-dark);

  &__visibility {
    cursor: pointer;
  }

  &--expired {
    .icon-wrapper {
      opacity: 0.5;
    }

    color: var(--gray-darker);
  }

  &--highlighted {
    color: var(--primary);
  }
}

.payment-method-id {
  &--loading {
    span {
      opacity: 0;
      animation: blink 0.5s infinite;
    }

    span:nth-child(1) {
      animation-delay: 0s;
    }

    span:nth-child(2) {
      animation-delay: 0.1s;
    }

    span:nth-child(3) {
      animation-delay: 0.2s;
    }

    span:nth-child(4) {
      animation-delay: 0.3s;
    }
  }
}

@keyframes blink {
  0%,
  100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}
</style>
