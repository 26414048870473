import type { IHDomainResource } from '@hostinger/hdomains-status';
import type { HIconUnion } from 'hcomponents/dist/types';

import { Client } from '../enums';

import type {
  AmplitudeEvent,
  HelpPage,
  HInvoice,
  IHResource,
  ProWebsiteBase,
  VpsServer,
} from '@/types';

export const ALWAYS_VISIBLE_CHAT_BRAND_IDS = [
  Client.BrandId.Hostinger.PT,
  Client.BrandId.Hostinger.COM_AR,
  Client.BrandId.Hostinger.CO,
  Client.BrandId.Hostinger.MX,
  Client.BrandId.Hostinger.AE,
  Client.BrandId.Hostinger.LT,
  Client.BrandId.Hostinger.ES,
  Client.BrandId.Hostinger.COM_BR,
  Client.BrandId.Hostinger.FR,
  Client.BrandId.Hostinger.VN,
  Client.BrandId.Hostinger.IN_TH,
  Client.BrandId.Hostinger.HU,
  Client.BrandId.Hostinger.COM_HK,
  Client.BrandId.Hostinger.ES,
  Client.BrandId.Hostinger.MY,
  Client.BrandId.Hostinger.CO_ID,
  Client.BrandId.Other.WEBLINK,
  Client.BrandId.Other.HOSTMANIA,
  Client.BrandId.Other.NG_CO_ID,
] as const;

export type AlwaysVisibleChatBrandId =
  (typeof ALWAYS_VISIBLE_CHAT_BRAND_IDS)[keyof typeof ALWAYS_VISIBLE_CHAT_BRAND_IDS];
export const CRM_LINK_BASE = 'https://crm.hostinger.io/client/';

export interface HelpPrecheckResource {
  domainName: string;
  resourceId?: string;
  serverHostname?: string;
  website?: ProWebsiteBase | null;
}

export interface HelpPrecheckDomain {
  domain?: string;
  resource: HelpHdomainResource | null;
  registrar: string | null;
  tld: string | null;
}

export type HelpDomainOptions = {
  label: string;
  value: HelpHdomainResource | string | null;
};

export type HelpHdomainResource = Partial<IHDomainResource> & {
  isExternalDomain?: boolean;
};

export interface HelpPrecheckVPSResource {
  name: string;
  vps?: VpsServer;
}

export type HelpPrecheckCpanelResource = {
  name: string;
  resource?: IHResource;
  serverHostname?: string;
};

export interface HelpPrecheckMailboxResource {
  name: string;
  resource?: IHResource;
}

export interface IPrecheckData {
  icon?: HIconUnion;
  title: string;
  subtitle?: string;
  message: string;
  btnLabel: string;
  ctaCallback: Function | null;
  isLoading?: boolean;
  isMessageActionsHidden?: boolean;
  isCtaContactCS?: boolean;
  isBtnDisabled?: boolean;
  isInstantlyOpenTicketCreation?: boolean;
  assistanceAmplitudeEvent?: AmplitudeEvent.Help;
  showSecondAction?: boolean;
  secondActionCallback?: Function;
  secondActionTitle?: string;
  secondaryMessage?: string | null;
  actionBtnLabel?: string;
  actionCallback?: Function | null;
  secondaryActionBtnLabel?: string;
  secondaryActionCallback?: Function | null;
}

export type HelpPageDataItem = {
  title: string;
  id: any;
  hideFromNiagahoster?: boolean;
  items?: any[];
  action?: {
    route: string;
    label: string;
    icon: boolean;
  };
};

export type HelpPageTopic = {
  id: HelpPage.TopicId;
  label: string;
};

export type HelpPageDataElement = {
  title: string;
  description: string;
  team: string;
  id: string;
  items: HelpPageDataItem[];
};

export type HelpInvoiceOptions = {
  label: string;
  value: HInvoice | string | null;
};

export type HelpBillingInvoice = {
  invoice: HInvoice | string | null;
  meta?: {
    paymentGateway: string | null;
  };
};
