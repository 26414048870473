import { NEXT_BEST_ACTION } from '@hostinger/hdomains-status';
import { storeToRefs } from 'pinia';
import { computed } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';

import {
  defaultSupportedActions,
  useDomainStatus,
} from '@/composables/useDomainStatus';
import { useGlobals } from '@/composables/useGlobals';
import { useDomainsStatusStore } from '@/stores/domainsStatusStore';
import { useProfileStore } from '@/stores/profile/profileStore';
import { Route } from '@/types';
import { kebabToReadable } from '@/utils/helpers/helpers';

export const useDomainAvailabilityInfo = () => {
  const store = useStore();
  const route = useRoute();
  const { t } = useGlobals();

  const routeName = route.name ? String(route.name) : '';

  const {
    isNewDomain,
    getHostingWordpressSelectedAppData,
    getDomainInfoLoaded,
  } = store.getters;

  const domain = computed(() => {
    if (
      routeName.includes('wordpress') ||
      routeName === Route.HostingWordpress.MANAGE_STAGING_DASHBOARD
    ) {
      return getHostingWordpressSelectedAppData?.coreDetails?.domain;
    }

    return '';
  });

  const { domainStatus: domainStatusRef } = useDomainStatus(
    domain.value,
    defaultSupportedActions,
  );

  const domainStatus = computed(
    () => domainStatusRef.value?.domainStatus ?? {},
  );

  const { isLoading: domainStatusIsLoading } = storeToRefs(
    useDomainsStatusStore(),
  );

  const domainInactiveStatus = computed(() => {
    if (!getDomainInfoLoaded || domainStatusIsLoading.value) {
      return '';
    }

    const { nextBestAction, isDomainPointing, isInPropagationTime } =
      domainStatus.value ?? {};

    if (
      nextBestAction === NEXT_BEST_ACTION.RENEW_DOMAIN ||
      nextBestAction === NEXT_BEST_ACTION.RESTORE_DOMAIN
    ) {
      return 'not-paid';
    }

    if (nextBestAction === NEXT_BEST_ACTION.BUY_DOMAIN) {
      return 'not-registered';
    }

    if (isNewDomain && !isDomainPointing) {
      return 'inactive';
    }

    if (
      (!isDomainPointing &&
        nextBestAction !== NEXT_BEST_ACTION.FINISH_REGISTRATION) ||
      (nextBestAction === NEXT_BEST_ACTION.CONNECT_DOMAIN &&
        isInPropagationTime)
    ) {
      return 'not-pointing';
    }

    return '';
  });

  const showNotRegistered = computed(
    () => domainInactiveStatus.value === 'not-registered',
  );

  const inactiveTooltipText = computed(() => {
    const profileStore = useProfileStore();
    const statusText = t(kebabToReadable(domainInactiveStatus.value));
    if (!statusText) return '';
    const brandSuffix = ['not-pointing', 'not-registered'].includes(
      domainInactiveStatus.value,
    )
      ? profileStore.clientBrandTitle
      : '';

    return t('v2.feature.is.not.available', { statusText, brandSuffix });
  });

  return {
    domainInactiveStatus,
    showNotRegistered,
    inactiveTooltipText,
    getHostingWordpressSelectedAppData,
  };
};
