import { Client } from '@/types';

const baseUrlHostinger = 'https://www.hostinger.com';
const baseUrlSupportHostinger = 'https://support.hostinger.com';
const baseUrlNiagahoster = 'https://www.niagahoster.co.id';
const baseUrlSupportNiagahoster = 'https://support.niagahoster.co.id';

export const KB_URL_DATA = {
  default: {
    WP_LITE_SPEED: `${baseUrlHostinger}/tutorials/litespeed-website-optimization-tool/`,
    WP_OBJECT_CACHE: `${baseUrlSupportHostinger}/en/articles/6099689-how-to-manage-object-cache-for-my-wordpress-website`,
    WP_CREATE_STAGING_ENV: `${baseUrlSupportHostinger}/en/articles/5720286-how-to-create-a-wordpress-staging-environment`,
    CDN: `${baseUrlHostinger}/tutorials/what-is-cdn`,
    WP_IMAGE_OPTIMIZATION: `${baseUrlHostinger}/tutorials/complete-guide-to-image-optimization`,
    FIX_SSL_INSTALLATION: `${baseUrlSupportHostinger}/en/articles/6064886-how-to-fix-ssl-installation-failed-issues`,
    FIX_LIFETIME_SSL_INSTALLATION: `${baseUrlSupportHostinger}/en/articles/5613445-what-to-do-if-lifetime-ssl-installation-is-failed`,
    INSTALL_SSL: `${baseUrlSupportHostinger}/en/articles/1583258-how-to-install-my-lifetime-ssl-at-hostinger`,
    CHANGE_MAIN_DOMAIN: `${baseUrlSupportHostinger}/domains/can-i-change-my-domain`,
    HTACCESS_REDIRECT: `${baseUrlHostinger}/tutorials/how-to-redirect-a-domain`,
    COPY_WEBSITE: `${baseUrlSupportHostinger}/en/articles/6601521-how-to-copy-a-wordpress-website-to-another-domain-name`,
    CONNECT_FTP_ACCOUNT: `${baseUrlSupportHostinger}/en/articles/4480505-how-to-connect-to-ftp-account`,
    FIX_FTP_CONNECTION: `${baseUrlSupportHostinger}/en/articles/1583242-i-m-having-trouble-connecting-to-ftp-what-should-i-do`,
    CREATE_FTP_ACCOUNT: `${baseUrlHostinger}/tutorials/ftp/filezilla-ftp-configuration`,
    CONNECT_SSH: `${baseUrlSupportHostinger}/en/articles/1583245-how-can-i-connect-to-my-account-via-ssh`,
    BASIC_SSH_COMMANDS: `${baseUrlHostinger}/tutorials/ssh/basic-ssh-commands`,
    FIX_SSH_ERRORS: `${baseUrlSupportHostinger}/en/articles/5634587-how-to-solve-the-most-common-errors-in-ssh`,
    SETUP_EMAIL_FORWARDER: `${baseUrlSupportHostinger}/en/articles/1583221-how-to-set-up-a-forwarder-for-hostinger-email`,
    SETUP_EMAIL_CATCH_ALL: `${baseUrlSupportHostinger}/en/articles/1583450-how-to-set-up-a-catch-all-for-hostinger-email`,
    SETUP_EMAIL_ALIAS: `${baseUrlSupportHostinger}/en/articles/5240877-how-to-set-up-an-email-alias-for-hostinger-email`,
    SETUP_EMAIL_AUTO_REPLY: `${baseUrlSupportHostinger}/en/articles/1583449-how-to-set-up-an-automatic-reply-for-hostinger-email`,
    SETUP_EMAIL_DOMAIN: `${baseUrlSupportHostinger}/en/articles/4767973-how-to-set-up-a-domain-for-hostinger-email`,
    SETUP_EMAIL_DEVICE: `${baseUrlSupportHostinger}/en/articles/4305847-how-to-set-up-hostinger-emails-on-your-devices`,
    EMAIL_AUTO_CONFIG: `${baseUrlSupportHostinger}/en/articles/5030333-what-is-email-automatic-configuration`,
    CHECK_EMAIL_ACCESS_LOG: `${baseUrlSupportHostinger}/en/articles/5760253-how-to-check-access-logs-for-hostinger-email`,
    MIGRATE_EMAIL: `${baseUrlSupportHostinger}/en/articles/5866288-how-to-migrate-emails-to-hostinger`,
    UNLOCK_DOMAIN_TRANSFER: `${baseUrlSupportHostinger}/en/articles/5465728-how-to-unlock-your-domain-before-transferring-to-hostinger`,
    DOMAIN_POINTING: `${baseUrlSupportHostinger}/en/articles/1863967-how-to-point-a-domain-to-hostinger`,
    ENABLE_DISK_QUOTA: `${baseUrlSupportHostinger}/en/articles/4805487-how-to-enable-disk-quota-at-vps`,
    ENABLE_NFS_SUPPORT: `${baseUrlSupportHostinger}/en/articles/4797562-how-to-enable-network-file-system-nfs-at-vps`,
    USE_SSH_KEY: `${baseUrlSupportHostinger}/en/articles/4792364-how-to-use-ssh-keys-at-vps`,
    VPS_HELP: `${baseUrlSupportHostinger}/en/collections/944797-vps`,
    MAIN: `${baseUrlSupportHostinger}`,
    ADD_WEBSITE: `${baseUrlSupportHostinger}/en/articles/1583214-how-to-add-a-website`,
    INSTALL_WORDPRESS: `${baseUrlSupportHostinger}/en/articles/3220304-how-to-install-wordpress-using-auto-installer`,
    CLAIM_FREE_DOMAIN: `${baseUrlSupportHostinger}/en/articles/1583208-i-bought-a-premium-business-hosting-how-can-i-get-my-free-domain`,
    DOMAIN_ACTIVATION_TIME: `${baseUrlSupportHostinger}/en/articles/1583403-i-ve-just-registered-my-domain-when-will-it-be-available`,
    INVOICE_STATUS: `${baseUrlSupportHostinger}/en/articles/3592497-i-have-just-paid-but-invoice-is-unpaid-and-i-can-t-see-my-services`,
    REFUND: `${baseUrlSupportHostinger}/en/articles/3958999-how-to-request-a-refund-from-hostinger`,
    FIX_EMPTY_ACCOUNT: `${baseUrlSupportHostinger}/en/articles/2474251-why-is-my-account-empty`,
    FIX_PAYMENT_ERROR: `${baseUrlSupportHostinger}/en/articles/4147167-i-can-t-make-a-payment`,
    STOP_AUTO_RENEWAL: `${baseUrlSupportHostinger}/en/articles/1583234-how-to-stop-auto-renewal`,
    FIX_WEBSITE_ACCESS_ERROR: `${baseUrlSupportHostinger}/en/articles/1583286-i-cannot-access-my-website`,
    CREATE_WEBSITE_BACKUP: `${baseUrlSupportHostinger}/en/articles/2298928-how-to-make-backups-at-hostinger`,
    DNS_PROPAGATION: `${baseUrlSupportHostinger}/en/articles/4146975-what-is-dns-propagation`,
    FIX_SUSPENDED_ACCOUNT: `${baseUrlSupportHostinger}/en/articles/4146981-why-is-my-hosting-suspended-or-cancelled`,
    WEBSITE_OPTIMIZATION: `${baseUrlSupportHostinger}/en/articles/1583325-how-to-optimize-my-website-to-make-it-load-faster`,
    FIX_WP_MIXED_CONTENT: `http://support.hostinger.com/en/articles/3592520-how-to-fix-mixed-content-error-in-wordpress`,
    HTTPS_REDIRECT: `${baseUrlSupportHostinger}/en/articles/1583201-how-can-i-redirect-my-http-site-to-https`,
    DNS_ZONE_INFORMATION: `http://support.hostinger.com/en/articles/1583671-when-can-i-see-changes-in-my-domain-if-i-just-edited-my-dns-records`,
    DOMAIN_NAMESERVER_INFORMATION: `${baseUrlSupportHostinger}/en/articles/1583247-where-can-i-find-nameservers`,
    TRANSFER_DOMAIN: `${baseUrlSupportHostinger}/en/articles/1583436-how-to-transfer-domain-to-hostinger`,
    DOMAIN_TRANSFER_STATUS: `${baseUrlSupportHostinger}/en/articles/4134118-what-does-domain-transfer-status-mean`,
    FIX_DOMAIN_RENEWAL: `${baseUrlSupportHostinger}/en/articles/4147505-how-to-renew-a-domain`,
    FIX_EMAIL_CLIENT_LOGIN_ERROR: `${baseUrlSupportHostinger}/en/articles/1583447-i-can-t-login-to-my-webmail-mail-client-or-send-mails-what-should-i-do`,
    SETUP_EMAIL_OUTLOOK: `${baseUrlHostinger}/tutorials/how-to-set-up-outlook`,
    SETUP_EMAIL_ANDROID: `${baseUrlHostinger}/tutorials/how-to-setup-email-on-android/`,
    SEND_EMAIL_MAILER: `${baseUrlHostinger}/tutorials/send-emails-using-php-mail`,
    SETUP_EMAIL_IOS: `${baseUrlHostinger}/tutorials/how-to-setup-email-on-ios/`,
    MIGRATION_REQUEST: `${baseUrlSupportHostinger}/en/articles/4455931-how-to-migrate-your-website-to-hostinger#:~:text=select%20the%20correct-,control%20panel,-you%20need%20the`,
    STATUS: `https://statuspage.hostinger.com`,
    CHANGE_ACCOUNT_PASSWORD: `${baseUrlHostinger}/how-to/how-can-i-change-my-hosting-account-s-password`,
  },
  [Client.Brand.NG_CO_ID]: {
    WP_LITE_SPEED: `${baseUrlSupportNiagahoster}/optimasi-dan-percepat-wordpress-dengan-litespeed-cache/`,
    WP_OBJECT_CACHE: `${baseUrlSupportNiagahoster}/cara-mengelola-object-cache-untuk-website-wordpress-di-niagahoster/`,
    WP_CREATE_STAGING_ENV: `${baseUrlSupportNiagahoster}/cara-membuat-wordpress-staging-environment-di-hpanel/`,
    CDN: `${baseUrlNiagahoster}/blog/apa-itu-cdn/`,
    WP_IMAGE_OPTIMIZATION: `${baseUrlNiagahoster}/blog/cara-optimasi-gambar-wordpress/`,
    FIX_SSL_INSTALLATION: `${baseUrlSupportNiagahoster}/bagaimana-cara-memperbaiki-error-saat-instalasi-ssl/`,
    FIX_LIFETIME_SSL_INSTALLATION: `${baseUrlSupportNiagahoster}/bagaimana-cara-mengatasi-status-instalasi-ssl-seumur-hidup-yang-gagal/`,
    INSTALL_SSL: `${baseUrlSupportNiagahoster}/bagaimana-cara-install-ssl-seumur-hidup-di-niagahoster/`,
    CHANGE_MAIN_DOMAIN: `${baseUrlSupportNiagahoster}/bagaimana-cara-mengubah-domain-utama-akun-hosting-saya/`,
    HTACCESS_REDIRECT: `${baseUrlSupportNiagahoster}/cara-force-https-menggunakan-htaccess-di-hpanel/`,
    COPY_WEBSITE: `${baseUrlSupportNiagahoster}/bagaimana-cara-menyalin-website-wordpress-ke-domain-lain/`,
    CONNECT_FTP_ACCOUNT: `${baseUrlSupportNiagahoster}/cara-membuat-sambungan-ke-akun-ftp/`,
    FIX_FTP_CONNECTION: `${baseUrlSupportNiagahoster}/apa-yang-harus-dilakukan-jika-terjadi-masalah-saat-mengakses-ftp/`,
    CREATE_FTP_ACCOUNT: `${baseUrlSupportNiagahoster}/cara-menambah-akun-ftp-di-hpanel/`,
    CONNECT_SSH: `${baseUrlSupportNiagahoster}/cara-menghubungkan-akun-melalui-ssh/`,
    BASIC_SSH_COMMANDS: `${baseUrlSupportNiagahoster}/cheat-sheet-ssh/`,
    FIX_SSH_ERRORS: `${baseUrlSupportNiagahoster}/cara-mengatasi-error-yang-sering-terjadi-di-ssh/`,
    SETUP_EMAIL_FORWARDER: `${baseUrlSupportNiagahoster}/bagaimana-cara-setup-forwarder-untuk-niagahoster-email-hostinger-email/`,
    SETUP_EMAIL_CATCH_ALL: `${baseUrlSupportNiagahoster}/bagaimana-cara-catch-all-email-di-hpanel/`,
    SETUP_EMAIL_ALIAS: `${baseUrlSupportNiagahoster}/cara-setting-email-alias-untuk-email-niagahoster/`,
    SETUP_EMAIL_AUTO_REPLY: `${baseUrlSupportNiagahoster}/bagaimana-cara-membuat-auto-reply-di-hpanel/`,
    SETUP_EMAIL_DOMAIN: `${baseUrlSupportNiagahoster}/bagaimana-cara-setup-domain-untuk-niagahoster-email/`,
    SETUP_EMAIL_DEVICE: `${baseUrlSupportNiagahoster}/bagaimana-cara-setup-email-niagahoster-di-perangkat-saya/`,
    EMAIL_AUTO_CONFIG: `${baseUrlSupportNiagahoster}/apa-itu-konfigurasi-email-otomatis/`,
    CHECK_EMAIL_ACCESS_LOG: `${baseUrlSupportNiagahoster}/cara-cek-log-akses-niagahoster-email-hostinger-email/`,
    MIGRATE_EMAIL: `${baseUrlSupportNiagahoster}/bagaimana-cara-memindahkan-email-ke-niagahoster-email/`,
    UNLOCK_DOMAIN_TRANSFER: `${baseUrlSupportNiagahoster}/bagaimana-cara-membuka-kunci-domain-sebelum-transfer-domain-ke-niagahoster/`,
    DOMAIN_POINTING: `${baseUrlSupportNiagahoster}/cara-mengarahkan-domain-ke-niagahoster/`,
    ENABLE_DISK_QUOTA: `${baseUrlSupportNiagahoster}/bagaimana-cara-mengaktifkan-disk-quota-di-vps/`,
    ENABLE_NFS_SUPPORT: `${baseUrlSupportNiagahoster}/bagaimana-cara-mengaktifkan-network-file-system-nfs-di-vps/`,
    USE_SSH_KEY: `${baseUrlSupportNiagahoster}/bagaimana-cara-menggunakan-ssh-key-di-vps/`,
    VPS_HELP: `${baseUrlSupportNiagahoster}/category/vps/`,
    MAIN: `${baseUrlSupportNiagahoster}/`,
    ADD_WEBSITE: `${baseUrlSupportNiagahoster}/bagaimana-cara-menambahkan-website`,
    INSTALL_WORDPRESS: `${baseUrlSupportNiagahoster}/cara-install-wordpress-dengan-fitur-instalasi-otomatis-di-hpanel/`,
    CLAIM_FREE_DOMAIN: `${baseUrlSupportNiagahoster}/bagaimana-caranya-mendapatkan-domain-gratis/`,
    DOMAIN_ACTIVATION_TIME: `${baseUrlSupportNiagahoster}/kapan-domain-yang-baru-saja-saya-daftarkan-aktif/`,
    INVOICE_STATUS: `${baseUrlSupportNiagahoster}/status-invoice-belum-berubah-setelah-pelunasan-tagihan/`,
    REFUND: `${baseUrlSupportNiagahoster}/bagaimana-cara-meminta-pengembalian-uang-di-niagahoster/`,
    FIX_EMPTY_ACCOUNT: `${baseUrlSupportNiagahoster}/mengapa-akun-saya-kosong/`,
    FIX_PAYMENT_ERROR: `${baseUrlSupportNiagahoster}/saya-tidak-dapat-melakukan-pembayaran/`,
    STOP_AUTO_RENEWAL: `${baseUrlSupportNiagahoster}/bagaimana-cara-menonaktifkan-perpanjangan-otomatis/`,
    FIX_WEBSITE_ACCESS_ERROR: `${baseUrlSupportNiagahoster}/website-saya-tidak-bisa-diakses/`,
    CREATE_WEBSITE_BACKUP: `${baseUrlSupportNiagahoster}/bagaimana-cara-membuat-backup-di-hpanel-niagahoster/`,
    DNS_PROPAGATION: `${baseUrlSupportNiagahoster}/apa-itu-propagasi-dns/`,
    FIX_SUSPENDED_ACCOUNT: `${baseUrlSupportNiagahoster}/kenapa-akun-hosting-saya-di-suspend-atau-terhapus/`,
    WEBSITE_OPTIMIZATION: `${baseUrlSupportNiagahoster}/cara-optimasi-untuk-mempercepat-loading-website/`,
    FIX_WP_MIXED_CONTENT: `${baseUrlSupportNiagahoster}/bagimana-cara-memperbaiki-mixed-content-di-wordpress/`,
    HTTPS_REDIRECT: `${baseUrlSupportNiagahoster}/cara-redirect-http-ke-https/`,
    DNS_ZONE_INFORMATION: `${baseUrlSupportNiagahoster}/bagaimana-cara-melihat-perubahan-dns-di-domain/`,
    DOMAIN_NAMESERVER_INFORMATION: `${baseUrlSupportNiagahoster}/di-mana-informasi-nameserver-tersimpan/`,
    TRANSFER_DOMAIN: `${baseUrlSupportNiagahoster}/bagaimana-cara-transfer-domain-ke-niagahoster/`,
    DOMAIN_TRANSFER_STATUS: `${baseUrlSupportNiagahoster}/apa-yang-dimaksud-dengan-status-transfer-domain-di-hpanel/`,
    FIX_DOMAIN_RENEWAL: `${baseUrlSupportNiagahoster}/bagaimana-cara-memperpanjang-masa-aktif-domain/`,
    FIX_EMAIL_CLIENT_LOGIN_ERROR: `${baseUrlSupportNiagahoster}/saya-tidak-bisa-login-ke-akun-email-apa-yang-harus-saya-lakukan/`,
    SETUP_EMAIL_OUTLOOK: `${baseUrlSupportNiagahoster}/bagaimana-cara-setup-otomatis-email-hostinger-di-outlook/`,
    SETUP_EMAIL_ANDROID: `${baseUrlSupportNiagahoster}/konfigurasi-email-di-android/`,
    SEND_EMAIL_MAILER: `${baseUrlNiagahoster}/blog/cara-kirim-email-dengan-php/`,
    SETUP_EMAIL_IOS: `${baseUrlSupportNiagahoster}/cara-setup-niagahoster-email-di-ipad-mail-dan-iphone-mail-ios/`,
    MIGRATION_REQUEST: `${baseUrlSupportNiagahoster}/cara-migrasi-website-ke-hpanel-di-niagahoster/`,
    STATUS: `https://status.niagahoster.co.id`,
    CHANGE_ACCOUNT_PASSWORD: `${baseUrlSupportNiagahoster}/cara-mengubah-password-akun-hpanel/`,
  },
};
