import { filesRepo, wordpressRepo } from '@/repositories';
import { BACKUP_STATUS, Hosting } from '@/types';
import { camelToSnakeObj } from '@/utils/services/namingConventionsService';

const FAILED_OR_COMPLETED = [BACKUP_STATUS.COMPLETED, BACKUP_STATUS.FAILED];

const trackingCall = async (commit, dispatch, firstCall, requestConfig) => {
  const [{ data }] = await filesRepo.trackBackup(requestConfig);
  if (!data || FAILED_OR_COMPLETED.includes(data?.status)) {
    if (trackingInterval) clearInterval(trackingInterval);

    if (!firstCall) dispatch('fetchRestoredBackups');

    if (!firstCall && data?.type === Hosting.backupTypes.FILES) {
      commit('SET_TRACKING_INFO', {
        ...data,
        status: BACKUP_STATUS.PROCESSING,
      });
      await new Promise((resolve) => setTimeout(resolve, 6000));
      commit('SET_TRACKING_INFO', data);
    } else {
      if (data?.status === BACKUP_STATUS.FAILED) {
        commit('UPDATE_BACKUPS_RESTORE_HISTORY', data);
      }
      commit('SET_TRACKING_INFO', data);
    }
  } else {
    commit('UPDATE_BACKUPS_RESTORE_HISTORY', data);
    commit('SET_TRACKING_INFO', data);
  }
};

const lockTrackingCall = async (commit, dispatch, state, requestConfig) => {
  const [{ data }] = await filesRepo.checkBackupsLocked(requestConfig);

  if (data) {
    return commit('SET_BACKUPS_LOCK', data);
  }

  clearInterval(lockTrackingInterval);
  const backups = await dispatch('fetchBackups', {
    noLoading: true,
    requestConfig,
  });
  const preselectedBackup = state.preselectedBackupName;

  const backup = backups?.find(
    ({ archive, name }) =>
      (archive === state.selectedBackup?.archive &&
        state.selectedBackup?.name === name) ||
      (archive === preselectedBackup?.archive &&
        name === preselectedBackup?.name),
  );

  if (backup) {
    commit('SET_PREPARED_BACKUP', backup);
  }

  commit('SET_PRESELECTED_BACKUP_NAME', undefined);
  if (!state.isTrackingActive) commit('SET_SELECTED_TYPE', undefined);
  commit('SET_BACKUPS_LOCK', null);
};

let trackingInterval = undefined;
let lockTrackingInterval = undefined;

const getInitialState = () => ({
  backups: [],
  allAvailableBackups: [],
  individualBackups: {},
  lastNonHostBackup: null,
  restoredBackups: {
    data: [],
    loading: false,
    error: false,
  },
  backupsLoaded: false,
  loading: false,
  error: false,
  selectedBackup: undefined,
  preselectedBackupName: undefined,
  selectedType: undefined,
  tracking: null,
  upsell: {
    loading: true,
    error: false,
    data: {},
  },
  lock: {
    type: undefined,
    locked: false,
  },
  preparedBackup: null,
  isTrackingActive: false,
  isLockTrackingActive: false,
});

export default {
  state: getInitialState(),
  mutations: {
    SET_INITIAL_BACKUPS_DATA: (state) =>
      Object.assign(state, getInitialState()),
    SET_INDIVIDUAL_BACKUPS: (state, { backups, snapshotId }) => {
      state.individualBackups[snapshotId] = backups;
    },
    SET_SELECTED_TYPE: (state, payload) => (state.selectedType = payload),
    SET_IS_TRACKING_ACTIVE: (state, data) => (state.isTrackingActive = data),
    SET_IS_LOCK_TRACKING_ACTIVE: (state, data) =>
      (state.isLockTrackingActive = data),
    SET_BACKUPS_LOCK: ({ lock }, data) => {
      lock.locked = !!data;
      lock.type = data;
    },
    SET_BACKUPS_LOCK_TYPE: ({ lock }, type) => {
      lock.type = type;
      lock.locked = true;
    },
    SET_BACKUPS_UPSELL: ({ upsell }, payload) => {
      upsell.data = payload;
    },
    SET_BACKUPS: (state, payload) => (state.backups = payload),
    SET_LAST_NON_HOST_BACKUP: (state, payload) => {
      state.lastNonHostBackup = payload;
    },
    SET_AVAILABLE_BACKUPS: (state, payload) =>
      (state.allAvailableBackups = [...state.allAvailableBackups, ...payload]),
    SET_UPSELL_LOADING: ({ upsell }, { loading, error }) => {
      upsell.loading = loading;
      upsell.error = error;
    },
    SET_UPSELL_ACTIVATED: ({ upsell }) => {
      upsell.data.setup_link = undefined;
      upsell.data.purchased = true;
    },
    SET_TRACKING_INFO: (state, payload) => (state.tracking = payload),
    SET_BACKUPS_LOADED: (state) => (state.backupsLoaded = true),
    SET_BACKUPS_LOADING: (state, { loading, error }) => {
      state.loading = loading;
      state.error = error;
    },
    SET_SELECTED_BACKUP: (state, payload) => {
      if (!payload) {
        state.selectedBackup = undefined;

        return;
      }
      state.selectedBackup = state.backups.find(
        (backup) =>
          backup?.archive === payload?.archive &&
          backup?.snapshotId === payload?.snapshotId,
      );
    },
    SET_SELECTED_BACKUPS: (state, backups) => {
      if (!backups) {
        state.selectedBackups = undefined;

        return;
      }
      state.selectedBackups = backups.map((backup) => ({
        archive: backup.archive,
        snapshotId: backup.snapshotId,
        backupSource: backup.backupSource,
      }));
    },
    SET_PRESELECTED_BACKUP_NAME: (state, backup) => {
      state.preselectedBackupName = backup;
    },
    SET_BACKUP_DOWNLOAD_URL: (state, { selectedBackup, url }) => {
      const backup = state.backups.find(
        (backup) =>
          backup.archive === selectedBackup.archive &&
          backup.name === selectedBackup.name,
      );
      backup.url = url;
    },
    SET_RESTORED_BACKUPS: (state, payload) => {
      state.restoredBackups.data = payload;
    },
    SET_RESTORE_HISTORY_LOADING: (state, { loading, error }) => {
      state.restoredBackups.loading = loading;
      state.restoredBackups.error = error;
    },
    UPDATE_BACKUPS_RESTORE_HISTORY: (state, payload) => {
      const found = state.restoredBackups.data.find(
        (backup) => backup.id === payload.id,
      );
      if (!found) {
        state.restoredBackups.data = [payload, ...state.restoredBackups.data];
      }
    },
    SET_PREPARED_BACKUP: (state, data) => {
      state.preparedBackup = data;
    },
  },
  getters: {
    getBackupsState: (state) => state,
    getRestoredBackups: (state) => state.restoredBackups,
    getSelectedBackup: (state) => state.selectedBackup,
    getBackupUpsell: (state) => state.upsell,
    getBackupLockType: (state) => state.lock.type,
    getPreparedBackup: (state) => state.preparedBackup,
    getSelectedBackups: (state) => state.selectedBackups,
    getAllAvailableBackups: (state) => state.allAvailableBackups,
    getLastNonHostBackup: (state) => state.lastNonHostBackup,
    getLatestBackups: (state) => (orderId) => {
      const filteredBackups = state.allAvailableBackups.filter(
        (backup) => backup.orderId === orderId,
      );
      const latestBackups = {
        files: [],
        databases: [],
      };

      const filesBackups = filteredBackups.filter(
        (backup) => backup.type === Hosting.backupTypes.FILES,
      );
      const databasesBackups = filteredBackups.filter(
        (backup) => backup.type === Hosting.backupTypes.DATABASES,
      );

      if (filesBackups.length) {
        latestBackups.files = [
          filesBackups.reduce((max, backup) =>
            max.unix_time > backup.unix_time ? max : backup,
          ),
        ];
      }

      if (databasesBackups.length) {
        const uniqueDatabaseBackups = Array.from(
          new Set(databasesBackups.map(JSON.stringify)),
        ).map(JSON.parse);

        latestBackups.databases = uniqueDatabaseBackups;
      }

      return latestBackups;
    },
  },
  actions: {
    async fetchBackups({ commit }, { noLoading = false, requestConfig = {} }) {
      if (!noLoading) {
        commit('SET_BACKUPS_LOADING', { loading: true, error: false });
      }
      const [{ data }, err] = await filesRepo.getBackups(requestConfig);
      const orderId = requestConfig.headers?.['X-Hpanel-Order-ID'];
      const backupsWithOrderId =
        data?.backups.map((backup) => ({
          ...backup,
          orderId,
        })) || [];

      // Find last non-host backup
      const nonHostBackups = backupsWithOrderId.filter(
        (backup) => !backup.isHostBackup,
      );
      commit('SET_AVAILABLE_BACKUPS', backupsWithOrderId);
      commit('SET_BACKUPS', backupsWithOrderId);

      if (nonHostBackups.length > 0) {
        const lastNonHostBackup = nonHostBackups.reduce((latest, current) => {
          const latestDate = latest.unixTime;
          const currentDate = current.unixTime;

          return currentDate > latestDate ? current : latest;
        });

        commit('SET_LAST_NON_HOST_BACKUP', lastNonHostBackup);
      } else {
        commit('SET_LAST_NON_HOST_BACKUP', null);
      }

      commit('SET_BACKUPS_LOADED');
      commit('SET_BACKUPS_LOADING', { loading: false, error: !!err });

      return backupsWithOrderId;
    },
    async startRestore({ commit }, { params, type }) {
      commit('SET_TRACKING_INFO', {
        status: BACKUP_STATUS.PENDING,
        failureDetails: null,
        type,
      });

      type === 'files'
        ? await filesRepo.restoreFiles(params)
        : await filesRepo.restoreDatabase(params);
    },
    async startFullRestore({ commit }, { params }) {
      commit('SET_TRACKING_INFO', {
        status: BACKUP_STATUS.PENDING,
        failureDetails: null,
        type: Hosting.backupTypes.WEBSITES,
      });

      await filesRepo.restoreFull(params);
    },
    async prepareBackupDownload({ commit, getters }) {
      const selectedBackup = getters.getSelectedBackup;
      const { archive, backupSource, snapshotId } = selectedBackup;

      const [{ data }] = await filesRepo.prepareBackupsDownload(
        archive,
        backupSource,
        snapshotId,
      );

      commit('SET_BACKUP_DOWNLOAD_URL', {
        selectedBackup,
        url: data,
      });
    },
    async prepareBackupsDownload({ getters }, { headers = {} }) {
      const selectedBackups = getters.getSelectedBackups;

      await filesRepo.prepareBackupsDownloadList(
        { backups: selectedBackups },
        headers,
      );
    },

    async getBackupsUpsell({ commit }) {
      const [{ data }, error] = await wordpressRepo.getUpsells();

      if (error) {
        return commit('SET_UPSELL_LOADING', { loading: false, error: true });
      }

      const dailyBackup = data.find(
        (upsell) => upsell.title === 'Daily Backup',
      );

      if (!dailyBackup) {
        return commit('SET_UPSELL_LOADING', { loading: false, error: true });
      }

      commit('SET_UPSELL_LOADING', { loading: false, error: false });
      commit('SET_BACKUPS_UPSELL', camelToSnakeObj(dailyBackup));
    },
    async backupsLockTrack({ commit, dispatch, state }, requestConfig = {}) {
      if (state.isLockTrackingActive) return;

      if (lockTrackingInterval) clearInterval(lockTrackingInterval);

      commit('SET_IS_LOCK_TRACKING_ACTIVE', true);

      await lockTrackingCall(commit, dispatch, state, requestConfig);

      if (!state?.lock?.locked) {
        return commit('SET_IS_LOCK_TRACKING_ACTIVE', false);
      }

      return new Promise((resolve) => {
        lockTrackingInterval = setInterval(async () => {
          await lockTrackingCall(commit, dispatch, state, requestConfig);

          if (!state.lock.locked) {
            commit('SET_IS_LOCK_TRACKING_ACTIVE', false);

            return resolve();
          }
        }, 5000);
      });
    },
    async backupRestoreTrack({ commit, state, dispatch }, requestConfig = {}) {
      if (state.isTrackingActive) return;

      if (trackingInterval) clearInterval(trackingInterval);

      commit('SET_IS_TRACKING_ACTIVE', true);

      await trackingCall(commit, dispatch, true, requestConfig);

      if (
        !state.tracking ||
        FAILED_OR_COMPLETED.includes(state.tracking.status)
      ) {
        return commit('SET_IS_TRACKING_ACTIVE', false);
      }

      commit('SET_SELECTED_TYPE', state.tracking.type);

      return new Promise((resolve) => {
        trackingInterval = setInterval(async () => {
          await trackingCall(commit, dispatch, false, requestConfig);

          if (FAILED_OR_COMPLETED.includes(state.tracking.status)) {
            commit('SET_IS_TRACKING_ACTIVE', false);
            commit('SET_SELECTED_TYPE', undefined);

            return resolve();
          }
        }, 5000);
      });
    },
    async generateBackup({ commit, dispatch }) {
      const [, err] = await filesRepo.generateBackup();
      if (err) {
        commit('SET_BACKUPS_LOCK', false);
        commit('SET_PREPARED_BACKUP', null);
        commit('SET_BACKUPS_LOCK_TYPE', null);

        return;
      }
      await dispatch('fetchBackups', true);
    },
    async fetchRestoredBackups({ commit }) {
      commit('SET_RESTORE_HISTORY_LOADING', { loading: true, error: false });

      const [{ data }, err] = await filesRepo.getRestoreHistory();

      if (err || !data) {
        return commit('SET_RESTORE_HISTORY_LOADING', {
          loading: false,
          error: true,
        });
      }

      commit('SET_RESTORE_HISTORY_LOADING', {
        loading: false,
        error: false,
      });
      commit('SET_RESTORED_BACKUPS', data);
    },
  },
};
