import type { RouteRecordRaw } from 'vue-router';

import autoRefundGuard from '@/guards/autoRefundGuard';
import refundInvoiceGuard from '@/guards/refundInvoiceGuard';
import { Route } from '@/types';
import { RouteOwner } from '@/types/enums/routeOwnerEnum';

const baseMeta = {
  breadcrumbs: false,
  backButtonLabel: 'v2.cancel.refund',
  routeOwner: RouteOwner.HPANEL,
};

const autoRefundRoutes: RouteRecordRaw[] = [
  {
    path: '/refund/:hash',
    name: Route.HRefunds.AUTO_REFUND,
    meta: { ...baseMeta, backButtonLabel: 'Back to list', title: 'Refund' },
    beforeEnter: [autoRefundGuard, refundInvoiceGuard],
    redirect: (to) => ({
      name: Route.HRefunds.AUTO_REFUND_SERVICES,
      params: {
        hash: to.params.hash,
      },
    }),
    component: () => import('@/views/AutoRefund/AutoRefund.vue'),
    children: [
      {
        path: 'services',
        name: Route.HRefunds.AUTO_REFUND_SERVICES,
        meta: {
          ...baseMeta,
          title: 'Submit {hash} Refund Request',
        },
        component: () => import('@/views/AutoRefund/AutoRefundServices.vue'),
      },
      {
        path: 'confirmation',
        name: Route.HRefunds.AUTO_REFUND_CONFIRMATION,
        meta: {
          ...baseMeta,
          title: 'If Refunded, You Will Lose All the Data',
        },
        component: () =>
          import('@/views/AutoRefund/AutoRefundConfirmation.vue'),
      },
      {
        path: 'survey',
        name: Route.HRefunds.AUTO_REFUND_SURVEY,
        meta: {
          ...baseMeta,
          title: 'Your Feedback Matters',
        },
        component: () => import('@/views/AutoRefund/AutoRefundSurvey.vue'),
      },
      {
        path: 'summary',
        name: Route.HRefunds.AUTO_REFUND_SUMMARY,
        meta: {
          ...baseMeta,
          title: 'Refund Summary',
        },
        component: () => import('@/views/AutoRefund/AutoRefundSummary.vue'),
      },
    ],
  },

  {
    path: '/refundV2/:hash',
    name: Route.HRefunds.AUTO_REFUND_V2,
    meta: {
      ...baseMeta,
      title: 'v2.submit.hash.refund.request',
    },
    beforeEnter: refundInvoiceGuard,
    component: () => import('@/views/AutoRefund/AutoRefundV2.vue'),
  },
];

export default autoRefundRoutes as RouteRecordRaw[];
