import type { CancelToken } from 'axios';

import initRavelin from '@/plugins/ravelin';
import type {
  RequestConfig,
  IReactivationEstimateRequest,
  IRenewalEstimateRequest,
  IUpgradeEstimateRequest,
  ICartEstimateRequest,
  IAddonEstimateRequest,
  IFilterCatalogItemsRequest,
  ICatalogItemByPriceParams,
  ICustomerInvoicesParams,
  IOrderRequest,
  IOneClickPayOrderRequestRequest,
  IOneClickPayAddonOrderRequest,
  IAddonOrderRequest,
  IUpgradeOrderRequest,
  IOneClickPayUpgradeOrderRequest,
  IRenewalOrderRequest,
  IOneClickPayRenewalOrderRequest,
  IReactivationOrderRequest,
  IOneClickPayReactivationOrderRequest,
  IOneClickPayInvoiceOrderRequest,
  IInvoiceOrderRequest,
  ISubscriptionRequest,
  IAddonRolbackRequest,
  AddPaymentMethodRequest,
  CatalogItem,
  BillingSubscription,
  PaymentMethod,
  ISubscriptionOrder,
  ResolveUSAddressResponse,
  ResolvedAddress,
  BillingInvoice,
  BillingOrder,
  IDataLayerEvent,
  Customer,
} from '@/types';
import { getHBillingMeta } from '@/utils/getLocationParams';
import { hAsync } from '@/utils/helpers';
import { errorLogger } from '@/utils/services/errorLogging';
import http from '@/utils/services/http/httpService';

const HBILLING_API_URL = `${process.env.VITE_API_BILLING_V2}/api/v1`;

const estimatePricing = async function (
  request: ICartEstimateRequest,
  cancelToken?: CancelToken,
  settings?: RequestConfig,
  options?: RequestConfig,
) {
  return await hAsync(
    http.post(`${HBILLING_API_URL}/estimate/cart/customer`, request, {
      cancelToken,
      hideToastr: !!settings?.hideToastr,
      whitelistedStatusCodes: [422, 406],
      ...options,
    }),
  );
};
const estimatePlanUpgrade = async function (
  id: string,
  request: IUpgradeEstimateRequest | IAddonEstimateRequest,
  isAddon?: boolean,
  options?: RequestConfig,
) {
  return isAddon
    ? await hAsync(
        http.post(
          `${HBILLING_API_URL}/estimate/${id}/add-addon`,
          request,
          options,
        ),
      )
    : await hAsync(
        http.post(
          `${HBILLING_API_URL}/estimate/${id}/plan-upgrade`,
          request,
          options,
        ),
      );
};
const purchasePlan = async function (
  request: IOrderRequest | IOneClickPayOrderRequestRequest,
  isOneClick: boolean,
) {
  return isOneClick && !request.isTrial
    ? await hAsync(
        http.post(
          `${HBILLING_API_URL}/order/one-click-pay`,
          {
            ...request,
            ...getHBillingMeta(),
          },
          { hBillingGaTracking: true, whitelistedStatusCodes: [422] },
        ),
      )
    : await hAsync(
        http.post(
          `${HBILLING_API_URL}/order`,
          {
            ...request,
            ...getHBillingMeta(),
          },
          { hBillingGaTracking: true },
        ),
      );
};
const purchaseAddonForPlan = async function (
  request: IAddonOrderRequest | IOneClickPayAddonOrderRequest,
  isOneClick: boolean,
) {
  return isOneClick
    ? await hAsync(
        http.post(
          `${HBILLING_API_URL}/order/one-click-pay-addon`,
          {
            ...request,
            ...getHBillingMeta(),
          },
          { hBillingGaTracking: true },
        ),
      )
    : await hAsync(
        http.post(
          `${HBILLING_API_URL}/order/addon`,
          {
            ...request,
            ...getHBillingMeta(),
          },
          { hBillingGaTracking: true },
        ),
      );
};
const upgradePlan = async function (
  request: IUpgradeOrderRequest | IOneClickPayUpgradeOrderRequest,
  isOneClick: boolean,
) {
  return isOneClick
    ? await hAsync(
        http.post(
          `${HBILLING_API_URL}/order/one-click-pay-plan-upgrade`,
          {
            ...request,
            ...getHBillingMeta(),
          },
          { hBillingGaTracking: true },
        ),
      )
    : await hAsync(
        http.post(
          `${HBILLING_API_URL}/order/plan-upgrade`,
          {
            ...request,
            ...getHBillingMeta(),
          },
          { hBillingGaTracking: true },
        ),
      );
};
const renewPlan = async function (
  request: IRenewalOrderRequest | IOneClickPayRenewalOrderRequest,
  isOneClick: boolean,
) {
  return isOneClick
    ? payRenewal(request as IOneClickPayRenewalOrderRequest)
    : createRenewOrder(request as IRenewalOrderRequest);
};
const reactivatePlan = async function (
  request: IReactivationOrderRequest | IOneClickPayReactivationOrderRequest,
  isOneClick: boolean,
) {
  return isOneClick
    ? payReactivate(request as IOneClickPayReactivationOrderRequest)
    : createReactivateOrder(request as IReactivationOrderRequest);
};
const payInvoice = async function (
  request: IInvoiceOrderRequest | IOneClickPayInvoiceOrderRequest,
  isOneClick: boolean,
) {
  return isOneClick
    ? payOrderInvoice(request as IOneClickPayInvoiceOrderRequest)
    : getOrderInvoicePaymentLink(request as IInvoiceOrderRequest);
};
const getCatalog = async function (requestConfig?: RequestConfig) {
  return await hAsync<CatalogItem[]>(
    http.get(`${HBILLING_API_URL}/catalog/minimized`, requestConfig),
  );
};
const getItemById = async function (itemId: string) {
  return await hAsync(http.get(`${HBILLING_API_URL}/catalog/${itemId}`));
};
const getCatalogByFilter = async function ({
  family,
  categories,
  plans,
}: IFilterCatalogItemsRequest) {
  return await hAsync(
    http.post(`${HBILLING_API_URL}/catalog/filter`, {
      family,
      categories,
      plans,
    }),
  );
};

const getItemByPrice = async function (
  priceId: string,
  params?: ICatalogItemByPriceParams,
) {
  return await hAsync<CatalogItem>(
    http.get(`${HBILLING_API_URL}/catalog/price/${priceId}`, { params }),
  );
};
const getOrders = async function (config: RequestConfig) {
  return await hAsync<ISubscriptionOrder[]>(
    http.get(`${HBILLING_API_URL}/order`, config),
  );
};
const getInvoices = async function (params?: ICustomerInvoicesParams) {
  return await hAsync<BillingInvoice[]>(
    http.get(`${HBILLING_API_URL}/customer/invoices`, { params }),
  );
};
const payOrderInvoice = async function ({
  invoiceId,
  methodId,
}: IOneClickPayInvoiceOrderRequest) {
  return await hAsync(
    http.post(
      `${HBILLING_API_URL}/order/one-click-pay-invoice`,
      {
        invoiceId,
        methodId,
        ...getHBillingMeta(),
      },
      { hBillingGaTracking: true },
    ),
  );
};
const getOrderInvoicePaymentLink = async function ({
  invoiceId,
}: IInvoiceOrderRequest) {
  const requestBody = {
    invoiceId,
    ...getHBillingMeta(),
  };

  errorLogger.addBreadcrumb({
    name: 'Request sent: /order/invoice',
    data: requestBody,
  });

  return await hAsync(
    http.post(`${HBILLING_API_URL}/order/invoice`, requestBody, {
      hBillingGaTracking: true,
    }),
  );
};

const initiateAntiFraudPlugin = (response: PaymentMethod[]) => {
  const paymentMethod = response.find(
    (paymentMethod) => paymentMethod.keys?.ravelinPublicKey,
  );

  const ravelinPublicKey = paymentMethod?.keys?.ravelinPublicKey;

  if (ravelinPublicKey) {
    initRavelin(ravelinPublicKey, paymentMethod?.keys?.ravelinApiBase);
  }
};

const getPaymentMethods = async function () {
  const response = await hAsync<PaymentMethod[]>(
    http.get(`${HBILLING_API_URL}/payment/get-payment-methods`),
  );

  if (response[0].data?.length) {
    initiateAntiFraudPlugin(response[0].data);
  }

  return response;
};

const addPaymentMethod = async function (
  {
    redirectCancel,
    redirectReturn,
    merchantAccounts,
  }: AddPaymentMethodRequest = {
    redirectCancel: '',
    redirectReturn: '',
  },
) {
  return await hAsync(
    http.post(`${HBILLING_API_URL}/payment/add-payment-method`, {
      redirectCancel: `${window.location.origin}${redirectCancel}`,
      redirectReturn: `${window.location.origin}${redirectReturn}`,
      merchantAccounts,
    }),
  );
};
const removePaymentMethod = async function (methodId: string) {
  return await hAsync(
    http.delete(`${HBILLING_API_URL}/payment/delete-payment-method`, {
      data: {
        methodId,
      },
    }),
  );
};
const setDefaultPaymentMethod = async function (methodId: string) {
  return await hAsync(
    http.post(`${HBILLING_API_URL}/payment/set-default-payment-method`, {
      methodId,
    }),
  );
};
const getSubscriptions = async function () {
  return await hAsync(http.get(`${HBILLING_API_URL}/customer/subscriptions`));
};

const getSubscriptionsWithScheduledChanges = async function (
  config: RequestConfig,
) {
  return await hAsync<BillingSubscription[]>(
    http.get(
      `${HBILLING_API_URL}/customer/subscriptions-with-scheduled-changes`,
      config,
    ),
  );
};

const enableSubscriptionAutoRenew = async function (subscriptionId: string) {
  return await hAsync(
    http.post(
      `${HBILLING_API_URL}/subscription/${subscriptionId}/auto-renewal/enable`,
    ),
  );
};
const disableSubscriptionAutoRenew = async function (subscriptionId: string) {
  return await hAsync(
    http.post(
      `${HBILLING_API_URL}/subscription/${subscriptionId}/auto-renewal/disable`,
    ),
  );
};
const changeSubscriptionPlan = async function ({
  subscriptionId,
  itemPriceId,
  quantity,
}: ISubscriptionRequest) {
  return await hAsync(
    http.post(
      `${HBILLING_API_URL}/subscription/${subscriptionId}/schedule-plan-change`,
      {
        itemPriceId,
        quantity,
      },
    ),
  );
};
const getSubscriptionWithScheduledChanges = async function (
  subscriptionId: string,
) {
  return await hAsync(
    http.get(
      `${HBILLING_API_URL}/subscription/${subscriptionId}/with-scheduled-changes`,
    ),
  );
};
const getInvoicesBySubscriptionId = async function (subscriptionId: string) {
  return await hAsync(
    http.get(`${HBILLING_API_URL}/subscription/${subscriptionId}/get-invoices`),
  );
};
const removeSubscriptionAddon = async function ({
  subscriptionId,
  itemPriceId,
  quantity,
}: ISubscriptionRequest) {
  return await hAsync(
    http.post(
      `${HBILLING_API_URL}/subscription/${subscriptionId}/remove-addon`,
      {
        itemPriceId,
        quantity,
      },
    ),
  );
};
const rollBackAddonCancellation = async function ({
  subscriptionId,
  itemPriceId,
}: IAddonRolbackRequest) {
  return await hAsync(
    http.post(
      `${HBILLING_API_URL}/subscription/${subscriptionId}/rollback-addon-cancellation`,
      {
        itemPriceId,
      },
    ),
  );
};

// Renewal - Create renewal order for a subscription

const createRenewOrder = async function (req: IRenewalOrderRequest) {
  const requestBody = {
    ...req,
    ...getHBillingMeta(),
  };

  errorLogger.addBreadcrumb({
    name: 'Request sent: /order/renewal',
    data: requestBody,
  });

  return await hAsync(
    http.post(`${HBILLING_API_URL}/order/renewal`, requestBody, {
      hBillingGaTracking: true,
    }),
  );
};

// Reactivation - Create renewal order for a subscription
const createReactivateOrder = async function ({
  subscriptionId,
  redirectReturn,
  redirectCancel,
  itemPriceId,
  coupons,
  chargeItemPriceId,
}: IReactivationOrderRequest) {
  const requestBody = {
    subscriptionId,
    redirectReturn,
    redirectCancel,
    itemPriceId,
    coupons,
    chargeItemPriceId,
    ...getHBillingMeta(),
  };

  errorLogger.addBreadcrumb({
    name: 'Request sent: /order/reactivate',
    data: requestBody,
  });

  return await hAsync(
    http.post(`${HBILLING_API_URL}/order/reactivate`, requestBody, {
      hBillingGaTracking: true,
    }),
  );
};

// One click Pay Renewal - Create one click renewal order for a subscription and pay it
const payRenewal = async function (req: IOneClickPayRenewalOrderRequest) {
  return await hAsync(
    http.post(
      `${HBILLING_API_URL}/order/one-click-pay-renewal`,
      {
        ...req,
        ...getHBillingMeta(),
      },
      { hBillingGaTracking: true },
    ),
  );
};

// One click Pay Reactivate - Create one click reactivate order for a subscription and pay it
const payReactivate = async function ({
  subscriptionId,
  methodId,
  redirectReturn,
  redirectCancel,
  itemPriceId,
  chargeItemPriceId,
  coupons,
}: IOneClickPayReactivationOrderRequest) {
  return await hAsync(
    http.post(
      `${HBILLING_API_URL}/order/one-click-pay-reactivate`,
      {
        subscriptionId,
        methodId,
        redirectReturn,
        redirectCancel,
        itemPriceId,
        chargeItemPriceId,
        coupons,
        ...getHBillingMeta(),
      },
      { hBillingGaTracking: true },
    ),
  );
};

const estimatePlanRenew = async function (
  request: IRenewalEstimateRequest,
  hideToastr: boolean,
) {
  return await hAsync(
    http.post(`${HBILLING_API_URL}/estimate/renewal`, request, {
      hideToastr,
    }),
  );
};

const estimateReactivation = async function (
  subscriptionId: string,
  data: IReactivationEstimateRequest,
  settings: RequestConfig,
) {
  return await hAsync(
    http.post(
      `${HBILLING_API_URL}/estimate/${subscriptionId}/reactivation`,
      data,
      { hideToastr: settings.hideToastr },
    ),
  );
};
const getOrderByOrderToken = async function (orderToken: string) {
  return await hAsync(http.get(`${HBILLING_API_URL}/order/${orderToken}`));
};
const getRefundPDF = async function (creditNoteId: string) {
  return await hAsync<{ file: string }>(
    http.get(`${HBILLING_API_URL}/credit-note/${creditNoteId}/pdf`),
  );
};
const getSubscription = async function (subscriptionId: string) {
  return await hAsync(
    http.get(`${HBILLING_API_URL}/subscription/${subscriptionId}`),
  );
};
const getSubscriptionOrders = async function (
  subscriptionId: string,
  params?: {
    statuses?: BillingOrder.Status[];
    types?: BillingOrder.Type[];
  },
) {
  return await hAsync<ISubscriptionOrder[]>(
    http.get(`${HBILLING_API_URL}/order/subscription/${subscriptionId}`, {
      params,
      hideToastr: true,
      whitelistedStatusCodes: [404],
    }),
  );
};

const getClientAnalytics = async function () {
  return await hAsync<IDataLayerEvent[]>(
    http.get(`${HBILLING_API_URL}/customer/data-layers`, {
      hideNetworkError: true,
    }),
  );
};
const acknowledgeClientAnalytics = async function (
  dataLayerItemId: IDataLayerEvent['id'],
) {
  return await hAsync(
    http.delete(`${HBILLING_API_URL}/customer/data-layer/${dataLayerItemId}`),
  );
};

const resolveUSAddress = async (data: ResolvedAddress) =>
  hAsync<ResolveUSAddressResponse>(
    http.post(`${HBILLING_API_URL}/customer/resolve-usa-address`, data),
  );

const getCustomer = async function () {
  return await hAsync<Customer>(
    http.get(`${process.env.VITE_API_BILLING_V2}/jwt/v1/customer`),
  );
};

export default {
  getSubscriptionOrders,
  estimatePricing,
  estimatePlanUpgrade,
  purchasePlan,
  purchaseAddonForPlan,
  upgradePlan,
  renewPlan,
  reactivatePlan,
  payInvoice,
  getCatalog,
  getItemById,
  getCatalogByFilter,
  getItemByPrice,
  getOrders,
  getInvoices,
  payOrderInvoice,
  getOrderInvoicePaymentLink,
  getPaymentMethods,
  addPaymentMethod,
  removePaymentMethod,
  setDefaultPaymentMethod,
  getSubscriptions,
  getSubscriptionsWithScheduledChanges,
  enableSubscriptionAutoRenew,
  disableSubscriptionAutoRenew,
  changeSubscriptionPlan,
  getSubscriptionWithScheduledChanges,
  getInvoicesBySubscriptionId,
  removeSubscriptionAddon,
  rollBackAddonCancellation,
  createRenewOrder,
  createReactivateOrder,
  payRenewal,
  payReactivate,
  estimatePlanRenew,
  estimateReactivation,
  getOrderByOrderToken,
  getRefundPDF,
  getSubscription,
  getClientAnalytics,
  acknowledgeClientAnalytics,
  resolveUSAddress,
  getCustomer,
};
