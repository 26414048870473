<script lang="ts" setup>
//JSON-slugs-migrated
import { computed } from 'vue';

import { useBrands } from '@/composables/useBrands';
import { useProfileStore } from '@/stores';

type Props = {
  initial?: boolean;
  fullScreen?: boolean;
};

const props = withDefaults(defineProps<Props>(), {
  initial: false,
});

const { brandConfiguration } = useBrands();

const profileStore = useProfileStore();

const loaderSrc = computed(() =>
  props.initial
    ? `@/images/${loaders.value.animated}`
    : `@/images/${loaders.value.static}`,
);

const loaders = computed(
  () =>
    brandConfiguration.value.loaders ?? {
      circle: 'circle-only.svg',
      animated: 'H-outline-animated.svg',
      static: 'H-outline-static.svg',
    },
);
</script>

<template>
  <div
    class="animation-loader__wrapper animation-loader__wrapper--absolute"
    data-qa="loader"
    :class="{
      'animation-loader__wrapper--full-screen': props.fullScreen,
    }"
  >
    <div v-if="profileStore.account?.brand.id" class="animation-loader">
      <HImage
        class="animation-loader__circle"
        :src="`@/images/${loaders.circle}`"
        alt=""
      />
      <HImage class="animation-loader__outline" :src="loaderSrc" alt="" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.animation-loader {
  position: relative;
  width: 176px;
  height: 176px;

  &__wrapper {
    display: flex;
    position: fixed;
    align-items: center;
    justify-content: center;
    background-color: var(--white-blue);
    height: 100%;
    width: 100vw;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: var(--z-index-3);

    &--full-screen {
      width: 100vw;
      height: 100%;
    }
  }

  &__circle {
    width: 176px;
    height: 176px;
  }
  &__outline {
    position: absolute;
    top: 0;
    left: 0;
    width: 176px;
    height: 176px;
  }

  @media (max-width: 992px) {
    &___wrapper {
      width: 100vw !important;
    }
  }
}
</style>
