import type { BackupElement, RequestConfig } from '@/types';
import { hAsync } from '@/utils/helpers';
import http from '@/utils/services/http/httpService';

export default {
  url: `${process.env.VITE_API_REST}/v2/files`,
  urlV3: `${process.env.VITE_API_REST}/v3/files`,

  async getBackups(requestConfig?: RequestConfig) {
    return await hAsync(http.get(`${this.url}/backups`, requestConfig));
  },
  async getArchiveList(
    // @ts-ignore
    archive,
    // @ts-ignore
    backupSource,
    // @ts-ignore
    snapshotId,
    // @ts-ignore
    pathToDisplay,
    // @ts-ignore
    cancelToken = undefined,
  ) {
    return await hAsync(
      http.get(`${this.url}/backups/archive-list`, {
        params: { archive, backupSource, snapshotId, pathToDisplay },
        timeout: 1000000,
        cancelToken,
      }),
    );
  },

  async generateBackup() {
    return await hAsync(
      http.post(`${this.url}/backups`, null, { timeout: 1000000 }),
    );
  },

  async prepareBackupsDownloadList(params: BackupElement[], headers = {}) {
    return await hAsync<boolean>(
      http.post(`${this.urlV3}/backups/prepare-download-list`, params, {
        headers,
      }),
    );
  },

  // @ts-ignore
  async restoreFiles(params) {
    return await hAsync(
      http.post(`${this.url}/backups/restore-selected`, params),
    );
  },
  // @ts-ignore
  async restoreDatabase(params) {
    return await hAsync(
      http.post(`${this.url}/backups/restore-database`, params),
    );
  },
  // @ts-ignore
  async restoreFull(params) {
    return await hAsync(
      http.post(
        `${process.env.VITE_API_REST}/v3/website-restore/restore`,
        params,
      ),
    );
  },

  async trackBackup(requestConfig?: RequestConfig) {
    return await hAsync(
      http.get(`${this.url}/backups/restore-history`, {
        hostingHeaderRequired: true,
        ...requestConfig,
      }),
    );
  },

  async checkBackupsLocked(requestConfig?: RequestConfig) {
    return await hAsync(http.get(`${this.url}/backups/locked`, requestConfig));
  },

  // @ts-ignore
  async prepareBackupsDownload(archive, backupSource, snapshotId) {
    return await hAsync(
      http.post(`${this.url}/backups/prepare`, {
        archive,
        backupSource,
        snapshotId,
      }),
    );
  },
  async getRestoreHistory() {
    return await hAsync(
      http.get(`${this.url}/backups/restore-history`, {
        params: { getList: 1 },
      }),
    );
  },

  async mountStatus(requestConfig?: RequestConfig) {
    return await hAsync(http.get(`${this.url}/mount-status`, requestConfig));
  },
  async mount() {
    return await hAsync(http.post(`${this.url}/mount`));
  },

  async getFileBrowserData(url: string) {
    return await hAsync(http.get(url));
  },

  async postFileBrowserData(url: string, headers = {}) {
    return await hAsync(http.post(url, headers));
  },
  // @ts-ignore
  async getFileManagerLink(params, domain) {
    return await hAsync(
      http.get(`${this.url}/file-manager`, {
        params,
        domain,
      }),
    );
  },

  // @ts-ignore
  getDirectories(params) {
    return hAsync(
      http.get(`${this.url}/directories`, {
        params,
      }),
    );
  },

  async cleanTempFiles() {
    return await hAsync(http.post(`${this.url}/clean-tmp-files`));
  },

  async truncateLogFiles() {
    return await hAsync(http.post(`${this.url}/truncate-log-files`));
  },

  async diskUsageTopFiles() {
    return await hAsync(
      http.get(`${this.url}/disk-usage-top-files`, {
        hostingHeaderRequired: true,
      }),
    );
  },
};
