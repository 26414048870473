import type { RouteRecordRaw } from 'vue-router';

import h5gOnboardingGuard from '@/guards/h5g/h5gOnboardingGuard';
import { Route } from '@/types';
import { RouteOwner } from '@/types/enums/routeOwnerEnum';
import { H5G_ONBOARDING_STEPS } from '@/types/models/onboardingV2Models';

const meta = {
  isPageWrapperV2: true,
  routeOwner: RouteOwner.HOSTING,
};

export default [
  {
    path: '/h5g/onboarding/:h5gId/',
    name: Route.H5G.ONBOARDING,
    meta,
    beforeEnter: h5gOnboardingGuard,
    component: () => import('@/views/OnboardingV2.vue'),
    children: [
      {
        path: 'agree',
        name: H5G_ONBOARDING_STEPS.H5G_AGREE,
        meta: {
          ...meta,
          title: 'Become a Tester by Agreeing to These Terms',
        },
        component: () =>
          import('@/views/H5G/components/H5GOnboardingAgree.vue'),
      },
      {
        path: 'choose-action',
        name: H5G_ONBOARDING_STEPS.H5G_CHOOSE_ACTION,
        meta: {
          ...meta,
          title: 'How do you want to test the new infrastructure?',
        },
        component: () =>
          import('@/views/H5G/components/H5GOnboardingChooseAction.vue'),
      },
      {
        path: 'choose-website',
        name: H5G_ONBOARDING_STEPS.H5G_CHOOSE_WEBSITE,
        meta: {
          ...meta,
          title: 'What WordPress website do you want to copy?',
        },
        component: () =>
          import('@/views/H5G/components/H5GOnboardingChooseWebsite.vue'),
      },
      {
        path: 'wordpress-admin-setup',
        name: H5G_ONBOARDING_STEPS.H5G_WORDPRESS_ADMIN_SETUP,
        meta: {
          ...meta,
          title: 'Set up WordPress',
        },
        component: () =>
          import('@/views/OnboardingV2/OnboardingWordPressAdminSetup.vue'),
      },
      {
        path: 'domain',
        name: H5G_ONBOARDING_STEPS.H5G_DOMAIN,
        meta: {
          ...meta,
          title: 'Choose a Domain',
        },
        component: () => import('@/views/OnboardingV2/OnboardingDomain.vue'),
      },
      {
        path: 'domain-registration/:domain',
        name: H5G_ONBOARDING_STEPS.H5G_DOMAIN_REGISTRATION,
        meta: {
          ...meta,
          title: 'Register domain',
        },
        component: () =>
          import('@/views/OnboardingV2/OnboardingDomainRegistration.vue'),
      },
      {
        path: 'building',
        name: H5G_ONBOARDING_STEPS.H5G_SETUP,
        meta: {
          ...meta,
          title: 'Building your website',
        },
        component: () =>
          import('@/views/H5G/components/H5GOnboardingSetup.vue'),
      },
      {
        path: 'copying',
        name: H5G_ONBOARDING_STEPS.H5G_COPY,
        meta: {
          ...meta,
          title: 'Building your website',
        },
        component: () => import('@/views/H5G/components/H5GOnboardingCopy.vue'),
      },
    ],
  },
] as RouteRecordRaw[];
