import type { CrossSellCardData, IMarketplaceProduct } from '@/types';
import { AmplitudeEvent, AmplitudeParam, Hosting, Route } from '@/types';

export const HOSTING_PRODUCTS: IMarketplaceProduct[] = [
  {
    discount: 80,
    title: 'Web Hosting',
    description:
      'Host and manage small and medium size websites on the same server using our web hosting solution',
    icon: '@/images/icons/marketplace/hosting.svg',
    cta: 'Explore',
    redirect: {
      to: {
        name: Route.Base.BUY_HOSTING,
      },
    },
    actionAmplitudeEvent: AmplitudeEvent.Hosting.OFFER_SHOWN,
    actionAmplitudeParams: {
      location: Route.Base.MARKETPLACE,
      product: AmplitudeParam.Product.WEB_HOSTING,
    },
  },
  {
    discount: 70,
    title: 'Cloud Hosting',
    description:
      'Safe and trustworthy, our cloud hosting is an ideal choice for large-scale websites or other projects',
    icon: '@/images/icons/marketplace/cloudHosting.svg',
    cta: 'Explore',
    redirect: {
      to: {
        name: Route.Base.BUY_HOSTING,
        query: {
          tab: Hosting.HostingTab.CLOUD,
        },
      },
    },
    actionAmplitudeEvent: AmplitudeEvent.Hosting.OFFER_SHOWN,
    actionAmplitudeParams: {
      location: Route.Base.MARKETPLACE,
      product: AmplitudeParam.Product.CLOUD_HOSTING,
    },
  },
  {
    discount: 70,
    title: 'VPS Hosting',
    description:
      'Take full control over your server. Our virtual private servers offer great power, flexibility, and speed.',
    icon: '@/images/icons/marketplace/vps.svg',
    cta: 'Explore',
    redirect: {
      to: {
        name: Route.Base.BUY_VPS,
      },
    },
    actionAmplitudeEvent: AmplitudeEvent.Vps.OFFER_SHOWN,
    actionAmplitudeParams: {
      location: Route.Base.MARKETPLACE,
      product: AmplitudeParam.Product.VPS_HOSTING,
    },
  },
];

export const DOMAIN_PRODUCTS: IMarketplaceProduct[] = [
  {
    title: 'Register domain',
    description:
      'Find the perfect domain name. Choose from more than 200 extensions.',
    icon: '@/images/icons/marketplace/domain.svg',
    cta: 'Get now',
    redirect: {
      to: {
        name: Route.Domain.DOMAIN_CHECKER,
      },
    },
    actionAmplitudeEvent: AmplitudeEvent.Domain.CHECKER_ENTERED,
  },
  {
    title: 'Transfer domains',
    description:
      'Bring domain to Hostinger to ensure easy management from a single place',
    icon: '@/images/icons/marketplace/domain.svg',
    cta: 'Transfer',
    redirect: {
      to: {
        name: Route.Domain.TRANSFER_DOMAIN,
      },
    },
    actionAmplitudeEvent: AmplitudeEvent.Domain.TRANSFER_ENTERED,
  },
];

export const SERVICE_PRODUCTS: IMarketplaceProduct[] = [
  {
    title: 'Logo Maker',
    description: 'Create a professional logo in a few clicks for your brand',
    icon: '@/images/icons/marketplace/hostinger.svg',
    cta: 'Learn more',
    redirect: {
      to: 'https://logo.hostinger.com?ref=hpanel-marketplace',
      target: '_blank',
    },
  },
  {
    title: 'Logo Design',
    description:
      'Stand out from the crowd with a logo that fits your brand personality',
    icon: '@/images/icons/marketplace/fiverr.svg',
    cta: 'Learn more',
    redirect: {
      to: 'https://partner.fiverr.com/visit/?bta=56213&brand=fiverrcpa&utm_campaign=logodesign_store&landingPage=https%3A%2F%2Fwww.fiverr.com%2Fcategories%2Fgraphics-design%2Fcreative-logo-design',
      target: '_blank',
    },
  },
  {
    title: 'Website Design',
    description:
      'Get a beautiful website design that people love to engage with',
    icon: '@/images/icons/marketplace/fiverr.svg',
    cta: 'Learn more',
    redirect: {
      to: 'https://partner.fiverr.com/visit/?bta=56213&brand=fiverrcpa&utm_campaign=webdesign_store&landingPage=https%3A%2F%2Fwww.fiverr.com%2Fcategories%2Fgraphics-design%2Fwebsite-design',
      target: '_blank',
    },
  },
  {
    title: 'Website Development',
    description:
      'Find a developer to improve your website or build one from scratch',
    icon: '@/images/icons/marketplace/fiverr.svg',
    cta: 'Learn more',
    redirect: {
      to: 'https://partner.fiverr.com/visit/?bta=56213&brand=fb&utm_campaign=webdev_store&landingPage=https%3A%2F%2Fbusiness.fiverr.com%2Fsearch%2Fgigs%3Fquery%3Dwebsite%2520development%26source%3Dtop-bar%26search_in%3Deverywhere%26search-autocomplete-original-term%3Dwebsite%2520development%26expert_listings%3Dfalsehttps://partner.fiverr.com/visit/?bta=56213&brand=fb&utm_campaign=webdev_store&landingPage=https%3A%2F%2Fbusiness.fiverr.com%2Fsearch%2Fgigs%3Fquery%3Dwebsite%2520development%26source%3Dtop-bar%26search_in%3Deverywhere%26search-autocomplete-original-term%3Dwebsite%2520development%26expert_listings%3Dfalse',
      target: '_blank',
    },
  },
  {
    title: 'Web Programming',
    description:
      'Add features to your website with custom web applications and extensions',
    icon: '@/images/icons/marketplace/fiverr.svg',
    cta: 'Learn more',
    redirect: {
      to: 'https://partner.fiverr.com/visit/?bta=56213&brand=fb&utm_campaign=webprogramming_store&landingPage=https%3A%2F%2Fbusiness.fiverr.com%2Fcategories%2Fprogramming-tech%2Fweb-programming-services%3Fsource%3Dcategory_tree',
      target: '_blank',
    },
  },
  {
    title: 'SEO',
    description: 'Rank high in search engines and get more visitors with SEO',
    icon: '@/images/icons/marketplace/fiverr.svg',
    cta: 'Learn more',
    redirect: {
      to: 'https://partner.fiverr.com/visit/?bta=56213&brand=fb&utm_campaign=seo_store&landingPage=https%3A%2F%2Fbusiness.fiverr.com%2Fcategories%2Fonline-marketing%2Fseo-services%3Fsource%3Dcategory_tree',
      target: '_blank',
    },
  },
  {
    title: 'Articles & BlogPosts',
    description: 'Keep your readers engaged with fresh content',
    icon: '@/images/icons/marketplace/fiverr.svg',
    cta: 'Learn more',
    redirect: {
      to: 'https://partner.fiverr.com/visit/?bta=56213&brand=fb&utm_campaign=articlesblogs_store&landingPage=https%3A%2F%2Fbusiness.fiverr.com%2Fcategories%2Fwriting-translation%2Farticles-blogposts%3Fsource%3Dcategory_tree',
      target: '_blank',
    },
  },
  {
    title: 'Web Speed Optimization',
    description:
      'A strong, technical SEO foundation ensures your site’s properties perform flawlessly',
    icon: '@/images/icons/marketplace/fiverr.svg',
    cta: 'Learn more',
    redirect: {
      to: 'https://partner.fiverr.com/visit/?bta=56213&brand=fb&utm_campaign=webspeed_store&landingPage=https%3A%2F%2Fbusiness.fiverr.com%2Fsearch%2Fgigs%3Fquery%3Dwebsite%2520speed%2520optimization%26source%3Dtop-bar%26acmpl%3D1%26search_in%3Deverywhere%26search-autocomplete-original-term%3Dweb%2520speed%2520%26search-autocomplete-available%3Dtrue%26search-autocomplete-type%3Dsuggest%26search-autocomplete-position%3D6',
      target: '_blank',
    },
  },
  {
    title: 'Banner Design',
    description:
      "Skyrocket growth with banner ads customers can't help but click",
    icon: '@/images/icons/marketplace/fiverr.svg',
    cta: 'Learn more',
    redirect: {
      to: 'https://partner.fiverr.com/visit/?bta=56213&brand=fb&utm_campaign=banners_store&landingPage=https%3A%2F%2Fbusiness.fiverr.com%2Fsearch%2Fgigs%3Fquery%3Dbanner%2520design%26source%3Dtop-bar%26acmpl%3D1%26search_in%3Deverywhere%26search-autocomplete-original-term%3Dbanner%2520%26search-autocomplete-available%3Dtrue%26search-autocomplete-type%3Dsuggest%26search-autocomplete-position%3D2',
      target: '_blank',
    },
  },
];

export const OTHER_PRODUCTS: IMarketplaceProduct[] = [
  {
    title: 'NordLocker - Private File Vault',
    description:
      'Encrypt personal and business data with NordLocker — your private file vault. Your files are protected, backed up, and always within reach.',
    category: 'other',
    label: 'NEW',
    icon: '@/images/icons/marketplace/nordlocker.svg',
    redirect: {
      to: 'https://nordlocker.com/special/?utm_campaign=off489&utm_content&utm_term&utm_medium=affiliate&utm_source=aff39917',
      target: '_blank',
    },
    actionAmplitudeEvent: AmplitudeEvent.Marketplace.NEW_OFFER_SHOWN,
    actionAmplitudeParams: {
      location: Route.Base.MARKETPLACE,
      product: AmplitudeParam.Product.NORD_LOCKER,
    },
    cta: 'Get now',
  },
  {
    title: 'NordVPN',
    description:
      'Your search for the world’s most advanced Virtual Private Network ends here.',
    category: 'other',
    icon: '@/images/icons/marketplace/nordvpn.svg',
    cta: 'Get now',
    redirect: {
      to: 'https://go.nordvpn.net/aff_c?offer_id=15&aff_id=325&source=hostinger_panel',
      target: '_blank',
    },
    actionAmplitudeEvent: AmplitudeEvent.Marketplace.NEW_OFFER_SHOWN,
    actionAmplitudeParams: {
      location: Route.Base.MARKETPLACE,
      product: AmplitudeParam.Product.NORDVPN,
    },
  },
  {
    title: 'NordPass - Password Manager',
    description:
      'Tired of resetting passwords? Take care of your passwords safety with a free and easy to use tool.',
    category: 'other',
    icon: '@/images/icons/marketplace/nordpass.svg',
    cta: 'Get now',
    redirect: {
      to: 'https://go.nordpass.io/aff_c?offer_id=571&aff_id=39917&url_id=11744&aff_sub=PowerStore',
      target: '_blank',
    },
    actionAmplitudeEvent: AmplitudeEvent.Marketplace.NEW_OFFER_SHOWN,
    actionAmplitudeParams: {
      location: Route.Base.MARKETPLACE,
      product: AmplitudeParam.Product.NORDPASS_PASSWORD_MANAGER,
    },
  },
  {
    title: 'Whatagraph Data Analytics',
    description:
      'Use Whatagraph to track campaign results across different channels. Build customizable visual reports and let data insights lead the way.',
    category: 'other',
    icon: '@/images/icons/marketplace/whatagraph.svg',
    cta: 'Get now',
    redirect: {
      to: 'https://whatagraph.com/?utm_source=affiliate&utm_medium=hostinger&utm_campaign=tst',
      target: '_blank',
    },
    actionAmplitudeEvent: AmplitudeEvent.Marketplace.NEW_OFFER_SHOWN,
    actionAmplitudeParams: {
      location: Route.Base.MARKETPLACE,
      product: AmplitudeParam.Product.WHATAGRAPH_DATA_ANALYTICS,
    },
  },
];

export const MARKETPLACE_CROSSSELL_CARDS: CrossSellCardData[] = [
  {
    id: 'web-hosting',
    type: 'simple',
    title: 'Web hosting',
    isActive: true,
    subtitle:
      'Host and manage small and medium size websites on the same server using our web hosting solution',
    actionText: 'Explore offers',
    label: { text: 'Save 80%', theme: 'primary' },
    buttonOptions: {
      isOutlined: false,
    },
    redirect: {
      to: {
        name: Route.Base.BUY_HOSTING,
      },
      amplitudeParams: {
        location: Route.Base.MARKETPLACE,
        product: AmplitudeParam.Product.WEB_HOSTING,
      },
    },
    imageUrl: `@/images/illustrations/CrossSell/webHostingCrossSell.svg`,
  },
  {
    id: 'cloud-hosting',
    type: 'simple',
    title: 'Cloud Hosting',
    subtitle:
      'Safe and trustworthy, our cloud hosting is an ideal choice for large-scale websites or other projects',
    actionText: 'Explore offers',
    label: { text: 'Save 70%', theme: 'primary' },
    buttonOptions: {
      isOutlined: false,
    },
    redirect: {
      to: {
        name: Route.Base.BUY_HOSTING,
        query: {
          tab: Hosting.HostingTab.CLOUD,
        },
      },
      amplitudeParams: {
        location: Route.Base.MARKETPLACE,
        product: AmplitudeParam.Product.CLOUD_HOSTING,
      },
    },
    imageUrl: `@/images/illustrations/CrossSell/cloudHostingCrossSell.svg`,
  },
  {
    id: 'vps-hosting',
    type: 'simple',
    title: 'VPS Hosting',
    subtitle:
      'Take full control over your server. Our virtual private servers offer great power, flexibility, and speed.',
    actionText: 'Explore offers',
    label: { text: 'Save 80%', theme: 'primary' },
    buttonOptions: {
      isOutlined: false,
    },
    redirect: {
      to: {
        name: Route.Base.BUY_VPS,
      },
      amplitudeParams: {
        location: Route.Base.MARKETPLACE,
        product: AmplitudeParam.Product.VPS_HOSTING,
      },
    },
    imageUrl: `@/images/illustrations/CrossSell/vpsHostingCrossSell.svg`,
  },
];

export const MARKETPLACE_NAVIGATION = [
  {
    name: Route.Base.MARKETPLACE,
    path: '',
    title: 'All',
  },
  {
    name: Route.Marketplace.HOSTING,
    path: 'hosting',
    title: 'Hosting',
  },
  {
    name: Route.Marketplace.DOMAIN,
    path: 'domain',
    title: 'Domain',
  },
  {
    name: Route.Marketplace.EMAIL,
    path: 'email',
    title: 'Email',
  },
  {
    name: Route.Marketplace.SERVICES,
    path: 'services',
    title: 'Services',
  },
  {
    name: Route.Marketplace.OTHER,
    path: 'other',
    title: 'Other',
  },
];
