import type { ModalObject } from '@/types';

export default [
  {
    name: 'DomainAddedModal',
    type: 'HModal',
    props: {
      hideX: false,
    },
  },
  {
    name: 'GeneralModal',
    type: 'HModal',
    closingType: 'input',
    props: {
      hideX: true,
    },
  },
  {
    name: 'AddCustomWidgetModal',
    type: 'HModal',
    props: {
      w800: true,
      noHeaderMargin: true,
      hideX: true,
      customPadding: '24px 24px 40px 24px',
    },
  },
  {
    name: 'WhoisPrivacyModal',
    type: 'HModal',
    steps: [
      {
        title: 'Buy WHOIS Privacy',
        subtitle: 'Hide personal information of your domain',
        hideX: true,
      },
    ],
  },
  {
    name: 'DomainVerificationModal',
    type: 'HModalFancy',
    props: {
      title: 'Domain verification needed',
      imgSrc: 'alert_full.svg',
    },
  },
  {
    name: 'TitanMailModal',
    type: 'HModal',
    steps: [
      {
        title: 'Upgrade to Premium Email Plan',
        subtitle:
          'Email hosting is a unique solution focused primarily on email servers. This service works independently from website hosting.',
        hideX: true,
        noTrans: false,
      },
      {
        title: 'Select Plan Duration',
        subtitle: 'Choose a billing period and finish the checkout',
        hideX: true,
        noTrans: false,
        goBackText: 'Back to plan selection',
      },
      {
        hideX: true,
      },
    ],
  },
  {
    name: 'UpPositionModal',
    type: 'HModal',
  },
  {
    name: 'RedirectModal',
    type: 'HModal',
  },
  {
    name: 'HostingPurchaseModal',
    type: 'HModal',
    steps: [
      {
        title: 'Select Plan Duration',
        subtitle: 'Choose a billing period and finish the checkout',
        hideX: true,
        noTrans: false,
      },
      {
        title: '',
        subtitle: '',
        hideX: true,
        noTrans: false,
        persistent: true,
      },
    ],
  },
  {
    name: 'LifetimeSslPurchaseModal',
    type: 'HModal',
    steps: [
      {
        title: 'Purchase SSL',
        subtitle: '',
        hideX: true,
        noTrans: false,
      },
    ],
  },

  {
    name: 'DeleteMigrationModal',
    type: 'HModalFancy',
    props: {
      title: 'Delete migration request?',
      imgSrc: 'modal/modal-trash.svg',
    },
  },
  {
    name: 'MigrationInfoModal',
    type: 'HModal',
    closingType: 'notification',
    props: {
      title: 'More Information About Migration',
      subtitle: 'Notes from the migration team about your migration request',
      hideX: true,
    },
  },
  {
    name: 'MigrationConnectionModal',
    type: 'HModal',
    closingType: 'notification',
    props: {
      hideX: true,
    },
  },
  {
    name: 'LoaderModal',
    type: 'HModal',
    steps: [
      {
        hideX: true,
        noHeaderMargin: true,
        m: true,
      },
    ],
  },
  {
    name: 'OverrideDirectoryModal',
    type: 'HModal',
    steps: [
      {
        title: 'Chosen Website Already Has Files',
        hideX: true,
        noHeaderMargin: true,
      },
    ],
  },

  {
    name: 'SslInstallSetupModal',
    type: 'HModalFancy',
    steps: [
      {
        title: 'Install SSL Certificate',
        imgSrc: 'icons/power-store/products/SslInstall.svg',
      },
      {
        title: 'SSL will be automatically installed!',
        imgSrc: 'icons/power-store/products/SslInstall.svg',
      },
      {
        title: 'SSL is successfully installed!',
        imgSrc: 'icons/power-store/products/SslInstall.svg',
      },
      {
        title: 'SSL is being installed',
        imgSrc: 'icons/power-store/products/SslInstall.svg',
      },
    ],
  },
  {
    name: 'NoActiveHostingPlan',
    type: 'HModalFancy',
    steps: [
      {
        title: 'No active hosting plan found!',
        imgSrc: 'error_full.svg',
      },
    ],
  },
  {
    name: 'MaybeWeCanHelpModal',
    type: 'HModalFancy',
    steps: [
      {
        title: 'Maybe we can help?',
        imgSrc: 'deactivateAccount/we_can_help.svg',
      },
    ],
  },
  {
    name: 'DomainNotPointingSsl',
    type: 'HModalFancy',
    steps: [
      {
        title: 'Your domain is not pointing to our nameservers',
        imgSrc: 'alert_full.svg',
      },
    ],
  },
  {
    name: 'SadToGoModal',
    type: 'HModalFancy',
    steps: [
      {
        title: 'We’re sad to see you go!',
        imgSrc: 'deactivateAccount/broken_heart.svg',
      },
    ],
  },
  {
    name: 'ErrorOccurred',
    type: 'HModalFancy',
    steps: [
      {
        title: 'Unexpected error occurred!',
        imgSrc: 'alert_full.svg',
      },
    ],
  },
  {
    name: 'CancelHostingSuccessModal',
    type: 'HModalFancy',
    steps: [
      {
        title: 'Your hosting account has been cancelled!',
        imgSrc: 'deactivateAccount/hosting_cancel_success.svg',
      },
    ],
  },
  {
    name: 'SadToGoModal',
    type: 'HModalFancy',
    steps: [
      {
        title: 'We’re sad to see you go!',
        imgSrc: 'deactivateAccount/broken_heart.svg',
      },
    ],
  },
  {
    name: 'RefundRequestCreatedModal',
    type: 'HModalFancy',
    steps: [
      {
        title: 'Your refund request has been created!',
        imgSrc: 'deactivateAccount/hosting_cancel_success.svg',
      },
    ],
  },
  {
    name: 'AddonDeletedSuccessModal',
    type: 'HModalFancy',
    steps: [
      {
        title: 'Your addon domain has been deleted!',
        imgSrc: 'deactivateAccount/addon_delete_success.svg',
      },
    ],
  },
  {
    name: 'BackupInfoModal',
    type: 'HModalFancy',
    subtypes: {
      databases: {
        title: 'Restoring database',
        subtitle:
          'Your selected database is currently being restored. This process can take up to 15 minutes. You will see a notification in the backups section once the process is done!',
        imgSrc: 'restore-primary.svg',
      },
      files: {
        title: 'Restoring files',
        subtitle:
          'Your selected files are currently being restored. This process can take up to 15 minutes. You will see a notification in the backups section once the process is done!',
        imgSrc: 'restore-primary.svg',
      },
      download: {
        title: 'Preparing download',
        subtitle:
          'Download file is currently being prepared. This process can take up to few minutes.',
        imgSrc: 'restore-primary.svg',
      },
      generate: {
        title: 'Generate a new backup?',
        subtitle:
          'We will start generating a new backup of your current files and databases. Keep in mind you can only generate one new backup in 24 hours.',
        imgSrc: 'restore-primary.svg',
      },
    },
  },
  {
    name: 'AddWebsiteRankingCoachModal',
    type: 'HModal',
    steps: [
      {
        title: 'Enter Website Name for SEO Marketing Panel',
        hideX: true,
        noHeaderMargin: true,
      },
    ],
  },
  {
    name: 'WordpressUpdateModal',
    type: 'HModalFancy',
    steps: [
      {
        title: 'Manage WordPress Update Settings',
        subtitle:
          'Update process affects all files and folders included in the main WordPress installation. This includes all the core files used to run WordPress.',
        imgSrc: 'icons/wordpress_white.svg',
        noTrans: false,
      },
    ],
  },
  {
    name: 'DomainNotPointing',
    type: 'HModalFancy',
    steps: [
      {
        title: 'Domain is not pointing to our nameservers',
        imgSrc: 'alert_full.svg',
      },
    ],
  },
  {
    name: 'WhoIsSetUsageModal',
    type: 'HModal',
    steps: [
      {
        title: '',
        subtitle: '',
        hideX: true,
        noTrans: false,
      },
    ],
  },
  {
    name: 'WhoIsDeleteConfirmationModal',
    type: 'HModal',
    steps: [
      {
        title: 'Delete contact profile?',
        subtitle: '',
        hideX: true,
        noTrans: false,
      },
    ],
  },
  {
    name: 'WhoIsEditConfirmationModal',
    type: 'HModal',
    steps: [
      {
        title: 'Are you sure you want to save changes?',
        subtitle: '',
        hideX: true,
        noTrans: false,
      },
    ],
  },
  {
    name: 'WhoIsUsageModal',
    type: 'HModal',
    steps: [
      {
        title: 'Profile is being used with the following domains:',
        subtitle: '',
        hideX: true,
        noTrans: false,
      },
    ],
  },
  {
    name: 'DeleteModal',
    type: 'HModal',
    closingType: 'input',
    props: {
      hideX: true,
    },
  },
  {
    name: 'EditFirewallRuleModal',
    type: 'HModal',
    closingType: 'input',
    props: {
      hideX: true,
      title: 'Edit Firewall Rule',
    },
  },
  {
    name: 'ConfirmRegistrationEmail',
    type: 'HModalFancy',
    steps: [
      {
        title: 'Confirm your email address',
        imgSrc: 'modal/modal-question.svg',
      },
    ],
  },
  {
    name: 'ExistingDomainModal',
    type: 'HModal',
    steps: [
      {
        title: 'Existing Domain Profile Found',
        subtitle:
          'Save your time by selecting the profile and avoid filling the similar information',
        hideX: true,
      },
    ],
  },
  {
    name: 'CreateSnapshotModal',
    type: 'HModalFancy',
    subtype: 'Hvps',
    steps: [
      {
        title: 'Create a new Snapshot?',
        imgSrc: 'modalFancy/create.svg',
      },
    ],
  },
  {
    name: 'RestoreSnapshotModal',
    type: 'HModalFancy',
    subtype: 'Hvps',
    steps: [
      {
        title: 'Restore Snapshot?',
        imgSrc: 'modalFancy/restore.svg',
      },
    ],
  },
  {
    name: 'DeleteSnapshotModal',
    type: 'HModalFancy',
    subtype: 'Hvps',
    steps: [
      {
        title: 'Delete Snapshot?',
        imgSrc: 'modalFancy/delete.svg',
      },
    ],
  },
  {
    name: 'RestoreBackupModal',
    type: 'HModalFancy',
    subtype: 'Hvps',
    steps: [
      {
        title: 'Restore Backup?',
        imgSrc: 'modalFancy/restore.svg',
      },
    ],
  },
  {
    name: 'SetPtrModal',
    type: 'HModal',
    subtype: 'Hvps',
    steps: [
      {
        title: 'v2.vps.ptr_modal.title',
        subtitle: 'v2.vps.ptr_modal.subtitle',
        hideX: true,
      },
    ],
  },
  {
    name: 'EmergencyModeModal',
    type: 'HModal',
    subtype: 'Hvps',
  },
  {
    name: 'OsDetailModal',
    type: 'HModal',
    subtype: 'Hvps',
    steps: [
      {
        hideX: true,
      },
    ],
  },
  {
    name: 'AddSshKeyModal',
    type: 'HModalFancy',
    subtype: 'Hvps',
    steps: [
      {
        title: 'Add SSH key',
        imgSrc: 'modalFancy/key.svg',
      },
    ],
  },
  {
    name: 'DeleteSshKeyModal',
    type: 'HModalFancy',
    subtype: 'Hvps',
    steps: [
      {
        title: 'Delete SSH key',
        imgSrc: 'modalFancy/delete.svg',
      },
    ],
  },
  {
    name: 'VpsRenewOrUpgradeModal',
    type: 'HModal',
    subtype: 'Hvps',
    steps: [
      {
        title: 'Renew your Daily Backups',
        subtitle: 'Choose a billing period and finish the renew process',
        hideX: true,
      },
      { hideX: true, noTrans: true },
    ],
  },
  {
    name: 'SslInstalledModal',
    type: 'HModalFancy',
    steps: [
      {
        title: 'SSL is successfully installed!',
        imgSrc: 'check-green.svg',
      },
    ],
  },
  {
    name: 'SslWillBeInstalledModal',
    type: 'HModalFancy',
    steps: [
      {
        title: 'SSL is being installed',
        imgSrc: 'check-green.svg',
      },
    ],
  },
  {
    name: 'SslAutoInstall',
    type: 'HModalFancy',
    steps: [
      {
        title: 'SSL will be automatically installed!',
        imgSrc: 'icons/power-store/products/SslInstall.svg',
      },
    ],
  },
  {
    name: 'SslUninstallModal',
    type: 'HModalFancy',
    steps: [
      {
        title: 'Uninstall SSL?',
        imgSrc: 'modalFancy/fancyLock.svg',
      },
    ],
  },
  {
    name: 'SslReinstallModal',
    type: 'HModalFancy',
    steps: [
      {
        title: 'SSL installation failed',
        imgSrc: 'error_full.svg',
      },
    ],
  },
  {
    name: 'RestoreConfirmModal',
    type: 'HModalInputs',
    steps: [
      {
        icon: 'ic_refresh.svg',
        noBackground: true,
      },
    ],
  },
  {
    name: 'ReferralsTermsModalDeprecated',
    type: 'HModalFancy',
    steps: [
      {
        noBackground: true,
        noPadding: true,
      },
    ],
  },
  {
    name: 'TransferTermsModal',
    type: 'HModalFancy',
    steps: [
      {
        noBackground: true,
        noPadding: true,
      },
    ],
  },
  {
    name: 'NameserversChangedModal',
    type: 'HModalFancy',
    steps: [
      {
        title: 'Nameservers changed!',
        subtitle: 'Your nameservers has been changed to:',
        imgSrc: 'check-green.svg',
      },
    ],
  },
  {
    name: 'ResetDNSRecordsModal',
    type: 'HModalFancy',
    steps: [
      {
        title: 'Are you sure to reset DNS?',
        subtitle:
          'Your DNS settings will be set to default values. Your website might not be reachable during DNS propagation',
        imgSrc: 'modal/modal-warning-circle.svg',
      },
    ],
  },
  {
    name: 'WordpressDetectionModal',
    type: 'HModalFancy',
    steps: [
      {
        title: 'WordPress detection requested',
        imgSrc: 'check-green.svg',
      },
    ],
  },
  {
    name: 'ReferralTermsModal',
    type: 'HModalFancy',
    steps: [
      {
        noBackground: true,
        noPadding: true,
      },
    ],
  },
  {
    name: 'ReferralTermsModal',
    type: 'HModalFancy',
    steps: [
      {
        noBackground: true,
        noPadding: true,
      },
    ],
  },
  {
    name: 'NewAddonsViewModal',
    type: 'HModalFancy',
    steps: [
      {
        title: 'New addon views',
        imgSrc: 'modal/modal-warning-circle.svg',
        noTrans: false,
      },
    ],
  },
  {
    name: 'PhpVersionWarningModal',
    type: 'HModalFancy',
    steps: [
      {
        title: 'Change PHP version to 8.0',
        imgSrc: 'modal/modal-warning-circle.svg',
        noTrans: false,
      },
    ],
  },
  {
    name: 'ManageUniversalSslModal',
    type: 'HModal',
    subtype: 'Hosting',
    steps: [
      {
        hideX: true,
        lg: true,
      },
    ],
  },
  {
    name: 'DeleteApplicationModal',
    type: 'HModal',
    subtype: 'Hosting',
  },
  {
    name: 'CreateStagingEnvironmentModal',
    type: 'HModal',
    subtype: 'Hosting',
  },
  {
    name: 'LimitsStagingEnviromentModal',
    type: 'HModal',
    subtype: 'Hosting',
  },
  {
    name: 'DeleteStagingEnviromentModal',
    type: 'HModal',
    subtype: 'Hosting',
  },
  {
    name: 'PublishStagingEnviromentModal',
    type: 'HModal',
    subtype: 'Hosting',
  },
  {
    name: 'RevertStagingEnviromentModal',
    type: 'HModal',
    subtype: 'Hosting',
  },
  {
    name: 'WordpressPluginChangeModal',
    type: 'HModal',
    subtype: 'Hosting',
    steps: [
      {
        hideX: true,
        autoWidth: true,
      },
    ],
  },
  {
    name: 'WordpressVersionUpdateModal',
    type: 'HModal',
    subtype: 'Hosting',
    steps: [
      {
        hideX: true,
        autoWidth: true,
      },
    ],
  },
  {
    name: 'EnableLSCacheModal',
    type: 'HModal',
  },
  {
    name: 'AssignDomainToWebsite',
    type: 'HModal',
    subtype: 'Hosting',
  },
  {
    name: 'CreateSshKeyModal',
    type: 'HModal',
    subtype: 'Hosting',
  },
  {
    name: 'CronJobOutputModal',
    type: 'HModal',
    subtype: 'Hosting',
  },
  {
    name: 'UpdateSshKeyModal',
    type: 'HModal',
    subtype: 'Hosting',
  },
  {
    name: 'RemoveSshKeyModal',
    type: 'HModal',
    subtype: 'Hosting',
  },
  {
    name: 'RestoreCanceledPlanModal',
    type: 'HModal',
    subtype: 'Hosting',
    props: {
      hideX: true,
    },
  },
  {
    name: 'ChangeSshPasswordModal',
    type: 'HModal',
    subtype: 'Hosting',
  },
  {
    name: 'PhpExtensionsResetWarningModal',
    type: 'HModalFancy',
    steps: [
      {
        title: 'Reset PHP extentions?',
        imgSrc: 'modal/modal-warning-circle-primary.svg',
        noTrans: false,
      },
    ],
  },
  {
    name: 'ChangeComplexPasswordModalV2',
    type: 'HModal',
    props: {
      hideX: true,
      persistent: true,
    },
  },
  {
    name: 'ResetAccountModal',
    type: 'HModalFancy',
    steps: [
      {
        title: 'Reset account?',
        imgSrc: 'modalFancy/warning.svg',
      },
    ],
  },
  {
    name: 'StopProcessesModal',
    type: 'HModalFancy',
    steps: [
      {
        title: 'Stop running processes',
        imgSrc: 'modalFancy/warning.svg',
      },
    ],
  },
  {
    name: 'OutdatedNameserversModal',
    type: 'HModalFancy',
    steps: [
      {
        title: 'Hostinger nameservers change',
        imgSrc: 'modalFancy/warningYellow.svg',
      },
    ],
  },
  {
    name: 'PlannedMaintenanceModal',
    type: 'HModalFancy',
    steps: [
      {
        title: 'Planned Maintenance',
        imgSrc: 'modalFancy/history.svg',
      },
    ],
  },
  {
    name: 'MaintenanceInProgressModal',
    type: 'HModalFancy',
    steps: [
      {
        title: 'Maintenance in progress',
        imgSrc: 'modalFancy/timelapse.svg',
      },
    ],
  },
  {
    name: 'WordpressDetectionModal',
    type: 'HModalFancy',
    steps: [
      {
        title: 'WordPress detection requested',
        imgSrc: 'check-green.svg',
      },
    ],
  },
  {
    name: 'PatchstackIsAlreadyInstalledModal',
    type: 'HModalFancy',
    steps: [
      {
        title: 'Maintenance in progress',
        imgSrc: 'modalFancy/timelapse.svg',
      },
    ],
  },
  {
    name: 'HostingUpgradeCompareModal',
    type: 'HModal',
    steps: [
      {
        title: 'Feature comparison',
      },
    ],
  },
  {
    name: 'DomainMoveInitiateModal',
    type: 'HModal',
    props: {
      hideX: true,
      lg: true,
    },
  },
  {
    name: 'DomainMoveCancelationModal',
    type: 'HModal',
    props: {
      hideX: true,
      lg: true,
    },
  },
  {
    name: 'DomainNsFlowModal',
    type: 'HModal',
    props: {
      xxl: true,
    },
  },
  {
    name: 'DomainNsFlowResetModal',
    type: 'HModal',
    props: {
      hideX: true,
    },
  },
  {
    name: 'DomainNsFlowPreviewModal',
    type: 'HModal',
    props: {
      xxl: true,
    },
  },
  {
    name: 'VpsRemoveLimitationsModal',
    type: 'HModal',
    props: {
      hideX: true,
    },
  },
  {
    name: 'SalesCallModal',
    type: 'HModal',
  },
  {
    name: 'UpgradeOrRenewModal',
    type: 'HModal',
    props: {
      hideX: true,
      xl: true,
    },
  },
  {
    name: 'RequiredPasswordChangeModalV2',
    type: 'HModal',
    props: {
      hideX: true,
      persistent: true,
    },
  },
  {
    name: 'ChangeServerLocationModal',
    type: 'HModal',
  },
  {
    name: 'PlanBoostModal',
    type: 'HModal',
    props: {
      hideX: true,
      isAboveIntercom: true,
    },
  },
  {
    name: 'EmbeddedVideoModal',
    type: 'HModal',
    props: {
      persistent: true,
      hideX: true,
    },
  },
  {
    name: 'DeleteSubdomainModal',
    type: 'HModalFancy',
    steps: [
      {
        title: 'Delete Subdomain',
        imgSrc: 'modal/modal-trash.svg',
      },
    ],
  },
  {
    name: 'DomainNotPointingSubdomainsModal',
    type: 'HModalFancy',
    steps: [
      {
        title: 'Domain Is Not Pointing to Our Nameservers',
        imgSrc: 'modalFancy/warningOrange.svg',
      },
    ],
  },
  {
    name: 'ChangeServerLocationModal',
    type: 'HModalFancy',
  },
  {
    name: 'OptimizeDatabaseModal',
    type: 'HModalFancy',
    steps: [
      {
        title: 'Optimize Database',
        imgSrc: 'modalFancy/warningOrange.svg',
      },
    ],
  },
  {
    name: 'HostingUpgradeRecommendationModal',
    type: 'HModal',
    steps: [
      {
        hideX: true,
      },
      {
        hideX: true,
        goBackText: 'Back to feature comparison',
      },
    ],
  },
  {
    name: 'EmbeddedVideoModal',
    type: 'HModal',
    props: {
      persistent: true,
      hideX: true,
    },
  },
  {
    name: 'DisableAuthenticationModalV2',
    type: 'HModal',
    closingType: 'input',
  },
  {
    name: 'VpsBackupStatusManagement',
    type: 'HModal',
    subtype: 'Hvps',
    steps: [
      {
        title: 'Manage Automated Backups',
        subtitle: 'Choose how often the backups will be made',
        hideX: true,
      },
    ],
  },
  {
    name: 'VpsOsChangePasswordModal',
    type: 'HModal',
    subtype: 'Hvps',
    steps: [
      {
        title: 'Reset Panel Password',
        subtitle:
          'Reset your VPS control panel password by creating a new password',
        hideX: true,
      },
    ],
  },
  {
    name: 'ChangeVpsServerLocationModal',
    type: 'HModal',
  },
  {
    name: 'VpsOsChangeModal',
    type: 'HModal',
    subtype: 'Hvps',
  },
  {
    name: 'VpsRestoreCanceledPlanModal',
    type: 'HModal',
    subtype: 'Hvps',
    props: {
      hideX: true,
    },
  },
  {
    name: 'ChangeHostnameModal',
    type: 'HModal',
    subtype: 'Hvps',
    steps: [
      {
        title: 'Change Hostname',
        subtitle:
          "Once you've changed your hostname, you won't be able to revert it back to default. Example for hostname: <strong>servername.domain.tld</strong>",
      },
    ],
  },
  {
    name: 'ChangeDataCenterModal',
    type: 'HModal',
    subtype: 'Hvps',
    steps: [
      {
        title: 'Change Server Location',
        subtitle: 'Choose your preferred server location',
        hideX: true,
      },
    ],
  },
  {
    name: 'ChangeOsModal',
    type: 'HModal',
    subtype: 'Hvps',
    closingType: 'input',
  },
  {
    name: 'DeleteLogsModal',
    type: 'HModal',
    subtype: 'Hvps',
    steps: [
      {
        title: 'Delete Unnecessary Logs',
        subtitle:
          'Deletion of logs should not affect your website. The files will be permanently deleted from <strong>/var/log</strong> folder.',
      },
    ],
  },
  {
    name: 'RemoveDomainModal',
    type: 'HModal',
    subtype: 'Hvps',
    steps: [
      {
        title: 'Remove Domain',
        subtitle:
          'Removing the domain that is connected to your VPS may cause your website to become inaccessible',
      },
    ],
  },
  {
    name: 'HvpsLicenseModal',
    type: 'HModal',
  },
  {
    name: 'AutoRefundDisableRenewalModal',
    type: 'HModal',
    props: {
      title: 'Disable Auto-Renewal for Upcoming Charges',
      persistent: true,
      hideX: true,
    },
  },
  {
    name: 'RefundIsAlreadyRequestedModal',
    type: 'HModal',
    props: {
      title: 'Refund is Already Requested',
      persistent: true,
    },
  },
  {
    name: 'PlanSelectionModal',
    type: 'HModal',
    props: {
      title: 'Select a Hosting Plan',
      subtitle:
        'Select to which hosting plan you would like to add a new website',
      persistent: true,
      hideX: true,
    },
  },
  {
    name: 'PlanChangeSelectionModal',
    type: 'HModal',
    props: {
      title: 'Select a Hosting Plan',
      subtitle: 'Select hosting plan for seamless website transfer',
      persistent: true,
      hideX: true,
    },
  },
  {
    name: 'InvoiceNonRefundableModal',
    type: 'HModal',
    closingType: 'input',
    props: {
      persistent: true,
      hideX: true,
    },
  },
  {
    name: 'SelectProductModal',
    type: 'HModal',
  },
  {
    name: 'VpsOsChangeModal',
    type: 'HModal',
    subtype: 'Hvps',
  },
  {
    name: 'DataLossModal',
    type: 'HModal',
    subtype: 'Hosting',
    closingType: 'confirmation',
    props: {
      hideX: true,
    },
  },
  {
    name: 'BlockIPAddressModal',
    type: 'HModal',
    subtype: 'Hosting',
    closingType: 'confirmation',
    props: {
      hideX: true,
    },
  },
  {
    name: 'BlockCountryModal',
    type: 'HModal',
    subtype: 'Hosting',
    closingType: 'confirmation',
    props: {
      hideX: true,
    },
  },
  {
    name: 'FullRestoreModal',
    type: 'HModal',
    subtype: 'Hosting',
    closingType: 'confirmation',
    props: {
      hideX: true,
    },
  },
  {
    name: 'SiteCleanupInfoModal',
    type: 'HModal',
    subtype: 'Hosting',
    closingType: 'confirmation',
    props: {
      hideX: true,
    },
  },
  {
    name: 'PostBlackFridayDealsModal',
    type: 'HModal',
    closingType: 'notification',
    props: {
      hideX: true,
      title: 'Discover the Best Deals',
    },
  },
  {
    name: 'DomainAutoConnectLoaderModal',
    type: 'HModal',
    closingType: 'input',
    props: {
      hideX: true,
      persistent: true,
    },
  },
  {
    name: 'CPanelWordpressUpdateSettingsModal',
    type: 'HModal',
    subtype: 'Cpanel',
    closingType: 'input',
    props: {
      title: 'Manage WordPress Update Settings',
      subtitle:
        'Update process affects all files and folders included in the main WordPress installation. This includes all the core files used to run WordPress.',
      hideX: true,
      persistent: true,
    },
  },
  {
    name: 'CPanelInstallWordPressModal',
    type: 'HModal',
    subtype: 'Cpanel',
    props: {
      hideX: true,
      xl: true,
    },
  },
  {
    name: 'ADnsRecordModal',
    type: 'HModal',
    closingType: 'confirmation',
    props: {
      hideX: true,
    },
  },
  {
    name: 'ExpirationReminderModal',
    type: 'HModal',
    closingType: 'confirmation',
    props: {
      title: 'Service Expiration Reminders via WhatsApp and SMS',
      hideX: true,
    },
  },
  {
    name: 'ChangePayoutMethodModal',
    type: 'HModal',
    subtype: 'Referrals',
    closingType: 'confirmation',
  },
  {
    name: 'ChangeReferralLinkModal',
    type: 'HModal',
    subtype: 'Referrals',
    closingType: 'input',
    steps: [
      {
        hideX: true,
      },
    ],
  },
  {
    name: 'FirewallCreateOrEditModal',
    type: 'HModal',
    subtype: 'Hvps',
    closingType: 'input',
    props: {
      title: 'Create Firewall Configuration',
      hideX: true,
    },
  },
  {
    name: 'GenerateBackupFilesModal',
    type: 'HModal',
    subtype: 'Hosting',
    closingType: 'confirmation',
    props: {
      hideX: true,
    },
  },
  {
    name: 'SshKeyCreateOrEditModal',
    type: 'HModal',
    subtype: 'Hvps',
    closingType: 'input',
    props: {
      hideX: true,
      xxl: true,
    },
  },
  {
    name: 'ShowSshKeyModal',
    type: 'HModal',
    subtype: 'Hvps',
    props: {
      hideX: true,
    },
  },
  {
    name: 'MigrationRequirementsModal',
    type: 'HModal',
    closingType: 'confirmation',
    props: {
      hideX: true,
    },
  },
  {
    name: 'WebsiteMonitoringEmailNotificationModal',
    type: 'HModal',
    closingType: 'confirmation',
    props: {
      title: 'Would You Like To Enable Email Notifications?',
      hideX: true,
    },
  },
  {
    name: 'LiteSpeedSettingsModal',
    type: 'HModal',
    closingType: 'confirmation',
    props: {
      hideX: true,
    },
  },
  {
    name: 'MigrationHostingSelectModal',
    type: 'HModal',
    closingType: 'confirmation',
    props: {
      hideX: true,
    },
  },
  {
    name: 'MigrationHowMigrationWorksModal',
    type: 'HModal',
    closingType: 'confirmation',
    props: {
      hideX: true,
    },
  },
  {
    name: 'MigrationUploadFilesModal',
    type: 'HModal',
    closingType: 'confirmation',
    props: {
      hideX: true,
      xxl: true,
    },
  },
  {
    name: 'TagsManagementModal',
    type: 'HModal',
    props: {
      m: true,
      hideX: true,
    },
  },
  {
    name: 'DCSelectionModalV2',
    type: 'HModal',
    closingType: 'input',
    props: {
      title: 'Select Your Server Location',
      hideX: true,
    },
  },
  {
    name: 'DataCenterChangeModal',
    type: 'HModal',
    closingType: 'input',
  },
  {
    name: 'LanguageSelectionModal',
    type: 'HModal',
    props: {
      w800: true,
      title: 'v2.language.selection.title',
      subtitle: 'v2.language.selection.subtitle',
    },
  },
  {
    name: 'OnboardingThemeSelectModal',
    type: 'HModal',
    props: {
      hideX: true,
      xxl: true,
    },
  },
  {
    name: 'ConnectUsedDomainToWebsiteModal',
    type: 'HModal',
    closingType: 'input',
    props: {
      hideX: true,
      persistent: true,
    },
  },
  {
    name: 'ChipListModal',
    type: 'HModal',
    props: {
      hideXMobile: true,
    },
  },
  {
    name: 'RequestAccessFormModal',
    type: 'HModal',
    props: {
      title: 'Summary',
    },
  },
  {
    name: 'TwoChoicePromptModal',
    type: 'HModal',
  },
  {
    name: 'VpsOnboardingSurveyModal',
    type: 'HModal',
    subtype: 'Hvps',
    closingType: 'input',
    props: {
      title: 'v2.vps_survey.modal_title',
      subtitle: 'v2.vps_survey.modal_subtitle',
      hideX: true,
    },
  },
  {
    name: 'BlackFridayPlanSelectionModal',
    type: 'HModal',
    props: {
      hideX: true,
    },
  },
  {
    name: 'SingleWebsiteAccessSharingFakeDoorModal',
    type: 'HModal',
    props: {
      hideX: true,
    },
  },
  {
    name: 'EditGrantedAccessModal',
    type: 'HModal',
    props: {
      hideX: true,
    },
  },
  {
    name: 'GrantAccessModal',
    type: 'HModal',
    props: {
      hideX: true,
    },
  },
] as ModalObject[];
