import cookies from 'js-cookie';
import { computed } from 'vue';

import { useBrands } from '@/composables/useBrands';
import { useFeatureFlag } from '@/composables/useFeatureFlag';
import { useGlobals } from '@/composables/useGlobals';
import { useLanguageStore } from '@/stores/languageStore';
import { useProfileStore } from '@/stores/profile/profileStore';
import type { TLanguageCode } from '@/types';
import {
  AVAILABLE_LANGUAGE_CONFIGURATIONS,
  Cookie,
  FeatureFlag,
  LANGUAGE_CODE,
} from '@/types';

const EN_US = 'en_US';

type TLanguageCodeWithUS = TLanguageCode | typeof EN_US;

export const useLanguages = () => {
  const { t } = useGlobals();
  const languageStore = useLanguageStore();
  const { isCoUkBrand } = useBrands();
  const profileStore = useProfileStore();
  const { isFeatureEnabled: isKoreanLanguageEnabled } = useFeatureFlag(
    FeatureFlag.ID.KOREAN_LANGUAGE,
  );

  const getCookieLanguage = () => {
    const languageFromCookie = cookies.get(Cookie.LANGUAGE);

    return languageFromCookie !== undefined &&
      (Object.values(LANGUAGE_CODE) as string[]).includes(languageFromCookie)
      ? (languageFromCookie as TLanguageCodeWithUS)
      : undefined;
  };

  const isEnglishWithoutBrand = (languageCode: TLanguageCodeWithUS) =>
    languageCode === LANGUAGE_CODE.en_GB && !isCoUkBrand.value;

  const getCurrentLanguageCode = () => {
    const languageCode =
      getCookieLanguage() || languageStore.currentLanguage || EN_US;

    if (isEnglishWithoutBrand(languageCode)) {
      return EN_US;
    }

    return languageCode;
  };

  const getLanguageByCode = (languageCode: TLanguageCodeWithUS) => {
    if ([EN_US, LANGUAGE_CODE.en_GB].includes(languageCode)) {
      return AVAILABLE_LANGUAGE_CONFIGURATIONS.find(
        ({ code }) => code === 'en_GB',
      );
    }

    return AVAILABLE_LANGUAGE_CONFIGURATIONS.find(
      ({ code }) => code === languageCode,
    );
  };

  const getTranslatedLanguageNameByCode = (
    languageCode: TLanguageCodeWithUS,
  ) => {
    if ([EN_US, LANGUAGE_CODE.en_GB].includes(languageCode)) {
      return t('v2.language.english');
    }

    return t(
      AVAILABLE_LANGUAGE_CONFIGURATIONS.find(
        ({ code }) => code === languageCode,
      )?.translationSlug,
    );
  };

  const getLanguageIcon = (languageCode: TLanguageCodeWithUS) =>
    isEnglishWithoutBrand(languageCode) ? EN_US : languageCode;

  const getLanguageIconPath = (languageCode: TLanguageCodeWithUS) =>
    `@/images/languageCodes/${getLanguageIcon(languageCode).toLowerCase()}.svg`;

  const isCurrentLanguageEnglish = () => {
    const currentLanguage = getCurrentLanguageCode();

    return [EN_US, LANGUAGE_CODE.en_GB].includes(currentLanguage);
  };

  const getAvailableLanguages = () => {
    if (!isKoreanLanguageEnabled.value) {
      return AVAILABLE_LANGUAGE_CONFIGURATIONS.filter(
        (language) => language.code !== LANGUAGE_CODE.ko_KR,
      );
    }

    return AVAILABLE_LANGUAGE_CONFIGURATIONS;
  };

  const isSlugSelectionEnabled = computed(
    () => profileStore.isStaff && cookies.get(Cookie.SHOW_SLUG_SELECTION),
  );

  return {
    getCurrentLanguageCode,
    getLanguageByCode,
    getTranslatedLanguageNameByCode,
    getLanguageIcon,
    getLanguageIconPath,
    isCurrentLanguageEnglish,
    isSlugSelectionEnabled,
    getAvailableLanguages,
  };
};
